import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RaiseRequestService {

  constructor(
    private http: HttpClient
  ) { }

  createTicket(ticketPayload: any) {
    return this.http.post(`${environment.apiURL}/partner/ticket`, ticketPayload)
  }

  ticketList(search: any, limit: number, page: number) {
    return this.http.get(`${environment.apiURL}/partner/ticket?search=${search}&limit=${limit}&page=${page}`)
  }

  ticketSubList(isPaid : boolean) {
    return this.http.get(`${environment.apiURL}/partner/ticket/sub?isPaid=${isPaid}`)
  }

  customerticketList(search: any, limit: number, page: number) {
    return this.http.get(`${environment.apiURL}/partner/customer/ticket?search=${search}&limit=${limit}&page=${page}`)
  }

  ticketDetail(id: any) {
    return this.http.get(`${environment.apiURL}/partner/ticket/details?id=${id}`)
  }

  replyToCustomer(replyPayload: any) {
    return this.http.post(`${environment.apiURL}/partner/reply`, replyPayload)
  }

  categoryList() {
    return this.http.get(`${environment.apiURL}/category`)
  }

  subCategoryList(categoryId: string) {
    return this.http.get(`${environment.apiURL}/subCategory?categoryId=${categoryId}`)
  }
}
