import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';

@Component({
  selector: 'app-reason-for-churn',
  templateUrl: './reason-for-churn.component.html',
  styleUrls: ['./reason-for-churn.component.scss']
})
export class ReasonForChurnComponent implements OnInit {

  @Input() partnerId!: any
  @Input() subId!: any
  remarkForm = new FormGroup({
    remark: new FormControl('', Validators.required)
  })
  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private subscriptionService: SubscriptionsService,
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    const remarkPayload = {
      remark: this.remarkForm.value.remark,
      partnerId: this.partnerId,
      subId: this.subId
    }
    this.subscriptionService.reasonForChurn(remarkPayload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message)
        this.bsModalRef.hide()
      }

    }, (err) => {
      this.toastr.error(err.error.message)

    })
  }
}
