<div class="container-fluid ps-0 pe-0">
    <div class="main-panel">
        <div class="row">
            <div class="col-md-12 ">
                <div class="card">
                    <div class="card-body class-padding">
                        <div class="px-0 text-start mt-1 mb-2 s-logo d-flex justify-content-center align-items-center">
                            <img src='../../../assets/images/comhard-logo.png'alt="logo" class="logo"/>
                          </div>
                        <p class="heading text-center ">Welcome to Comhard Technologies Pvt Ltd, your trusted partner in Cloud hosting services and Mobile distribution.</p>
                        <div class="row mt-4">
                            <p class="sub-heading">Our Mission</p>
                            <p class="text">At Comhard Technologies Pvt Ltd, our mission is to empower businesses with cutting-edge technology solutions that drive growth, efficiency, and innovation.</p>
                        </div>
                        <div class="row mt-2">
                            <p class="sub-heading">What We Offer</p>
                            <p class="text">We specialize in three main areas:</p>
                           <ul>
                            <li>1. 
                                Cloud Hosting Services: Our cloud hosting services ensure reliable, scalable, and secure infrastructure for businesses of all sizes. Whether you’re a startup or an enterprise, we have the right cloud solution for you.
                            </li>
                            <li>2. 
                                Mobile Distribution: As one of the leading mobile distributors in Delhi, we offer a comprehensive range of mobile devices from top brands such as Vivo, Oppo, Lava, and Jio. With more than 25 years of experience, we ensure unmatched service and support to meet your diverse mobile needs.
                            </li>
                            
                           
                           </ul>
                        </div>

                        <div class="row mt-2">
                            <p class="sub-heading">Why Choose Us?</p>
                            <p class="text">Comhard Technologies Pvt Ltd stands out for:</p>
                           <ul>
                            <li>1. 
                                Reliability: We prioritize reliability in everything we do, from the quality of our products and services to the support we provide to our clients.
                            </li>
                            <li>2. 
                                Cloud Hosting Services: Our cloud hosting services ensure reliable, scalable, and secure infrastructure for businesses of all sizes. Whether you’re a startup or an enterprise, we have the right cloud solution for you.
                            </li>
                            <li>3. 
                                Innovation: Innovation is at the core of our approach. We continuously explore new technologies and trends to deliver innovative solutions that keep our clients ahead of the curve.
                            </li>
                            <li>4.
                                Customer Satisfaction: Your satisfaction is our priority. We strive to build long-lasting relationships with our clients by delivering exceptional service and support at every step.

                            </li>
                           </ul>
                        </div>
                        <div class="row mt-4 text-muted">
                            <p class="sub-heading">Contact Us</p>
                            <p class="text-muted mb-1">Address : Embassy Galaxy Business Park, Sector 62, Noida, Uttar Pradesh 201309</p>
                            <p class="text-muted mb-1">Phone No : 08047299709</p>
                            <p class="text-muted mb-1">Email ID : contact@comhard.co.in</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>