import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class WalletService implements OnInit{

  constructor(
    private http : HttpClient
  ) { }

  ngOnInit(){
  }

  userWalletAmount : any;
  customLogo : any ;
  partnerType: any;

  walletAmountSubject = new BehaviorSubject<any>(this.showWalletAmount());
  logoSubject = new BehaviorSubject<any>(this.showWalletAmount())
  partnerTypeSubject = new BehaviorSubject<any>(this.showWalletAmount())

  getWalletDetails(){
    return this.http.get(`${environment.apiURL}/partner/profile`)
  }

  getDomainName(){
    return this.http.get(`${environment.apiURL}/partner/domain`)
  }


  showWalletAmount(){
    this.getWalletDetails().subscribe((res:any)=>{
      if(res.success){
        this.userWalletAmount = res?.partner?.wallet;
        this.customLogo = res?.partner?.logo;  
        this.partnerType=res?.partner?.partnerType;
        this.walletAmountSubject.next(this.userWalletAmount);
        this.logoSubject.next(this.customLogo);
        this.partnerTypeSubject.next(this.partnerType);
      }
    })
  }



}
