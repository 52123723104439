<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
    <div class="main-panel">
     <div class="content-wrapper">
      <div class="row">
       <div class="col-md-12 grid-margin">
        <div class="row">
         <div class="col-md-6">
          <h3 class="font-weight-bold">Accounts </h3>
          <span class="text-primary">Manage Label</span>
         </div>
         <!-- <div class="col-md-6">
          <div class="input-group mb-3">
           <span class="input-group-text comman-input" id="basic-addon1"><i class="bi bi-search"></i></span>
           <input type="text" class="form-control comman-input" placeholder="Search here.." aria-label="Username"
            aria-describedby="basic-addon1">
          </div>
         </div> -->
         <div class="col-md-6 text-end">
          <button type="button" class="comman-btn1 btn-primary" (click)="addLabelModal()">+ Label</button>
         </div>
        </div>
       </div>
       <!-- ------------------ -->
       <div class="row table-section pe-0">
        <div class="col-md-12 grid-margin stretch-card pe-0">
         <div class="card">
          <div class="card-body">
           <p class="card-title mb-0">Label Details</p>
           <div class="table-responsive">
            <table class="table table-striped table-borderless">
             <thead>
              <tr>
               <th scope="col">Label Name</th>
               <th scope="col" class="text-center">Number Of Accounts<i class="bi bi-arrow-down-up"></i></th>
               <th scope="col">Label Color<i class="bi bi-arrow-down-up"></i></th>
               <th scope="col" class="text-center">Action<i class="bi bi-arrow-down-up"></i></th>
              </tr>
             </thead>
             <tbody *ngFor="let list of labelList; let indexOfElement = index">
              <tr>
               <td>{{list.name | titlecase}}</td>
               <td class="text-center">{{list.count}}</td>
               <td>
                <div class="lebel-color-data"[ngStyle]="{ 'background-color' : list.color }">
                </div>
               </td>
               <td class="text-center">
                <!-- <div class="dropdown">
                 <a class="btn px-0 py-0" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown"
                  aria-expanded="false">
                  <i class="bi bi-three-dots-vertical"></i>
                 </a>
                 <ul class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <li><a class="dropdown-item" (click)="editLabelModal(list._id)"><i class="bi bi-pencil-fill text-primary"></i> Edit</a></li>
                  <li><a class="dropdown-item" (click)="openModal(list._id)"><i class="bi bi-trash3-fill text-danger"></i> Delete</a></li>
                 </ul>
                </div> -->
                <i class="bi bi-pencil-fill text-primary me-3 action-icon" (click)="editLabelModal(list._id)"></i>
                <i class="bi bi-trash3-fill text-danger action-icon" (click)="openModal(list._id)"></i>
                
               </td>
              </tr>             
             </tbody>
            </table>
           </div>
           <!-- <div class="mt-4 float-end me-4">
            <nav aria-label="Page navigation example">
             <ul class="pagination">
              <li class="page-item">
               <a class="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
               </a>
              </li>
              <li class="page-item"><a class="page-link" href="#">1</a></li>
              <li class="page-item"><a class="page-link" href="#">2</a></li>
              <li class="page-item"><a class="page-link" href="#">3</a></li>
              <li class="page-item">
               <a class="page-link" href="#" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
               </a>
              </li>
             </ul>
            </nav>
           </div> -->
          </div>
         </div>
        </div>
       </div>
       <!-- ----------- -->
      </div>
     </div>
    </div>