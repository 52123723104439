import {
  AfterContentInit,
  Component,
  DoCheck,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/services/login.service';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { UserService } from 'src/app/services/user.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.scss'],
})
export class ManageUsersComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
    private user: UserService,
    private toastr: ToastrService,
    private subscriptionService: SubscriptionsService,
    private walletService: WalletService,
  ) { }

  @Input() customerId!: string;
  @Input() subscriptionId!: string;
  @Input() subsId!: string;
  subscriptionStatus: boolean = false;
  subscriptionData: any;
  newUserEnable: boolean = true;
  showPrice: boolean = false;
  users!: number;
  priceData: any;
  currentAmount!: number;
  newAmount!: number;
  freePlan: boolean = false;
  subData: any
  salesPersonList: any
  connectedPerson: any
  event: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    setTimeout(() => {
      if (this.subscriptionId) {
        this.subscriptionStatus = true;
        this.selectedPlan();
      }
      this.salesPersonLists(this.subsId);
    });

  }

  changeUserForm = new FormGroup({
    noOfUsers: new FormControl(''),
    newUsers: new FormControl(''),
    salesPersonName: new FormControl('direct')
  });


  selectedPlan() {
    let freePlanName: string = '';
    this.subscriptionService.subscriptionDetails(this.subscriptionId).subscribe((res: any) => {
      if (res.success) {
        this.subData = res?.subscription
        freePlanName = res?.subscription?.plan?.name;
        this.changeUserForm.controls.noOfUsers.patchValue(
          res?.subscription?.plan?.noOfUsers
        );
        if (this.subscriptionId !== undefined) {
          this.newUserEnable = false;
        }
      }
    });
  }

  changeUsers() {
    this.users = Number(this.changeUserForm.controls.newUsers.value);
    if (this.subscriptionId === undefined) {
      this.toastr.warning('Select a plan first');
    } else if (this.changeUserForm.controls.newUsers.value === '') {
      this.changeUserForm.controls.newUsers.markAsTouched();
    } else if (this.changeUserForm.controls.newUsers.valid) {
      this.subscriptionService.newPrice(this.subData._id, this.users).subscribe(
        (res: any) => {
          if (res.success) {
            this.newUserEnable = true;
            this.showPrice = true;
            this.priceData = res?.data;
            this.currentAmount = res?.data?.currentPrice;
            this.newAmount = res?.data?.nextPlanPrice;
            this.event.emit('ok');
          }
        },
        (error) => {
          this.toastr.warning(error.error.message);
        }
      );
    }
  }

  changePlanUsers() {
    this.priceData.connectedPerson = this.changeUserForm.get('salesPersonName')?.value
    this.subscriptionService.changeSubscriptionUsers(this.priceData).subscribe(
      (res: any) => {
        if (res.success) {
          this.walletService.showWalletAmount();
          this.event.emit('ok');
          this.closedModal();
          this.toastr.info(res?.message);
        }
      },
      (error) => {
        this.toastr.warning(error.error.message);
      }
    );
  }

  closedModal() {
    this.bsModalRef.hide();
  }

  salesPersons(name: string){
    this.changeUserForm.controls.salesPersonName.setValue(name);
  }

  salesPersonLists(subscriptionId: any) {
    this.subscriptionService.subscriptionSalesPersonList(subscriptionId).subscribe((res: any) => {
      if (res.success) {
        this.salesPersonList = res.salesPersons
      }
    })
  }

}
