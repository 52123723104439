import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginWithAdminService {

  constructor(private http: HttpClient) { }

  verifyToken(userPayload: any) {
    return this.http.post(`${environment.apiURL}/partner/verify/token`, userPayload)
  }
}
