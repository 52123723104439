<!-- ----------subscription---list----page-- -->
<div class="main-panel">
    <div class="content-wrapper">
        <!-- <form [formGroup]="customerForm"> -->
        <div class="row search-form">
            <div class="col-md-12">
                <div class="row mb-2">
                    <div class="d-flex title mb-3">
                        <div>
                            <h3 class="mb-0 header">{{subDetail?.plan?.name | titlecase}}</h3>
                        </div>

                        <div *ngIf="!isShow">
                            <button class="plan-change" *ngIf="!userType && !subDetail?.isDeleted"
                                (click)="changePlan()">
                                {{(subDetail?.plan?.unitPrice>5)?'Change Plan':'Move To Paid Plan'}}</button>
                        </div>

                    </div>
                    <div>
                        <p class="sub-id">#{{subDetail?.code | uppercase}} <span [ngClass]="[(subDetail?.plan?.unitPrice>5)?subDetail?.isDeleted?'expire':
                            subDetail?.isExpired?'expire':'live':'expire']">
                                {{(subDetail?.plan?.unitPrice>5)?subDetail?.isDeleted?'Deleted':subDetail?.isExpired?'Expired':
                                'Live':subDetail?.isDeleted?'Deleted':subDetail?.isExpired?
                                'Trial Expired':'Trial'}}</span>&nbsp;
                            <span class="renew-now"
                                *ngIf="subDetail?.plan?.unitPrice>0 && subDetail?.isExpired && !subDetail?.isDeleted"
                                (click)="renewConfirmation()">Renew Now</span>
                        </p>
                        <span class="float-end">
                            <div class="dropdown">
                                <button class="btn-color  dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-mdb-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                    [disabled]="subDetail?.isDeleted">
                                    Action
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <a class="dropdown-item" *ngIf="!subDetail?.isDeleted "
                                        (click)="statusModal(subDetail?.id,subDetail?.isActive)">{{subDetail?.isActive?'Suspend':'Activate'}}</a>
                                    <a class="dropdown-item" *ngIf="!subDetail?.isDeleted && !userType && !isShow"
                                        (click)="deleteSubscription()">Delete
                                        Subscriptions</a>
                                    <a class="dropdown-item"
                                        *ngIf="subDetail?.plan?.unitPrice>0 && !subDetail?.isDeleted"
                                        (click)="openModal()">{{subDetail?.isAutoRenew ?'Set to Auto Expire' :
                                        'Set to Auto Renew'}}</a>
                                    <a class="dropdown-item" *ngIf="subDetail?.isExpired"
                                        (click)="openReasonModal()">Reason
                                        For Churn</a>
                                </div>
                            </div>
                        </span>
                    </div>
                </div>
                <div class="row">
                    <div>
                        <ul class="d-flex service ps-0 mb-0">
                            <li (click)="toggleSection('overview')"
                                [ngClass]="(activeSection==='overview') ? 'activate': ''">
                                <a>Overview</a>
                            </li>
                            <li (click)="toggleSection('invoice-history')"
                                [ngClass]="(activeSection==='invoice-history') ? 'activate': ''">
                                <a>Transactions</a>
                            </li>
                            <li (click)="toggleSection('recent-activities')"
                                [ngClass]="(activeSection==='recent-activities') ? 'activate': ''">
                                <a>Recent Activities</a>
                            </li>

                            <!-- <li (click)="toggleSection('crm')" [ngClass]="(activeSection==='crm') ? 'activate': ''">
                                <a>CRM (Coming Soon)</a>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <!-- OVERVIEW -->

                <div class="row " *ngIf="activeSection==='overview'">
                    <div class="col-md-5 ">
                        <div class="card-body part-1">
                            <h5 class="company_name">{{customer?.companyName |uppercase}} </h5>
                            <button class=" plan-change mt-2" *ngIf="!subDetail?.isDeleted"
                                (click)="customerLogin(subDetail?._id,subDetail?.customerId)">Login as a
                                Customer</button>
                            <div class="d-flex sub-contain mt-4 ">
                                <span class="icon">
                                    <i class="bi bi-person-fill "></i>
                                </span>
                                <div>
                                    <h6 class="sb-txt  mb-0">{{customer?.name |titlecase}}
                                    </h6>
                                    <p class="text-muted pt-0 email">
                                        {{customer?.email |titlecase}} <a class="link" *ngIf="!subDetail?.isDeleted"
                                            (click)="changeCustomerIdModal()">Update</a>
                                    </p>
                                </div>
                            </div>
                            <div class="mt-4">
                                <h5 class="address">{{"address" |uppercase}}</h5>
                                <hr />
                                <p>Billing Address</p>
                                <p>{{customer?.billing?.address | titlecase}}&nbsp;{{customer?.billing?.city |
                                    titlecase}}&nbsp;
                                    {{selectedState?.name | titlecase}} &nbsp;{{customer?.billing?.country | titlecase}}
                                    &nbsp;
                                    {{customer?.billing?.pinCode}} </p>
                            </div>

                            <div class="mt-4">
                                <h5 class="subscription">SUBSCRIPTION OPTIONS</h5>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-12 text-muted">
                                        <p>Subscription ID <span class="float-end">{{subDetail?.code |
                                                uppercase}}</span></p>
                                        <p>Subscriber Since<span class="float-end">{{subDetail?.startDate |
                                                date:"dd/MM/yyyy"}}</span></p>
                                        <p>Sales Person <span class="float-end">{{subDetail?.salesPerson?.name |
                                                titlecase}}&nbsp;<a class="link"
                                                    *ngIf="!subDetail?.isDeleted && !userType"
                                                    (click)="changeSalesmanModal(true)"> Update</a></span></p>
                                        <p>Relationship Manager <span
                                                class="float-end">{{subDetail?.relationshipManager?.name |
                                                titlecase}}&nbsp;
                                                <a class="link" *ngIf="!subDetail?.isDeleted && !userType"
                                                    (click)="changeSalesmanModal(false)"> Update</a>
                                            </span>
                                        </p>
                                        <p>Place of Supply <span class="float-end"
                                                *ngIf="selectedState">{{selectedState?.name |
                                                titlecase}}({{selectedState?.key}})</span></p>
                                        <p>GST Treatment <span class="float-end">{{customer?.billing?.gstTreatment
                                                }}</span></p>
                                        <p>Last Login <span class="float-end">{{customer?.lastLogin |
                                                date:"dd/MM/yyyy,hh:mm a": "UTC + 05:30"}}</span></p>

                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7 " *ngIf="isPartnerSalesman">
                        <div class="table-responsive">
                            <table class="table table-borderless table-sm roundedTable">
                                <thead class="text-muted">
                                    <tr>
                                        <th scope="col">Subscription amount</th>
                                        <th scope="col">Next Billing Date</th>
                                        <th scope="col">Last Billing Date</th>

                                        <th scope="col "><span
                                                [ngClass]="subDetail?.isAutoRenew?'text-success':'text-danger'">
                                                {{subDetail?.isAutoRenew?'Renew forever':'Expiry on'}}<i
                                                    class="bi bi-info-circle ms-2" placement="left"
                                                    ngbTooltip="{{subDetail?.isAutoRenew ?'The subscription will automatically renew on the next billing date and the amount will be deducted from your wallet.':'The subscription will automatically expire and the associated data will be permanently deleted in 15 days after expiry date.'}}"></i></span>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="text-center">
                                    <tr>
                                        <td class="pt-0 ">
                                            <p class="mb-0 text-center">&#8377; {{subDetail?.plan?.unitPrice *
                                                subDetail?.plan?.noOfUsers}}</p>

                                            <p class="text-muted text-center">
                                                {{subDetail?.plan?.billEvery}}&nbsp;{{subDetail?.plan?.billingCycle}}(s)
                                            </p>
                                        </td>

                                        <td class="pt-0">
                                            <p class="mb-0 text-center"> {{subDetail?.endDate | date:"d MMM y"}}</p>
                                            <p class="m-t-4"></p>
                                        </td>
                                        <td class="pt-0">
                                            <p class="mb-0 text-center">
                                                {{(subDetail?.lastRenewDate===null?subDetail?.startDate:subDetail?.lastRenewDate)
                                                | date:"d MMM y"}}</p>
                                            <p class="m-t-4"></p>
                                        </td>
                                        <td class="pt-0  ">
                                            <i class="bi bi-infinity fs-3" *ngIf="subDetail?.isAutoRenew"></i>
                                            <p class="mb-0 text-center" *ngIf="!subDetail?.isAutoRenew">
                                                {{(subDetail?.endDate===null?subDetail?.startDate:subDetail?.endDate)
                                                | date:"d MMM y"}}</p>
                                            <p class="m-t-4"></p>
                                        </td>
                                    </tr>
                                </tbody>

                            </table>
                        </div>

                        <!-- <hr/> -->
                        <div class="table-responsive mt-3">
                            <table class="table table-sm ">
                                <thead class="text-muted ">
                                    <tr>
                                        <th scope="col">Plan & Addon Details</th>
                                        <th scope="col">Qty/No Of Users
                                        </th>
                                        <th scope="col">Current Rate</th>
                                        <th scope="col" *ngIf="partnerId=='645a4eca8c6428e9ac655957'">Updated Rate
                                            <i class="bi bi-info-circle ms-2" placement="left"
                                                ngbTooltip="This is the new subscription rate that will be applied at the time of subscription renewal."></i>
                                        </th>
                                        <th scope="col">Tax</th>
                                        <th scope="col">Amount</th>

                                    </tr>
                                </thead>
                                <tbody class="font-weight-bold1">
                                    <tr>
                                        <td>
                                            <div class="d-inline-block text-truncate" style="max-width: 150px;"
                                                ngbTooltip=" {{subDetail?.plan?.name |titlecase}}" placement="top">
                                                {{subDetail?.plan?.name |titlecase}}</div>
                                        </td>
                                        <td>{{subDetail?.plan?.noOfUsers}}<span
                                                *ngIf="!userType && !subDetail?.isDeleted">
                                              
                                                <a class="link" (click)="manageUsersModal()" *ngIf="!isShow">  &#40; Change Users &#41;</a>
                                            </span>
                                        </td>
                                        <td>&#8377;{{subDetail?.plan?.unitPrice | number :
                                            "1.0-2"}}
                                            <span
                                                *ngIf="!subDetail?.isDeleted && partnerId=='645a4eca8c6428e9ac655957' && subDetail?.plan?.unitPrice>0">
                                                &#40;
                                                <a class="link" (click)="changePrice()">
                                                    Change Rate </a>&#41;
                                            </span>
                                        </td>
                                        <td *ngIf="partnerId=='645a4eca8c6428e9ac655957'" class="text-primary">
                                            &#8377;{{subDetail?.newUnitPrice}}</td>
                                        <td>&#8377;{{((subDetail?.plan?.unitPrice *subDetail?.plan?.noOfUsers)*.18)
                                            |number:"1.0-2"}}</td>

                                        <td>&#8377;{{(subDetail?.plan?.unitPrice * subDetail?.plan?.noOfUsers)| number :
                                            "1.0-2"}}</td>

                                    </tr>
                                    <tr *ngIf="!(subDetail?.addOn?.description===null)">
                                        <td>
                                            <div class="d-inline-block text-truncate" style="max-width: 150px;"
                                                ngbTooltip="{{subDetail?.addOn?.description |titlecase}}"
                                                placement="top">{{subDetail?.addOn?.description |titlecase}}</div>
                                        </td>
                                        <td>{{subDetail?.addOn?.quantity}}</td>
                                        <td>&#8377;{{subDetail?.addOn?.price | number :
                                            "1.0-2"}}</td>
                                        <td *ngIf="partnerId=='645a4eca8c6428e9ac655957'"></td>
                                        <td>&#8377;{{((subDetail?.addOn?.price * subDetail?.addOn?.quantity)*.18)
                                            |number:"1.0-2"}}</td>
                                        <td>&#8377;{{(subDetail?.addOn?.price * subDetail?.addOn?.quantity)| number :
                                            "1.0-2"}}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td *ngIf="partnerId=='645a4eca8c6428e9ac655957'"></td>
                                        <td>Sub Total</td>
                                        <td>&#8377;{{((subDetail?.plan?.unitPrice *
                                            subDetail?.plan?.noOfUsers)+(subDetail?.addOn?.price *
                                            subDetail?.addOn?.quantity))| number :
                                            "1.0-2"}}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td *ngIf="partnerId=='645a4eca8c6428e9ac655957'"></td>
                                        <td>IGST18(18%)</td>
                                        <td>&#8377;{{(((subDetail?.plan?.unitPrice *
                                            subDetail?.plan?.noOfUsers)+(subDetail?.addOn?.price *
                                            subDetail?.addOn?.quantity))*.18)| number :
                                            "1.0-2"}}</td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td *ngIf="partnerId=='645a4eca8c6428e9ac655957'"></td>
                                        <td class="text-uppercase">Total</td>
                                        <td>&#8377;{{(((subDetail?.plan?.unitPrice *
                                            subDetail?.plan?.noOfUsers)+(subDetail?.addOn?.price *
                                            subDetail?.addOn?.quantity))*1.18)| number :
                                            "1.0-2"}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="col-md-7 " *ngIf="!isPartnerSalesman">
                        <table class="table table-borderless table-sm roundedTable">
                            <thead class="text-muted">
                                <tr>
                                    <th scope="col">Subscription Duration</th>
                                    <th scope="col">Next Billing Date</th>
                                    <th scope="col">Last Billing Date</th>
                                    <th scope="col "><span
                                            [ngClass]="subDetail?.isAutoRenew?'text-success':'text-danger'">
                                            {{subDetail?.isAutoRenew?'Renew forever':'Expiry on'}}<i
                                                class="bi bi-info-circle ms-2" placement="left"
                                                ngbTooltip="{{subDetail?.isAutoRenew ?'The subscription will automatically renew on the next billing date and the amount will be deducted from your wallet.':'The subscription will automatically expire and the associated data will be permanently deleted in 15 days after expiry date.'}}"></i></span>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="text-center">
                                <tr>
                                    <td class="pt-0 text-muted text-center ">

                                        {{subDetail?.plan?.billEvery}}&nbsp;{{subDetail?.plan?.billingCycle}}(s)
                                    </td>
                                    <td class="pt-0"> {{subDetail?.endDate | date:"d MMM y"}}
                                    </td>
                                    <td class="pt-0">
                                        {{(subDetail?.lastRenewDate===null?subDetail?.startDate:subDetail?.lastRenewDate)
                                        | date:"d MMM y"}}
                                    </td>
                                    <td class="pt-0  ">
                                        <i class="bi bi-infinity fs-3" *ngIf="subDetail?.isAutoRenew"></i>
                                        <span
                                            *ngIf="!subDetail?.isAutoRenew">{{(subDetail?.endDate===null?subDetail?.startDate:subDetail?.endDate)
                                            | date:"d MMM y"}}</span>

                                    </td>
                                </tr>
                            </tbody>

                        </table>
                        <!-- <hr/> -->
                        <div class="table-responsive">

                            <table class="table table-sm ">
                                <thead class="text-muted ">
                                    <tr>
                                        <th scope="col">Plan & Addon Details</th>
                                        <th scope="col">Qty/No Of Users
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="font-weight-bold1">
                                    <tr>
                                        <td>{{subDetail?.plan?.name |titlecase}}</td>
                                        <td>{{subDetail?.plan?.noOfUsers}}</td>

                                    </tr>
                                    <tr *ngIf="!(subDetail?.addOn?.description===null)">
                                        <td>
                                            <div class="d-inline-block text-truncate" style="max-width: 200px;"
                                                ngbTooltip="{{subDetail?.addOn?.description |titlecase}}"
                                                placement="top">{{subDetail?.addOn?.description |titlecase}}</div>
                                        </td>
                                        <td>{{subDetail?.addOn?.quantity}}</td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>

                    </div>
                </div>

                <!-- INVOICE HISTORY -->
                <div class="row mb-4" *ngIf="activeSection==='invoice-history'">
                    <div class="col-sm-12 table-responsive">
                        <h4 class="mt-2">Transaction Information</h4>
                        <table class="table table-sm">
                            <thead class="text-muted">
                                <tr>
                                    <th scope="col">Trans ID</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Action</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Credit</th>
                                    <th scope="col">Debit</th>

                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let billingData of subBillInfo">
                                    <td>{{billingData?.billNumber}}</td>
                                    <td>{{billingData?.createdAt |date:'dd/MM/YYYY'}}</td>
                                    <td>{{billingData?.action | titlecase}}</td>
                                    <td>{{billingData?.billingType | titlecase}}</td>
                                    <td class="text-success">&#8377; {{billingData?.credit | number :"1.0-2"}}</td>
                                    <td><span class="text-danger">&#8377; {{billingData?.debit | number
                                            :"1.0-2"}}</span></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-sm-12 table-responsive"
                        *ngIf="(subDetail?.partnerId==='645a4eca8c6428e9ac655957') && subPaymentData.length>0">
                        <h4 class="mt-2">Payment Information</h4>
                        <table class="table table-sm">
                            <thead class="text-muted">
                                <tr>
                                    <th scope="col">OrderId</th>
                                    <th scope="col">Date</th>
                                    <th scope="col">Amount</th>
                                    <th scope="col">Staus</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let payment of subPaymentData">
                                    <td>{{payment?.orderId}}</td>
                                    <td>{{payment?.createdAt |date:'dd/MM/YYYY'}}</td>
                                    <td>&#8377; {{payment?.amount | number :"1.0-2"}}</td>
                                    <td *ngIf="payment?.orderStatus === 'Successful' || payment?.orderStatus === 'Shipped'"
                                        class="text-success">
                                        Success
                                    </td>
                                    <td *ngIf="payment?.orderStatus === 'Unsuccessful' || payment?.orderStatus === 'Invalid' || payment?.orderStatus === 'Cancelled' || payment?.orderStatus === 'Timeout' || payment?.orderStatus === 'Aborted'"
                                        class="text-danger">
                                        Failed
                                    </td>
                                    <td *ngIf="payment?.orderStatus === 'Initiated' || payment?.orderStatus === 'Awaited'"
                                        class="text-warning">
                                        Pending
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <!-- RECENT ACTIVITIES -->
                <div class="row mt-4 mb-4" *ngIf="activeSection==='recent-activities'">
                    <div class="col-sm-12">
                        <ul class="events">
                            <li *ngFor="let activity of subActivityLog">
                                <time class="col-2">{{activity?.createdAt | date:"dd MMM y"}}<br />
                                    {{activity?.createdAt | date:'h:mm a': "UTC + 05:30"}}</time>
                                <span class="d-inline-block col-10 ps-5"><strong
                                        class="activities">{{activity?.description}} </strong> By
                                    {{activity?.taskBy?.name}}<small *ngIf="activity?.remark">, Remark:
                                        {{activity?.remark}} ,</small> <strong *ngIf="activity?.remarkBy"> Remark By:
                                        {{activity?.remarkBy}} </strong></span>
                            </li>
                        </ul>
                    </div>
                </div>


                <div *ngIf="activeSection==='crm'">
                    <router-outlet></router-outlet>
                </div>
            </div>
        </div>
    </div>
</div>