import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { SalesmanService } from 'src/app/services/salesman.service';
import { MrrService } from 'src/app/services/mrr.service';
import * as Highcharts from 'highcharts';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { WalletService } from 'src/app/services/wallet.service';
import * as XLSX from 'xlsx';
@Component({
  selector: 'app-mrr-report',
  templateUrl: './mrr-report.component.html',
  styleUrls: ['./mrr-report.component.scss']
})
export class MrrReportComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;
  @ViewChild('multiSelect1') multiSelect1!: MatSelect;
  @ViewChild('multiSelect2') multiSelect2!: MatSelect;
  allSalesPersonSelected = false;
  allRelationshipSelected = false;
  isShowDetails = false
  salesmans: any
  mrrReports: any
  relationshipManager: any
  mrrDetail: any
  mrrGrowths: any
  details: any
  subUserData: boolean = true


  Highcharts: typeof Highcharts = Highcharts;
  mrrReportOption: Highcharts.Options = {}
  updateFromInput: boolean = true
  objFromInput: any = {}
  chartType: string = "area"
  domainName: string = ''
  searchForm = new FormGroup(
    {
      salesPersonId: new FormControl<string[]>([]),
      relationshipManagerId: new FormControl<string[]>([]),
      searchText: new FormControl(''),
      searchText1: new FormControl(''),
    }
  )
  constructor(
    private salesmanService: SalesmanService,
    private mrrService: MrrService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private walletService: WalletService

  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      const salespersonId: string = params['salesPersonId'];
      const relationShipManagerId: string = params['relationshipManagerId']
      if (salespersonId) {
        this.subUserData = false
        this.searchForm.get('salesPersonId')?.setValue([salespersonId]);
      }
      else if (relationShipManagerId) {
        this.subUserData = false
        this.searchForm.get('relationshipManagerId')?.setValue([relationShipManagerId]);
      } else {
        this.searchForm.reset()
      }
      this.salesmanList();
      this.submitAll();
    });
    this.getDomain()
  }
  submitAll() {
    this.isShowDetails = false

    this.mrrReport()
    this.mrrGrowth()
  }
  salesmanList() {
    this.salesmanService.salesmanList().subscribe((res: any) => {
      if (res.success) {
        this.salesmans = res?.salesPerson;
        this.relationshipManager = res?.relationShipManager
      }
    });
  }

  mrrReport() {
    const salesPersonId = this.searchForm.get('salesPersonId')?.value
    const relationshipManagerId = this.searchForm.get('relationshipManagerId')?.value
    const mrrReportPlayload = {
      salesPersonIds: salesPersonId,
      relationshipManagerIds: relationshipManagerId,
      subUserData: this.subUserData
    }
    this.mrrService.mrrMovement(mrrReportPlayload).subscribe((res: any) => {
      if (res.success) {
        this.mrrReports = res?.mrrMovement
      }
    })
  }
  get searchText() {
    return this.searchForm.controls.searchText.value || '';
  }
  get searchText1() {
    return this.searchForm.controls.searchText1.value || '';
  }
  mrrGrowth() {
    const salesPersonId = this.searchForm.get('salesPersonId')?.value
    const relationshipManagerId = this.searchForm.get('relationshipManagerId')?.value
    const mrrGrowthPlayload = {
      salesPersonIds: salesPersonId,
      relationshipManagerIds: relationshipManagerId,
      subUserData: this.subUserData
    }
    this.mrrService.mrrGrowth(mrrGrowthPlayload).subscribe((res: any) => {
      if (res.success) {
        this.mrrGrowths = res?.mrrGrowth
        this.setChartVariableForMrr(this.mrrGrowths);
      }
    })
  }

  mrrDetails(month: any, billingType: any) {
    this.isShowDetails = true
    setTimeout(() => {
      if (this.scrollContainer && this.scrollContainer.nativeElement) {
        this.scrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    })

    const salesPersonId = this.searchForm.get('salesPersonId')?.value
    const relationshipManagerId = this.searchForm.get('relationshipManagerId')?.value
    const mrrDetailsPlayload = {
      month: month,
      billingType: billingType,
      salesPersonIds: salesPersonId,
      relationshipManagerIds: relationshipManagerId,
      subUserData: this.subUserData
    }
    this.mrrService.mrrDetails(mrrDetailsPlayload).subscribe((res: any) => {
      if (res.success) {
        this.mrrDetail = res?.mrrDetails

      }
    })
  }
  setChartVariableForMrr(mrrData: any) {
    const objFromInput: any = {};
    objFromInput['credits'] = false;
    objFromInput['chart'] = { type: this.chartType };
    objFromInput['legend'] = { align: 'center', verticalAlign: 'top', lineHeight: 0, padding: 0 };
    objFromInput['title'] = { text: '' };
    objFromInput['subtitle'] = { text: `<b>Monthly Revenue Report<b/>` };
    objFromInput['yAxis'] = {
      title: {
        text: 'MRR values',
      },
    };

    objFromInput['xAxis'] = {
      type: 'datetime',
      title: {
        text: 'Months',
      },
      labels: {
        format: '{value: %b %Y}'
      },
      tickPositioner: function () {
        return this.series[0].xData;
      },
    };

    objFromInput['tooltip'] = {
      formatter: function (): string {
        const point = this.point;
        return `<br/><b>Month :</b> ${point.month}<br/><b>MRR :</b> ${point.value}<br/> <b>Percentage :</b> ${point.changePercentage}%`;
      }
    };

    objFromInput['plotOptions'] = {
      series: {
        borderRadius: 3
      }
    };

    objFromInput['series'] = [
      {
        name: 'MRR',
        data: mrrData.map((data: any) => {
          const x = Date.UTC(new Date(data.month).getFullYear(), new Date(data.month).getMonth(), 1);
          const y = data.value;
          return {
            x,
            y,
            month: data.month,
            value: data.value,
            changePercentage: data.changePercentage
          };
        }),
      }
    ];
    objFromInput['colors'] = ['#4B49AC'];
    this.mrrReportOption = objFromInput;
    Highcharts.chart('mrrReportChart', this.mrrReportOption);
    this.updateFromInput = true;
  }


  toggleChartType(graph: string) {
    this.chartType = graph
    this.mrrReportOption['chart'] = { type: graph }
    Highcharts.chart('mrrReportChart', this.mrrReportOption)
    this.updateFromInput = true
  }
  toggleAllSelection(multiSelect: MatSelect, id: number) {

    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1 && this.allSalesPersonSelected) {
          item.select();

        } else if (id === 2 && this.allRelationshipSelected) {
          item.select();

        } else {
          item.deselect();
        }
      });
    }
  }

  optionClick(multiSelect: MatSelect, id: number) {
    let newStatus = true;
    multiSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });

    if (id === 1) {
      this.allSalesPersonSelected = newStatus;
    } else if (id === 2) {
      this.allRelationshipSelected = newStatus;
    }
  }

  deselectAll(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1) {
          item.deselect();
          this.allSalesPersonSelected = false
        } else if (id === 2) {
          item.deselect();
        }
      });
    }
  }
  getDomain() {
    this.walletService.getDomainName().subscribe((res: any) => {
      if (res.success) {
        this.domainName = res?.domain;
      }
    });
  }
  get userType() {
    return this.authService.userType()
  }

  get isSalesman() {
    return this.authService.isSalesman()
  }
  exportexcel(): void {
    const mrrDetail = document.getElementById('mrr-detail-table')
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(mrrDetail);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'MRR_Details.xlsx');
  }
}
