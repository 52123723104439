<!-- ----------subscription---list----page-- -->
<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12">

        <div class="row mb-3">
          <div class="col-8">
            <h3 class="heading">{{subTypeTitle}}</h3>
          </div>
          <div class="col-4 text-end" *ngIf="!userType">
            <button type="button" class="comman-btn1 btn-primary" (click)="createNew()">+ New</button>
          </div>
        </div>

        <div class="row mb-2" *ngIf="customerId == null">
          <div class="col-md-2 mb-2"><button class="btn-color btn-color5 w-100" (click)="clickableData('')"
              [ngClass]="subTypeTitle==='All Subscriptions'?'active-btn-color5':''">All Subscription</button></div>
          <div class="col-md-2 mb-2"><button class="btn-color btn-color1 w-100" (click)="clickableData('paid')"
              [ngClass]="subType==='paid'?'active-btn-color1':''">Paid Subscription</button></div>
          <div class="col-md-2 mb-2"><button class="btn-color btn-color2 w-100" (click)="clickableData('trial')"
              [ngClass]="subType==='trial'?'active-btn-color2':''">Trial Subscription</button></div>
          <div class="col-md-3 mb-2"><button class="btn-color btn-color3 w-100" (click)="clickableData('renewal')"
              [ngClass]="subType==='renewal'?'active-btn-color3':''">Upcoming Renewal</button></div>
          <div class="col-md-3"><button class="btn-color btn-color4 w-100" (click)="clickableData('expired')"
              [ngClass]="subType==='expired'?'active-btn-color4':''">Expired Subscription</button></div>

        </div>

        <div class="row mt-4">
          <div class="col-sm-6  grid-margin">
            <div class="card card1">
              <div class="card-body ps-3 pe-3">
                <p class="txt">Total Subscriptions</p>
                <div class="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <p class="number text-center">{{ subscriptionData?.total }}</p>
                    <p class="text-center sub-txt">Subscriptions</p>
                  </div>
                  <div class="vr"></div>
                  <div>
                    <p class="number text-center">{{subscriptionData?.totalUsers}}</p>
                    <p class="text-center sub-txt">Users</p>
                  </div>
                  <div class="vr"></div>
                  <div *ngIf="isPartnerSalesman">
                    <p class="number text-center">{{subscriptionData?.totalAmount | pricePipe}}</p>
                    <p class="text-center sub-txt">Amount</p>
                  </div>
                  <div>
                    <div class="circle">
                      <img src="../../../../assets/images/icons8-resume.png" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class=" col-sm-6 grid-margin">
            <div class="card card2">
              <div class="card-body ps-3 pe-3 ">
                <p class="txt">New Paid Subscriptions
                  <select class="dropdown select-dropdown float-end selected" #selectedReportTime
                    (change)="subscriptionList()">
                    <option value="week">This Week</option>
                    <option value="month">This Month</option>
                    <option value="year">This Year</option>
                  </select>
                </p>
                <div class="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <p class="number text-center">{{subReportData?.totalSub}}</p>
                    <p class="text-center sub-txt">Subscriptions</p>
                  </div>
                  <div class="vr"></div>
                  <div>
                    <p class="number text-center">{{subReportData?.totalUsers}}</p>
                    <p class="text-center sub-txt">Users</p>
                  </div>
                  <div class="vr"></div>
                  <div *ngIf="isPartnerSalesman">
                    <p class="number text-center">{{subReportData?.amount | pricePipe}}</p>
                    <p class="text-center sub-txt">Amount</p>
                  </div>
                  <div>
                    <div class="circle">
                      <img src="../../../../assets/images/icons8-new.png" />
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

        <div class="row mt-4 " *ngIf="customerId != undefined">
          <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-6">
                    <p class="card-title">Customer Name</p>
                    <p>{{customerDetail?.name | titlecase}}</p>
                  </div>

                  <div class="col-md-6">
                    <p class="card-title">Company Name</p>
                    <p>{{customerDetail?.companyName | titlecase}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-12 mb-3" *ngIf="customerId == null">
          <div class="search-form">
            <p class="light mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
              aria-expanded="false" aria-controls="collapseExample">
              <i class="bi bi-search"></i> <span class="ms-5">Search here</span>
            </p>

            <div class="collapse" id="collapseExample">
              <form class="row g-3 pt-3" [formGroup]="searchForm">

                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>Start Date</mat-label>
                    <mat-date-range-input [rangePicker]="startPicker">
                      <input matStartDate formControlName="startDateFrom" placeholder="From">
                      <input matEndDate formControlName="startDateTo" placeholder="To">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #startPicker></mat-date-range-picker>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>Next Billing Date</mat-label>
                    <mat-date-range-input [rangePicker]="billingPicker">
                      <input matStartDate placeholder="From" formControlName="expiryDateFrom">
                      <input matEndDate placeholder="To" formControlName="expiryDateTo">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="billingPicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #billingPicker></mat-date-range-picker>
                  </mat-form-field>
                </div>


                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>Last Billing Date</mat-label>
                    <mat-date-range-input [rangePicker]="lastbillingPicker">
                      <input matStartDate placeholder="From" formControlName="lastDateFrom">
                      <input matEndDate placeholder="To" formControlName="lastDateTo">
                    </mat-date-range-input>
                    <mat-datepicker-toggle matSuffix [for]="lastbillingPicker"></mat-datepicker-toggle>
                    <mat-date-range-picker #lastbillingPicker></mat-date-range-picker>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>Company/Email Address:</mat-label>
                    <input type="text" matInput formControlName="userDetail" class="form-input-field"
                      onkeypress="return event.charCode !== 47 && event.charCode !== 92 " />
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>SubCode:</mat-label>
                    <input type="text" matInput formControlName="subCode" class="form-input-field"
                      onkeypress="return event.charCode !== 47 && event.charCode !== 92 " />
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>Status</mat-label>
                    <mat-select formControlName="status">
                      <mat-option value="option1" selected>All </mat-option>
                      <mat-option [value]="true">Active</mat-option>
                      <mat-option [value]="false">Suspend</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>Stage</mat-label>
                    <mat-select multiple #multiSelect formControlName="stage">
                      <mat-select-trigger>
                        {{ multiSelect.value ? multiSelect.value[0] : '' }}
                        <span *ngIf="multiSelect.value?.length > 1" class="example-additional-selection">
                          (+{{ multiSelect.value.length - 1 }} {{ multiSelect.value?.length === 2 ? 'other' : 'others'
                          }})
                        </span>
                      </mat-select-trigger>
                      <div class="select-all">
                        <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                          (change)="toggleAllSelection(multiSelect,0)" [checked]="selectAllStage">Select
                          All</mat-checkbox>
                      </div>

                      <mat-option *ngFor="let stage of stageOptions" [value]="stage.value"
                        (click)="optionClick(multiSelect, 0)">
                        {{ stage.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>Plan Name</mat-label>
                    <mat-select multiple #multiSelect1 formControlName="planName">
                      <mat-select-trigger>
                        {{ multiSelect1.value ? multiSelect1.value[0] : '' }}
                        <span *ngIf="multiSelect1.value?.length > 1" class="example-additional-selection">
                          (+{{ multiSelect1.value.length - 1 }} {{ multiSelect1.value?.length === 2 ? 'other' : 'others'
                          }})
                        </span>
                      </mat-select-trigger>
                      <div class="select-all">
                        <mat-checkbox [(ngModel)]="allPlanSelected" [ngModelOptions]="{standalone: true}"
                          (change)="toggleAllSelection(multiSelect1,1)">Select All</mat-checkbox>
                      </div>
                      <mat-option *ngFor="let list of totalProductList" [value]="list?.name"
                        (click)="optionClick(multiSelect1, 1)">{{list?.name |
                        titlecase}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-4" *ngIf="!userType ||(userType && isSalesman)">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>Salesperson</mat-label>
                    <mat-select multiple #multiSelect2 formControlName="salesPersonId">
                      <div class="search ">
                        <input type="text" class="custom-input-style  p-2" placeholder="Search here..."
                          formControlName="searchText">
                      </div>
                      <div class="select-all">
                        <mat-checkbox [(ngModel)]="allSalesPersonSelected" [ngModelOptions]="{standalone: true}"
                          (change)="toggleAllSelection(multiSelect2,2)">Select All</mat-checkbox>
                      </div>
                      <mat-option *ngFor="let salesman of salesmans | subFilter: searchText" [value]="salesman?._id"
                        (click)="optionClick(multiSelect2,2)">
                        {{salesman?.name |
                        titlecase}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <div class="col-md-4" *ngIf="!userType ||(userType && !isSalesman)">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>Relationship Manager</mat-label>
                    <mat-select multiple #multiSelect3 formControlName="relationshipManagerId">
                      <div class="search ">
                        <input type="text" class="custom-input-style  p-2" placeholder="Search here..."
                          formControlName="searchText">
                      </div>
                      <div class="select-all">
                        <mat-checkbox [(ngModel)]="allRelationshipSelected" [ngModelOptions]="{standalone: true}"
                          (change)="toggleAllSelection(multiSelect3,3)">Select All</mat-checkbox>
                      </div>
                      <mat-option *ngFor="let relationship of relationshipManager | subFilter: searchText"
                        [value]="relationship?._id" (click)="optionClick(multiSelect3,3)">
                        {{relationship?.name |
                        titlecase}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>Set For</mat-label>
                    <mat-select formControlName="autoRenewExpire">
                      <mat-option value="option1" selected>All </mat-option>
                      <mat-option [value]="true">Auto Renew</mat-option>
                      <mat-option [value]="false">Auto Expire</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>Tally Serial No</mat-label>
                    <input type="text" matInput formControlName="tallySerialNo" class="form-input-field" />
                  </mat-form-field>
                </div>
                <div class="col-md-4">
                  <mat-form-field appearance="fill" class="example-form-field w-100">
                    <mat-label>Schedular</mat-label>
                    <mat-select formControlName="isSchedular">
                      <mat-option value="" selected>All </mat-option>
                      <mat-option [value]="true">Added</mat-option>
                      <mat-option [value]="false">Not Added</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-md-12 text-end">
                  <button class="comman-btn1 btn-primary me-2" type="search"
                    (click)="subscriptionList()">Search</button>
                  <button class="comman-btn1 btn-secondary text-light" type="reset"
                    (click)="resetSearchForm()">Reset</button>
                </div>

              </form>
            </div>
          </div>
        </div>


      </div>
    </div>

    <!-- ------------------ -->
    <div class="row table-section pe-0">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card detailed-section">
          <div class="card-body">
            <div class="records mb-5">{{updatePaginationText}}
              <button (click)="performBulkAction()" class=" float-end exl-btn ms-2 me-2" *ngIf="countSelected()">Assign
                User({{countSelected()}})</button>
              <button class="float-end exl-btn" (click)="excelSubsData()" *ngIf="!userType">
                Export To Excel
              </button>
            </div>
            <div class="table-responsive ">
              <table class="table  table-borderless" *ngIf="subscriptionFound">
                <thead class="table-header ">
                  <tr>
                    <th scope="col">
                      <input type="checkbox" (change)="selectAlls($event)" [checked]="isAllSelect()">
                    </th>
                    <th scope="col">#</th>
                    <th scope="col " class="arrow-down-up" (click)="sortingPartner('code')">Sub ID<i
                        class="bi bi-arrow-down-up"></i></th>
                    <th scope="col" *ngIf="customerId == null">Company Name</th>
                    <th scope="col">Phone No.</th>
                    <th scope="col">Set For</th>
                    <th scope="col " class="arrow-down-up" (click)="sortingPartner('name')">Plan Name<i
                        class="bi bi-arrow-down-up"></i></th>
                    <th scope="col " class="arrow-down-up" (click)="sortingPartner('unitPrice')"
                      *ngIf="isPartnerSalesman">Unit Price<i class="bi bi-arrow-down-up"></i>
                    </th>
                    <th scope="col " class="arrow-down-up" *ngIf="partnerId == '645a4eca8c6428e9ac655957'">Updated Unit
                      Price
                    </th>
                    <th scope="col " class="arrow-down-up" (click)="sortingPartner('noOfUsers')">No Of Users<i
                        class="bi bi-arrow-down-up"></i>
                    </th>
                    
                    <th scope="col " class="arrow-down-up" (click)="sortingPartner('price')" *ngIf="isPartnerSalesman">
                      Amount<i class="bi bi-arrow-down-up"></i></th>
                      <th scope="col " class="arrow-down-up" (click)="sortingPartner('currentMRR')">Current MRR<i
                        class="bi bi-arrow-down-up"></i>
                    </th>
                    <th scope="col">Tally Serial No</th>
                    <th scope="col">Start Date</th>
                    <th scope="col " class="arrow-down-up" (click)="sortingPartner('endDate')">Next Billing Date<i
                        class="bi bi-arrow-down-up"></i></th>
                    <th scope="col">Last billing date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Stage</th>
                  </tr>
                </thead>
                <tbody *ngFor="let list of subsList | paginate:config; let indexOfElement = index" class="ps-0">
                  <tr class="row-hover ">
                    <td>
                      <input type="checkbox" [checked]="selectedSubs[list._id]" (change)="toggleSelection(list._id)">
                    </td>
                    <td>
                      <a [href]="'#'+'details'+indexOfElement" class="accordion-toggle collapsed collapse-btn "
                        id="accordion1" data-mdb-toggle="collapse" data-mdb-parent="#accordion1">
                        <span class="expand-button"></span>
                      </a>
                    </td>
                    <td>
                      <a class="sub-link" [routerLink]="['/subscriptions-details']"
                        [queryParams]="{ subId: list?.code, domainName: this.domainName  }"
                        [ngClass]="list.isDeleted ? 'text-danger' : 'text-primary'"> {{list?.code | uppercase}}</a>
                    </td>
                    <td *ngIf="customerId == null">
                      <span class="d-inline-block text-truncate" style="max-width: 120px;"
                        [ngbTooltip]="list?.customer?.companyName" placement="top">{{ list?.customer?.companyName |titlecase }}</span>
                      <!-- {{ (list?.customer?.companyName | slice:0:25) +
                      (list?.customer?.companyName.length > 25 ? '...' : '') | titlecase }} -->
                    </td>
                    <td>{{list?.customer?.mobile}}</td>

                    <td [ngClass]="list?.isAutoRenew ?'text-success' :'text-danger'">{{list?.isAutoRenew?'Auto Renew'
                      :'Auto Expire'}}</td>

                    <td>
                      <span class="d-inline-block text-truncate" style="max-width: 120px;"
                        [ngbTooltip]="list?.plan?.name | titlecase" placement="top">{{ list?.plan?.name |
                        titlecase }}
                      </span>
                    </td>
                    <td *ngIf="isPartnerSalesman">{{list?.plan?.unitPrice }}</td>
                    <td *ngIf="partnerId == '645a4eca8c6428e9ac655957'" class="text-primary">{{list?.newUnitPrice }}
                    </td>
                    <td>{{list?.plan?.noOfUsers }}</td>
                    <td *ngIf="isPartnerSalesman">{{(list?.plan?.unitPrice*list?.plan?.noOfUsers) + (list?.addOn?.price
                      * list?.addOn?.quantity)}}
                    </td>
                    
                    <td>{{list?.currentMRR }}</td>
                    <td>{{list?.tallySerialNo?list?.tallySerialNo:'N/A'}}</td>

                    <td>{{list?.startDate === null ? 'Not Activated' : list?.startDate | date}}</td>
                    <td>{{list?.startDate === null ? 'Not Activated' : list?.endDate | date}}</td>
                    <td>{{list?.startDate === null ? 'Not Activated' : list?.lastRenewDate | date}}</td>
                    <td><span class="" [ngClass]="list?.isActive ?'text-success' :'text-danger'">
                        {{list?.isActive ? 'Active' : 'Inactive'}}
                      </span></td>
                    <td [ngClass]="[(list?.plan?.unitPrice>5)?list?.isDeleted?'text-danger':
                      list?.isExpired?'text-danger':'text-success':'text-danger']">
                      {{(list?.plan?.unitPrice>5)?list?.isDeleted?'Deleted':list?.isExpired?'Expired':
                      'Live':list?.isDeleted?'Deleted':list?.isExpired?
                      'Trial Expired':'Trial'}}</td>
                  </tr>

                  <tr class="hide-table-padding">
                    <td colspan="3">
                      <div [id]="'details'+indexOfElement" class="collapse mt-2">
                        <div class="row">
                          <p><b>CreatedOn: </b> <span>{{list?.createdAt | date}}</span></p>
                          <p><b>AddOn : </b> <span>{{list?.addOn?.price === null ? 'Not Assigned' :
                              list?.addOn?.name}}</span></p>
                          <p><b>ReferenceID: </b>
                            <span>{{list?.refrenceId === '' ? 'None' : list?.refrenceId}}</span>
                          </p>
                          <p *ngIf="isPartnerSalesman"><b>Amount: </b>
                            <span><i class="bi bi-currency-rupee"></i>{{list?.plan?.unitPrice |
                              number:'1.0-2'}}</span>
                          </p>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="text-center" *ngIf="!subscriptionFound">
                <p class="fw-bold" *ngIf="!userType">No subscriptions data found </p>
                <p class="fw-bold" *ngIf="userType && isSalesman">Subscription is not assigned to your ID.</p>
                <p class="add-btn" *ngIf="userType && isSalesman">
                  <button class="user-not-found " (click)="userNotFound()">Assign Subscription</button>
                  <!-- Add now<i class="bi bi-plus-circle ms-2 "></i> -->
                </p>
              </div>
            </div>
            <div class="records mt-4">{{updatePaginationText}}</div>
            <div class=" float-end me-4" *ngIf="subscriptionFound">
              <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- --------------- -->
  </div>
</div>