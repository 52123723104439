import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
@Component({
  selector: 'app-change-price',
  templateUrl: './change-price.component.html',
  styleUrls: ['./change-price.component.scss']
})
export class ChangePriceComponent implements OnInit {
  event: EventEmitter<any> = new EventEmitter();

  @Input() CurrentUnitPrice!: number
  @Input() subscriptionId!: string;
  constructor(
    public bsModalRef: BsModalRef,
    private subscriptionsService: SubscriptionsService,
    private toastr: ToastrService

  ) { }

  changePriceForm = new FormGroup({
    newPrice: new FormControl(''),
    remark: new FormControl('', [Validators.required])
  })
  ngOnInit(): void {
  }

  closedModal() {
    this.bsModalRef.hide();
  }
  submit() {
    const newPrice = this.changePriceForm.get('newPrice')?.value
    const newPricePayload = {
      newPrice: newPrice,
      remark: this.changePriceForm.get('remark')?.value,
      subscriptionId: this.subscriptionId
    }
    if (!this.changePriceForm.valid) {
      this.changePriceForm.markAllAsTouched()
    }
    else {
      if (Number(newPrice) >= this.CurrentUnitPrice) {
        this.subscriptionsService.changeNewPrice(newPricePayload).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success(res.message)
            this.event.emit('ok');
            this.closedModal()
          }
        },
          (err) => {
            this.toastr.error(err.error.message);
          }
        )
      } else {
        this.toastr.warning('New price must be greater than current unit price.')
      }
    }
  }
}
