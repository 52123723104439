import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { WalletService } from 'src/app/services/wallet.service';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';



@Component({
  selector: 'app-license-details',
  templateUrl: './license-details.component.html',
  styleUrls: ['./license-details.component.scss']
})
export class LicenseDetailsComponent implements OnInit {
  domainName: string = ''
  searchForm = new FormGroup({
    serialNumber: new FormControl(''),
    expiryDate: new FormControl(''),
    subId: new FormControl('')
  })
  totalTallyDataCounts: any
  isTallyDataAvailable: boolean = false
  tallyDatas: any = []
  totalCount: number = 0;
  page: number = 1;
  config = {
    itemsPerPage: 20,
    currentPage: this.page,
    totalItems: this.totalCount,
  };

  constructor(
    private walletService: WalletService,
    private subscriptionsService: SubscriptionsService,
    private toastr: ToastrService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getDomain()
    this.tallyDataList()
  }
  getDomain() {
    this.walletService.getDomainName().subscribe((res: any) => {
      if (res.success) {
        this.domainName = res?.domain;
      }
    });
  }


  searchList() {
    const searchFormField = this.searchForm.value
    if (searchFormField.serialNumber === "" && searchFormField.expiryDate === "" && searchFormField.subId === "") {
      this.toastr.warning('Please fill in data in at least one of the search fields.')
    }
    else {
      this.tallyDataList()
    }
  }


  resetSearchForm() {
    this.searchForm.reset()
    this.config.currentPage = 1
    this.searchList()
  }

  tallyDataList() {
    const searchPayload = this.searchForm.value
    this.subscriptionsService.tallyData(JSON.stringify(searchPayload), this.config.currentPage, this.config.itemsPerPage).subscribe((res: any) => {
      if (res.success) {
        if (res.tallyDatas.length === 0) {
          this.toastr.error('No data found');
        } else {
          this.tallyDatas = res.tallyDatas;
          this.totalTallyDataCounts = res.totalTallyDataCount;
          this.isTallyDataAvailable = true;
          this.config.currentPage = this.totalTallyDataCounts?.pages
          this.config.totalItems = this.totalTallyDataCounts?.total;
        }
      }
    }, (error) => {
      this.config.currentPage = 0;
      this.config.totalItems = 0;
    })
  }

  pageChanged(event: any) {
    this.config.currentPage = event
    if (this.searchForm.value.serialNumber || this.searchForm.value.expiryDate || this.searchForm.value.subId) {
      this.searchList()
    }
    else {
    this.tallyDataList()      
    }
  }
  get updatePaginationText(){
    return this.authService.getPaginationText(this.config.currentPage,this.config.itemsPerPage,this.config.totalItems);
  }
}
