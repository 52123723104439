<div class="container-scroller">
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <p class="heading">Raise Service Request</p>
                </div>
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="mt-2">
                                    <p class="txt">My Service Requests:</p>
                                    <hr />
                                    <div>
                                        <ul class="d-flex service">
                                            <span class="boot-icon" class="bi bi-link"></span>
                                            <li [routerLink]="['/ticket-to-cocloud']" class="services ">
                                                Tickets to CoCloud
                                            </li>
                                            <li [routerLink]="['raise-service-request']" class="services active">
                                                Raise Service Request
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col-md-12 ">
                    <div class="card">
                        <div class="card-body">
                            <form [formGroup]="issueFormControl">
                                <div class="row">
                                    <p class="txt">Raise Request:</p>
                                    <div class="col-sm-4 ">
                                        <div class="network mt-3">
                                            <div class="col-sm-12 box " (click)="selectedIssue('tech')"
                                                [ngClass]="selectedIssueType ==='tech' ? 'activated':''">
                                                <label>
                                                    <div class="box1">
                                                        <input type="radio" name="network" class="me-2" />
                                                        <b>Technical Issue</b>
                                                        <p class="mb-0 txt-1 ms-3">Link down & other network issues</p>
                                                    </div>
                                                </label>
                                            </div>

                                            <div class=" col-sm-12 box "
                                                [ngClass]="selectedIssueType ==='bill' ? 'activated':''">
                                                <label (click)="selectedIssue('bill')">
                                                    <div class="box1">
                                                        <input type="radio" name="network" class="me-2" />
                                                        <b>Billing & Payment</b>
                                                        <p class="mb-0 txt-1 ms-3">Bill disputes & Payment related issue
                                                        </p>
                                                    </div>
                                                </label>
                                            </div>
                                            <div class="col-sm-12 box "
                                                [ngClass]="selectedIssueType ==='general' ? 'activated':''">
                                                <label (click)="selectedIssue('general')">
                                                    <div class="box1">
                                                        <input type="radio" name="network" class="me-2" />
                                                        <b>Feedback or Feature Request</b>
                                                        <p class="mb-0 txt-1 ms-3">Your Input and Suggestions</p>
                                                    </div>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-8 mt-3">
                                        <div class="form-group" *ngIf="selectedIssueType === 'tech'">
                                            <div class="row">
                                                <label class="lbls" for="name">Select Subscription Id</label>
                                                <div class="col-sm-12">
                                                    <div class="dropdown-field custom-select">
                                                        <div class="dropdown ">
                                                            <p class="down-btn mt-0 mb-0 form-select form-group form-control"
                                                                type="button" id="dropdownMenuButton"
                                                                data-mdb-toggle="dropdown" aria-expanded="false">
                                                                {{(selectedSub!="")?selectedSub:'Select SubId'}}

                                                            </p>
                                                            <ul class="dropdown-menu menu-item-1 col-12 pt-0"
                                                                aria-labelledby="dropdownMenuButton">
                                                                <div class=" search p-2">
                                                                    <input type="text" class="form-control  p-2"
                                                                        formControlName="searchText"
                                                                        placeholder="Search here">
                                                                </div>
                                                                <div class="customerList">
                                                                    <li
                                                                        *ngFor="let sub of subCodes | subFilter: searchText">
                                                                        <a class="dropdown-item"
                                                                            (click)="selectedSubId(sub._id,sub.code)">
                                                                            {{ sub.code }}
                                                                        </a>
                                                                    </li>
                                                                </div>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="form-group " *ngIf="subCodeSelected">
                                            <label class="lbls" for="name">Select Issue
                                                Type</label>
                                            <select class="form-control form-select" formControlName="issueSubType"
                                                (change)="subCategoryList()">
                                                <option value="" selected disabled>Select sub category
                                                </option>
                                                <option *ngFor="let category of categories"
                                                    value="{{ category?.name }}">
                                                    {{ category?.name }}
                                                </option>
                                            </select>

                                        </div>

                                        <div class="form-group " *ngIf="subCategorySelected">
                                            <label class="lbls" for="name">Select Issue
                                                Sub-Type</label>
                                            <select class="form-control form-select" formControlName="issueSubSubType"
                                                (change)="subCategory()">
                                                <option value="" selected disabled>Select sub sub category
                                                </option>
                                                <option *ngFor="let subCategory of subCategories"
                                                    value="{{ subCategory?.name }}">
                                                    {{ subCategory?.name }}
                                                </option>
                                            </select>

                                        </div>
                                        <div class="form-group"
                                            *ngIf="selectedIssueType === 'bill' ||selectedIssueType === 'general' ||subSubSelected">
                                            <label class="lbls" for="name">Description</label>
                                            <textarea id="exampleFormControlTextarea1" rows="4" cols="30"
                                                placeholder="Type some description here..."
                                                formControlName="description" class="form-control txt-area"></textarea>
                                            <!-- <p class="mt-2 lbls">Attachment <i
                                                    class="bi bi-question-circle-fill text-uted"
                                                    ngbTooltip="Attach file(.jpeg, .jpg, .png)"></i></p>
                                            <input type="file" #fileInput (change)="handleFileChange(fileInput)" multiple accept="image/*"> -->
                                        </div>

                                    </div>
                                </div>
                                <div class="mt-3 float-end">
                                    <button type="button" class="btn btns-secondary me-2" (click)="cancel()">
                                        Cancel
                                    </button>
                                    <button type="button" class="btn btn-primary" [disabled]="!issueFormControl.valid"
                                        (click)="submit()">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>