import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ChangePlanComponent } from '../../modals/change-plan/change-plan.component';
import { ChangeCustomerComponent } from '../../modals/change-customer/change-customer.component';
import { ChangeFreeTrialComponent } from '../../modals/change-free-trial/change-free-trial.component';
import { ManageUsersComponent } from '../../modals/manage-users/manage-users.component';
import { environment } from 'src/environments/environment';
import { WalletService } from 'src/app/services/wallet.service';
import { AuthService } from 'src/app/services/auth.service';
import { ChangeSalesmanComponent } from '../../modals/change-salesman/change-salesman.component';
import { ProductListRoot } from 'src/app/interfaces/subscription/product';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { stateList } from '../../../../../state';
import { PlanUpgradeDowngradeComponent } from '../../modals/plan-upgrade-downgrade/plan-upgrade-downgrade.component';
import { RenewConfirmationComponent } from '../../modals/renew-confirmation/renew-confirmation.component';
import { ExpireComponent } from '../../modals/expire/expire.component';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { ChangePriceComponent } from '../../modals/change-price/change-price.component';
import { ReasonForChurnComponent } from '../../modals/reason-for-churn/reason-for-churn.component';
@Component({
  selector: 'app-subscriptions-details',
  templateUrl: './subscriptions-details.component.html',
  styleUrls: ['./subscriptions-details.component.scss']
})
export class SubscriptionsDetailsComponent implements OnInit {

  isShow:boolean =false
  constructor(
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private subscriptionService: SubscriptionsService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private userService: UserService
  ) { }

  activeSection: string = 'overview';
  bsModalRef!: BsModalRef;
  subId: string = ''
  subDetail: any
  customer: any
  stateList: any = stateList
  selectedState: any
  domainName: string = ''
  subActivityLog: any
  subBillInfo: any
  subPaymentData: any
  partnerId: any


  ngOnInit(): void {
    this.partnerId = localStorage.getItem('PartnerId')
    this.subId = this.activeRoute.snapshot.queryParamMap.get('subId') || '';
    this.domainName = this.activeRoute.snapshot.queryParamMap.get('domainName') || '';
    this.subscriptionDetails()
    this.subscriptionActivityLog()
  }


  toggleSection(section: string) {
    this.activeSection = section
  }

  subscriptionDetails() {
    this.subscriptionService.subscriptionDetails(this.subId).subscribe((res: any) => {
      if (res.success) {
        this.subDetail = res.subscription
        this.isShow= this.subDetail?.plan.code?.toLowerCase().includes("vm");
        this.customerDetail()
        this.subscriptionBillingInfo()
      }
    },
      (error) => {
        this.toastr.warning(error.error.message);
      }
    );
  }

  subscriptionActivityLog() {
    this.subscriptionService.subRecentActivityLog(this.subId).subscribe((res: any) => {
      if (res.success) {
        this.subActivityLog = res.subscriptionLogs
      }
    })
  }

  subscriptionBillingInfo() {
    this.subscriptionService.subBillingInfo(this.subDetail?._id, this.subDetail?.code).subscribe((res: any) => {
      if (res.success) {
        this.subBillInfo = res.billingData;
        this.subPaymentData = res.paymentData
      }
    })
  }

  customerDetail() {
    this.userService.userDetails(this.subDetail.customerId).subscribe((res: any) => {
      if (res.success) {
        this.customer = res.customer
        this.selectedState = stateList.find(state => state.key === res.customer?.billing?.state);
      }
    })
  }

  customerLogin(subscriptionId: string, customerId: string) {
    const payload = {
      subscriptionId: subscriptionId,
      customerId: customerId,
      isSalesMan: this.userType
    }
    this.userService.loginWithPartner(payload).subscribe((res: any) => {
      if (res.success) {
        const url = this.domainName
          ? `http://${this.domainName}/loginWithPartner?token=${res.user.token} `
          : `${environment.url}/loginWithPartner?token=${res.user.token}`;
        window.open(url, '_blank');
      }
    },
      (error) => {
        this.toastr.error(error.error.message)
      });
  }
  statusModal(id: string, isActive: boolean) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-sm',
      backdrop: 'static',
    })
    this.bsModalRef.content.statusId = id;
    // this.bsModalRef.content.status=status;
    this.bsModalRef.content.title = isActive ? 'Suspend' : 'Activate';
    this.bsModalRef.content.message = `Are you sure you want to ${isActive ? 'suspend' : 'activate'} this subscription?`;
    this.bsModalRef.content.note = `${isActive ? 'Suspension is separate from deletion. During renewal of suspended account, the subscription will auto renew/auto expire and amount will be deducted from your wallet.' : ''}`;
    this.bsModalRef.content.confirmText = 'Yes';
    this.bsModalRef.content.cancelText = 'Cancel';
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.statusChange();
      } else {
        this.statusChange();
      }
    });

  }


  statusChange() {
    const payload = {
      subscriptionId: this.subDetail._id,
      status: !this.subDetail.isActive
    }
    this.subscriptionService.toggleStatus(payload).subscribe((res: any) => {
      if (res.success) {
        this.subscriptionDetails()
        this.toastr.success(res.message)
      }
    },
      (error) => {
        this.toastr.error(error.error.message)
      })
  }



  changeCustomerIdModal() {
    this.bsModalRef = this.modalService.show(ChangeCustomerComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });

    this.bsModalRef.content.subscriptionCode = this.subDetail.code;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionDetails();
      }
    });
  }

  changeSalesmanModal(isSalesman: boolean) {
    this.bsModalRef = this.modalService.show(ChangeSalesmanComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef.content.isSalesman = isSalesman;
    this.bsModalRef.content.subscriptionId = this.subDetail.code;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionDetails();
      }
    });
  }

  openModal() {
    this.bsModalRef = this.modalService.show(ExpireComponent, {
      class: 'modal-sm',
      backdrop: 'static',
    });
    this.bsModalRef.content.subscriptionId = this.subDetail._id;
    this.bsModalRef.content.autoRenew = this.subDetail.isAutoRenew;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionDetails();
      }
    });
  }

  changePlan() {
    if (this.subDetail.plan.unitPrice > 5) {
      this.planUpgradeDowngrade()
    } else {
      this.moveToPaidPlan()
    }
  }

  moveToPaidPlan() {
    this.bsModalRef = this.modalService.show(ChangeFreeTrialComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });

    this.bsModalRef.content.subscriptionId = this.subDetail._id;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionDetails();
      }
    });
  }

  planUpgradeDowngrade() {
    this.bsModalRef = this.modalService.show(PlanUpgradeDowngradeComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });

    this.bsModalRef.content.subscriptionId = this.subDetail._id;
    this.bsModalRef.content.plan = this.subDetail.plan;
    this.bsModalRef.content.code = this.subDetail.code;
    this.bsModalRef.content.endDate = this.subDetail.endDate;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionDetails();
      }
    });
  }

  manageUsersModal() {
    this.bsModalRef = this.modalService.show(ManageUsersComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef.content.subscriptionId = this.subDetail.code;
    this.bsModalRef.content.subsId = this.subDetail._id;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionDetails();
      }
    });
  }

  renewConfirmation() {
    this.bsModalRef = this.modalService.show(RenewConfirmationComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });

    this.bsModalRef.content.subscriptionId = this.subDetail._id;
    this.bsModalRef.content.subCode = this.subDetail.code;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionDetails();
      }
    });
  }

  deleteSubscription() {
    this.bsModalRef = this.modalService.show(ChangePlanComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });

    this.bsModalRef.content.subscriptionId = this.subDetail.code;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionDetails();
      }
    });
  }

  get userType() {
    return this.authService.userType()
  }

  get isPartnerSalesman() {
    const userType = this.authService.userType()
    const partnerType = localStorage.getItem('partnerType');
    if (userType && this.partnerId != '645a4eca8c6428e9ac655957' && partnerType == 'subUser') {
      return false
    } else {
      return true
    }
  }

  changePrice() {
    this.bsModalRef = this.modalService.show(ChangePriceComponent, {
      class: 'modal-md',
      backdrop: 'static',
    })
    this.bsModalRef.content.CurrentUnitPrice = this.subDetail.plan.unitPrice
    this.bsModalRef.content.subscriptionId = this.subDetail._id;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res == 'ok') {
        this.subscriptionDetails();
        this.subscriptionActivityLog()
      }
    })
  }

  openReasonModal() {
    this.bsModalRef = this.modalService.show(ReasonForChurnComponent, {
      class: 'modal-md',
      backdrop: 'static',
    })
    this.bsModalRef.content.partnerId = this.subDetail.partnerId;
    this.bsModalRef.content.subId = this.subDetail.code;

    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res == 'ok') {
        this.subscriptionDetails();
      }
    })
  }
}