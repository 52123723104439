<div class="subscription-preview-modal px-4">
  <div>
    <h3 class="mt-2 mb-3">Subscription Preview</h3>
  </div>
  <div class="card">
    <div class="row">
      <div class="col-md-6">
        <h5 class="text-danger">Immediate Charges</h5>
        <p>On 27 feb 2020</p>
      </div>
      <div class="col-md-6 text-end">
        <h4><i class="bi bi-currency-rupee"></i> 602.00</h4>
      </div>
    </div>
  </div>
  <div class="card mt-4">
    <div class="row">
      <div class="col-md-6">
        <h5 class="text-info">Recurring Charges</h5>
        <p>On 27 feb 2020</p>
      </div>
      <div class="col-md-6 text-end">
        <h4><i class="bi bi-currency-rupee"></i> 602.00</h4>
      </div>
    </div>
  </div>
  <div class="text-end mt-4">
    <button type="button" class="comman-btn1 btn-primary me-2">Create</button>
    <button type="button" class="comman-btn1 btn-secondary text-light" (click)="bsModalRef.hide()">Back</button>
  </div>
</div>
