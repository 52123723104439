<div class="main-panel">
    <div class="content-wrapper">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-12 col-xl-8 mb-2 mb-xl-0">
                        <p class="heading">Reports</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-8  stretch-card grid-margin">
                <div class="card">
                    <div class="card-body">
                        <p class="trail">Free Trials:
                           
                            <!-- <span class="float-end">
                                <div class="float-end selected">
                                    <select class="dropdown">
                                        <option value="weekly">Today</option>
                                      <option value="weekly">Weekly</option>
                                      <option value="monthly">Monthly</option>
                                    </select>
                                  </div>
                            </span> -->
                        </p>
                        <p class="coming-soon text-center mt-5 mb-5">Coming Soon</p>
                        <div>

                        </div>
                        <!-- <div class="chart-container  mt-2">
                            <canvas id="lineCharts"></canvas>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-sm-4 stretch-card grid-margin ">
                <div class="card">
                    <div class="card-body">
                        <p class="trail">Large Customers:</p>
                        <p class="coming-soon text-center mt-5 mb-5">Coming Soon</p>
                        <!-- <div class="d-flex justify-content-center align-items-center mt-3 position-relative">
                            <svg xmlns="http://www.w3.org/2000/svg" width="200" height="200" viewBox="0 0 237 237"
                                fill="none">
                                <path
                                    d="M237 118.5C237 183.946 183.946 237 118.5 237C53.0543 237 0 183.946 0 118.5C0 53.0543 53.0543 0 118.5 0C183.946 0 237 53.0543 237 118.5ZM14.22 118.5C14.22 176.092 60.9077 222.78 118.5 222.78C176.092 222.78 222.78 176.092 222.78 118.5C222.78 60.9077 176.092 14.22 118.5 14.22C60.9077 14.22 14.22 60.9077 14.22 118.5Z"
                                    fill="url(#paint0_linear_147_827)" />
                                <defs>
                                    <linearGradient id="paint0_linear_147_827" x1="130.35" y1="12.4425" x2="231.667"
                                        y2="115.538" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#4B49AC" />
                                        <stop offset="1" stop-color="#E6EEFF" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            <span class="customer col-sm-5">More Than 50 Users</span>

                        </div>
                        <p class="sub-head text-center mt-4">Large Customers: More Than <span
                                class="text-danger">50</span> Users</p> -->
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                    <div class="card-body">
                        <p class="trail">Renewals:</p>
                        <p class="coming-soon text-center mt-5 mb-5">Coming Soon</p>
                        <!-- <div class="chart-container d-flex justify-content-center align-items-center ">
                            <canvas id="doughnutChart"></canvas>
                        </div> -->
                    </div>
                </div>
            </div>
            <div class="col-sm-6 stretch-card grid-margin">
                <div class="card">
                    <div class="card-body">
                        <p class="trail">Churned:</p>
                        <p class="coming-soon text-center mt-5 mb-5">Coming Soon</p>
                        <!-- <div class=" d-flex justify-content-center align-items-center">
                            <canvas id="barChart"></canvas>
                        </div> -->
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>