import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-roles',
  templateUrl: './add-roles.component.html',
  styleUrls: ['./add-roles.component.scss']
})
export class AddRolesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
