<div class="content-wrapper">
  <div class="row">
    <div class="heading ">Salesperson Report</div>
    <div class="col-sm-12">
      <form [formGroup]="searchForm">
        <div class="row mt-3 align-items-center">
          <div class="col-md-3" *ngIf="!hideSubDateRange">
            <mat-form-field appearance="fill" class="custom-date-picker">
              <mat-label>Choose a month and year</mat-label>
              <input matInput type="month" formControlName="date" min="2024-03" />
            </mat-form-field>


          </div>


          <div class="col-md-3" *ngIf="hideSubDateRange">
            <mat-form-field appearance="fill" lass="example-form-field w-100">
              <mat-label>Custom Date</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate formControlName="startDate" placeholder="From">
                <input matEndDate formControlName="endDate" placeholder="To">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker>
                <mat-date-range-picker-actions>
                  <button mat-button matDateRangePickerCancel>Cancel</button>
                  <button (click)="changeDate()" mat-raised-button color="primary"
                    matDateRangePickerApply>Apply</button>
                </mat-date-range-picker-actions>
              </mat-date-range-picker>
            </mat-form-field>
          </div>

        </div>
      </form>
      <div class="col-lg-12 mt-2">
        <div class="card ">
          <div class="card-body ">
            <div class="mb-4">
              <div class="d-flex flex-row justify-content-between">
                <div class="">
                  <button class="graph me-2" [ngClass]="activation=='mrr'?'active':''" (click)="toggle('mrr')">
                    MRR
                  </button>
                  <button class="graph" [ngClass]="activation=='subcription'?'active':''"
                    (click)="toggle('subcription')">Subscription
                  </button>
                </div>
                <div class="" *ngIf="hideSubDateRange">
                  <ul class=" list d-flex flex-row justify-content-center">
                    <li class="mx-3"> <span class="circle-green mx-1"></span> Subscriptions</li>
                    <li class=""><span class="circle-blue mx-1"></span> Users</li>
                  </ul>
                </div>
                <div class="text-end export" (click)="exportexcel()" *ngIf="!userType">
                  <span>EXPORT (EXCEL)</span>
                </div>
              </div>

            </div>
            <div class="row " *ngIf="!hideSubDateRange">
              <div class="col-12 table-responsive position-relative  ps-0 pe-0">
                <table class="table table-bordered  fixed-columns-table ">
                  <thead class=" thead-header">
                    <tr>
                      <th scope="col " class="sno">S.No</th>
                      <th scope="col " class="fixed-col ">Salesperson</th>

                      <th scope="col " class="col" (click)="sortData('netNewBiz')">New Business MRR <i
                          class="bi bi-question-circle me-1 "
                          ngbTooltip="Revenue increases when customers convert to paid subscriptions." ngb></i><i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col " class="col" (click)="sortData('netUpgrade')">Expansion MRR <i
                          class="bi bi-question-circle  me-1"
                          ngbTooltip="Revenue increases upon upgrades from subscribers."></i><i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col " class="col" (click)="sortData('netDowngrade')">Contraction MRR <i
                          class="bi bi-question-circle  me-1"
                          ngbTooltip="Revenue decreases upon downgrade from subscribers."></i><i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col" class="col" (click)="sortData('netChurn')">Churn MRR <i class="bi bi-question-circle  me-1"
                          ngbTooltip="Revenue lost from subscribers cancelling all their subscriptions."></i><i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col " class="col" (click)="sortData('netReactivation')">Reactivation MRR <i
                          class="bi bi-question-circle  me-1"
                          ngbTooltip="Revenue added when customers renew their subscriptions after expired"></i><i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col " class="col" (click)="sortData('netNetChurn')">Net Churn MRR <i
                          class="bi bi-question-circle  me-1"
                          ngbTooltip="Revenue lost from subscribers cancelling all their subscriptions."></i><i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col " class="col" (click)="sortData('netMrrMovement')">Net MRR Movement <i
                          class="bi bi-question-circle  me-1"
                          ngbTooltip="Net change in recurring revenue from the previous period."></i><i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col " class="col" (click)="sortData('previousMrrMovement')">Net Previous MRR <i
                          class="bi bi-question-circle  me-1"
                          ngbTooltip="Net change in recurring revenue from the previous period."></i><i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col " class="col" (click)="sortData('currentMrr')">Current MRR <i class="bi bi-arrow-down-up"></i>
                      </th>
                      <th scope="col " class="col" (click)="sortData('prevMonthMrr')">Previous MRR <i
                          class="bi bi-arrow-down-up"></i></th>
                      <th scope="col " class="col" (click)="sortData('percentageChange')">Change <i
                          class="bi bi-question-circle  me-1" ngbTooltip="Compared to the previous interval."></i><i
                          class="bi bi-arrow-down-up"></i></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of salespersonList;let i = index">
                      
                      <td class="sno">{{i + 1}}</td>
                      <td class="fixed-col"><b><a class="sub-link" [routerLink]="['/mrr-report']"
                            [queryParams]="{salesPersonId : list?._id}">{{list?.salesPerson |titlecase}}</a></b></td>

                      <td class="fixed-col" (click)="list?.newBizCount>0?mrrDetails(list?._id,'new subscription'):''"
                        [ngClass]="list?.newBizCount>0?'pointer':''">
                        <div class=" row d-flex justify-content-center align-items-center">
                          <div [ngClass]="list?.newBizCount>0?'col-7 text-end':'col-12 text-center'">
                            {{list?.newBizCount>0 ? (list?.netNewBiz|
                            currency:"INR"):'-'}}
                          </div>
                          <div *ngIf="list?.newBizCount>0" class="col-5"><span
                              class="text-success ms-2">{{list?.newBizCount}}</span>
                          </div>

                        </div>
                      </td>
                      <td class="fixed-col" (click)="list?.upgradeCount>0?mrrDetails(list?._id,'upgrade'):''"
                        [ngClass]="list?.upgradeCount>0?'pointer':''">
                        <div class=" row d-flex justify-content-center align-items-center">
                          <div [ngClass]="list?.upgradeCount>0?'col-7 text-end':'col-12 text-center'">
                            {{list?.upgradeCount>0?(list?.netUpgrade |
                            currency:"INR"):'-'}}
                          </div>
                          <div *ngIf="list?.upgradeCount>0" class="col-5"><span
                              class="text-success ms-2">{{list?.upgradeCount}}</span>
                          </div>

                        </div>
                      </td>
                      <td class="fixed-col" (click)="list?.downgradeCount>0?mrrDetails(list?._id,'downgrade'):''"
                        [ngClass]="list?.downgradeCount>0?'pointer':''">
                        <div class="row d-flex justify-content-center align-items-center">
                          <div [ngClass]="list?.downgradeCount>0?'col-7 text-end':'col-12 text-center'">
                            {{list?.downgradeCount>0?(list?.netDowngrade |
                            currency:"INR"):'-'}}</div>
                          <div *ngIf="list?.downgradeCount>0" class="col-5"><span
                              class="text-danger ms-2">{{list?.downgradeCount}}</span>
                          </div>

                        </div>
                      </td>
                      <td class="fixed-col" (click)="list?.churnCount>0?mrrDetails(list?._id,'churn'):''"
                        [ngClass]="list?.churnCount>0?'pointer':''">
                        <div class="row d-flex justify-content-center align-items-center">
                          <div [ngClass]="list?.churnCount>0?'col-7 text-end':'col-12 text-center'">
                            {{list?.churnCount?(list?.netChurn | currency:"INR"):'-'}}
                          </div>
                          <div *ngIf="list?.churnCount>0" class="col-5"><span
                              class="text-danger ms-2">{{list?.churnCount}}</span>
                          </div>

                        </div>
                      </td>
                      <td class="fixed-col" (click)="list?.reactivationCount>0?mrrDetails(list?._id,'reactivation'):''"
                        [ngClass]="list?.reactivationCount>0?'pointer':''">
                        <div class="row d-flex justify-content-center align-items-center">
                          <div [ngClass]="list?.reActivationCount>0?'col-7 text-end':'col-12 text-center'">
                            {{list?.reActivationCount>0?(list?.netReactivation |
                            currency:"INR"):"-"}}</div>
                          <div *ngIf="list?.reActivationCount>0" class="col-5"><span
                              class="text-success ms-2">{{list?.reActivationCount}}</span>
                          </div>

                        </div>
                      </td>
                      <td class="fixed-col" (click)="list?.netChurnCount>0?mrrDetails(list?._id,'netchurn'):''"
                        [ngClass]="list?.netChurnCount>0?'pointer':''">
                        <div class="row d-flex justify-content-center align-items-center">
                          <div [ngClass]="list?.netChurnCount>0?'col-7 text-end':'col-12 text-center'">
                            {{list?.netChurnCount?(list?.netNetChurn | currency:"INR"):'-'}}
                          </div>
                          <div *ngIf="list?.netChurnCount>0" class="col-5"><span
                              class="text-danger ms-2">{{list?.netChurnCount}}</span>
                          </div>

                        </div>
                      </td>
                      <td class="fixed-col">
                        <div class="d-flex justify-content-center align-items-center">
                          <div>{{list?.netMrrMovement | currency:"INR"}}</div>
                          <div><span class="ms-2">&nbsp;</span></div>
                        </div>
                      </td>
                      <td class="fixed-col">
                        <div class="d-flex justify-content-center align-items-center">
                          <div>{{list?.previousMrrMovement | currency:"INR"}}</div>
                          <div><span class="ms-2">&nbsp;</span></div>
                        </div>
                      </td>
                      <td class="fixed-col">
                        <div class="d-flex justify-content-center align-items-center">
                          <div>{{list?.currentMrr | currency:"INR"}}</div>
                          <div><span class="ms-2">&nbsp;</span></div>
                        </div>
                      </td>
                      <td class="fixed-col">
                        <div class="d-flex justify-content-center align-items-center">
                          <div>{{list?.prevMonthMrr | currency:"INR"}}</div>
                          <div><span class="ms-2">&nbsp;</span></div>
                        </div>
                      </td>
                      <td class="fixed-col">

                        <div class="d-flex justify-content-center align-items-center">
                          <div> <span [ngClass]="list?.percentageChange>0?'text-success':'text-danger'">
                              {{list?.percentageChange ? list?.percentageChange+'%' : '0%'
                              }}</span></div>
                          <div><span class="ms-2">&nbsp;</span></div>
                        </div>
                      </td>
                    </tr>

                    <tr class="fixed-col1">
                      <td scope="col ">&nbsp;</td>
                      <td><b>Total</b></td>

                      <td class="text-center"><strong>{{totals?.totalNetNewBiz | currency:"INR"}}</strong></td>
                      <td class="text-center"><strong>{{totals?.totalNetUpgrade | currency:"INR"}}</strong></td>
                      <td class="text-center"><strong>{{totals?.totalNetDowngrade | currency:"INR"}}</strong></td>
                      <td class="text-center"><strong>{{totals?.totalChurn | currency:"INR"}}</strong></td>
                      <td class="text-center"><strong>{{totals?.totalNetReactivation | currency:"INR"}}</strong>
                      </td>
                      <td class="text-center"><strong>{{totals?.totalNetChurn | currency:"INR"}}</strong></td>
                      <td class="text-center"><strong>{{totals?.totalNetMrrMovement | currency:"INR"}}</strong>
                      </td>
                      <td class="text-center"><strong>{{totals?.totalPrevMrrMovement | currency:"INR"}}</strong>
                      </td>
                      <td class="text-center"><strong>{{totals?.totalNetCurrentMrr | currency:"INR"}}</strong>
                      <td class="text-center"><strong>{{totals?.totalNetPrevMonthMrr | currency:"INR"}}</strong>
                      </td>
                      <td>&nbsp;</td>
                    </tr>


                  </tbody>
                </table>
              </div>

            </div>

            <div class="row " *ngIf="hideSubDateRange">
              <div class="col-12 table-responsive position-relative  ps-0 pe-0">
                <table class="table table-bordered  fixed-columns-table">
                  <thead class=" thead-header">
                    <tr>
                      <th scope="col ">&nbsp;</th>
                      <th scope="col ">&nbsp;</th>
                      <th scope="col ">&nbsp;</th>
                      <th scope="col " class="text-center" colspan="7">Renewal Summary</th>
                      <th scope="col ">&nbsp;</th>
                      <th scope="col " class="text-center" colspan="4">Trials</th>
                      <th scope="col ">&nbsp;</th>
                      <th scope="col ">&nbsp;</th>
                    </tr>
                    <tr>
                      <th scope="col " class="sno">S.No</th>
                      <th scope="col " class="fixed-col ">Salesperson</th>
                      <th  scope="col " (click)="sortData('totalSub')" class="text-center col">Total Subscriptions <i 
                          class="bi bi-arrow-down-up"></i></th>
                      <th  scope="col " (click)="sortData('newSub')" class="text-center col">New Subscriptions <i 
                          class="bi bi-arrow-down-up"></i></th>
                      <th class="col" scope="col " (click)="sortData('totalRenewals')">Total Renewals <i 
                          class="bi bi-arrow-down-up"></i></th>
                      <th class="col" scope="col " (click)="sortData('renewedSub')" >Renewed <i class="bi bi-arrow-down-up"></i></th>
                      <th class="col" scope="col " (click)="sortData('upgradeSub')">Upgrades <i  class="bi bi-arrow-down-up"></i>
                      </th>
                      <th class="col" scope="col " (click)="sortData('downgradeSub')">Downgrade <i  class="bi bi-arrow-down-up"></i>
                      </th>
                      <th class="col" scope="col " (click)="sortData('upcomingRenewals')">Upcoming Renewals <i 
                          class="bi bi-arrow-down-up"></i></th>
                      <th class="col" scope="col " (click)="sortData('expiredSub')">Expired <i  class="bi bi-arrow-down-up"></i></th>
                      <th class="col" scope="col " (click)="sortData('deletedSub')">Deleted <i  class="bi bi-arrow-down-up"></i></th>
                      <th class="col" scope="col " (click)="sortData('riskySub')" class="text-center col">Risky Customers Value <i 
                          class="bi bi-arrow-down-up"></i></th>
                      <th class="col" scope="col " (click)="sortData('totalTrialSub')">Trial Signups <i 
                          class="bi bi-arrow-down-up"></i></th>
                      <th class="col" scope="col " (click)="sortData('totalExpiredSub')">Trial Expired <i 
                          class="bi bi-arrow-down-up"></i></th>
                      <th class="col" scope="col " (click)="sortData('trialToPaidSub')">Trial to Paid <i 
                          class="bi bi-arrow-down-up"></i></th>
                      <th class="col" scope="col " (click)="sortData('liveTrialSub')">Live Trial <i  class="bi bi-arrow-down-up"></i>
                      </th>
                      <th class="col" scope="col " (click)="sortData('currentMrr')" class="text-center col">Current MRR <i 
                          class="bi bi-arrow-down-up"></i></th>
                      <!-- <th scope="col ">&nbsp;</th>
                                            <th scope="col ">&nbsp;</th> -->

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let list of salesPersonSubList; let i = index">
                      <td class="sno">{{i+1}}</td>
                      <td class="fixed-col"><b>{{list?.salesPerson |titlecase}}</b></td>
                      <!-- <td class="fixed-col">30</td> -->
                      <td class="fixed-col ">
                        <div class="d-flex flex-row justify-content-between">
                          <span> {{list?.totalSub?.amount | currency:"INR"}}</span>

                          <span class="sub"> {{list?.totalSub?.count}}
                            <span class="users"> ({{list?.totalSub?.noOfUsers}})</span>
                          </span>

                        </div>
                      </td>
                      <td class="fixed-col  ">
                        <div class="d-flex flex-row justify-content-between">
                          <span>
                            {{list?.newSub?.amount | currency:"INR"}}
                          </span>
                          <span class="sub">
                            {{list?.newSub?.count}}
                            <span class="users">
                              ({{list?.newSub?.noOfUsers}})
                            </span>
                          </span>
                        </div>
                      </td>
                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.totalRenewals?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ list?.totalRenewals?.count }}
                            <span class="users">({{ list?.totalRenewals?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.renewedSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ list?.renewedSub?.count }}
                            <span class="users">({{ list?.renewedSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.upgradeSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ list?.upgradeSub?.count }}
                            <span class="users">({{ list?.upgradeSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.downgradeSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ list?.downgradeSub?.count }}
                            <span class="users">({{ list?.downgradeSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.upcomingRenewals?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ list?.upcomingRenewals?.count }}
                            <span class="users">({{ list?.upcomingRenewals?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.expiredSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ list?.expiredSub?.count }}
                            <span class="users">({{ list?.expiredSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.deletedSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ list?.deletedSub?.count }}
                            <span class="users">({{ list?.deletedSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.riskySub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ list?.riskySub?.count }}
                            <span class="users">({{ list?.riskySub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.totalTrialSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ list?.totalTrialSub?.count }}
                            <span class="users">({{ list?.totalTrialSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.totalExpiredSub.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ list?.totalExpiredSub?.count }}
                            <span class="users">({{ list?.totalExpiredSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.trialToPaidSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ list?.trialToPaidSub?.count }}
                            <span class="users">({{ list?.trialToPaidSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.liveTrialSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ list?.liveTrialSub?.count }}
                            <span class="users">({{ list?.liveTrialSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ list?.currentMrr | currency:"INR" }}</span>
                        </div>
                      </td>

                    </tr>
                    <tr class="fixed-col1">
                      <td scope="col ">&nbsp;</td>
                      <td><b>Total</b></td>
                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.totalSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.totalSub?.count }}
                            <span class="users">({{ salesPersonSubTotal?.totalSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.newSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.newSub?.count }}
                            <span class="users">({{ salesPersonSubTotal?.newSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.totalRenewals?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.totalRenewals?.count }}
                            <span class="users">({{ salesPersonSubTotal?.totalRenewals?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.renewedSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.renewedSub?.count }}
                            <span class="users">({{ salesPersonSubTotal?.renewedSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.upgradeSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.upgradeSub?.count }}
                            <span class="users">({{ salesPersonSubTotal?.upgradeSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.downgradeSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.downgradeSub?.count }}
                            <span class="users">({{ salesPersonSubTotal?.downgradeSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.upcomingRenewals?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.upcomingRenewals?.count }}
                            <span class="users">({{ salesPersonSubTotal?.upcomingRenewals?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.expiredSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.expiredSub?.count }}
                            <span class="users">({{ salesPersonSubTotal?.expiredSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.deletedSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.deletedSub?.count }}
                            <span class="users">({{ salesPersonSubTotal?.deletedSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.riskySub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.riskySub?.count }}
                            <span class="users">({{ salesPersonSubTotal?.riskySub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.totalTrialSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.totalTrialSub?.count }}
                            <span class="users">({{ salesPersonSubTotal?.totalTrialSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.totalExpiredSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.totalExpiredSub?.count }}
                            <span class="users">({{ salesPersonSubTotal?.totalExpiredSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.trialToPaidSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.trialToPaidSub?.count }}
                            <span class="users">({{ salesPersonSubTotal?.trialToPaidSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.liveTrialSub?.amount | currency:"INR" }}</span>
                          <span class="sub">
                            {{ salesPersonSubTotal?.liveTrialSub?.count }}
                            <span class="users">({{ salesPersonSubTotal?.liveTrialSub?.noOfUsers }})</span>
                          </span>
                        </div>
                      </td>

                      <td class="fixed-col">
                        <div class="d-flex flex-row justify-content-between">
                          <span>{{ salesPersonSubTotal?.currentMrr | currency:"INR" }}</span>
                        </div>
                      </td>

                    </tr>
                  </tbody>
                </table>
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="col-lg-12 mt-3" *ngIf="isShowDetails" #scrollContainer>
        <div class="  sub-heading ">Activity <span class="float-end excel" (click)="exportexcelDetails()"
            *ngIf="!userType">EXPORT (EXCEL)</span></div>
        <div class="card  mt-3">
          <div class="card-body">
            <div class="col-lg-12 table-responsive table-responsive1">
              <table class="table table-bordered" id="mrr-detail-table">
                <thead class="roundedTable thead-header">
                  <tr>
                    <th scope="col">Description</th>
                    <th scope="col">Sub Id</th>
                    <th scope="col">Partner Email</th>
                    <th scope="col">Relationship Manager </th>
                    <th scope="col">Salesperson</th>
                    <th scope="col">Customer Email</th>
                    <th scope="col">MRR Movement</th>
                    <th scope="col">Type</th>
                    <th scope="col">Date</th>

                  </tr>
                </thead>
                <tbody class="custom-table1">

                  <tr *ngFor="let details of mrrDetail">
                    <td>
                      <div class="d-inline-block text-truncate" style="max-width: 200px;"
                        ngbTooltip="{{details?.description}}">{{details?.description}}</div>
                    </td>
                    <td>
                      <a class="sub-link" [routerLink]="['/subscriptions-details']"
                        [queryParams]="{ subId: details?.subId, domainName: domainName  }">
                        {{details?.subId}}</a>

                    </td>
                    <td>{{details?.partnerEmail?details?.partnerEmail:'NA'}}</td>
                    <td>{{details?.subscription?.relationshipManager?.name ?
                      details?.subscription?.relationshipManager?.name : 'NA'}}</td>
                    <td>{{details?.subscription?.salesPerson?.name ? details?.subscription?.salesPerson?.name: 'NA'}}
                    </td>
                    <td>{{details?.customerEmail?details?.customerEmail:'NA'}}</td>
                    <td>{{details?.mrrMovement ? (details?.mrrMovement| currency:"INR"):'NA'}}</td>
                    <td>{{details?.billingType? (details?.billingType | titlecase):'NA'}}</td>
                    <td>{{details?.createdAt ? (details?.createdAt| date):''}}</td>
                  </tr>

                </tbody>

              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>