import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-partner-details',
  templateUrl: './partner-details.component.html',
  styleUrls: ['./partner-details.component.scss']
})
export class PartnerDetailsComponent implements OnInit {

  constructor(
    private user : UserService,
    private activeRoute : ActivatedRoute,
    private router : Router
  ) { }

  ngOnInit(){
    this.getUserId()
  }

  id : any;
  details : any; 
  isEdit! : boolean;  
  
  getUserId(){
    this.id = this.activeRoute.snapshot.queryParamMap.get('customerId')
    this.displayDetails(this.id)    
  }

  displayDetails(customerID:any){
    this.user.userDetails(customerID).subscribe((res:any)=>{
      if(res.success){
        this.details = res.customer       
      }
    })
  }

  customerEdit(id:any){    
    this.router.navigate(['add-account'],
    {queryParams:{customerId : id}});
  }
}
