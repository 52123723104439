<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
 <div class="main-panel">
  <div class="content-wrapper">
   <div class="row">
    <div class="col-md-12 grid-margin">
     <div class="col-12 col-xl-8 mb-4 mb-xl-0 heading-section">
      <h3 class="font-weight-bold"><i class="bi bi-arrow-left-short fs-2" [routerLink]="'/product'"></i>
       Plans and Add-on</h3>
     </div>
     <!-- <form [formGroup]="productForm">
            <div class="row mb-4 mx-4">
              <div class="col-md-6 mt-4">
                <div class="row mb-3">
                    <label for="addLabel" class="col-sm-5 col-form-label text-dark">Product Name*</label>
                    <div class="col-sm-7 mt-2">
                      <input type="text" class="form-control custom-select" aria-describedby="emailHelp"
                       formControlName="name" readonly>
                    </div>
                </div> 
            </div>
            <div class="col-md-6 mt-4">
              <div class="row mb-3">
                  <label for="addLabel" class="col-sm-5 col-form-label text-dark">Product Description*</label>
                  <div class="col-sm-7 mt-2">
                    <input type="text" class="form-control custom-select" aria-describedby="emailHelp"
                     formControlName="description" readonly>
                  </div>
              </div>  
            </div>         
            </div>
            </form> -->

     <!-- ------card--section------------ -->
     <div class="row mt-4 mx-2">
      <div class="col-md-12 grid-margin stretch-card">
       <div class="card">
        <div class="card-body">
         <div class="row">
          <div class="col-md-6">
           <p class="card-title">Product Name</p>
           <p>{{productDetail?.name | titlecase}}</p>
          </div>

          <div class="col-md-6">
           <p class="card-title">Product Description</p>
           <p>{{productDetail?.description}}</p>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
     <!-- -------end----------- -->
     <!-- -------table--section------ -->
     <div class="row table-section mx-2">
      <div class="col-md-12 grid-margin stretch-card">
       <div class="card">
        <div class="card-body">
         <div class="row">
          <div class="col-md-6">
           <p class="card-title mb-0">Plan</p>
          </div>
         </div>

         <div class="table-responsive">
          <table class="table table-striped table-borderless" *ngIf="(planList.length)>0">
           <thead>
            <tr>
             <th scope="col">Name</th>
             <th scope="col">Code</th>
             <th scope="col">Unit</th>
             <th scope="col">Price</th>
             <th scope="col">Billing Cycle</th>
            </tr>
           </thead>
           <tbody>
            <tr *ngFor="let list of planList">
             <td>{{list?.name | titlecase}}</td>
             <td>{{list?.code}}</td>
             <td>{{list?.unit | titlecase}}</td>
             <td>{{list?.price}}</td>
             <td>{{list?.billEvery}} {{list?.billingCycle}}</td>
            </tr>
           </tbody>
          </table>
          <div class="text-center" *ngIf="(planList.length) === 0">
           <p class="fw-bold">No Plans found for this product</p>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
     <!-- --------end--------- -->
     <!-- --------------------Add On Table----------------------- -->
     <div class="row table-section mx-2">
      <div class="col-md-12 grid-margin stretch-card">
       <div class="card">
        <div class="card-body">
         <div class="row">
          <div class="col-md-6">
           <p class="card-title mb-0">Add-on</p>
          </div>
         </div>
         <div class="table-responsive">
          <table class="table table-striped table-borderless" *ngIf="(addOnList.length)>0">
           <thead>
            <tr>
             <th scope="col">Name</th>
             <th scope="col">Code</th>
             <th scope="col">Unit</th>
             <th scope="col">Price</th>
             <th scope="col">Pricing Interval</th>
            </tr>
           </thead>
           <tbody>
            <tr *ngFor="let list of addOnList">
             <td>{{list?.name | titlecase}}</td>
             <td>{{list?.code}}</td>
             <td>{{list?.unit | titlecase}}</td>
             <td>{{list?.price}}</td>
             <td>{{list?.pricingEvery}} {{list?.pricingInterval}}</td>
            </tr>
           </tbody>
          </table>
          <div class="text-center" *ngIf="addOnList.length === 0">
           <p class="fw-bold">No AddOns found for this product</p>
          </div>
         </div>
        </div>
       </div>
      </div>
     </div>
     <!-- --------------Add On Table----end------ -->
    </div>
   </div>
  </div>
 </div>
</div>
<!-- ----------- -->