import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';

@Component({
  selector: 'app-assgin-user',
  templateUrl: './assgin-user.component.html',
  styleUrls: ['./assgin-user.component.scss']
})

export class AssginUserComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();

  @Input() selectedIds: any
  @Input() salesmans: any
  @Input() relationshipManager: any

  assignUserForm = new FormGroup({
    salesPersonId: new FormControl(''),
    relationshipManagerId: new FormControl(''),
  })

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private subscriptionsService: SubscriptionsService
  ) { }
  ngOnInit(): void {

    setTimeout(() => {
      this.salesmans = this.salesmans.filter((salesman: any) => salesman.isActive && !salesman.isDeleted);
      this.relationshipManager = this.relationshipManager.filter((manager: any) => manager.isActive && !manager.isDeleted);
    });

  }

  closedModal() {
    this.event.emit('modalClosed');
    this.bsModalRef.hide();
  }
  submit() {
    debugger
    const salespersonSelected = this.assignUserForm.get('salesPersonId')?.value
    const relationshipManagerSelected = this.assignUserForm.get('relationshipManagerId')?.value
    if (salespersonSelected || relationshipManagerSelected) {
     
      const assignPayload = {
        salesPersonId: this.assignUserForm.get('salesPersonId')?.value,
        relationshipManagerId : this.assignUserForm.get('relationshipManagerId')?.value,
        subscriptionIds: this.selectedIds
      }

      this.subscriptionsService.assignUser(assignPayload).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.message)
          this.event.emit('ok');
          this.closedModal()
        }
      }
      ,(err) => {
        this.toastr.error(err.error.message);
      }
    )
    }
    else{
      this.toastr.warning("At least one field reqired.")
    }
  }
}
