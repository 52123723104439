import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, Subject, Subscription } from 'rxjs';
import { AuditLogService } from 'src/app/services/audit-log.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-audit-log',
  templateUrl: './audit-log.component.html',
  styleUrls: ['./audit-log.component.scss']
})
export class AuditLogComponent implements OnInit {

  auditLogs: any = []
  isAuditLog = false
  searchText: string = ''
  search$ = new Subject<void>()
  searchSubscription$!: Subscription;
  totalCount: number = 0
  page: number = 1
  config = {
    itemsPerPage: 10,
    currentPage: this.page,
    totalItems: this.totalCount
  }; 

  constructor( private auditLogService: AuditLogService,
    private authService: AuthService,
    private toasts: ToastrService) { }

  ngOnInit(): void {
    this.auditLog()
    this.searchSubscription$ = this.search$.pipe(debounceTime(600)).subscribe(() => {
      this.auditLog()
    })
  }

  searchVMLogs(event: any) {
    this.searchText = event.target.value
    this.config.currentPage = 1;
    this.search$.next();
  }

  auditLog() {
    this.auditLogService.auditLogList(this.config.currentPage, this.config.itemsPerPage, this.searchText).subscribe((res: any) => {
      if (res.success) {
        if (res.partnerLogs.length == 0) {
          this.toasts.error('No data found!')
          this.isAuditLog = false
          this.config.currentPage = 1;
          this.config.totalItems = 0;
          this.auditLogs=[]
        }
        else {
          this.auditLogs = res.partnerLogs;
          this.isAuditLog = true
          this.config.currentPage = res.totalPartnerLog.pages;
          this.config.totalItems = res.totalPartnerLog.total;
        }
      }
    }, (error) => {
      this.toasts.error(error.error.message);
    })
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    this.auditLog()
  }

 get updatePaginationText(){
    return this.authService.getPaginationText(this.config.currentPage,this.config.itemsPerPage,this.config.totalItems);
  }
}
