import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'subFilter'
})
export class SubFilterPipe implements PipeTransform {

  transform(items: any[], searchText: string): any[] {
    if (!items || !searchText) {
      return items;
    }
    searchText = searchText.toLowerCase();

    // return items.filter(item => item.code.toLowerCase().includes(searchText));

    return items.filter(item => {
      if(item.code){
        return item.code.toLowerCase().includes(searchText)
      }
      else if(item.name){
        return item.name.toLowerCase().includes(searchText)
      }
    });
  }

}

