import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BillingMonthlyRoot, BillingRoot } from '../interfaces/billing';

@Injectable({
  providedIn: 'root'
})
export class BillingService {

  constructor(private http: HttpClient) { }


  getList(search: any,limit: number, page: number,) {
    return this.http.get<BillingRoot>(`${environment.apiURL}/partner/bill?search=${search}&limit=${limit}&page=${page}`)
  }

  getMonthlyList(search: any,limit: number, page: number, month: number, year: number) {
    return this.http.get<BillingMonthlyRoot>(`${environment.apiURL}/partner/bill/details?search=${search}&limit=${limit}&page=${page}&month=${month}&year=${year}`)
  }
}
