
<div class="common-modal">
    <div class="modal-header border-0 py-0">
        <p class="modal-title pull-left border-0 title">Are you sure you want to set this subscription for {{autoRenew ?'Auto Expire':'Auto Renew'}}?</p>
        <i class="bi bi-x"  (click)="closedModal()"></i>
    </div>
    <!-- <div class="modal-body  pt-1 pb-1">
        <p> </p>
       
    </div> -->
    
    
    <div class="text-center border-0 ps-4 custome-btn mt-3">
        <button type="button" class="me-3 no-btn" (click)="closedModal()">NO</button>
        <button type="button" class="text-light me-4 yes-btn" (click)="setForRenew()">YES</button>
    </div>
    </div>
