import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { PlanService } from 'src/app/services/plan.service';
import { ToastrService } from 'ngx-toastr';
import { WalletService } from 'src/app/services/wallet.service';
import { CustomerAddUpdateRoot } from 'src/app/interfaces/customer/add-and-update';



@Component({
  selector: 'app-add-account',
  templateUrl: './add-account.component.html',
  styleUrls: ['./add-account.component.scss']
})
export class AddAccountComponent implements OnInit {

  ngOnInit() {
    this.editUser()
  }

  id: any;
  isEdit: boolean = false;
  planList!: any[];
  planName!: string;
  planValue!: any;
  showPassword1: boolean = false;
  showPassword2: boolean = false;

  constructor(
    private router: Router,
    private user: UserService,
    private activeRoute: ActivatedRoute,
    private planService: PlanService,
    private toastr: ToastrService,
    private walletService: WalletService
  ) { }

  accountForm = new FormGroup({
    name: new FormControl('', Validators.required),
    companyName: new FormControl('', Validators.required),
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    confirmPassword: new FormControl('', Validators.required),
    mobile: new FormControl('', Validators.required),
  });

  addAccount() {
    const userPayload = {
      name: this.accountForm.controls.name.value,
      companyName: this.accountForm.controls.companyName.value,
      email: this.accountForm.controls.email.value?.trim(),
      password: this.accountForm.controls.password.value,
      mobile: this.accountForm.controls.mobile.value,
    }

    if (this.accountForm.controls.password.value !==
      this.accountForm.controls.confirmPassword.value) {
      this.toastr.warning("Re-entered password does not match with password ")
    }
    else {
      this.user.userAdd(userPayload).subscribe((res: CustomerAddUpdateRoot) => {
        if (res.success) {
          this.walletService.showWalletAmount()
          this.router.navigate(['account'])
          this.toastr.success("Customer added successfully")
        }
      },
        (error) => {
          this.toastr.error(error.error.message)
        }
      )
    }
  }

  updateAccount() {
    const userPayload = {
      name: this.accountForm.controls.name.value,
      companyName: this.accountForm.controls.companyName.value,
      email: this.accountForm.controls.email.value,
      mobile: this.accountForm.controls.mobile.value,
      customerId: this.id
    }
    this.user.userUpdate(userPayload).subscribe((res: CustomerAddUpdateRoot) => {
      if (res.success) {
        this.toastr.info("Customer Profile updated")
        this.router.navigate(['account'])
      }
    }, (error) => {
      this.toastr.error(error.error.message)
    }
    )
  }

  editUser() {
    this.id = this.activeRoute.snapshot.queryParamMap.get('customerId')
    if (this.id != null) {
      this.isEdit = true
      this.showDetails(this.id)
      this.accountForm.get('planId')?.disable();
    }
  }

  showDetails(customerID: any) {
    this.user.userDetails(customerID).subscribe((res: CustomerAddUpdateRoot) => {
      if (res.success) {
        this.accountForm.patchValue(res.customer)
      }
    })
  }

  hidePassword1() {
    this.showPassword1 = !this.showPassword1;
    (document.getElementById("pass") as HTMLInputElement).type = this.showPassword1 ? 'text' : 'password';
  }

  hidePassword2() {
    this.showPassword2 = !this.showPassword2;
    (document.getElementById("repass") as HTMLInputElement).type = this.showPassword2 ? 'text' : 'password';
  }



}
