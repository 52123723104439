<!-- -----branding ----page ---start------>
<div class="container-scroller">
 <div class="main-panel">
  <div class="content-wrapper">
   <div class="row">
    <div class="col-md-12 grid-margin">
     <div class="row">
      <div class="col-12 mb-4">
       <h3 class="font-weight-bold">Branding</h3>
       <span class="text-primary">Set Branding - Step 1 of 5</span>
      </div>
      <!-- ------------------- -->
      <div class="col-md-12 grid-margin stretch-card">
       <div class="card brand-card">
        <div class="card-body py-0 px-0">
         <button type="button" class="comman-btn1 btn-primary">1. Domain</button>
         <button type="button" class="comman-btn1 btn-primary ms-2">2. Website</button>
         <!-- --------------brand-section-------------- -->
         <div class="brand-section">
          <h4><i class="bi bi-building font-weight-light"></i> Brand Name</h4>
          <div class="brand-label">
           <p>This information will be displayed on the customer’s web console and
            software</p>
           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Brand Name</label>
            <div class="col-sm-6 mt-2">
             <input type="text" class="form-control" id="mobile" formControlName="mobile">
            </div>
            <div class="col-sm-3 mt-2">
             <button type="button" class="comman-btn1 btn-primary">Change</button>
            </div>
           </div>
          </div>
         </div>
         <!-- -------end--------- -->
         <!-- --------------brand-section-------------- -->
         <div class="brand-section">
          <h4><i class="bi bi-envelope-at font-weight-light"></i> Email</h4>
          <div class="brand-label">
           <p>Specify the sender’s name and email you would like the system emails to
            be sent in</p>
           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Sender's Name</label>
            <div class="col-sm-6 mt-2">
             <input type="text" class="form-control" id="Name" formControlName="Name">
            </div>

           </div>
           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Sender's EMail</label>
            <div class="col-sm-6 mt-2">
             <input type="email" class="form-control" id="Email" formControlName="Email">
            </div>
            <div class="col-sm-3 mt-2">
             <button type="button" class="comman-btn1 btn-primary">Validate
              Email</button>
            </div>
           </div>
          </div>
         </div>
         <!-- -------end--------- -->
         <!-- --------------brand-section-------------- -->
         <div class="brand-section">
          <h4><i class="bi bi-hdd-network font-weight-light"></i> Art Work</h4>
          <div class="brand-label">
           <p>This information will be displayed on the customer’s web console and
            software</p>
           <div class="row mb-3">
            <label for="text" class="col-sm-2 col-form-label">Logo</label>
            <div class="col-sm-4 mt-2">
             <div class="choose-file">
              <img src="../../../assets/images/comhard-logo.svg" alt="" class="mt-2">
              <input id="inptLogo2" accept=".ico" type="file" name="file" class="btn btn-info inputFile">
             </div>
            </div>
            <div class="col-sm-6 mt-2">
             This logo will be used in the customer's control panel and reseller
             system. Your logo should be PNG, transparent preferred, maximum
             dimensions of 160px * 30px and minimum dimentions of 16px * 16px:
             Favicon
            </div>
           </div>
           <div class="row mb-3">
            <label for="text" class="col-sm-2 col-form-label">Favicon</label>
            <div class="col-sm-4 mt-2">
             <div class="choose-file">
              <img src="../../../assets/images/comhard-logo.svg" alt="" class="mt-2">
              <input id="inptLogo2" accept=".ico" type="file" name="file" class="btn btn-info inputFile">
             </div>
            </div>
            <div class="col-sm-6 mt-2">
             Windows .ico file (16px X 16px or 32px X 32px)
            </div>
           </div>
          </div>
         </div>
         <!-- -------end--------- -->
         <!-- ---------Links--section-------------- -->
         <div class="brand-section">
          <h4><i class="bi bi-link font-weight-light"></i> Links</h4>
          <div class="brand-label">
           <p>These links should guide to your site when the user clicks on them in the
            program</p>
           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Sign in Page</label>
            <div class="col-sm-8 mt-2">
             <input type="text" class="form-control" placeholder="https://company6938.backupinsta.com/Signin" id="Email"
              formControlName="Email">
            </div>
            <div class="col-sm-1 mt-3">
             <i class="bi bi-info-circle text-info fs-5"></i>
            </div>
           </div>

           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Contact
             Support</label>
            <div class="col-sm-8 mt-2">
             <input type="email" class="form-control" id="Email" formControlName="Email">
            </div>
            <div class="col-sm-1 mt-3">
             <i class="bi bi-info-circle text-info fs-5"></i>
            </div>
           </div>

           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Online Help</label>
            <div class="col-sm-8 mt-2">
             <input type="email" class="form-control" id="Email" formControlName="Email">
            </div>
            <div class="col-sm-1 mt-3">
             <i class="bi bi-info-circle text-info fs-5"></i>
            </div>
           </div>

           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Upgrade</label>
            <div class="col-sm-8 mt-2">
             <input type="email" class="form-control" id="Email" formControlName="Email">
            </div>
            <div class="col-sm-1 mt-3">
             <i class="bi bi-info-circle text-info fs-5"></i>
            </div>
           </div>

           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Reset Password</label>
            <div class="col-sm-8 mt-2">
             <input type="email" class="form-control" id="Email" formControlName="Email">
            </div>
            <div class="col-sm-1 mt-3">
             <i class="bi bi-info-circle text-info fs-5"></i>
            </div>
           </div>

           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">About Product</label>
            <div class="col-sm-8 mt-2">
             <input type="email" class="form-control" id="Email" formControlName="Email">
            </div>
            <div class="col-sm-1 mt-3">
             <i class="bi bi-info-circle text-info fs-5"></i>
            </div>
           </div>
          </div>
         </div>
         <!-- -------end--------- -->
         <!-- --------------Sign Up Page--section-------------- -->
         <div class="brand-section">
          <h4><i class="bi bi-person-fill-add font-weight-light"></i> Sign Up Page</h4>
          <div class="brand-label">
           <p>Specify the sender’s name and email you would like the system emails to
            be sent in</p>
           <div class="form-check">
            <input class="form-check-input m-0" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
            <label class="form-check-label" for="flexRadioDefault1">
             Use contact us page
             <p>https://company6938.backupinsta.com/contact-us</p>
            </label>
           </div>
           <div class="form-check">
            <input class="form-check-input m-0" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
            <label class="form-check-label" for="flexRadioDefault2">
             Use your own sign up page
            </label>
           </div>
           <div class="form-check">
            <input class="form-check-input m-0" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
            <label class="form-check-label" for="flexRadioDefault2">
             Use our generic signup page
            </label>
           </div>
           <div class="form-check">
            <input class="form-check-input m-0" type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
             Send signup email notification
            </label>
           </div>
           <p>Send signup email notification to :</p>
           <div class="form-check">
            <input class="form-check-input m-0" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
            <label class="form-check-label" for="flexRadioDefault2">
             All admins
            </label>
           </div>
           <div class="form-check">
            <input class="form-check-input m-0" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
            <label class="form-check-label" for="flexRadioDefault2">
             Specific admins
            </label>
           </div>
          </div>
         </div>
         <!-- -------end--------- -->
         <!-- -------------Terms Of Use------------- -->
         <div class="brand-section">
          <h4><i class="bi bi-file-text font-weight-light"></i> Terms Of Use</h4>
          <div class="brand-label">
           <div class="form-check mt-4">
            <input class="form-check-input m-0" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
            <label class="form-check-label" for="flexRadioDefault1">
             Use our dynamic terms of use page
            </label>
           </div>
           <div class="row">
            <label for="text" class="col-sm-3 col-form-label">Terms of
             Service</label>
            <div class="col-sm-9 mt-3">
             <a href="#">https://company6938.backupinsta.com/terms#terms</a>
            </div>
           </div>
           <div class="row">
            <label for="text" class="col-sm-3 col-form-label">Product
             Agreement</label>
            <div class="col-sm-9 mt-3">
             <a href="#">https://company6938.backupinsta.com/terms#privacy</a>
            </div>
           </div>
           <div class="row">
            <label for="text" class="col-sm-3 col-form-label">Privacy Policy</label>
            <div class="col-sm-9 mt-3">
             <a href="#">https://company6938.backupinsta.com/terms#privacy</a>
            </div>
           </div>
           <div class="row">
            <label for="text" class="col-sm-3 col-form-label">Third-Party
             Licenses</label>
            <div class="col-sm-9 mt-3">
             <a href="#">https://company6938.backupinsta.com/terms#thirdPartyLicenses</a>
            </div>
           </div>

           <div class="form-check">
            <input class="form-check-input m-0" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked>
            <label class="form-check-label" for="flexRadioDefault2">
             Use your own terms of use page
            </label>
           </div>
           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Terms of
             Service</label>
            <div class="col-sm-9 mt-2">
             <input type="email" class="form-control" placeholder="Page Link" id="Email" formControlName="Email">
            </div>
           </div>
           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Product
             Agreement</label>
            <div class="col-sm-9 mt-2">
             <input type="email" class="form-control" placeholder="Page Link" id="Email" formControlName="Email">
            </div>
           </div>
           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Privacy Policy</label>
            <div class="col-sm-9 mt-2">
             <input type="email" class="form-control" placeholder="Page Link" id="Email" formControlName="Email">
            </div>
           </div>
           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Third-Party
             Licenses</label>
            <div class="col-sm-9 mt-2">
             <input type="email" class="form-control" placeholder="Page Link" id="Email" formControlName="Email">
            </div>
           </div>
           <!--  -->
           <div class="form-check">
            <input class="form-check-input m-0" type="checkbox" value="" id="flexCheckDefault">
            <label class="form-check-label" for="flexCheckDefault">
             Use your own DPA terms of use page
            </label>
           </div>
           <div class="row mb-3">
            <label for="text" class="col-sm-3 col-form-label">Data Processing
             Agreement</label>
            <div class="col-sm-9 mt-2">
             <input type="email" class="form-control" placeholder="Page Link" id="Email" formControlName="Email">
            </div>
           </div>
           <div class="row mb-3">
            <div class="col-sm-12 text-center mt-2">
             <p>Example: <span><a href="#">https://portal.backupinsta.com/Data-Processing-Agreement</a></span>
             </p>
            </div>
           </div>
           <!-- -- -->
          </div>
          <!-- -------end--------- -->
         </div>
         <div class="col-md-12 mt-3 text-end">
          <button type="button" class="comman-btn1 btn-primary me-2">Save</button>
          <button type="button" class="comman-btn1 btn-secondary text-light">Back</button>
         </div>
        </div>
       </div>
       <!-- -------------------- -->
       <!-- --------------------- -->
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>