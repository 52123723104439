<!-- --------dashboard---page------ -->
<div class="main-panel">
  <div class="content-wrapper parent">
    <div class="row">

      <div class="col-12 col-xl-8 mb-2 mb-xl-0">
        <p class="sub">Dashboard & Statistics</p>
      </div>

      <div class="sticky-container">
        <form [formGroup]="searchForm">
          <div class="row">
            <div [ngClass]="userType?'col-md-4':'col-md-3'">
              <mat-form-field appearance="fill" class="example-form-field w-100">
                <mat-label>Timeline Filter</mat-label>
                <mat-select formControlName="timeLine" #timeLineSelect>
                  <div class="custom-panel">
                    <mat-option *ngFor="let item of AllWeekMonthYear" [value]="item.value">
                      {{ item.label }}
                    </mat-option>
                  </div>
                </mat-select>
              </mat-form-field>
            </div>
            <div [ngClass]="userType?'col-md-4':'col-md-3'">
              <mat-form-field appearance="fill" class="example-form-field w-100">
                <mat-label>Custom Date</mat-label>
                <mat-date-range-input [rangePicker]="picker">
                  <input matStartDate placeholder="From" formControlName="startDate">
                  <input matEndDate placeholder="To" formControlName="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-date-range-picker #picker>
                  <mat-date-range-picker-actions>
                      <button mat-button matDateRangePickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDateRangePickerApply
                          (click)="submitAll()">Apply</button>
                  </mat-date-range-picker-actions>
              </mat-date-range-picker>
              </mat-form-field>
            </div>
            <div [ngClass]="userType?'col-md-4':'col-md-3'" *ngIf="!userType ||(userType && isSalesman)">
              <mat-form-field appearance="fill" class="example-form-field w-100">
                <mat-label>Salesman</mat-label>
                <mat-select multiple #multiSelect1 formControlName="salesmanIds">
                  <div class="custom-panel">
                    <div class="search ">
                      <input type="text" class="custom-input-style  p-2" placeholder="Search here..."
                        formControlName="searchText">
                    </div>
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allSalesmanSelected" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection(multiSelect1,1)">Select All</mat-checkbox>
                    </div>
                    <mat-option class="text-truncat" *ngFor="let list of salesmanData | subFilter: searchText"
                      [value]="list?._id" (click)="optionClick(multiSelect1,1)">
                      {{list?.name}}</mat-option>
                  </div>
                  <footer>
                    <button mat-raised-button class="me-2" (click)="submitAll();multiSelect1.close()">Ok</button>
                    <button mat-button (click)="multiSelect1.close()">Cancel</button>
                  </footer>
                </mat-select>
              </mat-form-field>
            </div>
            <div [ngClass]="userType?'col-md-4':'col-md-3'" *ngIf="!userType ||(userType && !isSalesman)">
              <mat-form-field appearance="fill" class="example-form-field w-100">
                <mat-label>Relationship Manager</mat-label>
                <mat-select multiple #multiSelect2 formControlName="relationshipManagerIds">
                  <div class="custom-panel">
                    <div class="search ">
                      <input type="text" class="custom-input-style  p-2" placeholder="Search here..."
                        formControlName="searchText">
                    </div>
                    <div class="select-all">
                      <mat-checkbox [(ngModel)]="allManagerSelected" [ngModelOptions]="{standalone: true}"
                        (change)="toggleAllSelection(multiSelect2,2)">Select All</mat-checkbox>
                    </div>
                    <mat-option class="text-truncat"
                      *ngFor="let list of relationshipManagerData | subFilter: searchText" [value]="list?._id"
                      (click)="optionClick(multiSelect2,2)">
                      {{list?.name}}</mat-option>
                  </div>
                  <footer>
                    <button mat-raised-button class="me-2" (click)="submitAll();multiSelect2.close()">Ok</button>
                    <button mat-button (click)="multiSelect2.close()">Cancel</button>
                  </footer>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </form>
      </div>

      <div class="col-xl-12">
        <div class="row">
          <div class="col-lg-4 col-md-4">
            <div class="card ">
              <div class="card-body card1">
                <p class="text" [routerLink]="['/subscriptions-list']" [queryParams]="{ subType: 'paid' }"
                  [ngClass]="subData?.totalAmount > 0? 'custom-arrow4':'custom-arrow3'">Total Paid Subscriptions: </p>
                <p class="number">{{ subData?.totalAmount | pricePipe}} <i class="bi bi-arrow-up"></i></p>
                <div class="justify-content-between  d-flex">
                  <div class="pe-0 ps-0">
                    <p class="text-center number mb-1">{{subData?.totalSub}}</p>
                    <p class="text-center sub-txt">Subscriptions</p>
                  </div>
                  <div class="vr"></div>
                  <div class="pe-0 ps-0">
                    <p class="text-center number mb-1">{{subData?.totalUsers}}</p>
                    <p class="text-center sub-txt ">Users</p>
                  </div>
                </div>

              </div>
            </div>
            <div class="card  mt-3">
              <div class="card-body card3">
                <p class="text">
                  <span (click)="sendDataToSubscription(newSubData?.subIds) "
                    [ngClass]="newSubData?.totalAmount > 0? 'custom-arrow4':'custom-arrow3'">New Subscriptions
                    Activations:</span>
                </p>
                <p class="number">{{newSubData?.totalAmount | pricePipe}} <i class="bi bi-arrow-up"></i></p>
                <div class="justify-content-between  d-flex">
                  <div class="pe-0 ps-0">
                    <p class="text-center number mb-1">{{newSubData?.totalSub}}</p>
                    <p class="text-center sub-txt ">Subscriptions</p>
                  </div>
                  <div class="vr"></div>
                  <div class="pe-0 ps-0">
                    <p class="text-center number mb-1">{{newSubData?.totalUsers}}</p>
                    <p class="text-center sub-txt ">Users</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-8 col-md-8  stretch-card m-t">
            <div class="card">
              <div class="card-body">
                <div class="heading">Subscriptions Summary
                </div>
                <div class="table-responsive tables tablerounededCorner mt-4">
                  <table class="table table-borderless roundedTable table-sm table-striped">
                    <thead class="table-head">
                      <tr class="">
                        <th scope="col">#</th>
                        <th scope="col">Renewals</th>
                        <th scope="col">Subscriptions</th>
                        <th scope="col">Users</th>
                        <th scope="col">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1</td>
                        <td><span (click)="sendDataToSubscription(totalRenewalSummary?.subIds) "
                            [ngClass]="totalRenewalSummary?.totalAmount > 0? 'custom-arrow':'custom-arrow2'">Total
                            Renewals</span></td>
                        <td>{{totalRenewalSummary?.totalSub || 0}}</td>
                        <td>{{totalRenewalSummary?.totalUsers || 0}}</td>
                        <td>{{totalRenewalSummary?.totalAmount || 0 | currency: 'INR'}}
                          <i class="bi bi-graph-up-arrow text-success ms-2"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>2</td>
                        <td><span (click)="sendDataToSubscription(renewSummary?.subIds) "
                            [ngClass]="renewSummary?.totalSub  > 0? 'custom-arrow':'custom-arrow2'">Renewed</span>
                        </td>
                        <td>{{renewSummary?.totalSub || 0}}</td>
                        <td>{{renewSummary?.totalUsers || 0}}</td>
                        <td>{{renewSummary?.totalAmount || 0 | currency: 'INR'}}
                          <i class="bi bi-graph-up-arrow text-success ms-2"></i>
                        </td>
                      </tr>

                      <tr>
                        <td>3</td>
                        <td><span (click)="sendDataToSubscription(upgradeSummary?.subIds) "
                            [ngClass]="upgradeSummary?.totalSub > 0? 'custom-arrow':'custom-arrow2'">Upgrades</span>
                        </td>
                        <td>{{upgradeSummary?.totalSub || 0}}</td>
                        <td>{{upgradeSummary?.totalUsers || 0}}</td>
                        <td>{{upgradeSummary?.totalAmount || 0 | currency: 'INR'}}
                          <i class="bi bi-graph-up-arrow text-success ms-2"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>4</td>
                        <td><span (click)="sendDataToSubscription(downgradeSummary?.subIds) "
                            [ngClass]="downgradeSummary?.totalSub > 0? 'custom-arrow':'custom-arrow2'">Downgrade</span>
                        </td>
                        <td>{{downgradeSummary?.totalSub || 0}}</td>
                        <td>{{downgradeSummary?.totalUsers || 0}}</td>
                        <td>{{downgradeSummary?.totalAmount || 0 | currency: 'INR'}}
                          <i class="bi bi-graph-down-arrow ms-2 text-danger"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>5</td>
                        <td><span (click)="sendDataToSubscription(upcomingRenewalSummary?.subIds) "
                            [ngClass]="upcomingRenewalSummary?.totalSub > 0? 'custom-arrow':'custom-arrow2'">Upcoming
                            Renewals</span></td>
                        <td>{{upcomingRenewalSummary?.totalSub || 0}}</td>
                        <td>{{upcomingRenewalSummary?.totalUsers || 0}}</td>
                        <td>{{upcomingRenewalSummary?.totalAmount || 0 | currency: 'INR'}}
                          <i class="bi bi-graph-up-arrow text-success ms-2"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>6</td>
                        <td><span (click)="sendDataToSubscription(expiredSummary?.subIds) "
                            [ngClass]="expiredSummary?.totalSub > 0? 'custom-arrow':'custom-arrow2'">Expired</span>
                        </td>
                        <td>{{expiredSummary?.totalSub || 0}}</td>
                        <td>{{expiredSummary?.totalUsers || 0}}</td>
                        <td>{{expiredSummary?.totalAmount || 0 | currency: 'INR'}}
                          <i class="bi bi-graph-down-arrow ms-2 text-danger"></i>
                        </td>
                      </tr>
                      <tr>
                        <td>7</td>
                        <td><span (click)="sendDataToSubscription(deletedSummary?.subIds) "
                            [ngClass]="deletedSummary?.totalSub > 0? 'custom-arrow':'custom-arrow2'">Deleted</span>
                        </td>
                        <td>{{deletedSummary?.totalSub || 0}}</td>
                        <td>{{deletedSummary?.totalUsers || 0}}</td>
                        <td>{{deletedSummary?.totalAmount || 0 | currency: 'INR'}}
                          <i class="bi bi-graph-down-arrow ms-2 text-danger"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
      <div class="col-xl-12">
        <div class="row mt-4">
          <div class="col-lg-7 col-sm-12 stretch-card">
            <div class="card">
              <div class="card-body">
                <p class="heading">Billing Details:<a class="edit float-end"
                    [routerLink]="['/billing-details']">Edit</a>
                </p>
                <div class="row mt-4">

                  <div class="d-flex ">
                    <div>
                      <p class="sub-heading ">Name:</p>
                    </div>
                    <div>
                      <p class='billing'>{{profileDatas?.name}}</p>
                    </div>
                  </div>
                  <div class="d-flex">
                    <p class="sub-heading ">Email ID:</p>
                    <div>
                      <p class='billing'>{{profileDatas?.email}}</p>
                    </div>
                  </div>
                  <div class="d-flex ">
                    <div>
                      <p class="sub-heading ">Mobile Number:</p>
                    </div>
                    <div class="d-flex justify-content-center">
                      <p class='billing '>{{profileDatas?.mobile}}</p>
                    </div>
                  </div>

                  <div class="d-flex ">
                    <div>
                      <p class="sub-heading ">Company Name:</p>
                    </div>
                    <div>
                      <p class='billing'>{{profileDatas?.organizationName}}</p>
                    </div>
                  </div>
                  <div class="d-flex ">
                    <div>
                      <p class="sub-heading ">Signup URL:</p>
                    </div>
                    <div>
                      <p class='billing ' *ngIf="domainName?.domainName">http://{{domainName?.domainName}}</p>
                    </div>
                  </div>
                  <div class="d-flex ">
                    <div>
                      <p class="sub-heading ">Address:</p>
                    </div>
                    <div>
                      <p class='billing '>
                        {{profileDatas?.address}},&nbsp;{{profileDatas?.city}},&nbsp;{{profileDatas?.state}},&nbsp;{{profileDatas?.country}}
                      </p>
                    </div>
                  </div>
                  <div class="d-flex ">
                    <div>
                      <p class="sub-heading ">Pincode:</p>
                    </div>
                    <div>
                      <p class='billing '>{{profileDatas?.pinCode}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 stretch-card m-t">
            <div class="card">
              <div class="card-body ">
                <div class="heading">Risky Customers:
                  <i class="bi bi-arrow-right-circle-fill" (click)="sendDataToSubscription(riskyCustomer?.subIds)"
                    [ngClass]="riskyCustomer?.totalAmount > 0? 'custom-arrow':'custom-arrow2'"></i>
                </div>
                <div class="text-end"><i class="bi bi-exclamation-diamond-fill fs-3"
                    ngbTooltip="Not logged-in past 7 days"></i></div>
                <div id="riskyCustomerChart" class="d-flex justify-content-center align-items-center">
                  <highcharts-chart [Highcharts]="Highcharts" [options]="riskyCustomerChartOption"
                    [(update)]="updateFromInput" style="width: 100%; height: 400px; display: block;"></highcharts-chart>
                </div>

                <div class="reseller">
                  <p class="text-center mb-0 numbers"><b>{{riskyCustomer?.totalAmount| pricePipe}}</b></p>
                  <p class="text-center fs-6 ms-2">Amount</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-12">
        <div class="row">
          <div class="col-12 stretch-card">
            <div class="card" style="margin-top: 20px;">
              <div class="card-body">

                <div class="d-flex justify-content-between align-items-center enterval-data">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="mt-2">
                      <button class="graph me-2" [ngClass]="paidChartType=='line'?'active':''"
                        (click)="toggleChartType('line',2)">Line</button>
                      <button class="graph" [ngClass]="paidChartType=='column'?'active':''"
                        (click)="toggleChartType('column',2)">Bar</button>
                    </div>
                  </div>
                  <div class="float end">
                    <ul class="days">
                      <li (click)="toggleDaysMonthYear('day',2)" [ngClass]="{'activate': activeSectionForPaid==='day'}">
                        <a>Day</a>
                      </li>
                      <li (click)="toggleDaysMonthYear('week',2)"
                        [ngClass]="{'activate': activeSectionForPaid==='week'}">
                        <a>Week</a>
                      </li>
                      <li (click)="toggleDaysMonthYear('month',2)"
                        [ngClass]="{'activate': activeSectionForPaid==='month'}">
                        <a>Month</a>
                      </li>
                      <li (click)="toggleDaysMonthYear('year',2)"
                        [ngClass]="{'activate': activeSectionForPaid==='year'}">
                        <a>Year</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div id="paidSubTrendChart" class="d-flex justify-content-center align-items-center">
                  <highcharts-chart [Highcharts]="Highcharts" [options]="paidSubTrendOption"
                    [(update)]="updateFromInput" style="width: 100%; height: 400px; display: flex; "></highcharts-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-xl-12">
        <div class="row">
          <div class="col-12 stretch-card mb-5">
            <div class="card" style="margin-top: 20px;">
              <div class="card-body ">
                <div class="d-flex justify-content-between align-items-center enterval-data">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="mt-2">
                      <button class="graph me-2" [ngClass]="chartType=='line'?'active':''"
                        (click)="toggleChartType('line',1)">Line</button>
                      <button class="graph" [ngClass]="chartType=='column'?'active':''"
                        (click)="toggleChartType('column',1)">Bar</button>
                    </div>
                  </div>
                  <div class="float end">
                    <ul class="days">
                      <li (click)="toggleDaysMonthYear('day',1)"
                        [ngClass]="{'activate': activeSectionForTrial==='day'}">
                        <a>Day</a>
                      </li>
                      <li (click)="toggleDaysMonthYear('week',1)"
                        [ngClass]="{'activate': activeSectionForTrial==='week'}">
                        <a>Week</a>
                      </li>
                      <li (click)="toggleDaysMonthYear('month',1)"
                        [ngClass]="{'activate': activeSectionForTrial==='month'}">
                        <a>Month</a>
                      </li>
                      <li (click)="toggleDaysMonthYear('year',1)"
                        [ngClass]="{'activate': activeSectionForTrial==='year'}">
                        <a>Year</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row p-3">
                  <div class="col-xl-3  col-sm-3  box-1">
                    <p class="card-heading card-heading1 mb-1"
                      (click)="sendDataToSubscription(totalTrialSubDetails?.subIds) "
                      [ngClass]="totalTrialSubDetails?.totalSub > 0? 'cursor-pointer':''">Trial Signups</p>
                    <p class=" mb-0 title"><b>Amount : {{totalTrialSubDetails?.totalAmount | pricePipe}}</b></p>
                    <div class="d-flex justify-content-between">
                      <div class="title">
                        <div class="mb-0  text-center">Subscription</div>
                        <div class="mb-0 text-center"><b>{{totalTrialSubDetails?.totalSub}}</b></div>
                      </div>
                      <div class="vr vr1"></div>
                      <div class="title">
                        <div class="mb-0  text-center">User</div>
                        <div class="mb-0 text-center "><b>{{totalTrialSubDetails?.totalUsers}}</b></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3  col-sm-3  box-2">
                    <p class="card-heading card-heading2 mb-1"
                      (click)="sendDataToSubscription(expiredSubDetails?.subIds) "
                      [ngClass]="expiredSubDetails?.totalSub > 0? 'cursor-pointer':''">Trial Expired</p>
                    <p class=" mb-0 title"><b>Amount : {{expiredSubDetails?.totalAmount | pricePipe}}</b></p>
                    <div class="d-flex justify-content-between">

                      <div class="title">
                        <div class="mb-0  text-center">Subscription</div>
                        <div class="mb-0 text-center"><b>{{expiredSubDetails?.totalSub}}</b></div>
                      </div>
                      <div class="vr vr1"></div>
                      <div class="title">
                        <div class="mb-0  text-center">User</div>
                        <div class="mb-0 text-center "><b>{{expiredSubDetails?.totalUsers}}</b></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-sm-3 box-3 ">
                    <p class="card-heading card-heading3 mb-1"
                      (click)="sendDataToSubscription(trialToPaidSubDetails?.subIds) "
                      [ngClass]="trialToPaidSubDetails?.totalSub > 0? 'cursor-pointer':''">Trial to Paid</p>
                    <p class="mb-0 title"><b>Amount : {{trialToPaidSubDetails?.totalAmount | pricePipe}}</b></p>
                    <div class="d-flex justify-content-between">
                      <div class="title">
                        <div class="mb-0  text-center">Subscription</div>
                        <div class="mb-0 text-center"><b>{{trialToPaidSubDetails?.totalSub}}</b></div>
                      </div>
                      <div class="vr vr1"></div>
                      <div class="title">
                        <div class="mb-0  text-center">User</div>
                        <div class="mb-0 text-center "><b>{{trialToPaidSubDetails?.totalUsers}}</b></div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-3 col-sm-3 box-4 ">
                    <p class="card-heading card-heading4 mb-1"
                      (click)="sendDataToSubscription(liveTrialDetails?.subIds) "
                      [ngClass]="liveTrialDetails?.totalSub > 0? 'cursor-pointer':''">Live Trial</p>
                    <p class="mb-0 title"><b>Amount : {{liveTrialDetails?.totalAmount | pricePipe}}</b></p>
                    <div class="d-flex justify-content-between">
                      <div class="title">
                        <div class="mb-0  text-center">Subscription</div>
                        <div class="mb-0 text-center"><b>{{liveTrialDetails?.totalSub}}</b></div>
                      </div>
                      <div class="vr vr1"></div>
                      <div class="title">
                        <div class="mb-0  text-center">User</div>
                        <div class="mb-0 text-center "><b>{{liveTrialDetails?.totalUsers}}</b></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="trialReportChart" class="d-flex justify-content-center align-items-center">
                  <highcharts-chart [Highcharts]="Highcharts" [options]="trialReportChartOption"
                    [(update)]="updateFromInput"
                    style="width: 100%; height: 400px; display: table-cell; position:absolute;"></highcharts-chart>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<!-- main-panel ends -->