<!-- -----------account--page--start-------------------- -->
<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12">
        <div class="row mb-4">
          <div class="col-4">
            <p class="heading mb-3">Account</p>
          </div>
          <div class="col-8 justify-content-end d-flex btn-section">
            <div>
              <button type="button" class="comman-btn btn-primary" [routerLink]="['/account/add-account']">+
                Account</button>
            </div>
            <!-- <div>
              <button type="button" class="comman-btn btn-primary ms-2" (click)="exportCustomer()">Export</button>
            </div> -->
            <div class="dropdown">
              <button class="comman-btn btn-primary ms-2 dropdown-toggle" type="button" id="dropdownMenuButton1"
                data-bs-toggle="dropdown" aria-expanded="false">
                <i class="bi bi-tags-fill me-2"></i>
              </button>
              <div class="dropdown-menu p-2" aria-labelledby="dropdownMenuButton1">
                <div class="mb-3 row">
                  <div class="col-sm-12">
                    <p>Search here</p>
                    <p><input type="text" class="form-control" (keyup)="searchLabel($event)"></p>

                    <div *ngFor="let label of labelListSearch">
                      <p [ngStyle]="{'background-color': label?.color,'cursor': 'pointer'}" class="label-class"
                        (click)="assignLabel(label._id)">{{label?.name}}</p>
                    </div>
                    <p class="mt-3 label-section" (click)="labelModal()"><b>Add Label</b></p>
                    <p class="label-section" routerLink="/manage-label"><b>Manage Labels</b></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- --------------form----------- -->
        <div class="col-md-12">
          <div class="search-form">
            <p class="light mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
              aria-expanded="false" aria-controls="collapseExample">
              <i class="bi bi-search"></i> <span class="ms-5">Search here</span>
            </p>
            <div class="collapse" id="collapseExample">
              <form class="row g-3 pt-3" [formGroup]="searchForm">
                <!-- ----------------------------------------- -->
                <div class="col-md-6">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">Name/Company Name</span>
                    <input type="text" class="form-control" placeholder="Name/Company Name" formControlName="name"
                      aria-label="Username" aria-describedby="basic-addon1" onkeypress="return event.charCode !== 47 && event.charCode !== 92 ">
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">Email</span>
                    <input type="email" class="form-control" placeholder="Email" formControlName="email"
                      aria-label="Username" aria-describedby="basic-addon1" onkeypress="return event.charCode !== 47 && event.charCode !== 92 ">
                  </div>
                </div>

                <div class="col-md-6 d-flex">
                  <span class="input-group-text custom-label" id="basic-addon1">Label Name</span>
                  <select class="form-select custom-select" id="validationServer04"
                    aria-describedby="validationServer04Feedback" formControlName="label">
                    <option selected disabled value="">Select label</option>
                    <ng-container *ngFor="let list of labelList">
                      <option [value]="list.name" [ngStyle]="{'background-color': list?.color}" class="label-search">
                        <p>{{list.name}}</p>
                      </option>
                    </ng-container>
                  </select>
                </div>

                <div class="col-md-6 d-flex">
                  <span class="input-group-text custom-label" id="basic-addon1">Status</span>
                  <select class="form-select custom-select" id="validationServer04"
                    aria-describedby="validationServer04Feedback" formControlName="isActive">
                    <option selected disabled value="">All</option>
                    <option [ngValue]="true">Active</option>
                    <option [ngValue]="false">Suspend</option>
                  </select>
                </div>

                <div class="col-md-6">
                  <div class="input-group">
                    <span class="input-group-text" id="basic-addon1">Mobile</span>
                    <input type="tel" class="form-control" formControlName="mobile" placeholder="Mobile"
                      aria-label="Username" aria-describedby="basic-addon1" maxlength="10" onkeypress="return event.charCode !== 47 && event.charCode !== 92 ">
                  </div>
                </div>

                <div class="col-12 text-end">
                  <button class="comman-btn1 btn-primary me-2" type="search" (click)="searchList()">Search</button>
                  <button class="comman-btn1 btn-secondary text-light" type="reset"
                    (click)="resetSearchForm()">Reset</button>
                </div>
                <!-- -------------form--end---------------- -->
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- -----table------------- -->
      <div class="row table-section mt-3 pe-0">
        <div class="col-md-12 grid-margin stretch-card pe-0">
          <div class="card">
            <div class="card-body">
              <div class=" mb-4 records">{{updatePaginationText}}</div>
              <div class="text-center " *ngIf="!customerFound">
                <p class="fw-bold">No customer found</p>
                <p class="add-btn" routerLink="/account/add-account">
                  Add now<i class="bi bi-plus-circle ms-2 "></i>
                </p>
              </div>
              <div class=" table-responsive">
                <table class="table table-borderless " id="customerTable" *ngIf="customerFound">
                  <thead class="table-header">
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">#</th>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Label</th>
                      <th scope="col">Company</th>
                      <th scope="col">Last Login</th>
                      <th scope="col">Status</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody *ngFor="let list of customerList| paginate : config; let indexOfElement = index ">
                    <tr class="row-hover">
                      <!-- (click)="showDetails(list?._id)" -->
                      <td>
                        <a [href]="'#'+'details'+indexOfElement" class="accordion-toggle collapsed collapse-btn "
                          id="accordion1" data-mdb-toggle="collapse" data-mdb-parent="#accordion1"><span
                            class="expand-button"></span></a>
                      </td>
                      <td><input class="form-check m-0" type="checkbox" value="" id="flexCheckDefault"
                          (click)="selectCustomer(list?._id)"></td>
                      <!-- <td class="partner-details" (click)="customerDetails(list?._id)">
                          {{list?.name | titlecase}}</td> -->
                      <td class="" (click)="subscriptionDetails(list?._id)">
                        
                        <div class="d-inline-block text-truncate" style="max-width: 120px;"
                        ngbTooltip=" {{list?.name| titlecase}}" placement="top"> {{list?.name| titlecase}}</div>
                       
                      </td>
                      <td>
                        <div class="d-inline-block text-truncate" style="max-width: 120px;"
                          ngbTooltip="   {{list?.email| titlecase}}" placement="top">{{list?.email| titlecase}}</div>

                      </td>
                      <td>
                        <p [ngStyle]="{'background-color':list?.label?.color}" class="mb-0 lable-class text-center">
                          {{list?.label?.name | titlecase}}
                        </p>
                      </td>
                      <td> <div class="d-inline-block text-truncate" style="max-width: 120px;"
                        ngbTooltip=" {{list?.companyName| titlecase}}" placement="top"> {{list?.companyName| titlecase}}</div>
                       
                      </td>
                      <td>{{list?.lastLogin | date : "dd/MM/yyyy, hh:mm a":'UTC + 05:30'}}</td>
                      <td *ngIf="list.isActive" class="text-success fw-bold">Active</td>
                      <td *ngIf="!list.isActive" class="text-danger fw-bold">Inactive</td>

                      <td>
                        <div class="dropdown">
                          <button class="comman-btn1 btn-primary dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-mdb-toggle="dropdown" aria-expanded="false">
                            Action
                          </button>
                          <ul class="dropdown-menu menu-item-1" aria-labelledby="dropdownMenuButton">
                            <li>
                              <a class="dropdown-item">
                                Manage &raquo;
                              </a>
                              <ul class="dropdown-menu dropdown-submenu">
                                <li *ngIf="list.isActive"><a class="dropdown-item"
                                    (click)="changeStatus(list._id,list.isActive)">Suspend</a></li>
                                <li *ngIf="!list.isActive"><a class="dropdown-item"
                                    (click)="changeStatus(list._id,list.isActive)">Activate</a></li>
                                <li><a class="dropdown-item" (click)="customerEdit(list._id)">Edit Account</a></li>
                                <li><a class="dropdown-item" (click)="openModal(list._id)">Delete Account</a></li>
                                <!-- <li><a class="dropdown-item" (click)="editExpiryModal(list._id)">Edit Expiry Date</a>
                                </li> -->
                              </ul>
                            </li>
                            <li><a class="dropdown-item" (click)="customerLogin(list?._id,list?.email)">Manage
                                Account</a></li>
                            <li><a class="dropdown-item"
                                (click)="removeSelectedCustomerLabel(list._id,list.label.id)">Remove
                                label</a></li>
                            <!-- <li><a class="dropdown-item" (click)="changePlanModal(list._id)">Change Plan</a></li> -->
                            <li><a class="dropdown-item" (click)="createSubscription(list?._id)">Subscriptions</a></li>
                          </ul>
                        </div>
                      </td>
                    </tr>
                    <!-- <tr>
                      <div class="collapse" [id]="'details'+indexOfElement">
                        <div class="card card-body">
                          <p><b>Email:</b> <span>ankit@algogenix.com</span></p>
                          <p><b>Mobile:</b> <span>7042768841</span></p>
                          <p><b>ID:</b> <span>#0983</span></p>
                          <p><b>Number of Users:</b> <span>76899</span></p>                         
                        </div> 
                      </div>
                    </tr> -->
                    <tr class="hide-table-padding">
                      <td colspan="3">
                        <div [id]="'details'+indexOfElement" class="collapse in">
                          <div class="row">
                            <p><b>Mobile: </b> <span>{{list?.mobile}}</span></p>
                            <p><b>CreatedOn: </b> <span>{{list?.createdAt | date}}</span></p>
                            <p><b>Customer Type: </b> <span>{{list?.customerType}}</span></p>
                            <p><b>Country: </b> <span>{{list?.country | titlecase}}</span></p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="records mt-4">{{updatePaginationText}}</div>
              <div class="float-end me-4" *ngIf="customerFound">
                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
              </div>
             
            </div>
          </div>
        </div>
      </div>
      <!-- -----end--------- -->
    </div>
  </div>