import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';

@Component({
  selector: 'app-order-management',
  templateUrl: './order-management.component.html',
  styleUrls: ['./order-management.component.scss']
})
export class OrderManagementComponent implements OnInit {
  detailList!: any
  page: number = 1;
  totalCount: number = 0;
  isOrderManagement: boolean = false
  activeSection :string ='addOn'
  config = {
    itemsPerPage: 20,
    currentPage: this.page,
    totalItems: this.totalCount,
  };
  constructor(
    private router: Router,
    private subscriptionsService: SubscriptionsService,
    private toastr: ToastrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.getdetailsSubManagement()
  }
  toggleSection(value: string) {
    this.activeSection = value
    this.detailList = []
    this.config.currentPage = 1; 
    this.getdetailsSubManagement()
  }
  createOrder(type: string) {
    this.router.navigate(['/order-management/create-order'], { queryParams: { type: type } });
  }

  updateOrder(type: string){
    this.router.navigate(['/order-management/create-order'], { queryParams: { type: type, update: 'update' }});
  }
  getdetailsSubManagement() {
    this.subscriptionsService.getdetailsSubManagement(this.activeSection, this.config.currentPage, this.config.itemsPerPage).subscribe((res: any) => {
      if (res?.subManagementRequestedList.length === 0) {
        this.toastr.error('No data found');
        this.isOrderManagement = false;
        this.config.currentPage = 1
        this.config.totalItems = 0
        this.detailList = []
      }
      else {
        this.detailList = res?.subManagementRequestedList
        this.config.currentPage = res.totalSubManagementRequest.pages;
        this.config.totalItems = res.totalSubManagementRequest.total;
        this.isOrderManagement = true;
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }
  pageChanged(event: any) {
    this.config.currentPage = event;
    this.getdetailsSubManagement();
  }
  get updatePaginationText(){
    return this.authService.getPaginationText(this.config.currentPage,this.config.itemsPerPage,this.config.totalItems);
  }
}
