import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PlanDetailRoot } from 'src/app/interfaces/subscription/plan';
import { ProductListRoot } from 'src/app/interfaces/subscription/product';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';

@Component({
  selector: 'app-change-free-trial',
  templateUrl: './change-free-trial.component.html',
  styleUrls: ['./change-free-trial.component.scss']
})
export class ChangeFreeTrialComponent implements OnInit {

  @Input() subscriptionId!: string
  productList: any = []
  planList: any = []
  connectedPerson: any

  changeFreeTrialPlanForm = new FormGroup({
    unitPrice: new FormControl(''),
    noOfUsers: new FormControl('', Validators.required),
    price: new FormControl(''),
    salesPersonName: new FormControl('direct')
  })
  productId: string = '';
  planId: string = '';
  planDetail: any;
  selectedProductName: string = ''
  salesPersonList: any
  event: EventEmitter<any> = new EventEmitter();

  constructor(
    private bsModalRef: BsModalRef,
    private toasts: ToastrService,
    private subscriptionService: SubscriptionsService

  ) { }

  ngOnInit(): void {
    this.showProductList()
    this.showPlanList()
    setTimeout(() => {
      this.salesPersonLists(this.subscriptionId);
    });

  }

  closeModal() {
    this.bsModalRef.hide();
  }


  showProductList() {
    this.subscriptionService.showProductList().subscribe((res: ProductListRoot) => {
      if (res.success) {
        this.productList = res.products.filter(product => {
          if (product.id > 1) {
            return product
          }
        });
      }
    })
  }
  submit() {
    if (this.changeFreeTrialPlanForm.controls.noOfUsers.value === '') {
      this.changeFreeTrialPlanForm.controls.noOfUsers.markAsTouched()
    } else {
      this.changeTrialPlan()
    }
  }
  showPlanList() {
    this.productId = (document.getElementById("productId") as HTMLInputElement).value
    if (this.productId) {
      this.subscriptionService.showPlanList(this.productId).subscribe((res: PlanDetailRoot) => {
        if (res.success) {
          this.planList = res.plans;
        }
      })
    }

  }

  getPlanPrice() {
    this.planId = (document.getElementById("planId") as HTMLInputElement).value
    this.subscriptionService.selectedPlan(this.planId).subscribe((res: any) => {
      if (res.success) {
        this.planDetail = res.plan
        this.changeFreeTrialPlanForm.controls.unitPrice.patchValue((res?.plan?.price).toFixed(2))
      }
    })
  }

  planAmount() {
    let unitPrice: any;
    let quantity: any;
    let amount: any;
    unitPrice = this.changeFreeTrialPlanForm.controls.unitPrice.value;
    quantity = this.changeFreeTrialPlanForm.controls.noOfUsers.value;
    amount = unitPrice * quantity;
    this.changeFreeTrialPlanForm.controls.price.patchValue(amount.toFixed(2));
  }

  changeTrialPlan() {
    const apiCallInProgress = localStorage.getItem('apiCallInProgress');
    const subId = localStorage.getItem('subId');
    if (apiCallInProgress === 'true' && this.subscriptionId === subId) {
      this.toasts.warning('Your request has already been submitted. Please wait for some time while we process it.');
      return;
    }

    let unitPrice: any;
    let quantity: any;
    let amount: any;
    unitPrice = this.changeFreeTrialPlanForm.controls.unitPrice.value;
    quantity = this.changeFreeTrialPlanForm.controls.noOfUsers.value;
    amount = unitPrice * quantity;

    const payload = {
      subscriptionId: this.subscriptionId,
      plan: {
        name: this.planDetail?.name,
        noOfUsers: quantity,
        unitPrice: unitPrice,
        price: amount,
        description: this.planDetail?.description,
        billingCycle: this.planDetail?.billingCycle,
        billEvery: this.planDetail?.billEvery,
        code: this.planDetail?.code
      },
      connectedPerson: this.changeFreeTrialPlanForm.get('salesPersonName')?.value
    };
    localStorage.setItem('subId', this.subscriptionId)
    this.event.emit('processing');
    this.closeModal();
    this.subscriptionService.changeTrialPlan(payload).subscribe(
      (res: any) => {
        if (res.success) {
          const subId = localStorage.getItem('subId') || ''
          this.updateSub(subId)
          localStorage.removeItem('subId')
          localStorage.removeItem('apiCallInProgress');
          this.event.emit('ok');
          this.toasts.success(res.message);
        } else {
          const subId = localStorage.getItem('subId') || ''
          this.updateSub(subId)
          localStorage.removeItem('subId')
          localStorage.removeItem('apiCallInProgress');
          this.event.emit('ok');
          this.toasts.warning(res.message);
        }
      },
      (error) => {
        const subId = localStorage.getItem('subId') || ''
        this.updateSub(subId)
        localStorage.removeItem('subId')
        localStorage.removeItem('apiCallInProgress');
        this.event.emit('ok');
        this.toasts.error(error.error.message);
      }
    );
  }

  updateSub(subId: string) {
    this.subscriptionService.updateSub(subId).subscribe((res: any) => {
      if (res.success) {

      }
    })
  }

  salesPersons(name: string){
    this.changeFreeTrialPlanForm.controls.salesPersonName.setValue(name);
  }

  salesPersonLists(subscriptionId: any) {
    this.subscriptionService.subscriptionSalesPersonList(subscriptionId).subscribe((res: any) => {
      if (res.success) {
        this.salesPersonList = res.salesPersons
      }
    })
  }



}
