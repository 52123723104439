import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MrrService } from 'src/app/services/mrr.service';
import { AuthService } from 'src/app/services/auth.service';
import * as XLSX from 'xlsx';
import * as moment from 'moment';
@Component({
  selector: 'app-relationship-manger-report',
  templateUrl: './relationship-manger-report.component.html',
  styleUrls: ['./relationship-manger-report.component.scss']
})
export class RelationshipMangerReportComponent implements OnInit {
  @ViewChild('scrollContainer') scrollContainer!: ElementRef;

  isShowDetails: boolean = false
  activation = 'mrr'
  hideSubDateRange: boolean = false
  relationShipManagerList: any
  currentMonthYear: any
  relationshipManagerSubList: any
  relationshipManagerSubTotal: any
  subAscending: boolean = false
  mrrAscending: boolean = false
  values: any
  totals: any
  mrrDetail: any
  sortValue: any = { _id: -1 };
  isSorting: boolean = false;
  domainName: string = ''
  searchForm = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
    date: new FormControl('')
  })
  constructor(
    private mrrService: MrrService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    const currentDate = new Date();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    const startOfNextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
    const endOfMonth = new Date(startOfNextMonth.getTime() - 1);
    const formattedDate = currentDate.toISOString().substring(0, 7);
    this.searchForm.get('date')?.setValue(formattedDate);
    this.searchForm.get('startDate')?.setValue(firstDayOfMonth.toISOString())
    this.searchForm.get('endDate')?.setValue(endOfMonth.toISOString())
    this.relationShipManagerMrrList()
    this.searchForm.get('date')?.valueChanges.subscribe((value: any) => {
      this.relationShipManagerMrrList()
    })
  }
  toggle(activation: any) {
    this.activation = activation;
    if (activation == 'subcription') {
      this.hideSubDateRange = true
      this.relationshipManagerSubReport()
    }
    else {
      this.hideSubDateRange = false
    }
  }

  changeDate() {
    this.relationshipManagerSubReport()
  }


  relationShipManagerMrrList() {
    this.isShowDetails = false
    let month = this.searchForm.get('date')?.value
    const mrrpayload = Object.assign({ month: month }, { sortValue: this.sortValue })
    this.mrrService.relationshipManagerMrrReport(JSON.stringify(mrrpayload)).subscribe((res: any) => {
      if (res.success) {
        this.relationShipManagerList = res?.rmMrrReport
        this.totals = res?.totals
      }
    })
  }

  relationshipManagerSubReport() {
    this.isShowDetails = false
    const relationshipManagerSubPayload = {
      startDate: this.searchForm.value.startDate,
      endDate: this.searchForm.value.endDate
    }
    this.mrrService.relationshipManagerSubscriptionReport(relationshipManagerSubPayload).subscribe((res: any) => {
      if (res.success) {
        this.relationshipManagerSubList = res?.relationshipManagerSubscriptionReport
        this.relationshipManagerSubTotal = res?.totalData
      }
    })
  }

  mrrDetails(rmId: string, billingType: string) {
    this.isShowDetails = true
    setTimeout(() => {
      if (this.scrollContainer && this.scrollContainer.nativeElement) {
        this.scrollContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
      }
    })

    const mrrDetailsPlayload = {
      relationshipManagerIds: [rmId],
      month: moment(this.searchForm.get('date')?.value).format("MMM YYYY"),
      billingType: billingType,
      subUserData: false
    }
    this.mrrService.mrrDetails(mrrDetailsPlayload).subscribe((res: any) => {
      if (res.success) {
        this.mrrDetail = res?.mrrDetails
      } else {
        this.mrrDetail = []
      }
    }, (err) => {
      this.mrrDetail = []
    })
  }

  exportexcel(): void {
    let excelData
    if (this.activation == "mrr") {
      excelData = this.relationShipManagerList.map((dataObj: any) => {
        return {
          "Relationship Manager": dataObj?.relationshipManager || '',
          "New Business MRR": dataObj?.netNewBiz || 0,
          "Expansion MRR ": dataObj?.netUpgrade || 0,
          "Contraction MRR": dataObj?.netDowngrade || 0,
          "Churn MRR": dataObj?.netChurn || 0,
          "Reactivation MRR": dataObj?.netReactivation || 0,
          "Net Churn MRR": dataObj?.netNetChurn || 0,
          "Net MRR Movement": dataObj?.netMrrMovement || 0,
          "Current MRR": dataObj?.currentMrr || 0,
          "Previous MRR": dataObj?.prevMonthMrr || 0,
          "Change": dataObj?.percentageChange || 0,
        }
      })
    }
    else {
      excelData = this.relationshipManagerSubList.map((dataObj: any) => {
        return {
          "Relationship Manager": dataObj?.RelationshipManager || '',
          "Total Subscriptions": dataObj?.totalSub.amount || 0,
          "New Subscriptions ": dataObj?.newSub.amount || 0,
          "Total Renewals": dataObj?.totalRenewals.amount || 0,
          "Renewed ": dataObj?.renewedSub.amount || 0,
          "Upgrade": dataObj?.upgradeSub.amount || 0,
          "Downgrade": dataObj?.downgradeSub.amount || 0,
          "Upcoming Renewals": dataObj?.upcomingRenewals.amount || 0,
          "Expired": dataObj?.expiredSub.amount || 0,
          "Deleted": dataObj?.deletedSub.amount || 0,
          "Risky Customer Value": dataObj?.riskySub.amount || 0,
          "Trial Signups": dataObj?.totalTrialSub.amount || 0,
          "Trial Expired": dataObj?.totalExpiredSub.amount || 0,
          "Trial to Paid": dataObj?.trialToPaidSub.amount || 0,
          "Live Trials": dataObj?.liveTrialSub.amount || 0,
          "Current Mrr": dataObj?.currentMrr || 0
        }
      })
    }
    const reportName = this.activation == "mrr" ? 'Relationship-Manager_Mrr.xlsx' : 'Relationship-Manager_subscription.xlsx'
    const jsonSheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, jsonSheet, 'JSON_Sheet');  // Changed sheet name to 'JSON_Sheet'
    XLSX.writeFile(wb, reportName);
  }

  get userType() {
    return this.authService.userType()
  }

  sortData(type: any) {
    if (this.activation == "mrr") {
      this.relationShipManagerList.sort((a: any, b: any) => {
        // Assuming `type` corresponds to a property in `a` and `b`
        if (a[type] < b[type]) {
          return this.mrrAscending ? -1 : 1;
        }
        if (a[type] > b[type]) {
          return this.mrrAscending ? 1 : -1;
        }
        return 0;

      });
      this.mrrAscending = !this.mrrAscending
    }

    else {
      this.relationshipManagerSubList.sort((a: any, b: any) => {

        const condition1 = type == 'currentMrr' ? a[type] < b[type] : a[type].amount < b[type].amount;
        const condition2 = type == 'currentMrr' ? a[type] > b[type] : a[type].amount > b[type].amount;

        if (condition1) {
          return this.subAscending ? -1 : 1;
        }
        if (condition2) {
          return this.subAscending ? 1 : -1;
        }
        return 0;

      });
      this.subAscending = !this.subAscending
    }
  }

  exportexcelDetails(): void {
    const mrrDetail = document.getElementById('mrr-detail-table')
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(mrrDetail);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'MRR_Details.xlsx');
  }
}
