<!-- -----add---user--modal------ -->
<div>
    <div class="modal-header border-0 pt-0 pb-3">
        <h4 class="modal-title pull-left">Change Customer Id</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="ps-4 pe-4">
        <form [formGroup]="changeCustomerForm">
  
            <div class="form-group">
                <input type="email" class="form-control" placeholder="Enter Customer Email" formControlName="email">
                <div *ngIf="changeCustomerForm.get('email')?.invalid" class="alert mb-0 p-0">
                    <div
                     *ngIf="changeCustomerForm.get('email')?.errors?.['required'] && (changeCustomerForm.get('email')?.dirty || changeCustomerForm.get('email')?.touched)">
                     <p class="text-danger m-0 p-0"><small>Email Id is mandatory.</small>
                     </p>
                    </div>
                    <div
                     *ngIf="changeCustomerForm.get('email')?.errors?.['email']">
                     <p class="text-danger m-0 p-0"><small>Please enter valid Email Id.</small>
                     </p>
                    </div>
                   </div>
            </div>
        </form>
    </div>
    <div class="ps-4 pe-4 text-end">
        <button type="button" class="comman-btn btn-primary me-2" (click)="changeCustomerId()">Submit</button>
        <button type="button" class="comman-btn btn-secondary text-light" (click)="bsModalRef.hide()">Close</button>
    </div>
    </div>
    <!-- -----end---- -->