<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12 ">
          <div class="row">
            <div class="col-6">
              <h3 class="heading">User & Roles</h3>
            </div>
            <div class="col-6 text-end">
              <button type="button" class="comman-btn1 btn-primary" [routerLink]="['/add-salesman']">+ Add User</button>
            </div>
          </div>
        </div>
        <!-- ------------------ -->
        <div class="col-6">
          <h3 class="heading">Sales Person:</h3>
        </div>
        <div class="row table-section pe-0">
          <div class="col-md-12 mb-3 stretch-card pe-0">
            <div class="card">
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table table-striped table-borderless">
                    <thead class="table-header ">
                      <tr>
                        <th scope="col">Name</th>
                        <th scope="col">Email</th>
                        <th scope="col">Mobile No.</th>
                        <th scope="col">Role</th>
                        <th scope="col">Status </th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let salesman of salesmanData">
                        <td class="mouseHover">{{salesman?.name | titlecase}}</td>
                        <td>{{salesman?.email}}</td>
                        <td>{{salesman?.mobile}}</td>
                        <td>{{
                          salesman?.role === 'salesPerson' ? 'Salesperson' :
                          salesman?.role === 'salesPersonManager' ? 'Salesperson Manager' : ''
                          }}</td>
                        <td [ngClass]="salesman?.isActive?'text-success': 'text-danger'">{{salesman?.isActive?
                          'Active': 'InActive'}}</td>
                        <td class="dropdown ">
                          <button class="comman-btn1 btn-primary dropdown-toggle" type="button" id="dropdownMenuButton"
                            data-mdb-toggle="dropdown" aria-expanded="false">
                            Action
                          </button>
                          <ul class="dropdown-menu menu-item-1" aria-labelledby="dropdownMenuButton">
                            <li><a class="dropdown-item"
                                (click)="toggleStatus(salesman?.isActive, salesman?._id)">{{salesman?.isActive?
                                'Suspend': 'Active'}}</a></li>
                            <li><a class="dropdown-item" (click)="editSalesman(salesman?.isActive,salesman?._id)">Edit
                                Salesman</a></li>
                            <li><a class="dropdown-item" (click)="deleteSalesman(salesman._id)">Delete Salesman</a></li>
                          </ul>
                          <!-- <i class="bi bi-pencil-fill me-3 text-info custom-select"(click)="updateSubscription(list?._id)"></i> -->

                          <!-- <i class="bi bi-trash text-danger custom-select" ></i> -->
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ----------- -->


        <!-- for relationshipManager table -->
        <div class="row">
          <div class="col-md-12 grid-margin">
            <div class="row">
              <div class="col-6">
                <h3 class="heading">Relationship Manager:</h3>
              </div>
            </div>
            <!-- ------------------ -->

            <div class="row table-section pe-0">
              <div class="col-md-12 mb-2 stretch-card pe-0">
                <div class="card">
                  <div class="card-body">
                    <div class="table-responsive">
                      <table class="table table-striped table-borderless">
                        <thead class="table-header ">
                          <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Mobile No.</th>
                            <th scope="col">Role</th>
                            <th scope="col">Status </th>
                            <th scope="col">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let relationshipManager of relationshipManagerData">
                            <td class="mouseHover">{{relationshipManager?.name | titlecase}}</td>
                            <td>{{relationshipManager?.email}}</td>
                            <td>{{relationshipManager?.mobile}}</td>
                            <td>{{ relationshipManager?.role === 'relationshipManager' ? 'Relationship Manager': '' }}
                            </td>
                            <td [ngClass]="relationshipManager?.isActive?'text-success': 'text-danger'">
                              {{relationshipManager?.isActive?
                              'Active': 'InActive'}}</td>
                            <td class="dropdown ">
                              <button class="comman-btn1 btn-primary dropdown-toggle" type="button"
                                id="dropdownMenuButton" data-mdb-toggle="dropdown" aria-expanded="false">
                                Action
                              </button>
                              <ul class="dropdown-menu menu-item-1" aria-labelledby="dropdownMenuButton">
                                <li><a class="dropdown-item"
                                    (click)="toggleStatus(relationshipManager?.isActive, relationshipManager?._id)">{{relationshipManager?.isActive?
                                    'Suspend': 'Active'}}</a></li>
                                <li><a class="dropdown-item"
                                    (click)="editSalesman(relationshipManager?.isActive,relationshipManager?._id)">Edit
                                    User</a></li>
                                <li><a class="dropdown-item" (click)="deleteSalesman(relationshipManager._id)">Delete
                                    User</a></li>
                              </ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- ----------- -->
          </div>
        </div>
      </div>