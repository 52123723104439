<!-- -----------login----page ---start---- -->
<div class="container-scroller vh-100 bg-cloud">
  <div class="container-fluid ">
    <div class="d-flex align-items-center auth px-0 justify-content-center">
      <div class="row w-100 mt-5">
        <div class="login-form">
          <div class="auth-form-light text-left py-5 px-4 px-sm-5">
            <div class="brand-logo mb-3">
              <img src="./../assets/images/cocloud-logo.jpg" alt="logo" class="logo">
            </div>
            <h4 class="text-center mt-2 mb-0 signUp">{{changePassword? 'Reset Password' : 'Sign in to CoCloud'}}</h4>

            <form class="pt-3" [formGroup]="loginPasswordForm" *ngIf="!changePassword">
              <div class="form-group login-input">
                <input type="email" class="form-control form-control-lg" id="exampleInputEmail1"
                  placeholder="Enter email id" formControlName="email">

                <div class="edit-icon" *ngIf="getOTP">
                  <!-- <i class="bi bi-pencil-fill edit-mobile" (click)="editMobile()"></i> -->
                </div>
                <ng-container *ngFor="let validation of validations.email">
                  <div class="error-message"
                    *ngIf="loginPasswordForm.get('email')?.hasError(validation.type)&& (loginPasswordForm.get('email')?.dirty || loginPasswordForm.get('email')?.touched)">
                    <span class="error-msg"> {{ validation.message }}</span>
                  </div>
                </ng-container>
              </div>

              <div class="form-group pass-field">
                <input type="password" class="form-control form-control-lg" id="pass" placeholder="Enter password"
                  formControlName="password" minlength="6">
                <span>
                  <i *ngIf="showPassword" class="bi bi-eye password-icon-1" (click)="hidePassword('login')"></i>
                  <i *ngIf="!showPassword" class="bi bi-eye-slash password-icon-1" (click)="hidePassword('login')"></i>
                </span>
                <ng-container *ngFor="let validation of validations.password">
                  <div class="error-message"
                    *ngIf="loginPasswordForm.get('password')?.hasError(validation.type)&& (loginPasswordForm.get('password')?.dirty || loginPasswordForm.get('password')?.touched)">
                    <span class="error-msg"> {{ validation.message }}</span>
                  </div>
                </ng-container>

                <p class="mt-1 mb-3 forgotPassword">
                  <span class="text-color float-end " (click)="changePass()">
                    Forgot Password?
                  </span>
                </p>
              </div>

              <div class="getOTP-btn">
                <button type="submit" class="comman-btns btn-block" (click)="loginWithPassword()"><b>Sign In</b>
                </button>
              </div>
              <div>
                <p class="mt-3 text-center mb-0">
                  <span class="text-color partner-line" (click)="partner()">
                  Become a Partner!
                  </span>
                </p>
              </div>
            </form>

            <form class="pt-3" [formGroup]="resetPasswordForm" *ngIf="changePassword">
              <div class="form-group login-input">
                <input type="email" class="form-control form-control-lg" placeholder="Enter registered email id"
                  formControlName="email" [readonly]="otpVerify">
                <ng-container *ngFor="let validation of validationsReset.email">
                  <div class="error-message"
                    *ngIf="resetPasswordForm.get('email')?.hasError(validation.type)
                                            && (resetPasswordForm.get('email')?.dirty || resetPasswordForm.get('email')?.touched)">

                    <span class="error-msg"> {{ validation.message }}</span>
                  </div>
                </ng-container>
              </div>

              <div class="form-group pass-field" *ngIf="otpVerify">
                <input type="password" class="form-control form-control-lg" id="reset1" placeholder="Enter password"
                  formControlName="password" minlength="6">
                <span>
                  <i *ngIf="showPassword2" class="bi bi-eye password-icon-1" (click)="hidePassword('reset')"></i>
                  <i *ngIf="!showPassword2" class="bi bi-eye-slash password-icon-1" (click)="hidePassword('reset')"></i>
                </span>
                <ng-container *ngFor="let validation of validationsReset.password">
                  <div class="error-message"
                    *ngIf="resetPasswordForm.get('password')?.hasError(validation.type)
                                          && (resetPasswordForm.get('password')?.dirty || resetPasswordForm.get('password')?.touched)">
                    <span class="error-msg"> {{ validation.message }}</span>
                  </div>
                </ng-container>
              </div>

              <div class="form-group pass-field" *ngIf="otpVerify">
                <input type="password" class="form-control form-control-lg" id="reset2" placeholder="ReEnter password"
                  formControlName="confirmPassword" minlength="6">
                <span>
                  <i *ngIf="showPassword3" class="bi bi-eye password-icon-1" (click)="hidePassword('confirm')"></i>
                  <i *ngIf="!showPassword3" class="bi bi-eye-slash password-icon-1"
                    (click)="hidePassword('confirm')"></i>
                </span>
                <ng-container *ngFor="let validation of validationsReset.password">
                  <div class="error-message"
                    *ngIf="resetPasswordForm.get('confirmPassword')?.hasError(validation.type)
                                             && (resetPasswordForm.get('confirmPassword')?.dirty || resetPasswordForm.get('confirmPassword')?.touched)">
                    <span class="error-msg"> {{ validation.message }}</span>
                  </div>
                </ng-container>
              </div>

              <div class="form-group" *ngIf="otpVerify">
                <input type="tel" class="form-control form-control-lg" placeholder="Enter otp" formControlName="otp"
                  maxlength="6">
                <ng-container *ngFor="let validation of validationsReset.otp">
                  <div class="error-message"
                    *ngIf="resetPasswordForm.get('otp')?.hasError(validation.type)
                                             && (resetPasswordForm.get('otp')?.dirty || resetPasswordForm.get('otp')?.touched)">
                    <span class="error-msg"> {{ validation.message }}</span>
                  </div>
                </ng-container>
              </div>

              <div class="getOTP-btn" *ngIf="!otpVerify">
                <button type="submit" class="comman-btns btn-block" (click)="getOtp()"><b>Request OTP</b>
                </button>
              </div>
              <div class="getOTP-btn" *ngIf="otpVerify">
                <button type="submit" class="comman-btns btn-block" (click)="confirmChange()"><b>Change Password</b>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- content-wrapper ends -->
  </div>
  <!-- page-body-wrapper ends -->
</div>