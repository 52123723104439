<!-- ---------instance------page----- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row mb-3">
        <h3 class="heading mb-0">Subscription Details</h3>
      </div>
      <!-- --------form-------- -->
      <div class="col-md-12">
        <div class="search-form">
          <p class="light mb-0" type="button" aria-expanded="true" aria-controls="collapseExample">
            <i class="bi bi-search"></i>
            <span class="ms-5">Search here</span>
          </p>
          <div class=" mt-3">
            <form class="row g-3" [formGroup]="searchForm">
              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">Sub Id</span>
                  <input type="text" class="form-control" formControlName="subCode"  onkeypress="return event.charCode !== 47 && event.charCode !== 92 ">
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">Admin User Email</span>
                  <input type="text" class="form-control" formControlName="customerEmail" onkeypress="return event.charCode !== 47 && event.charCode !== 92 ">
                </div>
              </div>

              <div class="col-md-6">
                <div class="input-group">
                  <span class="input-group-text" id="basic-addon1">Cloud User Email</span>
                  <input type="text" class="form-control" formControlName="cloudUserEmail" onkeypress="return event.charCode !== 47 && event.charCode !== 92 ">
                </div>
              </div>

              <div class="col-md-12 mb-4 text-end">
                <button type="submit" class="comman-btn1 btn-primary text-light me-2" (click)="submit()">
                  Search</button>
                <button type="button" class="comman-btn1 btn-secondary text-light" (click)="resetForm()">Reset</button>
              </div>
            </form>
            <!-- ------ -->
          </div>
        </div>
      </div>
      <!-- -----end----- -->
      <!-- --------table-section---------- -->
      <div class="row table-section mt-4">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <!-- <div class="text-center">
             <h4>There is no Instance created yet</h4>
            </div> -->
              <div class="table-responsive">
                <table class="table table-striped table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Sub ID</th>
                      <th scope="col">Status</th>
                      <th scope="col">Stage</th>
                      <th scope="col">Admin Email</th>
                      <th scope="col">Customer Company Name</th>
                      <th scope="col">Number Of User</th>
                      <th scope="col">Instance User Email Id</th>
                      <th scope="col">Instance User Name</th>
                      <th scope="col" *ngIf="!userType">Instance Password</th>
                      <th scope="col">Plan Name</th>
                      <th scope="col" *ngIf="!userType">Start Date</th>
                      <th scope="col" *ngIf="!userType">Next Billing Date</th>
                      <th scope="col" *ngIf="!userType">Last Billing Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="!showData">
                      <td colspan="9" ><div class="text-center">Too see the data search on any field.</div></td>
                    </tr>
                    <tr *ngFor="let subdata of instanceUsers">
                      <td><a class="subCode" (click)="instanceUserDetails(subdata?.code)">{{subdata?.code}}</a></td>
                      <td [ngClass]="[subdata.isActive? 'text-success': 'text-danger']">{{subdata.isActive? 'Active':
                        'InActive'}}</td>
                      <td [ngClass]="[subdata.isExpired? 'text-danger': 'text-success']">{{subdata.isExpired? 'Expired':
                        'live'}}</td>
                      <td>{{subdata?.customerEmail}}</td>
                      <td>{{subdata?.customer?.companyName}}</td>
                      <td>{{subdata?.instance?.noOfUsers}}</td>
                      <td>{{subdata?.instanceUsers[0]?.email}}</td>
                      <td>{{subdata?.instanceUsers[0]?.userName}}</td>
                      <td *ngIf="!userType">{{subdata?.instanceUsers[0]?.userKey}}</td>
                      <td>{{subdata?.plan?.name}}</td>
                      <td *ngIf="!userType">{{subdata?.startDate |date:'dd/MM/yyyy'}}</td>
                      <td *ngIf="!userType">{{subdata?.endDate |date:'dd/MM/yyyy'}}</td>
                      <td *ngIf="!userType">{{(subdata?.lastRenewDate===null)?(subdata?.startDate
                        |date:'dd/MM/yyyy'):(subdata?.lastRenewDate |date:'dd/MM/yyyy')}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <div class="mt-4 float-end me-4">
                  <pagination-controls></pagination-controls>
                </div> -->
            </div>
          </div>
        </div>
      </div>
      <!-- ------end----- -->
    </div>
  </div>
</div>