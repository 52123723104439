<div class="container-fluid ps-0 pe-0">
    <div class="main-panel">
        <div class="row">
            <div class="col-md-12 ">
                <div class="card">
                    <div class="card-body class-padding">
                        <p class="heading text-center ">Comhard Technologies Private Limited – Tally on Cloud
                            Subscription Service
                            Agreement</p>

                        <div class="row mt-4">
                            <p class="sub-heading mb-3">
                                Agreement between You and Comhard Technologies Pvt. Ltd.
                            </p>
                            <p class="text mb-3">This legally binding agreement ("Agreement") is hereby entered into
                                between you, individually or on
                                behalf of the entity you represent, hereinafter referred to as "You" or "Your," and
                                Comhard
                                Technologies Pvt Ltd, hereinafter referred to as "Comhard," governing your subscription
                                and use of
                                Comhard's Tally on Cloud service, hereinafter referred to as "the Service."
                            </p>
                            <p class="sub-heading mb-3">
                                Sections of this Agreement:
                            </p>
                            <p class="text mb-3">This Agreement is comprised of the following sections: "General Terms"
                                and any "Service Specific
                                Terms" applicable to individual components of the Service. In the event of any conflict
                                between the
                                General Terms and the Service Specific Terms, the latter shall take precedence.
                            </p>
                            <div class="ps-5">
                                <ul>
                                    <li>
                                        <p class="sub-heading mb-3"><b>1. Acceptance of the Agreement</b> </p>
                                        <p class="text mb-3">To subscribe to this Service, you must be of legal age and
                                            capable of entering into a binding agreement.
                                            Should You disagree with the General Terms, refrain from subscribing to or
                                            using the Service. If You
                                            accept the General Terms but not the Service Specific Terms, You must
                                            abstain from utilizing the specific
                                            component of the Service in question. You may express Your acceptance of
                                            this Agreement by selecting
                                            a checkbox, clicking a button indicating acceptance, or by actively using
                                            the Service.</p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>2. Description of Service</b></p>
                                        <p class="text mb-3">Comhard Technologies Pvt Ltd offers the Tally on Cloud
                                            service, which provides access to Tally
                                            accounting software hosted in the cloud, along with associated offline
                                            software collectively referred to
                                            as "the Service" or "Services." You may employ the Service for personal or
                                            business purposes, including
                                            internal organizational use. Access to the Service is facilitated through
                                            any Internet browser supported
                                            by the Service. You are responsible for acquiring internet access and
                                            necessary equipment to use the
                                            Service. With a user account, you can create, edit, publish, and share
                                            content.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>3. Beta Service</b></p>
                                        <p class="text mb-3">Comhard may offer certain components of the Service as
                                            closed or open beta services, hereinafter
                                            referred to as "Beta Service" or "Beta Services," for testing and evaluation
                                            purposes. Comhard retains
                                            sole authority and discretion to determine the testing period and the
                                            decision to offer Beta Services as
                                            commercial services. Subscribing to any Beta Service does not obligate You
                                            to acquire a subscription for
                                            any paid Service. Comhard reserves the right to fully or partially
                                            discontinue any Beta Service,
                                            temporarily or permanently, with or without notice. Comhard shall not be
                                            liable for any harm resulting
                                            from the modification, suspension, or discontinuance of Beta Services.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>4. Free Trial</b> </p>
                                        <p class="text mb-3">If You register for a free trial of the Service, Comhard
                                            will provide access to the Service on a trial basis
                                            free of charge until specific conditions are met. Data entered during the
                                            free trial may be lost if certain
                                            conditions are not fulfilled. Services provided during the free trial are
                                            offered "as-is," without warranty,
                                            to the extent permitted by law. After expiry of trial, customer shall make
                                            payment to continue services.
                                            Upon failing to pay after trial, they shall download their data locally,
                                            before expiry. Once expired,
                                            customer or Comhard cannot obtain data without making payment.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>5. User Sign-In Obligations</b> </p>
                                        <p class="text mb-3">To access and use the Service, you must subscribe and
                                            provide all necessary information. If Your
                                            organization plans to use the Service for corporate internal use, it is
                                            advisable for all users, including
                                            those from Your organization, to subscribe using corporate contact
                                            information. You agree to provide
                                            accurate and up-to-date information and promptly update it when necessary.
                                            Supplying false or
                                            incomplete information may result in the termination of Your subscription
                                            and access to the Service.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>6. Internet Connectivity & Device Configuration</b> </p>
                                        <p class="text mb-3">Customer/Users shall have stable internet connection with
                                            them to access cloud services. Comhard is
                                            not liable to support customer, in case of cloud issues being generating due
                                            to customer’s internet
                                            connectivity. User connecting to cloud shall have enough local resources to
                                            run cloud services.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>7. Restrictions on Use</b> </p>
                                        <p class="text mb-3">In addition to other terms and conditions in this
                                            Agreement, You shall not:
                                        </p>
                                        <ul class="mb-3">
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Transfer
                                                the Service to any third party.</li>
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Offer
                                                services based on the Service without prior written permission.</li>
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Share user
                                                licenses except through reassignment to new users.</li>
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Attempt to
                                                disassemble, reverse engineer, or decompile the Service.</li>
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Utilize
                                                third-party links or content without accepting their terms and
                                                conditions.</li>
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Attempt
                                                unauthorized access to the Service or related systems.</li>
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Employ the
                                                Service to send or store malicious content.</li>
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Use the
                                                Service in a manner that disrupts systems or networks.</li>
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Host,
                                                display, upload, or share information without consent.</li>
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Violate
                                                any applicable laws. </li>
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Use the
                                                Service in a manner that threatens India's integrity, security, or
                                                public order.</li>
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Create
                                                false identities. </li>
                                            <li class="item"><i class="bi bi-arrow-right-short me-1 fs-5"></i>Transmit
                                                false or misleading information.</li>

                                        </ul>

                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>8. Spamming and Illegal Activities </b> </p>
                                        <p class="text mb-3">Customer/Users shall have stable internet connection with
                                            them to access cloud services. Comhard is
                                            not liable to support customer, in case of cloud issues being generating due
                                            to customer’s internet
                                            connectivity. User connecting to cloud shall have enough local resources to
                                            run cloud services.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>9. Third-Party Applications</b> </p>
                                        <p class="text mb-3">The Service may integrate with third-party applications.
                                            The use of Third-Party Applications may
                                            necessitate acceptance of their terms of service and privacy policies.
                                            Comhard is not liable for any Third-
                                            Party Applications and may suspend or remove them without notice. Tally TDLs
                                            and 3rd party add ons
                                            also comes under 3rd party and are not a default feature. This may impact
                                            cloud operations and
                                            Comhard shall not be liable for issues being generated due to such add ons.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>10. Fees, Payments & Renewals</b> </p>
                                        <p class="text mb-3">The Service is available through various subscription
                                            plans. Payments for subscriptions can be made by
                                            Payment Gateway. Subscription fees will be automatically charged at the end
                                            of each subscription
                                            period. You may update payment details upon renewal. Comhard reserves the
                                            right to change prices or
                                            charge for previously free Services. Taxes may be invoiced separately.
                                            Comhard will send renewal
                                            reminders before expiry. In case, customer does not make payment before the
                                            expiry date, subscription
                                            services will be automatically paused. Customer can make online payment and
                                            renew their services,
                                            within certain period of expiry. After this certain period, customer’s
                                            account and data will be
                                            automatically deleted.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>11. Organization Accounts and Administrators</b> </p>
                                        <p class="text mb-3">If You create an organization account, you may designate
                                            administrators with specific rights and
                                            responsibilities. Administrators may configure and manage the Service. You
                                            are responsible for
                                            maintaining confidentiality, appointing competent administrators, and
                                            complying with this Agreement.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>12. Personal Information and Privacy</b> </p>
                                        <p class="text mb-3">Personal information provided to Comhard is governed by the
                                            Comhard Privacy Policy. You are
                                            responsible for safeguarding your account's confidentiality and reporting
                                            unauthorized use.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>13. Communications from Comhard</b> </p>
                                        <p class="text mb-3">The Service may include communications from Comhard, such
                                            as service announcements and
                                            newsletters. You may opt-out of newsletters but not service announcements.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>14. Dispute Resolution and Feedback</b> </p>
                                        <p class="text mb-3">In the event of any dispute or feedback related to our
                                            services or this Agreement, we encourage you to contact us at <a
                                                href="mailto:contact@comhard.co.in">contact&#64;comhard.co.in</a>. We
                                            are committed to addressing your concerns promptly and effectively. Both
                                            parties will try to resolve the dispute amicably in such scenarios.</p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>15. Inactive User Accounts Policy</b> </p>
                                        <p class="text mb-3">In case a subscription is expired, Comhard reserves the
                                            right to terminate inactive user accounts after 30
                                            days. Notice will be provided, and data may be deleted.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>16. Hosting Location</b> </p>
                                        <p class="text mb-3">The location of cloud facilities is at Ctrl-s Data Centre
                                            Noida, Uttar Pradesh, India, which is a Tier 4
                                            location. Masking your IP address may result in account actions. In case
                                            Comhard has to relocate hosting
                                            location, Comhard will provide same level of Data Center.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>17. Data Ownership</b> </p>
                                        <p class="text mb-3">You retain ownership of the content you create or store.
                                            Comhard has permission to access and use
                                            your content solely for providing the Service.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>18. Trademark</b> </p>
                                        <p class="text mb-3">Comhard trademarks may not be displayed or used without
                                            permission.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>19. Disclaimer of Warranties</b> </p>
                                        <p class="text mb-3">YOU EXPRESSLY UNDERSTAND AND AGREE THAT THE USE OF THE
                                            SERVICES IS AT YOUR SOLE RISK. THE
                                            SERVICES ARE PROVIDED ON AN AS-IS-AND-AS-AVAILABLE BASIS. COMHARD EXPRESSLY
                                            DISCLAIMS ALL
                                            WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
                                            LIMITED TO, THE
                                            IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.
                                            COMHARD
                                            MAKES NO WARRANTY THAT THE SERVICES WILL BE UNINTERRUPTED, TIMELY, SECURE,
                                            OR ERROR
                                            FREE. USE OF ANY MATERIAL DOWNLOADED OR OBTAINED THROUGH THE USE OF THE
                                            SERVICES SHALL
                                            BE AT YOUR OWN DISCRETION AND RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR
                                            ANY DAMAGE TO
                                            YOUR COMPUTER SYSTEM, MOBILE TELEPHONE, WIRELESS DEVICE OR DATA THAT RESULTS
                                            FROM THE
                                            USE OF THE SERVICES OR THE DOWNLOAD OF ANY SUCH MATERIAL. NO ADVICE OR
                                            INFORMATION,
                                            WHETHER WRITTEN OR ORAL, OBTAINED BY YOU FROM COMHARD, ITS EMPLOYEES OR
                                            REPRESENTATIVES SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THE
                                            AGREEMENT.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>20. Limitation of Liability</b> </p>
                                        <p class="text mb-3">YOU AGREE THAT COMHARD TECHNOLOGIES PVT. LTD. ("Comhard")
                                            SHALL, IN NO EVENT, BE LIABLE
                                            FOR ANY CONSEQUENTIAL, INCIDENTAL, INDIRECT, SPECIAL, PUNITIVE, OR OTHER
                                            LOSS OR DAMAGE
                                            WHATSOEVER OR FOR LOSS OF BUSINESS PROFITS, BUSINESS INTERRUPTION, COMPUTER
                                            FAILURE,
                                            LOSS OF BUSINESS INFORMATION, OR OTHER LOSS ARISING OUT OF OR CAUSED BY YOUR
                                            USE OF OR
                                            INABILITY TO USE THE TALLY ON CLOUD SUBSCRIPTION SERVICE FOR OFFLINE
                                            ACCOUNTING SOFTWARE,
                                            EVEN IF COMHARD HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. IN NO
                                            EVENT SHALL
                                            COMHARD’S ENTIRE LIABILITY TO YOU IN RESPECT OF THE TALLY ON CLOUD
                                            SUBSCRIPTION SERVICE,
                                            WHETHER DIRECT OR INDIRECT, EXCEED TEN THOUSAND RUPEES OR THE PRO RATA FEES
                                            REMAINING
                                            OF YOUR SUBSCRIPTION GIVING RISE TO SUCH LIABILITY, WHICHEVER IS HIGHER.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>21. Indemnification</b> </p>
                                        <p class="text mb-3">You agree to indemnify and hold harmless Comhard, its
                                            officers, directors, employees, suppliers, and
                                            affiliates, from and against any losses, damages, fines, and expenses
                                            (including attorney's fees and costs)
                                            arising out of or relating to any claims that you have used the Tally on
                                            Cloud Subscription Service in
                                            violation of another party's rights, in violation of any law, in violation
                                            of any provisions of this
                                            Agreement, or any other claim related to your use of the Tally on Cloud
                                            Subscription Service, except
                                            where such use is authorized by Comhard.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>22. Governing Law and Jurisdiction</b> </p>
                                        <p class="text mb-3">Any controversy or claim arising out of or relating to
                                            these Terms shall be settled and adjudicated
                                            exclusively by the courts of Noida, Uttar Pradesh, India, in accordance with
                                            the laws of India, without
                                            regard to conflict of law principles.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>23. Suspension and Termination</b> </p>
                                        <p class="text mb-3">Comhard may suspend your user account or temporarily
                                            disable access to the Tally on Cloud
                                            Subscription Service in the event of any suspected illegal activity,
                                            extended periods of inactivity, or
                                            requests by law enforcement or other government agencies. Objections to
                                            suspension or disabling of
                                            user accounts should be made to contact&#64;comhard.co.in within thirty days
                                            of being notified about the
                                            suspension. Comhard may terminate a suspended or disabled user account after
                                            thirty days. Comhard
                                            will also terminate your user account on your request.
                                            In addition, Comhard reserves the right to terminate your user account and
                                            deny the Tally on Cloud
                                            Subscription Service upon reasonable belief that you have violated this
                                            Agreement and to terminate
                                            your access to any Beta Service in case of unexpected technical issues or
                                            discontinuation of the Beta
                                            Service. You have the right to terminate your user account if Comhard
                                            breaches its obligations under
                                            this Agreement, and in such an event, you will be entitled to a prorated
                                            refund of any prepaid fees.
                                            Termination of the user account will include denial of access to all Tally
                                            on Cloud Subscription Service
                                            components, deletion of information in your user account such as your email
                                            address and password,
                                            and deletion of all data in your user account.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>24. Refund and Cancellation Policy</b> </p>
                                        <p class="text mb-3"><b>a)</b> Once products or services have been ordered, they
                                            cannot be canceled. Products or services that
                                            have been sold will not be accepted back by Comhard Technologies Pvt Ltd.
                                            However, we may, at our
                                            sole discretion, consider accepting the return of a product or service and
                                            grant a part or full refund in
                                            such circumstances.
                                        </p>
                                        <p class="text mb-3"><b>b) Refund Eligibility: </b>
                                    <li class="text mb-3">&#x2022; Refunds will only be considered if the issue is
                                        determined to be from Comhard Technologies Pvt
                                        Ltd's side and not from the client's system side
                                    </li>
                                    <li class="text mb-3">To be eligible for a refund, the following conditions must be met: 
                                        <p class="text mb-3">&#x2022; To request a refund, please contact our team at contact&#64;comhard.co.in, providing detailed 
                                            information about the issue.</p>
                                            <p class="text mb-3">&#x2022; Our team will review your request and assess its eligibility for a refund.</p>
                                            <p class="text mb-3">&#x2022; If your refund is approved, please note that it may take 90 working days for the refund to be 
                                                processed and credited back to your original mode of payment.</p>
                                    </li>
                                    <li>
                                        <p class="text mb-3"><b>c) Refund Process: </b> </p>
                                    </li>
                                    <li class="text mb-3">&#x2022; Once products or services have been ordered, they
                                        cannot be canceled. Products or services that
                                        have been sold will not be accepted back by Comhard Technologies Pvt Ltd.
                                        However, we may, at our
                                        sole discretion, consider accepting the return of a product or service and
                                        grant a part or full refund in
                                        such circumstances.
                                    </li>
                                    <li class="text mb-3">&#x2022; Our team will review your request and assess its eligibility for a refund. 
                                    </li>
                                    <li class="text mb-3">&#x2022; If your refund is approved, please note that it may take 90 working days for the refund to be 
                                        processed and credited back to your original mode of payment.  
                                    </li>
                                    <li>
                                        <p class="text mb-3"><b>d) Non-Refundable Services: </b> </p>
                                    </li>
                                    <li class="text mb-3">&#x2022; Certain services or fees, such as setup fees, customization fees, or one-time charges, are non-
                                        refundable unless explicitly stated otherwise.   
                                    </li>
                                    <li>
                                        <p class="text mb-3"><b>e) Currency of Refunds:  </b> </p>
                                    </li>
                                    <li class="text mb-3">&#x2022; All refunds will be issued in the same currency in which the original payment was made.   
                                    </li>
                                    <li>
                                        <p class="text mb-3"><b>f) Changes to Refund Policy:  </b> </p>
                                    </li>
                                    <li class="text mb-3">&#x2022; Comhard Technologies Pvt Ltd reserves the right to modify this Refund Policy at any time. Any 
                                        changes will be posted on our website, and it is your responsibility to review this policy 
                                        periodically.   
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>25. Modification of Terms of Service </b> </p>
                                        <p class="text mb-3">Comhard may modify this Agreement upon notice to you at any time through a service announcement 
                                            or by sending an email to your primary email address. If Comhard makes significant changes to the 
                                            Agreement that affect your rights, you will be provided with at least 30 days advance notice of the 
                                            changes by email to your primary email address. You may terminate your use of the Tally on Cloud 
                                            Subscription Service by providing Comhard notice by email within 30 days of being notified of the 
                                            availability of the modified Agreement if the Agreement is modified in a manner that substantially 
                                            affects your rights in connection with the use of the Tally on Cloud Subscription Service. In the event of 
                                            such termination, you will be entitled to a prorated refund of the unused portion of any prepaid fees. 
                                            Your continued use of the Service after the effective date of any change to the Agreement will be 
                                            deemed to be your agreement to the modified Agreement.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>26. End of Terms of Service</b> </p>
                                        <p class="text mb-3">If you have any questions or concerns regarding this agreement, please contact us at 
                                            <a href="mailto:contact@comhard.co.in">contact&#64;comhard.co.in</a>. 
                                       
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>