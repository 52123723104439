<!-- ---------change--plan--price--modal------------------ -->
<div class="px-4">
  <h4 class="modal-title pull-left mb-2"><b>Upgrade to paid plan.</b></h4>
  <h5 class="text-danger mt-0"> Note : After subscription is converted to paid, customer need to reactivate their
    license tomorrow. If they are using client file to login, client file will also change, as per live server.</h5>
  <div>
    <form action="" [formGroup]="changeFreeTrialPlanForm">
      <div class="row mb-2">
        <label for="fullName" class="col-sm-4 col-form-label">Select Product</label>
        <div class="col-sm-8">
          <select class="form-select form-control mt-2" aria-label="Default select example" id="productId"
            (change)="showPlanList()">
            <option value="" selected>Open this select menu</option>
            <option *ngFor="let product of productList" [value]="product._id">
              {{product?.name}}
            </option>
          </select>
        </div>
      </div>
      <div class="row mb-2">
        <label for="fullName" class="col-sm-4 col-form-label">Select Plan</label>
        <div class="col-sm-8">
          <select class="form-select form-control mt-2" aria-label="Default select example" id="planId"
            (change)="getPlanPrice()">
            <option value="" selected>Open this select menu</option>
            <option *ngFor="let plan of planList" [value]="plan._id">{{plan?.name}}</option>
          </select>
        </div>
      </div>
      <div class="row mb-3">
        <label for="fullName" class="col-sm-4 col-form-label">Unit Price</label>
        <div class="col-sm-8 mt-2">
          <input type="number" placeholder="Enter Unit Price" class="form-control" formControlName="unitPrice" min="0"
            readonly
            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
        </div>
      </div>

      <div class="row mb-3">
        <label for="fullName" class="col-sm-4 col-form-label">No. Of Users</label>
        <div class="col-sm-8 mt-2">
          <input type="number" placeholder="Enter No. Of Users" class="form-control" formControlName="noOfUsers"
            (input)="planAmount()" min="0"
            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
          <div *ngIf="changeFreeTrialPlanForm.get('noOfUsers')?.invalid" class="alert mb-0 p-0">
            <div class="error-message"
              *ngIf="changeFreeTrialPlanForm.get('noOfUsers')?.errors?.['required'] && (changeFreeTrialPlanForm.get('noOfUsers')?.dirty || changeFreeTrialPlanForm.get('noOfUsers')?.touched)">
              <p class="text-danger m-0 p-0"><small>it's a mandatory field.</small>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-3">
        <label for="fullName" class="col-sm-4 col-form-label">Price</label>
        <div class="col-sm-8 mt-2">
          <input type="number" placeholder="Enter Price" class="form-control" formControlName="price" readonly min="0"
            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
        </div>
      </div>
      <div class="row mb-3">
        <label for="fullName" class="col-sm-4 col-form-label">Select User</label>
        <div class="col-sm-8 mt-2">
          <select class="form-select form-control mt-2" id="salesPersonName" formControlName="salesPersonName">
            <option value="direct" selected (click)="salesPersons('direct')">Direct</option>
            <option value="support" (click)="salesPersons('support')">Support</option>
            <option *ngFor="let salesPerson of salesPersonList" (click)="salesPersons(salesPerson.name)" [value]="salesPerson.name">
                {{salesPerson?.name}}
            </option>
        </select>
        </div>
      </div>
    </form>
  </div>
  <div class="text-end">
    <button type="submit" class="comman-btn1 btn-primary me-2" (click)="submit()">Proceed</button>
    <button type="button" class="comman-btn1 btn-secondary text-light" (click)="closeModal()">Cancel</button>
  </div>
</div>