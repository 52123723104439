<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-12">
                            <h3 class="heading">Tickets To CoCloud <i class="bi bi-info-circle-fill" ngbTooltip="You can raise tickets from here, and our team will get back to you. You can also track the status of your ticket from here." placement="bottom"></i><span class="float-end"><a
                                        [routerLink]="['/raise-service-request']" class="raise-request">Raise Service
                                        Request</a></span></h3>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 tickets-tab">
                    <div class="row">
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class="card pe-auto" (click)="clickableData('')"
                                [ngClass]="ticketTitle===''?'active':''">
                                <div class="card-content">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between align-self-center">
                                            <div class="text-left">
                                                <h4>{{totalTickets}}</h4>

                                            </div>
                                            <div class="tickets">
                                                <i class="bi bi-ticket-perforated-fill icon"></i>
                                            </div>
                                        </div>
                                        <span class="heading">Total Tickets</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class="card pe-auto" (click)="clickableData('Open')"
                                [ngClass]="ticketTitle==='Open'?'active':''">
                                <div class="card-content">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between align-self-center">
                                            <div class="text-left">
                                                <h4>{{openTickets}}</h4>

                                            </div>
                                            <div class="tickets">
                                                <i class="bi bi-dropbox icon"></i>
                                            </div>
                                        </div>
                                        <span class="heading">Open Tickets</span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class="card pe-auto" (click)="clickableData('Pending')"
                                [ngClass]="ticketTitle==='Pending'?'active':''">
                                <div class="card-content">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between align-self-center">
                                            <div class="text-left">
                                                <h4 class="">{{inProgressTickets}}</h4>

                                            </div>
                                            <div class="tickets">
                                                <i class="bi bi-clock-history icon"></i>
                                            </div>
                                        </div>
                                        <span class="heading">In Progress Tickets</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class="card pe-auto" (click)="clickableData('Resolved')"
                                [ngClass]="ticketTitle==='Resolved'?'active':''">
                                <div class="card-content">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between align-self-center">
                                            <div class="text-left">
                                                <h4>{{resolvedTickets}}</h4>

                                            </div>
                                            <div class="tickets">
                                                <i class="bi bi-bag-check-fill icon"></i>
                                            </div>
                                        </div>
                                        <span class="heading">Resolved Tickets</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-sm-6 col-12">
                            <div class="card pe-auto" (click)="clickableData('Closed')"
                                [ngClass]="ticketTitle==='Closed'?'active':''">
                                <div class="card-content">
                                    <div class="card-body">
                                        <div class="d-flex justify-content-between align-self-center">
                                            <div class="text-left">
                                                <h4 class="primary">{{closedTickets}}</h4>

                                            </div>
                                            <div class="tickets">
                                                <i class="bi bi-exclamation-triangle-fill icon "></i>
                                            </div>
                                        </div>
                                        <span class="heading">Closed Tickets</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- ------------------ -->
                <div class="row table-section pe-0 mt-4">
                    <div class="col-md-12 grid-margin stretch-card pe-0">
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <h4 class="ticket-txt">Tickets</h4>
                                    </div>
                                    <div class="col-sm-5">
                                        <form [formGroup]="searchForm">
                                            <div class="input-group">

                                                <input type="text" class="form-control"
                                                    placeholder="Search by sub id..." aria-label="Recipient's username"
                                                    aria-describedby="basic-addon2" formControlName="subId" onkeypress="return event.charCode !==47 && event.charCode !==92">
                                                <div class="input-group-append">
                                                    <button class="btns bi bi-search" type="button"
                                                        (click)="ticketList(ticketsType)"></button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                               
                                <div class="mt-5 img-class mb-4" *ngIf="!isTicketAvailable">
                                    <div class='d-flex justify-content-center align-items-center'>
                                        <img src="../../../assets/images/speech bubble cloud.png" class="logo" />
                                    </div>
                                    <p class="request">No Service Request !</p>
                                </div>
                                <div class="records" *ngIf="isTicketAvailable">{{updatePaginationText}}</div>
                                <div class="table-responsive mt-2" *ngIf="isTicketAvailable">
                                    <table class="table table-striped table-borderless">
                                        <thead class="text-center table-header">
                                            <tr>
                                                <th scope="col">Ticket No</th>
                                                <th scope="col">Sub Id</th>
                                                <th scope="col">Subject</th>
                                                <th scope="col">Issue Type </th>
                                                <th scope="col">Issue Sub type</th>
                                                <th scope="col">Status </th>
                                                <th scope="col">Created At </th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr *ngFor="let ticket of tickets | paginate : config">
                                                <td scope="col">{{ticket?.ticketId }}</td>
                                                <td scope="col">
                                                    <a class="sub-link" [routerLink]="['/subscriptions-details']"
                                                        [queryParams]="{subId:ticket?.subscription?.code, domainName:this.domainName}">
                                                        {{ticket?.subscription?.code}}
                                                    </a>
                                                </td>
                                                <td scope="col">
                                                    <div class="d-inline-block text-truncate" style="max-width: 100px;"
                                                        ngbTooltip="{{ticket?.subject}}" placement="top">
                                                        {{ticket?.subject}}</div>
                                                </td>
                                                <td scope="col">
                                                    <div class="d-inline-block text-truncate" style="max-width: 100px;"
                                                        ngbTooltip=" {{ticket?.issueType}}" placement="top">
                                                        {{ticket?.issueType}}</div>

                                                </td>
                                                <td scope="col">
                                                    <div class="d-inline-block text-truncate" style="max-width: 100px;"
                                                        ngbTooltip="{{ticket?.issueSubType}}" placement="top">
                                                        {{ticket?.issueSubType}}</div>

                                                </td>
                                                <td scope="col">
                                                    <div class="status-wrapper">
                                                        <div [style.backgroundColor]="ticket?.status?.color"
                                                            class="square"></div>
                                                        <p class="fw-bold">{{ticket?.status?.name}}</p>
                                                    </div>
                                                </td>
                                                <td scope="col">{{ticket?.createdAt | date:"d MMM, yy"}}</td>
                                            </tr>
                                        </tbody>
                                    </table>

                                </div>
                                <div class="mt-4 float-end me-4" *ngIf="isTicketAvailable">
                                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ----------- -->
            </div>
        </div>
    </div>