<div class="modal-header border-0 py-0">
  <h4 class="modal-title pull-left border-0"><b>Manage Users</b></h4>
</div>
<div *ngIf="subscriptionStatus">
  <div class="modal-body">
    <form [formGroup]="changeUserForm">
      <div class="row mb-2">

        <div class="col-sm-5 col-form-label">
          <label class="form-check-label" for="flexCheckDefault">
            Current User Limit
          </label>
        </div>

        <div class="col-sm-7 mt-2">
          <div class="d-flex">
            <input type="number" class="form-control" formControlName="noOfUsers" readonly>
          </div>
        </div>

        <div class="col-sm-5 col-form-label" *ngIf="!freePlan">
          <label class="form-check-label" for="flexCheckDefault">
            Enter new user limit
          </label>
        </div>

        <div class="col-sm-7 mt-2" *ngIf="!freePlan">
          <div class="d-flex">
            <input type="number" class="form-control" id="newUsers" formControlName="newUsers" pattern="[1-9][0-9]*"
              [readOnly]="newUserEnable" required>
          </div>

          <div *ngIf="changeUserForm.controls.newUsers.invalid && 
            (changeUserForm.controls.newUsers.dirty
             || changeUserForm.controls.newUsers.touched)" class="error-message">
            <div *ngIf="changeUserForm.controls.newUsers.errors?.['required']">
              <p class="text-danger m-0 p-0"><small>It is mandatory field</small></p>
            </div>
            <div *ngIf="changeUserForm.controls.newUsers.errors?.['pattern']">
              <p class="text-danger m-0 p-0"><small>Invalid user limit</small></p>
            </div>
          </div>
        </div>

        <div class="col-sm-12" *ngIf="showPrice">
          <div class="alert alert-warning mt-4" role="alert">
            <p class="mb-0">
              <span class="fw-bold">Current Price :
                <i class="bi bi-currency-rupee text-primary fs-6 m-0"></i>
                <span class="text-primary">
                  {{currentAmount | number:'1.0-2'}}
                </span>
              </span>

              <span class="fw-bold float-end" *ngIf="priceData?.priceAdd>priceData?.priceSubtract">Extra Charges :
                <i class="bi bi-currency-rupee text-primary fs-6 m-0"></i>
                <span class="text-primary">
                  {{priceData?.priceAdd | number:'1.0-2'}}
                </span>
              </span>

              <span class="fw-bold float-end" *ngIf="priceData?.priceAdd<priceData?.priceSubtract">Refundable Amount :
                <i class="bi bi-currency-rupee text-primary fs-6 m-0"></i>
                <span class="text-primary">
                  {{priceData?.priceSubtract | number:'1.0-2'}}
                </span>
              </span>

            </p>
          </div>
          <!-- <p class="text-info ms-2 mt-0" *ngIf="priceData?.priceAdd>priceData?.priceSubtract">
          *Extra charge to be paid for upgrade :
          <i class="bi bi-currency-rupee text-primary m-0"></i>
          <span class="text-primary fw-bold">{{priceData?.priceAdd  | number:'1.0-2'}}</span>
        </p>
        <p class="text-info ms-2 mt-0" *ngIf="priceData?.priceAdd<priceData?.priceSubtract">
          *Refundable amount for this downgrade :
          <i class="bi bi-currency-rupee text-primary m-0"></i>
          <span class="text-primary fw-bold">{{priceData?.priceSubtract  | number:'1.0-2'}}</span>
        </p> -->

          <p class="text-info ms-2 mt-0" *ngIf="showPrice">
            *Charges for the next time when plan gets renewed :
            <i class="bi bi-currency-rupee text-primary m-0"></i>
            <span class="text-primary fw-bold">{{newAmount | number:'1.0-2'}}</span>
          </p>

        </div>

        <div class="col-sm-5 col-form-label" *ngIf="showPrice">
          <label class="form-check-label" for="flexCheckDefault">
            Select User
          </label>
        </div>

        <div class="col-sm-7 mt-2" *ngIf="showPrice">
          <select class="form-select form-control mt-2" id="salesPersonName" formControlName="salesPersonName">
            <option value="direct" selected (click)="salesPersons('direct')">Direct</option>
            <option value="support" (click)="salesPersons('support')">Support</option>
            <option *ngFor="let salesPerson of salesPersonList" (click)="salesPersons(salesPerson.name)" [value]="salesPerson.name">
                {{salesPerson?.name}}
            </option>
        </select>
        </div>
      </div>
    </form>
  </div>



  <div class="text-end border-0" *ngIf="!showPrice">
    <button type="button" class="comman-btn1 btn-primary me-2" (click)="changeUsers()">Continue</button>
    <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="bsModalRef.hide()">Cancel</button>
  </div>

  <div class="text-end border-0" *ngIf="showPrice">
    <button type="button" class="comman-btn1 btn-primary me-2" (click)="changePlanUsers()">Update</button>
    <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="bsModalRef.hide()">Cancel</button>
  </div>
</div>

<div *ngIf="!subscriptionStatus">
  <div class="alert alert-danger my-4 mx-4" role="alert">
    Users can only be managed after assigning a subscription
  </div>
  <div class="text-end border-0">
    <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="bsModalRef.hide()">Close</button>
  </div>
</div>