export const stateList = [
    {
        "key": "AN",
        "name": "Andaman & Nicobar",
        "zone": "southZone"
    },
    {
        "key": "AD",
        "name": "Andhra Pradesh",
        "zone": "southZone"
    },
    {
        "key": "AR",
        "name": "Arunachal Pradesh",
        "zone": "eastZone"
    },
    {
        "key": "AS",
        "name": "Assam",
        "zone": "eastZone"
    },
    {
        "key": "BR",
        "name": "Bihar",
        "zone": "eastZone"
    },
    {
        "key": "CH",
        "name": "Chandigarh",
        "zone": "northZone"
    },
    {
        "key": "CG",
        "name": "Chattisgarh",
        "zone": "eastZone"
    },
    {
        "key": "DN",
        "name": "Dadra & Nagar Haveli",
        "zone": "westZone"
    },
    {
        "key": "DD",
        "name": "Daman & Diu",
        "zone": "westZone"
    },
    {
        "key": "DL",
        "name": "Delhi",
        "zone": "northZone"
    },
    {
        "key": "GA",
        "name": "Goa",
        "zone": "westZone"
    },
    {
        "key": "GJ",
        "name": "Gujarat",
        "zone": "westZone"
    },
    {
        "key": "HR",
        "name": "Haryana",
        "zone": "northZone"
    },
    {
        "key": "HP",
        "name": "Himachal Pradesh",
        "zone": "northZone"
    },
    {
        "key": "JK",
        "name": "Jammu & Kashmir",
        "zone": "northZone"
    },
    {
        "key": "JH",
        "name": "Jharkhand",
        "zone": "eastZone"
    },
    {
        "key": "KA",
        "name": "Karnataka",
        "zone": "southZone"
    },
    {
        "key": "KL",
        "name": "Kerala",
        "zone": "southZone"
    },
    {
        "key": "LA",
        "name": "Ladakh",
        "zone": "northZone"
    },
    {
        "key": "LD",
        "name": "Lakshadweep",
        "zone": "southZone"
    },
    {
        "key": "MP",
        "name": "Madhya Pradesh",
        "zone": "westZone"
    },
    {
        "key": "MH",
        "name": "Maharashtra",
        "zone": "westZone"
    },
    {
        "key": "MN",
        "name": "Manipur",
        "zone": "eastZone"
    },
    {
        "key": "ML",
        "name": "Meghalaya",
        "zone": "eastZone"
    },
    {
        "key": "MZ",
        "name": "Mizoram",
        "zone": "eastZone"
    },
    {
        "key": "NL",
        "name": "Nagaland",
        "zone": "eastZone"
    },
    {
        "key": "OD",
        "name": "Odisha",
        "zone": "eastZone"
    },
    {
        "key": "PY",
        "name": "Pondicherry",
        "zone": "southZone"
    },
    {
        "key": "PB",
        "name": "Punjab",
        "zone": "northZone"
    },
    {
        "key": "RJ",
        "name": "Rajasthan",
        "zone": "westZone"
    },
    {
        "key": "SK",
        "name": "Sikkim",
        "zone": "eastZone"
    },
    {
        "key": "TN",
        "name": "Tamil Nadu",
        "zone": "southZone"
    },
    {
        "key": "TS",
        "name": "Telangana",
        "zone": "southZone"
    },
    {
        "key": "TR",
        "name": "Tripura",
        "zone": "eastZone"
    },
    {
        "key": "UP",
        "name": "Uttar Pradesh",
        "zone": "northZone"
    },
    {
        "key": "UK",
        "name": "Uttarakhand",
        "zone": "northZone"
    },
    {
        "key": "WB",
        "name": "West Bengal",
        "zone": "eastZone"
    }
]