<body>
    <div class="container-fluid " *ngIf="waitMessage">
        <div class="row">
            <div class="col-md-12 waitmessage " *ngIf="!notAuthrised">
                <h3 class="text-danger">Do not refresh the page</h3>
                <h3>Please Wait We processing your payment...</h3>
            </div>
            <div class="col-md-12 waitmessage " *ngIf="notAuthrised">
                <h3 class="text-wrap">{{message}}</h3>
            </div>
        </div>
    </div>
    <!--  -->
    <div class="main " *ngIf="pending">
        <div class="container-fluid">
            <div class="card">
                <p class=" mb-2 successful unsuccessful pending">Your Payment is Pending !</p>
                <p class="msg mb-2">Your payment is currently pending. Please check again in 10 minutes for an update on
                    your account details page.</p>
                <div style="margin:0 auto;" class="mb-4">
                    <img src="../../assets/images/pending.png" />
                </div>

                <div class="d-flex justify-content-between ms-3 me-3 mb-3 order">
                    <div class="">OrderId:</div>
                    <div>{{paymentDetail.order_no}}</div>
                </div>
                <div class="d-flex justify-content-between ms-3 me-3 order">
                    <div class="">Amount:</div>
                    <div>{{paymentDetail.order_amt}}</div>
                </div>

                <a href="redirectUrl" class="btn w-100">Go To Website</a>
            </div>
        </div>
    </div>

    <div class="main" *ngIf="failed">
        <div class="container-fluid">
            <div class="card">
                <p class="text-danger mb-2 unsuccessful">Your Payment was Unsuccessful !</p>
                <p class="msg mb-2">Something went to wrong, Please try again!</p>
                <div style="margin:0 auto;" class="mb-4">
                    <img src="../../assets/images/error.png" />
                </div>

                <div class="d-flex justify-content-between ms-3 me-3 mb-3 order">
                    <div class="">OrderId:</div>
                    <div>{{paymentDetail.order_no}}</div>
                </div>
                <div class="d-flex justify-content-between ms-3 me-3 order">
                    <div class="">Amount:</div>
                    <div>{{paymentDetail.order_amt}}</div>
                </div>

                <a href="redirectUrl" class="btn w-100">Go To Website</a>
            </div>
        </div>
    </div>
    <!--  -->
    <div class="main " *ngIf="success">
        <div class="container-fluid">
            <div class="card">
                <p class="text-bank mb-3">Your wallet will be credited upon confirmation of payment received from the bank.
                </p>
                <p class=" mb-2 successful unsuccessful">Thank you! Your payment was successful!</p>
                <p class="msg mb-2">We’ll be in touch shortly!</p>
                <div style="margin:0 auto;" class="mb-4 mt-3">
                    <img src="../../assets/images/success.png" />
                </div>

                <div class="d-flex justify-content-between ms-3 me-3 mb-3 order">
                    <div class="">OrderId:</div>
                    <div>{{paymentDetail.order_no}}</div>
                </div>
                <div class="d-flex justify-content-between ms-3 me-3 order">
                    <div class="">Amount:</div>
                    <div>{{paymentDetail.order_amt}}</div>
                </div>

                <a href="redirectUrl" class="btn w-100">Go To Website</a>
            </div>
        </div>
    </div>
</body>