<!-- ---Tally Management--modal-- -->
<div class="common-modal">
    <div class="modal-header border-0 py-0">
        <h4 class="modal-title pull-left border-0"><b>Verify Details</b></h4>
        <i class="bi bi-x" (click)="closedModal()"></i>
    </div>
    <div class="modal-body pb-0 pt-0">
        <form [formGroup]="mobileForm">
            <div class="row ">
                <label for="name" class="col-form-label">Name</label>
                <div>
                    <input type="text" class="form-control" id="name" formControlName="name">
                    <div *ngIf="mobileForm.get('name')?.invalid" class="alert mb-0 p-0">
                        <div
                            *ngIf="mobileForm.get('name')?.errors?.['required'] && (mobileForm.get('name')?.dirty || mobileForm.get('name')?.touched)">
                            <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
                            </p>
                        </div>

                    </div>
                </div>
                <label for="mobile" class="col-form-label">Mobile</label>
                <div>
                    <input type="number" class="form-control" id="mobile" formControlName="mobile" minlength="8">
                    <div *ngIf="mobileForm.get('mobile')?.invalid" class="alert mb-0 p-0">
                        <div
                            *ngIf="mobileForm.get('mobile')?.errors?.['required'] && (mobileForm.get('mobile')?.dirty || mobileForm.get('mobile')?.touched)">
                            <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
                            </p>
                        </div>
                        <div *ngIf="mobileForm.get('mobile')?.hasError('minlength')">
                            <p class="text-danger m-0 p-0"><small>Enter valid mobile</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row m-0 p-0 mt-2">
                <div class="col-sm-12  text-end pe-0">
                    <button type="submit" class="comman-btn1 btn-primary" (click)="submit()">Submit</button>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- ------end-------- -->