import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PlanService } from 'src/app/services/plan.service';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import * as moment from 'moment'

@Component({
  selector: 'app-plan-upgrade-downgrade',
  templateUrl: './plan-upgrade-downgrade.component.html',
  styleUrls: ['./plan-upgrade-downgrade.component.scss']
})
export class PlanUpgradeDowngradeComponent implements OnInit {
  @Input() subscriptionId!: string
  @Input() plan!: any
  @Input() code!: string
  @Input() endDate!: string

  previousPlanCost: number = 0
  currentPlanCost: number = 0
  selectedPlan: any
  plans: any
  currentTotalPrice: number = 0
  salesPersonList: any
  planUpgradeDowngradeForm = new FormGroup({
    previousPlan: new FormControl('', Validators.required),
    planId: new FormControl('', Validators.required),
    unitPrice: new FormControl('', Validators.required),
    noOfUsers: new FormControl(1, [Validators.required]),
    price: new FormControl(0, Validators.required),
    salesPersonName: new FormControl('direct')
  })
  event: EventEmitter<any> = new EventEmitter();

  constructor(
    private bsModalRef: BsModalRef,
    private toasts: ToastrService,
    private subscriptionService: SubscriptionsService,
    private planService: PlanService,
  ) { }

  ngOnInit(): void {
    this.planUpgradeDowngradeForm.get('planId')?.valueChanges.subscribe((value: any) => {
      this.selectedPlan = this.plans.find((plan: any) => { return plan._id === value })
      this.planUpgradeDowngradeForm.controls.unitPrice.setValue(this.selectedPlan.price)
      this.currentTotalPrice = this.selectedPlan.price * this.plan.noOfUsers
      this.planUpgradeDowngradeForm.controls.price.setValue(this.currentTotalPrice)
      this.calculateData()
    })
    setTimeout(() => {
      this.planUpgradeDowngradeForm.controls.noOfUsers.setValue(this.plan.noOfUsers)
      if (this.plan) {
        this.showProductList()
        this.planUpgradeDowngradeForm.controls.previousPlan.setValue(this.plan.name)
      }
      this.salesPersonLists(this.subscriptionId)
    }, 0);
  }

  closeModal() {
    this.bsModalRef.hide();
  }

  showProductList() {
    this.subscriptionService.showProductList().subscribe((res: any) => {
      if (res.success) {
        const productList = res.products.filter((product: any) => {
          if (product.id > 1) {
            return product
          }
        });
        const planList = productList.reduce((acc: any, item: any) => acc.concat(item.plans), []);
        const currentDate = moment()
        const planEndDate = moment(this.endDate)
        const planRemainDays = planEndDate.diff(currentDate, 'days');
        const planTotalDays = ((this.plan.billingCycle === 'year') ? 365 : 30) * this.plan.billEvery;
        const previousPlanDays = planTotalDays - planRemainDays
        this.plans = planList.filter((plan: any) => {
          const planDays = ((plan.billingCycle === 'year') ? 365 : 30) * plan.billEvery;
          if (planDays > previousPlanDays) {
            return !(plan.code === this.plan.code)
          }
        })
      }
    })
  }

  submit() {
    if (!this.planUpgradeDowngradeForm.valid) {
      this.planUpgradeDowngradeForm.markAllAsTouched()
    } else {
      this.planUpgradeDowngrade()
    }
  }

  planUpgradeDowngrade() {
    const planPayload = {
      subscriptionId: this.subscriptionId,
      plan: {
        name: this.selectedPlan.name,
        noOfUsers: this.planUpgradeDowngradeForm.controls.noOfUsers.value,
        unitPrice: this.selectedPlan.price,
        billingCycle: this.selectedPlan.billingCycle,
        billEvery: this.selectedPlan.billEvery,
        description: this.selectedPlan.description,
        code: this.selectedPlan.code
      },
      connectedName: this.planUpgradeDowngradeForm.get('salesPersonName')?.value
    }

    this.subscriptionService.planUpgradeDowngrade(planPayload).subscribe((res: any) => {
      if (res.success) {
        this.closeModal()
        this.toasts.success(res.message)
        this.event.emit('ok');

      }
    }, (err) => {
      this.toasts.error(err.error.message)
    })
  }

  calculateData() {
    const currentDate = moment()
    const planStartDate = moment(this.endDate)
    const previousPlanTotalDays = ((this.plan.billingCycle === 'year') ? 365 : 30) * this.plan.billEvery;
    const previousPlanOneDayPrice = this.plan.unitPrice / previousPlanTotalDays
    const previousPlanRemainingDays = planStartDate.diff(currentDate, 'days');
    const previousPlanDays = previousPlanTotalDays - previousPlanRemainingDays
    const previousPlanCost = previousPlanRemainingDays * previousPlanOneDayPrice * this.plan.noOfUsers

    const currentPlanTotalDays = ((this.selectedPlan.billingCycle === 'year') ? 365 : 30) * this.selectedPlan.billEvery;
    const currentPlanOneDayPrice = (+this.selectedPlan.price) / currentPlanTotalDays
    const currentPlanRemainingDays = currentPlanTotalDays - previousPlanDays
    const currentPlanCost = currentPlanRemainingDays * currentPlanOneDayPrice * this.plan.noOfUsers
    this.previousPlanCost = previousPlanCost
    this.currentPlanCost = currentPlanCost
  }

  salesPersons(name: string){
    this.planUpgradeDowngradeForm.controls.salesPersonName.setValue(name);
  }

  salesPersonLists(subscriptionId: any) {
    this.subscriptionService.subscriptionSalesPersonList(subscriptionId).subscribe((res: any) => {
      if (res.success) {
        this.salesPersonList = res.salesPersons
      }
    })
  }
}
