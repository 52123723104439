import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { UserService } from 'src/app/services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AddAccountComponent } from './add-account/add-account.component';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { AddLabelModalComponent } from '../modals/add-label-modal/add-label-modal.component';
import { ToastrService } from 'ngx-toastr';
import { EditExpiryDateComponent } from '../modals/edit-expiry-date/edit-expiry-date.component';
import { ManageUsersComponent } from '../modals/manage-users/manage-users.component';
import { FormControl, FormGroup } from '@angular/forms';
import { PlanService } from 'src/app/services/plan.service';
import { ChangePlanComponent } from '../modals/change-plan/change-plan.component';
import { CustomerListRoot } from 'src/app/interfaces/customer/list';
import { LabelListRoot } from 'src/app/interfaces/label/list';
import { SubscriptionListComponent } from '../subscriptions/subscription-list/subscription-list.component';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { SubscriptionListRoot } from 'src/app/interfaces/subscription/list';
import { SubscriptionSelectComponent } from '../modals/subscription-select/subscription-select.component';
import { WalletService } from 'src/app/services/wallet.service';
import * as XLSX from 'xlsx';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit {
  bsModalRef!: BsModalRef;
  domainName: string = '';

  constructor(
    private router: Router,
    private user: UserService,
    private http: HttpClient,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private planService: PlanService,
    private subscriptionService: SubscriptionsService,
    private walletService: WalletService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.getAccountList();
    this.getLabelList();
    this.profileDetail();
  }

  customerList: any = [];
  isEdit!: boolean;
  search: any = {};
  customerFound: boolean = false;
  planList!: any[];
  labelList!: any[];
  customerArray: any = [];
  labelListSearch!: any[];
  detailList: any;
  indexOfCustomer!: number;
  selectedId!: any;
  customTableFields!: {};
  excelFile = 'CustomerList.xlsx';
  page: number = 1;
  totalCount: number = 0;

  config = {
    itemsPerPage: 20,
    currentPage: this.page,
    totalItems: this.totalCount,
  };

  searchForm = new FormGroup({
    name: new FormControl(''),
    email: new FormControl(''),
    mobile: new FormControl(''),
    planType: new FormControl(''),
    planName: new FormControl(''),
    isActive: new FormControl(''),
    planAssignFrom: new FormControl(''),
    planAssignTo: new FormControl(''),
    planEndFrom: new FormControl(''),
    planEndTo: new FormControl(''),
    label: new FormControl(''),
  });

  profileDetail() {
    this.walletService.getDomainName().subscribe((res: any) => {
      if (res.success) {
        this.domainName = res?.domain;
      }
    });
  }

  getAccountList() {
    this.user.customerList(JSON.stringify(this.search), this.config.itemsPerPage, this.config.currentPage).subscribe(
      (res: CustomerListRoot) => {
        if (res.success) {
          this.customerList = res.customers;
          this.config.currentPage = res.totalCustomer.pages;
          this.config.totalItems = res.totalCustomer.total;
          this.customerFound = true;
        }
      },
      (error) => {
        if (error.status === 404) {
          this.customerFound = false;
        } else {
          this.toastr.warning(error.error.message);
        }
      }
    );
  }

  getPlanList() {
    this.planService.planList('').subscribe((res: any) => {
      if (res.success) {
        this.planList = res.plans;
      }
    });
  }

  getLabelList() {
    this.user.showLabel().subscribe((res: LabelListRoot) => {
      this.labelList = res.labels;
      this.labelListSearch = res.labels;
    });
  }

  customerDelete(id: any) {
    this.user.userDelete(id).subscribe((res: any) => {
      if (res.success) {
        this.getAccountList();
      }
    });
  }

  customerEdit(id: any) {
    this.isEdit = true;
    this.router.navigate(['/account/add-account'], {
      queryParams: { customerId: id },
    });
  }

  changeStatus(userId: string, status: boolean) {
    const userPayload = {
      customerId: userId,
      isActive: !status,
    };
    this.user.userStatus(userPayload).subscribe((res: any) => {
      if (res.success) {
        this.getAccountList();
      }
    });
  }

  showDetails(customerId: string) {
    this.selectedId = customerId;

    this.user.userDetails(customerId).subscribe((res: any) => {
      if (res.success) {
        this.detailList = res.customer;
      }
    });
  }

  createSubscription(customerId: string) {
    this.router.navigate(['/subscriptions-list'], {
      queryParams: { customerId: customerId },
    });
  }

  subscriptionDetails(customerId: string) {
    this.router.navigate(['account/subscriptions'], {
      queryParams: { customerId: customerId },
    });
  }

  customerDetails(customerID: any) {
    this.router.navigate(['partner-details'], {
      queryParams: { customerId: customerID },
    });
  }

  customerLogin(customerId: string, email: string) {
    this.subscriptionService.subscriptionList(customerId, {}, 1, 10, '').subscribe((res: SubscriptionListRoot) => {
      if (res.success) {
        const subscriptionList = res.subscriptions.filter(subscription => {
          if (!subscription.isDeleted) {
            return subscription;
          }
        });
        if (subscriptionList.length == 0) {
          this.toastr.warning('To navigate to the Customer portal, you need to assign a subscription to this customer.');
        }
        else if (subscriptionList.length > 1) {
          this.subscriptionListModal(customerId, email, this.domainName);
        } else if (subscriptionList.length === 1) {
          const payload = {
            email: email,
            subscriptionId: subscriptionList[0]?._id,
            isSalesMan: this.userType
          };
          this.user.loginWithPartner(payload).subscribe((res: any) => {
            if (res.success) {
              const url = this.domainName
                ? `http://${this.domainName}/loginWithPartner?token=${res.user.token} `
                : `${environment.url}/loginWithPartner?token=${res.user.token}`;
              window.open(url, '_blank');
            }
          });
        }
      }
    },
      (error) => {
        this.toastr.warning(error.error.message);
      }
    );
  }

  headerInfo = ['name', 'mobile', 'label', 'companyName', 'isActive'];

  exportCustomer() {
    let exportTable: any = [];
    for (let i = 0; i < this.customerList.length; i++) {
      let selectedFields: any = {
        Name: '',
        Mobile: '',
        Email: '',
        Company: '',
        Label: '',
        activeStatus: '',
      };
      selectedFields.Name = this.customerList[i].name;
      selectedFields.Mobile = this.customerList[i].mobile;
      selectedFields.Email = this.customerList[i].email;
      selectedFields.Company = this.customerList[i].companyName;
      selectedFields.Label = this.customerList[i].label.name;
      selectedFields.activeStatus = this.customerList[i].isActive;
      exportTable.push(selectedFields);
    }
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportTable);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, this.excelFile);
  }

  // Modals

  openModal(partnerId: string) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef.content.partnerId = partnerId;
    this.bsModalRef.content.title = 'Delete';
    this.bsModalRef.content.message =
      'Are you sure you want to delete this user?';
    this.bsModalRef.content.confirmText = 'Yes';
    this.bsModalRef.content.cancelText = 'Cancel';
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.getAccountList();
      } else {
        this.getAccountList();
      }
    });
  }

  subscriptionListModal(customerId: string, email: string, domainName: string) {
    this.bsModalRef = this.modalService.show(SubscriptionSelectComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef.content.customerId = customerId;
    this.bsModalRef.content.email = email;
    this.bsModalRef.content.domainName = domainName;
    // this.bsModalRef.content.event.subscribe((res: any) => {
    //   if (res === 'ok') {

    //   }
    //   else {

    //   }
    // }
    // )
  }

  editExpiryModal(partnerId: string) {
    this.bsModalRef = this.modalService.show(EditExpiryDateComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef.content.partnerId = partnerId;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.getAccountList();
      } else {
        this.getAccountList();
      }
    });
  }

  manageUsersModal(customerId: string) {
    this.bsModalRef = this.modalService.show(ManageUsersComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef.content.customerId = customerId;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.getAccountList();
      } else {
        this.getAccountList();
      }
    });
  }

  selectCustomer(customerId: string) {
    const existingCustomerId = this.customerArray.filter((id: string) => {
      if (id === customerId) {
        return id;
      }
    });
    if (existingCustomerId[0] === customerId) {
      this.customerArray.pop(customerId);
    } else {
      this.customerArray.push(customerId);
    }
  }

  assignLabel(labelId: string) {
    const setLabel = {
      customerId: this.customerArray,
      labelId: labelId,
    };
    this.user.setLabel(setLabel).subscribe((res: any) => {
      if (res.success) {
        this.getAccountList();
        this.toastr.info('Label assigned');
        this.customerArray = [];
      }
    });
  }

  removeSelectedCustomerLabel(customerId: string, labelId: string) {
    if (labelId === undefined) {
      this.toastr.warning('Customer has no label assigned');
    } else {
      this.user.removeSelectedLabel(customerId).subscribe((res: any) => {
        if (res.success) {
          this.getAccountList();
          this.toastr.info('Label removed for selected customer');
        }
      });
    }
  }

  searchList() {
    this.search = this.searchForm.value;
    const date1: any = this.searchForm.get('planAssignFrom')?.value;
    const date2: any = this.searchForm.get('planAssignTo')?.value;
    const date3: any = this.searchForm.get('planEndFrom')?.value;
    const date4: any = this.searchForm.get('planEndTo')?.value;
    const planAssign = new Date(date1);
    const planTo = new Date(date2);
    const expireFrom = new Date(date3);
    const expireTo = new Date(date4);

    if (planAssign > planTo) {
      this.toastr.warning(
        'Plan Assign To date must be greater than Plan Assign From date'
      );
    } else if (expireFrom > expireTo) {
      this.toastr.warning(
        'Plan Expire To date must be greater than Plan Expire From date'
      );
    } else {
      this.user.customerList(JSON.stringify(this.search),
        this.config.itemsPerPage,
        this.config.currentPage).subscribe(
          (res: CustomerListRoot) => {
            if (res.success) {
              if (res.customers.length === 0) {
                this.customerFound = false
                this.toastr.error('No data found');
                this.config.currentPage = 1
                this.config.totalItems = 0
                this.customerList =[]
              } else {
                this.toastr.info('Results fetched');
                this.config.currentPage = res.totalCustomer.pages;
                this.config.totalItems = res.totalCustomer.total;
                this.customerList = res.customers;
                this.customerFound = true
              }
            }
          },
          (error) => {
            this.toastr.error(error.error.message);
          }
        );
    }
  }

  resetSearchForm() {
    this.search = {};
    this.searchForm.reset();
    this.config.currentPage = 1;
    this.searchList();
  }

  searchLabel(event: any) {
    let labelName = event.target.value;

    this.user.labelSearch(labelName).subscribe((res: LabelListRoot) => {
      if (res.success) {
        this.labelListSearch = res.labels;
      }
    });
  }

  labelModal() {
    this.bsModalRef = this.modalService.show(AddLabelModalComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });

    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.getLabelList();
      } else {
        this.getLabelList();
      }
    });
  }

  changePlanModal(customerId: string) {
    this.bsModalRef = this.modalService.show(ChangePlanComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
    this.bsModalRef.content.customerId = customerId;
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    this.getAccountList();
  }

  get userType() {
    return this.authService.userType()
  }
  get updatePaginationText(){
    return this.authService.getPaginationText(this.config.currentPage,this.config.itemsPerPage,this.config.totalItems);
  }
}
