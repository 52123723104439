import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../_helpers/auth.guard';
import { AccountComponent } from './account/account.component';
import { AddAccountComponent } from './account/add-account/add-account.component';
import { PartnerDetailsComponent } from './account/partner-details/partner-details.component';
import { AuditComponent } from './audit/audit.component';
import { AccountsComponent } from './audit/accounts/accounts.component';
import { AdminsComponent } from './audit/admins/admins.component';
import { BillingComponent } from './billing/billing.component';
import { BrandingComponent } from './branding/branding.component';
import { DomainComponent } from './branding/domain/domain.component';
import { WebsiteComponent } from './branding/website/website.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PlanComponent } from './plan/plan.component';
import { ManageLabelComponent } from './plan/manage-label/manage-label.component';
import { AddPlanComponent } from './plan/add-plan/add-plan.component';
import { ReportsComponent } from './reports/reports.component';
import { SettingComponent } from './setting/setting.component';
import { SharedComponent } from './shared.component';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { NewSubscriptionsComponent } from './subscriptions/new-subscriptions/new-subscriptions.component';
import { ProductComponent } from './product/product.component';
import { PlanAndAddOnComponent } from './product/plan-and-add-on/plan-and-add-on.component';
import { AddSubscriptionComponent } from './subscriptions/add-subscription/add-subscription.component';
import { SubscriptionListComponent } from './subscriptions/subscription-list/subscription-list.component';
import { InstanceUserDetailsComponent } from './instance-user-details/instance-user-details.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { SalesManComponent } from './sales-man/sales-man.component';
import { RolesPermissionsComponent } from './roles-permissions/roles-permissions.component';
import { AddSalesmanComponent } from './sales-man/add-salesman/add-salesman.component';
import { AddRolesComponent } from './roles-permissions/add-roles/add-roles.component';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { PaymentComponent } from './payment/payment.component';
import { BillingDetailsComponent } from './billing-details/billing-details.component';
import { RaiseServiceRequestComponent } from './raise-service-request/raise-service-request.component';
import { AllServiceRequestComponent } from './all-service-request/all-service-request.component';
import { SubscriptionsDetailsComponent } from './subscriptions/subscriptions-details/subscriptions-details.component';
import { CrmComponent } from './subscriptions/crm/crm.component';
import { ActivityComponent } from './activity/activity.component';
import { PartnerSalesmanGuard } from '../_helpers/partner-salesman.guard'
import { TicketDetailsComponent } from './ticket-details/ticket-details.component';
import { CustomerTicketComponent } from './customer-ticket/customer-ticket.component';
import { LicenseDetailsComponent } from './license-details/license-details.component';
import { MrrReportComponent } from './mrr-report/mrr-report.component';
import { SalespersonReportComponent } from './salesperson-report/salesperson-report.component';
import { RelationshipMangerReportComponent } from './relationship-manger-report/relationship-manger-report.component';
import { OrderManagementComponent } from './order-management/order-management.component';
import { CreateOrderComponent } from './order-management/create-order/create-order.component';
const routes: Routes = [
  {
    path: '',
    component: SharedComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [PartnerSalesmanGuard],
      },
      {
        path: 'audit',
        component: AuditComponent
      },
      {
        path: 'accounts',
        component: AccountsComponent
      },
      {
        path: 'admins',
        component: AdminsComponent
      },
      {
        path: 'account',
        component: AccountComponent
      },
      {
        path: 'cloud-user',
        component: InstanceUserDetailsComponent
      },
      {
        path: 'user-details',
        component: UserDetailsComponent
      },
      {
        path: 'account/add-account',
        component: AddAccountComponent
      },
      {
        path: 'cloud-user-details',
        component: UserDetailsComponent
      },
      {
        path: 'partner-details',
        component: PartnerDetailsComponent
      },
      {
        path: 'billing',
        component: BillingComponent
      },
      {
        path: 'reports',
        component: ReportsComponent
      },
      {
        path: 'branding',
        component: BrandingComponent,
      },
      {
        path: 'domain',
        component: DomainComponent
      },
      {
        path: 'website',
        component: WebsiteComponent
      },
      {
        path: 'plan',
        component: PlanComponent
      },
      {
        path: 'manage-label',
        component: ManageLabelComponent
      },
      {
        path: 'subscriptions-list',
        component: SubscriptionListComponent
      },
      {
        path: 'subscriptions-details',
        component: SubscriptionsDetailsComponent,
        children: [
          {
            path: 'crm',
            component: CrmComponent
          }
        ]
      },
      {
        path: 'account/subscriptions',
        component: SubscriptionsComponent
      },
      {
        path: 'account/new-subscription',
        component: NewSubscriptionsComponent
      },
      {
        path: 'subscriptions-list/add-subscription',
        component: AddSubscriptionComponent
      },
      {
        path: 'product',
        component: ProductComponent
      },
      {
        path: 'plan-and-addOn',
        component: PlanAndAddOnComponent
      },
      {
        path: 'add-plan',
        component: AddPlanComponent
      },
      {
        path: 'billing-details',
        component: BillingDetailsComponent
      },
      {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [PartnerSalesmanGuard],
      },
      {
        path: 'setting',
        component: SettingComponent
      },
      {
        path: 'sales-man',
        component: SalesManComponent
      },
      {
        path: 'add-salesman',
        component: AddSalesmanComponent
      },
      {
        path: 'roles-permissions',
        component: RolesPermissionsComponent
      },
      {
        path: 'add-roles',
        component: AddRolesComponent
      },
      {
        path: 'audit-log',
        component: AuditLogComponent
      },
      {
        path: 'payment',
        component: PaymentComponent
      },
      {
        path: 'raise-service-request',
        component: RaiseServiceRequestComponent
      },
      {
        path: 'ticket-to-cocloud',
        component: AllServiceRequestComponent
      },
      {
        path: 'activity',
        component: ActivityComponent
      },
      {
        path: 'ticket-details',
        component: TicketDetailsComponent
      },
      {
        path: 'customer-ticket',
        component: CustomerTicketComponent
      },
      {
        path: 'license-details',
        component: LicenseDetailsComponent
      },
      {
        path: 'mrr-report',
        component: MrrReportComponent
      },
      {
        path: 'salesperson-report',
        component: SalespersonReportComponent
      },
      {
        path: 'relationship-manager-report',
        component: RelationshipMangerReportComponent
      },
      // {
      //   path: 'order-management',
      //   component: OrderManagementComponent
      // },
      // {
      //   path: 'order-management/create-order',
      //   component: CreateOrderComponent
      // },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
