import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(
    private http: HttpClient
  ) { }

  getOTP(otpPayload: any) {
    return this.http.post(`${environment.apiURL}/partner/login`, otpPayload)
  }

  verifyOTP(loginPayload: any) {
    return this.http.post(`${environment.apiURL}/partner/verify/otp`, loginPayload)
  }

  loginPassword(body: any) {
    return this.http.post(`${environment.apiURL}/partner/login`, body)
  }

  forgotPassword(body: any) {
    return this.http.post(`${environment.apiURL}/partner/forgotPassword`, body)
  }

  changePassword(body: any) {
    return this.http.post(`${environment.apiURL}/partner/changePassword`, body)
  }
  becomePartner(payload: any) {
    return this.http.post(`${environment.apiURL}/partner/signUp`, payload)
  }
  pincode(pinCode: any) {
    return this.http.get(`${environment.apiURL}/state?pincode=${pinCode}`)
  }

  accountManagerList() {
    return this.http.get(`${environment.apiURL}/partner/accountmanager`);    
  }
}
