import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PlanService } from 'src/app/services/plan.service';
import { SalesmanService } from 'src/app/services/salesman.service';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-delete-modal',
  templateUrl: './delete-modal.component.html',
  styleUrls: ['./delete-modal.component.scss']
})
export class DeleteModalComponent implements OnInit {

  @Input() planId!: string;
  @Input() partnerId!: string;
  @Input() labelId!: string;
  @Input() salesmanId!: string;
  @Input() statusId!:string
  // @Input() subscriptionId!: string;

  title: string = '';
  message: string = '';
  confirmText: string = '';
  cancelText: string = '';
  note :string = ''

  event: EventEmitter<any> = new EventEmitter();

  constructor(
    private user: UserService,
    private planService: PlanService,
    public bsModalRef: BsModalRef,
    private subscriptionService: SubscriptionsService,
    private toastr: ToastrService,
    private salesmanService: SalesmanService
  ) { }

  ngOnInit() {
  }

  closedModal() {
    this.bsModalRef.hide();
  }



  removePartner() {

    // console.log(this.partnerId)
    // console.log(this.planId)

    this.planService.delete(this.planId).subscribe((res: any) => {
      if (res.success) {
        // this.toasts.show(res.message);
        this.event.emit('ok');
        this.closedModal();
      }
      else {
        // this.toasts.show(res.message);
      }
    },
      (error) => {
        // this.toasts.show(error.error.message);
      })
  }

  removeCustomer() {
    this.user.userDelete(this.partnerId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.info(res.message);
        this.event.emit('ok');
        this.closedModal();
      }
    },
      (error) => {
        this.toastr.warning(error.error.message);
      })
  }

  removeLabel() {
    this.user.deleteLabel(this.labelId).subscribe((res: any) => {
      if (res.success) {
        this.event.emit('ok');
        this.closedModal();
      }
    },
      (error) => {
        this.toastr.warning(error.error.message)
        this.closedModal();
      }
    )
  }

  removeSalesman() {
    this.salesmanService.deleteSalesman(this.salesmanId).subscribe((res: any) => {
      if (res.success) {
        this.event.emit('ok');
        this.closedModal();
      }
    },
      (error) => {
        this.toastr.warning(error.error.message)
        this.closedModal();
      }
    )
  }



  checkDelete() {
    // if(this.planId == undefined){
    //   this.removeCustomer()
    // }
    // if(this.partnerId == undefined){
    //   this.removePartner()
    // }
    if (this.partnerId) {
      this.removeCustomer()
    }
    if (this.planId) {
      this.removePartner()
    }
    if (this.labelId) {
      // console.log(this.labelId)
      this.removeLabel()
    }
    if (this.salesmanId) {
      this.removeSalesman()
    }
    if(this.statusId){
      this.event.emit('ok');
      this.closedModal()
    }
    // if(this.subscriptionId){
    //   this.removeSubscription()
    // }
  }

}
