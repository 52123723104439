<footer>
    <div class="container">
        <div class="left-content">
            <ul>
                <li class="company">Copyright &copy; {{currentYear}} Comhard Technologies Pvt Ltd</li>
            </ul>
        </div>
        <div class="right-content">
            <ul>
                
                <li><a [routerLink]="['terms-conditions']" target="_blank">Terms & Conditions</a></li>
                <li><a [routerLink]="['privacy-policy']" target="_blank">Privacy Policy</a></li>
                <li><a [routerLink]="['about-us']" target="_blank">About Us</a></li>
            </ul>
        </div>
    </div>
</footer>