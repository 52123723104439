<div class="modal-header border-0 py-0">
    <h4 class="modal-title pull-left border-0"><b>Edit Expiry Date</b></h4>
  </div>
  <div>
    <div class="modal-body">
      <!-- <h4>Are you sure you want to delete this?</h4> -->
      <form [formGroup]="changeDateForm">
      <div class="row mb-2">
        <div class="col-sm-6 col-form-label">
         <label class="form-check-label" for="flexCheckDefault">
          Enter new plan expiry date
         </label>
        </div>
       
        <div class="col-sm-6 mt-2" >
          <input type="date" class="form-control" id="Date" formControlName="newDate" required>
        </div>
       </div>
      </form>
    </div>
    <div class="text-end border-0" >  
      <button type="button" class="comman-btn1 btn-primary me-2" (click)="changePlanEndDate()">Update</button>
      <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="bsModalRef.hide()">Cancel</button>
    </div>
  </div>