import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PlanService {

  constructor(
    private http: HttpClient
  ) { }

  createPlan(payload: any) {
    return this.http.post(`${environment.apiURL}/partner/plan`, payload)
  }

  planList(productId: string) {
    return this.http.get(`${environment.apiURL}/partner/plan?productId=${productId}`)
  }

  planDetail(planId: string) {
    return this.http.get(`${environment.apiURL}/partner/plan/details?planId=${planId}`)
  }

  editPlan(payload: any) {
    return this.http.put(`${environment.apiURL}/partner/plan`, payload)
  }

  delete(planId: string) {
    return this.http.delete(`${environment.apiURL}/partner/plan?planId=${planId}`)
  }
}
