import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'

})
export class ProfileService {

  constructor(
    private http: HttpClient
  ) { }

  profileDetails() {
    return this.http.get(`${environment.apiURL}/partner/profile`)
  }

  profileupdate(profilePayload: any) {
    return this.http.put(`${environment.apiURL}/partner/profile`, profilePayload);
  }

  countryList() {
    return this.http.get(`${environment.apiURL}/partner/country`);
  }
  pincode(pinCode: any) {
    return this.http.get(`${environment.apiURL}/state?pincode=${pinCode}`)
  }
}
