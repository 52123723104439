<!-- ******************** Current wallet Deatails ********************* -->

<div class="main-panel ">
  <div class="content-wrapper ">
    <div class="col-md-12 col-sm-12 grid-margin">
      <div class="row justify-content-center ">
        <div class="col-sm-12 col-md-7 mb-4">
          <div class="card bg-white card-radius">
            <div class="card-body ">
              <div class="d-flex justify-content-between">
                <h3 class="mb-4">Add <span style="color:#4B49AC;">Credits</span> to your wallet</h3>
                <div class="col-sm-6 text-end">
                  <p>Credit Balance: <span class="balance">{{walletBalance | number:'1.0-2' }}</span></p>
                </div>
              </div>
              <div class="c-d">
                <div class="row">
                  <div class="col-12 btn-div">
                    <div class="row pt-2 pb-2">
                      <div class="col-4 ">
                        <button type="button"
                          [ngClass]="currentAmount<minOrderValue?'billing-btn1':'billing-btn-activate'"
                          [disabled]="currentAmount<minOrderValue" (click)="decreasePayAmount()">-{{minOrderValue |
                          number:'1.0-2'}}</button>
                      </div>
                      <div class="col-4 wallet-div">
                        <form [formGroup]="tdsForm">
                          <input type="text" class="input-field" formControlName="creditWallet"
                            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))" />
                        </form>
                      </div>
                      <div class="col-4 ">
                        <button type="button" class="billing-btn2 float-end"
                          (click)="increasePayAmount()">+{{minOrderValue |
                          number:'1.0-2'}}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <p class="text-end " style="color: rgb(156, 154, 154);">min, {{minOrderValue | number:'1.0-2'}}
                  credits</p>
              </div>
              <div class="mt-0">
                <div>
                  <div class="d-flex justify-content-between">
                    <h4 class="text-gray">Price</h4>

                    <div class="col-sm-6 text-end">
                      <p class="text-gray"><i class="bi bi-currency-rupee rupees"></i>{{currentAmount |
                        number:'1.0-2'}}</p>
                    </div>
                  </div>
                  <div class="d-flex justify-content-between">
                    <h4 class="mb-1 text-gray">GST(18%)</h4>
                    <div class="col-sm-6 text-end">
                      <p class="text-gray"><i class="bi bi-currency-rupee rupees"></i>{{gstPrice | number:'1.0-2'}}
                      </p>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <div class="d-flex justify-content-between mt-2 mb-2">
                    <h4 class="mb-1 text-gray">Total Price</h4>
                    <div class="col-sm-6 text-end">
                      <p><i class="bi bi-currency-rupee rupees"></i>{{currentAmount+gstPrice | number:'1.0-2'}}</p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mb-2">
                <form [formGroup]="tdsForm">
                  <p><small><b>Payment Mode</b></small></p>
                  <select class="form-select text-muted mt-0" formControlName="paymentMode" >
                    <option value="" disabled selected>Select Payment mode</option>
                    <!-- <option value="creditDebitCard">Credit & Debit card</option>
                    <option value="upi">UPI</option>
                    <option value="netBanking">Net Banking</option> -->
                    <option value="online">Online</option>
                    <option value="manual">Manual</option>
                  </select>
                  <div *ngIf="tdsForm.get('paymentMode')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                      *ngIf="tdsForm.get('paymentMode')?.errors?.['required'] && (tdsForm.get('paymentMode')?.dirty || tdsForm.get('paymentMode')?.touched)">
                      <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                      </p>
                    </div>
                   
                  </div>
              </form>

            </div>

              <div class="d-flex mb-4 mt-4  justify-content-between align-items-center">
                <button class="me-2 comman-btn1 text-light billing-btn w-50" (click)="submit()"
                  [disabled]="currentAmount===0"> Pay {{currentAmount+gstPrice | currency:'INR'}} </button>
                <button class="comman-btn1  text-light billing-btn w-50" (click)="deductTds()">Deduct TDS</button>


              </div>
              <div *ngIf="isDeduct">
                <form [formGroup]="tdsForm">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="mb-3">
                      <p>TAN No</p>
                      <input class=" form-control" type="text" formControlName="tanNumber" />
                      <div *ngIf="tdsForm.get('tanNumber')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                          *ngIf="tdsForm.get('tanNumber')?.errors?.['required'] && (tdsForm.get('tanNumber')?.dirty || tdsForm.get('tanNumber')?.touched)">
                          <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                          </p>
                        </div>
                        <div class="error-message"
                          *ngIf="tdsForm.get('tanNumber')?.errors?.['pattern'] && (tdsForm.get('tanNumber')?.dirty || tdsForm.get('tanNumber')?.touched)">
                          <p class="text-danger m-0 p-0"><small>TAN No. is not correct.</small>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div>
                      <p>Section No</p>
                      <input class="form-control" type="text" [readonly]="true" value="194J A" />
                    </div>
                  </div>

                  <div class="d-flex justify-content-between align-items-center">
                    <div class="mt-3">
                      <p>TDS Rate : <b>2%</b></p>
                      <p class=""><b>Total Outstanding</b></p>
                      <p class=""> {{currentAmount |
                        currency:'INR'}}</p>
                    </div>
                    <div>
                      <p>TDS amount deducted</p>
                      <input class=" form-control" type="text" [readonly]="true"
                        [value]="deductPrice | currency : 'INR'" />
                    </div>
                  </div>
                </form>
                <div class="d-flex justify-content-between align-items-center mt-3">
                  <div>
                    <p class="r"><b>Payable Amount</b></p>
                    <p class=" "> {{amountAfterDeduction |
                      currency:'INR'}}</p>
                  </div>
                  <div>
                    <p class=""><b>GST</b></p>
                    <p class=" "> {{gstPrice |
                      currency:'INR'}}</p>
                  </div>
                  <div>
                    <p class=""><b>Amount To Be Paid</b></p>
                    <button class="me-2 comman-btn1 text-light billing-btn" (click)="deductPricePayment()"
                      [disabled]="amountAfterDeduction===0"> Pay
                      {{(amountAfterDeduction+gstPrice) | currency:'INR'}} </button>
                  </div>
                </div>

              </div>
              <form #form ngNoForm id="nonseamless" method="post" name="redirect" [action]="paymentRedirectUrl"
                target="_blank">
                <!-- Add target="_blank" here -->
                <input type="hidden" id="encRequest" name="encRequest" [ngModel]="encRequest">
                <input type="hidden" name="access_code" id="access_code" [ngModel]="accessCode">
              </form>

            </div>
          </div>
          <div class="mt-5">
            <p class="bank-details"><span class="notes">Note: </span>
              You're paying to <span><strong>"Comhard Technologies Private Limited"</strong></span>, You can view here
              alternative bank details for Bank transfer: <span class="click " (click)="isAccountDetails=true">Click
                here</span>
            </p>
            <div class="bank-details ms-4" *ngIf="isAccountDetails">
              <p>Company Name : COMHARD TECHNOLOGIES PVT LTD</p>
              <p>Account No. : 158005003598</p>
              <p>IFSC Code : ICIC0001580 </p>
              <p>Bank Name : ICICI Bank</p>
              <p>Bank Branch : Steller IT Park, Sector-62, Noida - 201309, U.P.</p>
            </div>
          </div>
        </div>

        <div class="col-md-4 col-sm-12 ">
          <div class="card details-card card-radius">
            <div class="card-body d-flex justify-content-between pb-2">
              <div>
                <h3 class="mb-1">Invoice Details:</h3>
              </div>
              <div class="text-end">
                <a routerLink="/billing-details" class="edit-btn">Edit</a>
              </div>
            </div>
            <div class="card-body">
              <div class="bg-light p-2">
                <h3 class=" rupees mb-1">Billing Details</h3>
                <p class="mb-1">{{profileDetail?.name}}</p>
                <p class="mb-1">{{profileDetail?.email}}</p>
                <p class="mb-1" *ngIf="profileDetail">+91&nbsp;{{profileDetail?.mobile}}</p>
                <p class="mb-1">{{profileDetail?.panCardNo}}</p>
              </div>
            </div>
            <div class="card-body ">
              <div class="bg-light p-2">
                <h3 class="mt-2 rupees mb-1">Billing Address</h3>
                <p class="mb-1">{{profileDetail?.organizationName | uppercase}}</p>
                <p class="mb-1" *ngIf="profileDetail">
                  {{profileDetail?.address}}&#44;&nbsp;{{profileDetail?.city}}&#44;&nbsp;{{profileDetail?.state}}&#44;&nbsp;{{profileDetail?.country}}&#44;&nbsp;{{profileDetail?.pinCode}}
                </p>
                <p></p>
              </div>
            </div>
            <div class="card-body ">
              <div class="bg-light p-2">
                <p class="disclaimer">Disclaimer: This information will be used for generating the transaction
                  invoice. The invoice once generated will not be editable</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>