import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';

@Component({
  selector: 'app-change-customer',
  templateUrl: './change-customer.component.html',
  styleUrls: ['./change-customer.component.scss']
})
export class ChangeCustomerComponent implements OnInit {

  @Input() subscriptionCode!:string 
  event: EventEmitter<any> = new EventEmitter();

changeCustomerForm = new FormGroup({
  email: new FormControl('', [Validators.required, Validators.email])
})

  constructor(
    public bsModalRef: BsModalRef,
    private subscriptionService: SubscriptionsService,
    private toasts: ToastrService
  ) { }

  ngOnInit(): void {
  }

  changeCustomerId(){
    const payload = Object.assign(this.changeCustomerForm.value, {subCode: this.subscriptionCode})
    this.subscriptionService.changeCustomer(payload).subscribe((res:any)=>{
      if(res.success){
        this.event.emit('ok');
        this.toasts.success(res.message);
        this.bsModalRef.hide();
      }
    },
    (error)=>{
      this.toasts.error(error.error.message)
    })
  }

}
