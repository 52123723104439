import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss']
})
export class ActivityComponent implements OnInit {
  activeSection : string ='all'

  isAll: boolean = false;
  isCall: boolean = false;
  isMeeting: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }


  toggleSection(section: string) {
    // Deactivate all sections
    this.isAll = false;
    this.isCall = false;
    this.isMeeting = false;

    // Activate the clicked section
    if (section === 'all') {
      this.isAll = true;
      this.activeSection = 'all';
    

    } else if (section === 'call') {
      this.isCall = true;
      this.activeSection = 'call';
      
      

    } else if (section === 'meeting') {
      this.isMeeting = true;
      this.activeSection = 'meeting';
      
    } 

  }
}
