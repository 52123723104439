import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PlanService } from 'src/app/services/plan.service';


@Component({
  selector: 'app-add-plan',
  templateUrl: './add-plan.component.html',
  styleUrls: ['./add-plan.component.scss']
})
export class AddPlanComponent implements OnInit {

  planId: string = '';
  isEdit: boolean = false;
  isMonthly! : boolean ;
  isYearly! : boolean ;

  selectedPlanType: string = '';
  planForm = new FormGroup({
    name: new FormControl(''),
    planType: new FormControl(''),
    price: new FormControl(''),
    // expiryDate: new FormControl(''),
    noOfUsers: new FormControl(''),
    planDuration : new FormControl(''),
    // isYearly: new FormControl(false),
    // isMonthly: new FormControl(false),
  
  })

  constructor(
    private planService: PlanService,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private toastr : ToastrService
  ) { }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(data => {
      this.planId = data['planId']
    })
    if (this.planId) {
      this.isEdit = true;
      this.planDetails();
    }
  }

  submit() {
    if (!this.isEdit) {
      this.createPlan();
    }
    else {
      this.updatePlan();
    }
  }

  createPlan() {
    // let planPayload = this.planForm.value
    this.togglePlanDuration(this.planForm.controls.planDuration.value)

    let planPayload = {
      name : this.planForm.controls.name.value,
      price : this.planForm.controls.price.value,
      // expiryDate : this.planForm.controls.expiryDate.value,
      noOfUsers : this.planForm.controls.noOfUsers.value,
      planType : this.selectedPlanType,
      isMonthly : this.isMonthly,
      isYearly : this.isYearly
    }
    planPayload.planType = this.selectedPlanType
    this.planService.createPlan(planPayload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Plan added")       
        this.router.navigate(['plan'])
        
      }
    })
  }

  togglePlan(currentPlan: string) {
    switch (currentPlan) {
      case 'homePlan':
        this.selectedPlanType = 'homePlan';
        
        break;
      case 'businessPlan':
        this.selectedPlanType = 'businessPlan';
        
        break;
      case 'deepFreezePlan':
        this.selectedPlanType = 'deepFreezePlan';
      
        break;
    }
  }

  planDetails() {
    this.planService.planDetail(this.planId).subscribe((res: any) => {
      if (res.success) {        
        this.planForm.patchValue(res.plan);
        this.isMonthly = res.plan.isMonthly;        
        this.isYearly = res.plan.isYearly;
        // console.log(this.isMonthly, this.isYearly)
        this.checkPlan()
      }
    })
  }

  updatePlan() {

    this.togglePlanDuration(this.planForm.controls.planDuration.value)
    
    let editPayload = {
      name : this.planForm.controls.name.value,
      price : this.planForm.controls.price.value,
      // expiryDate : this.planForm.controls.expiryDate.value,
      noOfUsers : this.planForm.controls.noOfUsers.value,
      planType : this.selectedPlanType,
      isMonthly : this.isMonthly,
      isYearly : this.isYearly
    }
    // let editPayload = this.planForm.value
    // editPayload.planType = this.selectedPlanType
    editPayload = Object.assign(editPayload, { planId: this.planId })
    this.planService.editPlan(editPayload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.info("Plan has been updated")
        this.router.navigate(['plan']);
      }
    })
  }

  togglePlanDuration(planTime:any){

    // console.log(planTime)

    if(planTime === 'month'){
      this.isMonthly = true;
      this.isYearly = false;
    }
    if(planTime === 'year'){
      this.isYearly = true;
      this.isMonthly = false;
    }
  }

  checkPlan(){
    if(this.isMonthly){
      this.isYearly = false
      this.isMonthly = true
    }
    if(this.isYearly){
      this.isYearly = true
      this.isMonthly = false
    }
  }
}
