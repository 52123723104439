<!-- ---------billing--section--start-------------- -->
<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12 ">
        <div class="row">
          <div class="col-md-6">
            <h3 class="heading">Billing</h3>
          </div>
          <!-- --------------Search Form---------------- -->
          <div class="col-md-8 grid-margin">
            <div class="row" *ngIf="!userType">
              <div class="col-md-6 mb-4 mb-lg-0 stretch-card transparent">
                <div class="card bg-white">
                  <div class="card-body ">
                    <div class="d-flex justify-content-between">
                      <div>
                        <h4 class="mb-4 commom-color">Billing details</h4>
                        <p *ngIf="!isBillDetailField">Please fill billing details.</p>
                        <p *ngIf="isBillDetailField"><b>Name : </b> {{profileData?.billing?.name}}</p>
                        <p *ngIf="isBillDetailField"><b>Email : </b> {{profileData?.billing?.email}}</p>
                        <p *ngIf="isBillDetailField"><b>Mobile No :</b> {{profileData?.billing?.mobile}}</p>
                      </div>
                      <span>
                        <a class="nav-link1" routerLink="/billing-details">{{isBillDetailField?'Edit':'Add'}}</a>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 stretch-card transparent">
                <div class="card details-card">
                  <div class="card-body d-flex justify-content-between">
                    <div>
                      <h4 class="mb-4 commom-color">Current Wallet </h4>
                      <p class="rate">
                        <b><i class="bi bi-currency-rupee"></i></b>
                        {{ profileData?.wallet | number : "1.0-2" }}
                      </p>
                    </div>
                    <span>
                      <a [routerLink]="['/payment']" class="float-end">Top Up</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="search-form">
              <p class="light mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
                aria-expanded="false" aria-controls="collapseExample">
                <i class="bi bi-search"></i>
                <span class="ms-5">Search here</span>
              </p>
              <div class="collapse mt-3" id="collapseExample">
                <form class="row g-3" [formGroup]="searchForm">
                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text" id="inputGroupPrepend">From</span>
                      <input type="date" class="form-control" id="validationCustomUsername" 
                        aria-describedby="inputGroupPrepend" required formControlName="startDate"/>
                      <div class="invalid-feedback">
                        Please choose a username.
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text" id="inputGroupPrepend">To</span>
                      <input type="date" class="form-control" id="validationCustomUsern" required formControlName="endDate"/>
                      <div class="invalid-feedback">
                        Please choose a username.
                      </div>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1">Actions</span>
                      <select class="form-select custom-select" aria-label="Default select example" formControlName="action">
                        <option value ='' selected disabled>Please Select</option>
                        <option value="freeCredit">Free Credit</option>
                        <option value="creditBought">Credit Bought</option>
                        <option value="adjustment">Adjustment</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-6">
                    <div class="input-group">
                      <span class="input-group-text" id="basic-addon1 ">Customer email</span>
                      <input type="email" class="form-control" formControlName="email" placeholder="Enter customer email" onkeypress="return event.charCode !== 47 && event.charCode !== 92 "/>
                    </div>
                  </div>

                  <div class="col-md-12 mb-4 text-end">
                    <button type="submit" class="comman-btn1 btn-primary text-light me-2" (click)="searchList()">
                      Search
                    </button>
                    <button type="button" class="comman-btn1 btn-secondary text-light" (click)="resetForm()">
                      Reset
                    </button>
                  </div>
                </form>
                <!-- ------ -->
              </div>
            </div>
          </div>
          <!-- --------------Search From End Here----------->
          <div class="row table-section pe-0">
            <div class="col-md-12 grid-margin stretch-card pe-0">
              <div class="card detailed-section">
                <div class="card-body">
                  <div class="records">{{updatePaginationText}}</div>
                  <!-- <div class="card-title">Billing Details</div> -->
                  <div class="table-responsive">
                    <table class="table table-borderless table-hover ">
                      <thead class=" table-header">
                        <tr>
                          <th class="box-0"></th>
                          <th scope="col">Date & Time</th>
                          <th scope="col">Starting Balance</th>
                          <th scope="col">Debit</th>
                          <th scope="col">Credit</th>
                          <th scope="col">Balance</th>
                        </tr>
                      </thead>
                      <tbody *ngFor="let bill of summarisedList | paginate: config">
                        <tr (click)="toggleTableExpend(bill)">
                          <td>
                            <span>
                              <i *ngIf="bill.collapse" class="bi bi-arrow-right-circle mouseCursor"></i>
                              <i *ngIf="!bill.collapse" class="bi bi-arrow-down-circle mouseCursor text-danger"></i>
                            </span>
                          </td>
                          <td><b>{{ bill?.createdAt | date : "MMMM,YYYY" }}</b></td>
                          <td>&#8377; {{ bill?.partnerStartingBalance | number : "1.0-2"}}</td>
                          <td class="text-danger"> &#8377; {{ bill?.debit | number : "1.0-2" }}</td>
                          <td class="text-success"> &#8377; {{ bill?.credit | number : "1.0-2" }}</td>
                          <td>&#8377; {{ bill?.partnerStartingBalance - bill?.debit + bill?.credit | number : "1.0-2" }}
                          </td>
                        </tr>
                        <tr class="collapse p-0" [class.show]="bill.collapse == false">
                          <td colspan="12" class="p-0">
                            <div class="table-responsive">
                              <table class="table table-borderless ">
                                <thead class="table-header1">
                                  <tr>
                                    <th class="box-type-0">Bill ID</th>
                                    <th scope="col" class="box-type-1">Date & Time</th>
                                    <th scope="col" class="box-type-2">Action</th>
                                    <th scope="col" class="box-type-2">Sub ID</th>
                                    <th scope="col" class="box-type-2">Sub Stage</th>
                                    <th scope="col" class="box-type-2">Customer Email</th>
                                    <th scope="col">Credit</th>
                                    <th scope="col">Debit</th>
                                    <th scope="col">Balance</th>
                                    <th scope="col" class="box-type-2">Created By</th>
                                    <th scope="col" class="box-type-3">Previous Data</th>
                                    <th scope="col" class="box-type-4">Change Data</th>
                                  </tr>
                                </thead>
                                <tbody *ngFor="let billDetail of detailedList | paginate : config">
                                  <tr
                                    *ngIf="bill?._id?.month === billDetail.month && bill?._id?.year === billDetail.year">
                                    <td>{{billDetail?.billNumber}}</td>
                                    <td>{{billDetail?.createdAt | date : "dd/MM/yyyy, hh:mm a": "UTC + 05:30"}}</td>
                                    <td><span [ngbTooltip]="billDetail?.description" placement="top">{{
                                        billDetail?.action | titlecase }}</span></td>
                                    <td>{{ billDetail?.subId | uppercase }}</td>
                                    <td>{{ billDetail?.stage | titlecase }}</td>
                                    <td>{{ billDetail?.customerEmail | titlecase }}</td>
                                    <td class="text-success"> &#8377;{{billDetail?.credit | number : "1.0-2"}}</td>
                                    <td class="text-danger">&#8377;{{ billDetail?.debit | number : "1.0-2" }} </td>
                                    <td> &#8377;{{billDetail?.partnerStartingBalance -billDetail?.debit +
                                      billDetail?.credit | number : "1.0-2" }}</td>
                                    <td>{{ billDetail?.createdBy | titlecase }}</td>
                                    <td>{{ billDetail?.previousData | titlecase }}</td>
                                    <td>{{ billDetail?.currentChange | titlecase }}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="mt-4 float-end me-4">
                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- --------------- -->
        </div>
      </div>
    </div>
  </div>
</div>