<!-- -------------add-account--page---------------------- -->
<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-12 col-xl-8 mb-4 mb-xl-0 heading-section">
        <h3 class="font-weight-bold" *ngIf="!isEdit">
          <i class="bi bi-arrow-left-short fs-2" [routerLink]="'/account'"></i> Add Customer
        </h3>
        <h3 class="font-weight-bold" *ngIf="isEdit">
          <i class="bi bi-arrow-left-short fs-2" [routerLink]="'/account'"></i> Edit Customer
        </h3>
      </div>
    </div>

    <div class="row mt-4 px-4">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- <p class="card-title mb-0">Add Account</p> -->
            <!-- ------form--section----------- -->
            <form [formGroup]="accountForm">
              <div class="row mb-3">
                <label for="fullName" class="col-sm-3 col-form-label">Full Name</label>
                <div class="col-sm-9 mt-2">
                  <input type="text" class="form-control" id="fullName" formControlName="name" minlength="1"
                    maxlength="50" required>
                  <div
                    *ngIf="accountForm.controls.name.invalid && (accountForm.controls.name.dirty || accountForm.controls.name.touched) "
                    class="error-message">
                    <div *ngIf="(accountForm.controls.name.errors?.['required']) ">
                      <p class="text-danger m-0 p-0"><small>Name is required</small></p>
                    </div>
                    <div *ngIf="accountForm.controls.name.errors?.['minlength'] ">
                      <p class="text-danger m-0 p-0"><small>Name must be at least 1 character long.</small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label for="company" class="col-sm-3 col-form-label">Company Name</label>
                <div class="col-sm-9 mt-2">
                  <input type="text" class="form-control" id="company" formControlName="companyName"
                     required>
                  <div
                    *ngIf="accountForm.controls.companyName.invalid && (accountForm.controls.companyName.dirty || accountForm.controls.companyName.touched)"
                    class="error-message">
                    <div *ngIf="(accountForm.controls.companyName.errors?.['required']) ">
                      <p class="text-danger m-0 p-0"><small>Company name is required</small></p>
                    </div>
                    <div *ngIf="accountForm.controls.companyName.errors?.['pattern']">
                      <p class="text-danger m-0 p-0"><small>Enter valid company name</small></p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3" disabled="isEdit" *ngIf="!isEdit">
                <label for="password" class="col-sm-3 col-form-label">Password</label>
                <div class="col-sm-9 mt-2">
                  <p class="input-password-1">
                    <input type="password" class="form-control" id="pass" formControlName="password" minlength="6"
                      required *ngIf="!isEdit"><span>
                      <i *ngIf="showPassword1" class="bi bi-eye password-icon-1" (click)="hidePassword1()"></i>
                      <i *ngIf="!showPassword1" class="bi bi-eye-slash password-icon-1" (click)="hidePassword1()"></i>
                    </span>
                  </p>

                  <div
                    *ngIf="accountForm.controls.password.invalid && (accountForm.controls.password.dirty || accountForm.controls.password.touched) "
                    class="error-message">
                    <div *ngIf="accountForm.controls.password.errors?.['required'] ">
                      <p class="text-danger m-0 p-0"><small>Password is required</small></p>
                    </div>
                    <div *ngIf="accountForm.controls.password.errors?.['minlength']">
                      <p class="text-danger m-0 p-0"><small>Enter min 6 digit password</small></p>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mb-3" *ngIf="!isEdit">
                <label for="mobileNo" class="col-sm-3 col-form-label">Confirm Password</label>
                <div class="col-sm-9 mt-2">
                  <p class="input-password-2">
                    <input type="password" class="form-control" id="repass" formControlName="confirmPassword"
                      minlength="6" required *ngIf="!isEdit">
                    <span>
                      <i *ngIf="showPassword2" class="bi bi-eye password-icon-2" (click)="hidePassword2()"></i>
                      <i *ngIf="!showPassword2" class="bi bi-eye-slash password-icon-2" (click)="hidePassword2()"></i>
                    </span>
                  </p>
                  <div
                    *ngIf="accountForm.controls.confirmPassword.invalid && (accountForm.controls.confirmPassword.dirty || accountForm.controls.confirmPassword.touched) "
                    class="error-message">
                    <div *ngIf="accountForm.controls.confirmPassword.errors?.['required'] ">
                      <p class="text-danger m-0 p-0"><small>Password is required</small></p>
                    </div>
                    <div *ngIf="accountForm.controls.confirmPassword.errors?.['minlength']">
                      <p class="text-danger m-0 p-0"><small>Enter min 6 digit password</small></p>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row mb-3">
                <label for="mobileNo" class="col-sm-3 col-form-label">Mobile No.</label>
                <div class="col-sm-9 mt-2">
                  <input type="text" class="form-control" id="mobileNo" formControlName="mobile" maxlength="20"
                    required>
                  <div
                    *ngIf="accountForm.controls.mobile.invalid && (accountForm.controls.mobile.dirty || accountForm.controls.mobile.touched) "
                    class="error-message">
                    <div *ngIf="accountForm.controls.mobile.errors?.['required'] ">
                      <p class="text-danger m-0 p-0"><small>Mobile No. is required</small></p>
                    </div>
                    <div *ngIf="accountForm.controls.mobile.errors?.['maxlength']">
                      <p class="text-danger m-0 p-0"><small>Enter 10-digit mobile no.</small></p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3">
                <label for="EmailAddress" class="col-sm-3 col-form-label">Email Address</label>
                <div class="col-sm-9 mt-2">
                  <input type="text" class="form-control" id="EmailAddress" formControlName="email" required>
                  <div
                    *ngIf="accountForm.controls.email.invalid && (accountForm.controls.email.dirty || accountForm.controls.email.touched)"
                    class="error-message">
                    <div *ngIf="accountForm.controls.email.errors?.['required']">
                      <p class="text-danger m-0 p-0"><small>Email address is required</small></p>
                    </div>
                  </div>


                </div>
              </div>
              <!-- 
       <div class="row mb-3">
        <label for="addLabel" class="col-sm-3 col-form-label">Add Label</label>
        <div class="col-sm-9 mt-2">
         <input type="text" class="form-control" id="addLabel" formControlName="label">         
        </div>
       </div> -->

              <!-- <div class="row mb-3">
        <label for="addLabel" class="col-sm-3 col-form-label">Subscription</label>
        <div class="col-sm-6" >
       <select class="form-select" aria-label="Default select example"formControlName="planId" required>
        <option selected disabled value="">Select Subscription</option>
        <ng-container *ngFor="let plan of planList" >
        <option  [value]="plan._id" >
         <p>{{plan.name}}<span>{{plan.price}}</span></p>
        </option>        
        </ng-container>        
      </select>
      
    </div>
    <div class="col-sm-3 mt-2">
    <a class="create-plan" routerLink="/new-subscription">+Create New Subscription</a>
    </div>
    </div> -->
              <div class="row mb-3">
                <!-- <label for="number" class="col-sm-3 col-form-label"><a >+Create New Plan</a></label> -->
                <div class="col-sm-12 mt-2 text-end">
                  <button type="button" class="comman-btn1 btn-primary" *ngIf="!isEdit" (click)="addAccount()"
                    [disabled]="!accountForm.valid">Add
                    Account</button>
                  <button type="button" class="comman-btn1 btn-primary" *ngIf="isEdit" (click)="updateAccount()"
                    [disabled]="(accountForm.controls.name.invalid) || (accountForm.controls.companyName.invalid)">Update
                    Account</button>
                  <button type="button" class="comman-btn1 btn-secondary ms-2" routerLink="/account">Cancel</button>
                </div>
              </div>
            </form>
            <!-- ------end------------ -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>