<div>
    <div class="modal-header py-1 border-0">
        <h4 class="modal-title pull-left"><b>Reason For churn</b></h4>
        <!-- <span (click)="closedModal()"><i class="bi bi-x-lg"></i></span> -->
    </div>
    <div>
        <form [formGroup]="remarkForm">
            <div class="modal-body pt-0 mt-0">
                <div>
                    <div class="row mb-3">
                        <label for="fullName" class="form-label">Remark</label>
                        <div class="mt-2" [formGroup]="remarkForm">
                            <input type="text" placeholder="Enter Remark" class="form-control" formControlName="remark">
                        </div>
                    </div>
                </div>
            </div>


            <div class="modal-footer border-0 pe-4">
                <div class="text-end border-0">
                    <button type="button" class="comman-btn1 btn-primary me-2" (click)="onSubmit()">Submit</button>
                    <button type="button" class="comman-btn1 btn-secondary text-light"
                        (click)="bsModalRef.hide()">Cancel</button>
                </div>
            </div>
        </form>
    </div>
</div>