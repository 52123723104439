<!-- ------Subscriptions--page--- -->
<div class="main-panel">
 <div class="content-wrapper">
  <!-- <form [formGroup]="customerForm"> -->
  <div class="row">
   <div class="col-md-12">
    <div class="row mb-4">
     <div class="col-8">
      <h3 class="font-weight-bold mb-3">All Subscriptions</h3>
     </div>
     <div class="col-4 text-end">
      <button type="button" class="comman-btn1 btn-primary" (click)="createNew(customerId)">+
       New</button>
     </div>
    </div>
   </div>
  </div>
  <!-- </form> -->
  <div class="row mt-4 ">
   <div class="col-md-12 grid-margin stretch-card">
    <div class="card">
     <div class="card-body">
      <div class="row">
       <div class="col-md-6">
        <p class="card-title">Customer Name</p>
        <p>{{customerDetail?.name | titlecase}}</p>
       </div>

       <div class="col-md-6">
        <p class="card-title">Company Name</p>
        <p>{{customerDetail?.companyName | titlecase}}</p>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
  <!-- ------------------ -->
  <div class="row table-section pe-0">
   <div class="col-md-12 grid-margin stretch-card">
    <div class="card detailed-section">
     <div class="card-body">
      <div class="card-title">
       Subscription Details
      </div>
      <div class="table-responsive">
       <table class="table table-striped table-borderless" *ngIf="subscriptionFound">
        <thead>
         <tr>
          <th scope="col" class="ps-3">#</th>
          <th scope="col">SubscriptionID</th>
          <th scope="col">Plan Name</th>
          <th scope="col">Start Date</th>
          <th scope="col">Next Billing Date</th>
          <th scope="col">Status</th>
          <th scope="col">Stage</th>
          <th scope="col" class="text-center">Action</th>
         </tr>
        </thead>
        <tbody *ngFor="let list of subsList; let indexOfElement = index">
         <tr>
          <td>
           <a [href]="'#'+'details'+indexOfElement" class="accordion-toggle collapsed collapse-btn " id="accordion1"
            data-mdb-toggle="collapse" data-mdb-parent="#accordion1"><span class="expand-button"></span></a>
          </td>
          <td>{{list?.code | uppercase}}</td>
          <td>{{list?.plan?.name | titlecase}}</td>
          <td>{{list?.startDate === null ? 'Not Activated' : list?.startDate | date }}
          </td>
          <td>{{list?.startDate === null ? 'Not Activated' :
           planEndDate(list?.startDate,list?.plan?.billingCycle,list?.plan?.billEvery)}}
          </td>
          <td><span class="" [ngClass]="list?.isActive ?'text-success' :'text-danger'">
            {{list?.isActive ? 'Active' : 'Inactive'}}
           </span></td>
          <td><span class="" [ngClass]="list?.isExpired ?'text-danger' :'text-success'">
            {{list?.isExpired ? 'Expired' : 'Live'}}
           </span></td>
          <td class="dropdown">
           <button class="comman-btn1 btn-primary dropdown-toggle" type="button" id="dropdownMenuButton"
            data-mdb-toggle="dropdown" aria-expanded="false">
            Action
           </button>
           <ul class="dropdown-menu menu-item-1" aria-labelledby="dropdownMenuButton">
            <li><a class="dropdown-item" (click)="statusChange(list?._id,list?.isActive)">{{list?.isActive ? 'Suspend' :
              'Activated'}}</a></li>
            <li><a class="dropdown-item" (click)="changeCustomerIdModal(list?.code)">Change Customer Id</a></li>
            <li><a class="dropdown-item" (click)="deleteSubscription(list?._id)">Delete Subscriptions</a></li>
           </ul>
           <!-- <i class="bi bi-pencil-fill me-3 text-info custom-select"(click)="updateSubscription(list?._id)"></i> -->

           <!-- <i class="bi bi-trash text-danger custom-select" ></i> -->
          </td>
         </tr>

         <tr class="hide-table-padding">
          <td colspan="3">
           <div [id]="'details'+indexOfElement" class="collapse">
            <div class="row">
             <p><b>CreatedOn: </b> <span>{{list?.createdAt | date}}</span></p>
             <p><b>ReferenceID: </b>
              <span>{{list?.refrenceId === '' ? 'None' :
               list?.refrenceId}}</span>
             </p>
             <p><b>Total Amount: </b>
              <span><i class="bi bi-currency-rupee"></i>{{list?.plan?.price +
               list?.addOn?.price|
               number:'1.0-2'}}</span>
             </p>
             <!-- <p><b>Subscription status: </b> 
                        <span [ngClass]="list?.isActive ?'text-success fw-bold' :'text-danger fw-bold'">
                        {{list?.isActive ? 'Active' : 'Inactive'}}</span>
                    </p> -->
            </div>
           </div>
          </td>
         </tr>
        </tbody>
       </table>
       <div class="text-center" *ngIf="!subscriptionFound">
        <p class="fw-bold">No subscriptions assigned yet</p>
        <p class="add-btn" (click)="createNew(customerId)">
         Add now<i class="bi bi-plus-circle ms-2 "></i>
        </p>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
  <!-- --------------- -->
 </div>
</div>