import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from 'src/app/services/profile.service';
import { RaiseRequestService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-verify-mobile',
  templateUrl: './verify-mobile.component.html',
  styleUrls: ['./verify-mobile.component.scss']
})
export class VerifyMobileComponent implements OnInit {

  @Input() payload: any
  mobileForm = new FormGroup({
    mobile: new FormControl('', Validators.required),
    name: new FormControl('', Validators.required)
  })
  event: EventEmitter<any> = new EventEmitter();
  constructor(
    private profileService: ProfileService,
    private raiseRequestService: RaiseRequestService,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit(): void {
    this.customerDetails()
  }

  customerDetails() {
    this.profileService.profileDetails().subscribe((res: any) => {
      if (res.success) {
        this.mobileForm.patchValue(res.customer)
      }
    });
  }

  submit() {
    if (!this.mobileForm.valid) {
      this.mobileForm.markAllAsTouched()
    } else {
      const ticketPayload = Object.assign(this.payload, this.mobileForm.value);
      this.raiseRequestService.createTicket(ticketPayload).subscribe((res: any) => {
        if (res.success) {
          this.event.emit('ok');
          this.closedModal()
          this.toastr.success(res.message)
        }
      }, (err) => {
        this.event.emit('ok');
        this.closedModal()
        this.toastr.warning(err.error.message)
      })
    }

  }

  closedModal() {
    this.bsModalRef.hide();
  }

}
