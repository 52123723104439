<!-- -----add---user--modal------ -->
<div>
    <div class="modal-header border-0 pt-0 pb-3">
        <h4 class="modal-title pull-left">Change {{isSalesman?'salesman':'relationship manager'}}:</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="ps-4 pe-4">
        <form [formGroup]="changeSalesmanForm">

            <div class="form-group" *ngIf="isSalesman">
                <input type="email" class="form-control" placeholder="" formControlName="subCode" readonly>
                <select class="form-select form-control mt-2" formControlName="salesmanId" required>
                    <option selected disabled value="">Select a salesperson</option>
                    <option value="self">Self</option>
                    <option *ngFor="let salesman of salesmanData" value="{{salesman._id}}">{{salesman.name}}
                    </option>
                </select>
                <div *ngIf="changeSalesmanForm.get('salesmanId')?.invalid" class="alert mb-0 p-0">
                    <div
                        *ngIf="changeSalesmanForm.get('salesmanId')?.errors?.['required'] &&  changeSalesmanForm.get('salesmanId')?.touched">
                        <p class="text-danger m-0 p-0"><small>it's mandatory field.</small>
                        </p>
                    </div>
                </div>
            </div>
            <div class="form-group" *ngIf="!isSalesman">
                <input type="email" class="form-control" placeholder="" formControlName="subCode" readonly>
                <select class="form-select form-control mt-2" formControlName="rmId" required>
                    <option selected disabled value="">Select a Relationship Manager</option>
                    <option value="self">Self</option>
                    <option *ngFor="let rm of rmData" value="{{rm._id}}">{{rm.name}}
                    </option>
                </select>
                <div *ngIf="changeSalesmanForm.get('rmId')?.invalid" class="alert mb-0 p-0">
                    <div
                        *ngIf="changeSalesmanForm.get('rmId')?.errors?.['required'] &&  changeSalesmanForm.get('rmId')?.touched">
                        <p class="text-danger m-0 p-0"><small>it's mandatory field.</small>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="ps-4 pe-4 text-end">
        <button type="button" class="comman-btn btn-primary me-2" (click)="submit()">Submit</button>
        <button type="button" class="comman-btn btn-secondary text-light" (click)="bsModalRef.hide()">Close</button>
    </div>
</div>
<!-- -----end---- -->