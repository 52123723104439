<div class="common-modal" *ngIf="subCode">
    <div class="modal-header border-0 py-0">
        <h4 class="modal-title pull-left border-0"><b>Renew({{subCode |uppercase}})</b></h4>
    </div>
    <div>
        <div class="modal-body text-center">
            <h4>Are you sure you want to renew this subscription?</h4>
        </div>
        <div class="text-end border-0">
            <button type="button" class="comman-btn1 btn-primary me-2" (click)="renewSubscription()">Yes</button>
            <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="closedModal()">No</button>
        </div>
    </div>
</div>


<div class="common-modal" *ngIf="!subCode && !paymentData">
    <div class="modal-header border-0 py-0">
        <p class="modal-title pull-left border-0 title">Payable Amount : {{currentAmount | currency : 'INR'}}</p>
        <i class="bi bi-x" (click)="bsModalRef.hide()"></i>
    </div>
    <div class="modal-header border-0 py-0">
        <p class="modal-title pull-left border-0 title">Manual Banking</p>
    </div>
    <div>
        <div class="modal-body border-0 py-0">
            <form [formGroup]="transForm">
                <div class="row">
                    <div class="col-sm-12">
                        <p>Payment Ref No / Transaction ID / UTR No</p>
                        <input type="text" class="form-control"
                            placeholder="Enter Payment Ref No / Transaction ID / UTR No" formControlName="trackingId">
                        <div *ngIf="transForm.get('trackingId')?.invalid" class="alert mb-0 p-0">
                            <div
                                *ngIf="transForm.get('trackingId')?.errors?.['required'] && (transForm.get('trackingId')?.dirty || transForm.get('trackingId')?.touched)">
                                <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 mb-2">
                        <p>Estimated Date & Time:</p>
                        <input type="datetime-local" class="form-control" placeholder="Estimated Date & Time"
                            formControlName="dateTime">
                    </div>
                    <div class="col-sm-12 mt-2">
                        <p>Paid Amount</p>
                        <input type="text" class="form-control" placeholder="Enter paid amount"
                            formControlName="paidAmount">
                        <div *ngIf="transForm.get('paidAmount')?.invalid" class="alert mb-0 p-0">
                            <div
                                *ngIf="transForm.get('paidAmount')?.errors?.['required'] && (transForm.get('paidAmount')?.dirty || transForm.get('paidAmount')?.touched)">
                                <p class="text-danger m-0 p-0"><small>It's mandatory field</small>
                                </p>
                            </div>
                            <div *ngIf="transForm.get('paidAmount')?.errors?.['pattern'] ">
                                <p class="text-danger m-0 p-0"><small>Enter valid amount</small>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 mt-2 mb-2" *ngIf="dueAmount>0">
                        <p>Remark</p>
                        <textarea rows="1" class="form-control " id="fullName"
                            placeholder="Reason for difference in amount..." style="overflow:hidden"
                            formControlName="remark"></textarea>
                        <div *ngIf="transForm.get('remark')?.invalid" class="alert mb-0 p-0">
                            <div class="error-message"
                                *ngIf="transForm.get('remark')?.errors?.['required'] && (transForm.get('remark')?.dirty || transForm.get('remark')?.touched)">
                                <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                                </p>
                            </div>
                        </div>
                        <div class="col-sm-12 mt-4 bg-warning p-2" *ngIf="dueAmount>0">
                            Due Amount: {{getPendingAmount | currency: 'INR'}}</div>
                        <p class="mt-2"><span class="notes">Note: </span><small><b>This process will take 6 working
                                    hours.</b></small></p>
                    </div>

                </div>
            </form>
        </div>

    </div>
    <div class="float-end border-0 p-3 custome-btn">
        <button type="button" class="me-3 no-btn" (click)="bsModalRef.hide()">Cancel</button>
        <button type="button" class="text-light yes-btn" (click)="submit()">Submit</button>
    </div>
</div>

<div *ngIf="paymentData">
    <div class="modal-body border-0">
        <div>
            <div class="mb-3 mt-1 text-center">
                <img src="../../../../assets/images/success.png" />
            </div>
            <p class="mb-3 thank-you text-center">Thank You!</p>
            <p class="msg mb-3">Thank you for your payment, we will reconcile it. Your order will be processed within 6
                business hours.</p>

            <div class="d-flex justify-content-between ms-3 me-3 mb-3 order mt-2">
                <div class="">OrderId:</div>
                <div>{{paymentData?.orderId}}</div>
            </div>
            <div class="d-flex justify-content-between ms-3 me-3 order">
                <div class="">Amount:</div>
                <div>{{paymentData?.paidAmount}}</div>
            </div>

            <a [href]="redirectUrl" class="btn w-100">Go To Website</a>
        </div>

    </div>


</div>
<!-- ---------------End-------------- -->