<!-- -----branding ----page ---start------>
<div class="container-scroller">
 <div class="main-panel">
  <div class="content-wrapper">
   <div class="row">
    <div class="col-md-12 grid-margin">
     <div class="row">
      <div class="col-12 mb-4">
       <h3 class="font-weight-bold">Plan</h3>
       <!-- -----table------------- -->
       <div class="row table-section mt-3 pe-0">
        <div class="col-md-12 grid-margin stretch-card pe-0">
         <div class="card">
          <div class="card-body">
           <button type="button" class="comman-btn1 btn-primary" [routerLink]="'/add-plan'">+Add
            Plan</button>
           <div class="table-responsive">
            <table class="table table-striped table-borderless">
             <thead>
              <tr>
               <th scope="col">Plan ID</th>
               <th scope="col">Name</th>
               <th scope="col">Price</th>
               <th scope="col">Plan Type</th>
               <th scope="col">Plan Details </th>
               <th scope="col">No. of Users</th>
               <th scope="col">Created By</th>
               <th scope="col">Action</th>
              </tr>
             </thead>

             <tbody>
              <tr *ngFor="let plan of plan">
               <td>{{plan.id}}</td>
               <td>{{plan.name}}</td>
               <td>&#8377; {{plan.price}}</td>
               <td>{{plan.planType}}</td>
               <td>{{plan.isMonthly? 'Monthly' : 'Yearly' }}</td>
               <td>{{plan.noOfUsers}}</td>
               <td>{{plan.createdBy}}</td>
               <td>
                <div class="dropdown">
                 <button class="comman-btn1 btn-primary dropdown-toggle" type="button" id="dropdownMenuButton1"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  Action
                 </button>
                 <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li><a class="dropdown-item" (click)="editPlan(plan._id)">Edit</a></li>
                  <li><a class="dropdown-item" (click)="openModal(plan._id)">Delete</a></li>
                 </ul>
                </div>
               </td>
              </tr>
             </tbody>
            </table>
           </div>
          </div>
         </div>
        </div>
       </div>
       <!-- -----------end---------- -->
      </div>
     </div>
    </div>
   </div>
  </div>
 </div>
</div>