import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { RaiseRequestService } from 'src/app/services/ticket.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-customer-ticket',
  templateUrl: './customer-ticket.component.html',
  styleUrls: ['./customer-ticket.component.scss']
})
export class CustomerTicketComponent implements OnInit {

  constructor(
    private raiseRequestService: RaiseRequestService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService,
    private walletService: WalletService,
    private authService: AuthService
  ) { }

  searchForm = new FormGroup({
    subId: new FormControl(''),
  });

  isTicketAvailable: boolean = false;
  tickets: any
  totalCount: number = 0
  page: number = 1
  ticketsType: any
  totalTickets: any
  ticketTitle: string = ''
  domainName: string = ''
  openTickets: any
  inProgressTickets: any
  resolvedTickets: any
  closedTickets: any
  averageRating: any
  config = {
    itemsPerPage: 15,
    currentPage: this.page,
    totalItems: this.totalCount
  };
  ngOnInit(): void {
    this.activeRoute.queryParams.subscribe((params: any) => {
      this.ticketTitle = params['ticketsType'] || '';
      const ticketsType = params['ticketsType'] || '';
      this.customerticketList(ticketsType)
      this.getDomain()
    });
  }



  customerticketList(ticketsType: any) {
    let payload = this.searchForm.value
    payload.subId = payload.subId?.trim()
    payload = Object.assign(payload, { ticketsType: ticketsType ? ticketsType : '' });
    this.raiseRequestService.customerticketList(JSON.stringify(payload), this.config.itemsPerPage, this.config.currentPage).subscribe((res: any) => {
      if (res.success) {
        this.openTickets = res.openTickets
        this.closedTickets = res?.closedTickets
        this.inProgressTickets = res?.inProgressTickets
        this.totalTickets = res?.totalNumOfTickets
        this.resolvedTickets = res?.resolvedTickets
        this.averageRating = res?.averageRating
        this.tickets = res.tickets
        this.config.currentPage = res?.totalTickets?.pages;
        this.config.totalItems = res?.totalTickets?.total;
        this.isTicketAvailable = (res.tickets.length > 0);
      }
    }, (error) => {
      this.config.currentPage = 0;
      this.config.totalItems = 0;
    })

  }

  clickableData(ticketsType: string) {
    if (ticketsType) {
      this.router.navigate(['/customer-ticket'], { queryParams: { ticketsType: ticketsType } });

    } else {
      this.router.navigate(['/customer-ticket']);
    }
    this.ticketsType = ticketsType
    this.searchForm.reset()
  }
  pageChanged(event: any) {
    this.config.currentPage = event
    this.customerticketList(this.ticketsType)
  }


  getDomain() {
    this.walletService.getDomainName().subscribe((res: any) => {
      if (res.success) {
        this.domainName = res?.domain;
      }
    });
  }
  get updatePaginationText(){
    return this.authService.getPaginationText(this.config.currentPage,this.config.itemsPerPage,this.config.totalItems);
  }
}
