
<!-- ----------Delete--Modal------- -->
<!-- <div class="modal-header border-0 py-0">
  <h4 class="modal-title pull-left border-0"><b>{{title}}</b></h4>
</div>
<div>
  <div class="modal-body text-center">
    <h4>{{message}}</h4>
  </div>
  <div class="text-end border-0" >  
    <button type="button" class="comman-btn1 btn-primary me-2" (click)="checkDelete()">Yes</button>
    <button type="button" class="comman-btn1 btn-secondary text-light me-4" (click)="bsModalRef.hide()">No</button>
  </div>
</div> -->
<!-- ---------------End-------------- -->

<div class="common-modal">
  <div class="modal-header border-0 py-0">
      <p class="modal-title pull-left border-0 title">{{title}}</p>
      <i class="bi bi-x"  (click)="bsModalRef.hide()"></i>
  </div>
  <div class="modal-body  pt-1 pb-1">
      <p>{{message}}</p>
      <p *ngIf="note != ''"><b>Note: </b>{{note}}</p>
  </div>
  
  
  <div class="text-center border-0 ps-4 custome-btn">
      <button type="button" class="me-3 no-btn" (click)="bsModalRef.hide()">NO</button>
      <button type="button" class="text-light me-4 yes-btn" (click)="checkDelete()">YES</button>
  </div>
  </div>