<!-- -----add---user--modal------ -->
<div>
    <div class="modal-header border-0 pt-0 pb-3">
        <h4 class="modal-title pull-left">Assign User:</h4>
        <!-- <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button> -->
    </div>
    <div class="ps-4 pe-4">
        <form [formGroup]="assignUserForm">

            <div class="form-group">
                <label class="lbl">Salesperson</label><br />
                <select class="form-select form-control" aria-label="Default select example"
                    formControlName="salesPersonId">
                    <option value="" disabled selected>Select Salesperson</option>
                    <option *ngFor="let salesperson of salesmans" [value]="salesperson._id">{{salesperson?.name}}</option>
                </select>
            </div>

            <div class="form-group">
                <label class="lbl">Relationship Manager</label><br />
                <select class="form-select form-control" aria-label="Default select example"
                    formControlName="relationshipManagerId">
                    <option value="" disabled selected>Select Relationship Manager</option>
                    <option *ngFor="let relationshipManager of relationshipManager" [value]="relationshipManager._id">{{relationshipManager?.name}}</option>
                </select>
            </div>
        </form>
    </div>
    <div class="ps-4 pe-4 text-end">
        <button type="button" class="comman-btn btn-primary me-2" (click)="submit()">Submit</button>
        <button type="button" class="comman-btn btn-secondary text-light" (click)="bsModalRef.hide()">Close</button>
    </div>
</div>
<!-- -----end---- -->