import { formatDate } from '@angular/common';
import { Component, EventEmitter, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { AddOnDetailRoot } from 'src/app/interfaces/subscription/addOn';
import { PlanDetailRoot } from 'src/app/interfaces/subscription/plan';
import { ProductListRoot } from 'src/app/interfaces/subscription/product';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { UserService } from 'src/app/services/user.service';
import { WalletService } from 'src/app/services/wallet.service';
import { SubscriptionPreviewComponent } from '../../modals/subscription-preview/subscription-preview.component';

@Component({
  selector: 'app-new-subscriptions',
  templateUrl: './new-subscriptions.component.html',
  styleUrls: ['./new-subscriptions.component.scss']
})
export class NewSubscriptionsComponent implements OnInit {

  constructor(
    private router: Router,
    private user: UserService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private activeRoute: ActivatedRoute,
    private subscriptionService: SubscriptionsService,
    private walletService: WalletService,
    @Inject(LOCALE_ID) public locale: string,
  ) { }

  ngOnInit(): void {
    this.getCustomerId()
    this.displayDetails()
    this.showProductList()
  }

  event: EventEmitter<any> = new EventEmitter();
  customerList!: any[];
  productList!: any[];
  search: any = {};

  planList!: any[];
  addOnList!: any[];
  addOn: any;
  plan: any;
  bsModalRef!: BsModalRef;
  productName!: any;
  productId!: any;
  planId!: any;
  addOnId!: any;
  planRefundAmount!: number;
  addOnRefundAmount!: number;

  showContent: boolean = false;
  cId: any;
  planName!: string;
  addOnName!: string;
  addOnToggle: boolean = true;
  addOnPriceEvery!: any;
  addOnPriceInterval!: any;
  unitAddON!: any;
  showAddOn: boolean = false;

  planToggle: boolean = true;
  planBillCycle!: string;
  planBillEvery!: number;

  subscriptionForm = new FormGroup({
    selectedName: new FormControl(''),
    customerName: new FormControl(''),
    productName: new FormControl(''),
    planName: new FormControl(''),
    planDescription: new FormControl(''),
    planQty: new FormControl(''),
    planUnitPrice: new FormControl(''),
    planAmount: new FormControl(''),
    addOnName: new FormControl(''),
    addOnDescription: new FormControl(''),
    addOnQty: new FormControl(''),
    addOnUnitPrice: new FormControl(''),
    addOnAmount: new FormControl(),
    referenceId: new FormControl(''),
    startOn: new FormControl(''),
    expireAfter: new FormControl(''),
    salesPersonName: new FormControl(''),
    noOfUsers: new FormControl(''),
  })

  getCustomerId() {
    this.cId = this.activeRoute.snapshot.queryParamMap.get('customerId')
  }

  selectedName() {
    this.cId = (document.getElementById("selected-name-field") as HTMLInputElement).value
  }

  showPlanList(productId: string) {
    this.subscriptionService.showPlanList(productId).subscribe((res: PlanDetailRoot) => {
      if (res.success) {
        this.planList = res.plans;
      }
    })
  }

  showAddOnList(productId: string) {
    this.subscriptionService.showAddOnList(productId).subscribe((res: AddOnDetailRoot) => {
      if (res.success) {
        this.addOnList = res.addOns;
      }
    })
  }

  showProductList() {
    this.subscriptionService.showProductList().subscribe((res: ProductListRoot) => {
      if (res.success) {
        this.productList = res.products;
      }
    })
  }

  selectedProduct() {
    this.productId = (document.getElementById("selected-field") as HTMLInputElement).value
    this.resetPlanAndAddOn()
    this.showPlanList(this.productId);
    this.showAddOnList(this.productId);
  }

  planAmount() {
    let unitPrice: any;
    let quantity: any;
    let amount: any;
    unitPrice = this.subscriptionForm.controls.planUnitPrice.value;
    quantity = this.subscriptionForm.controls.planQty.value;
    amount = unitPrice * quantity;
    this.subscriptionForm.controls.planAmount.patchValue(amount.toFixed(2));
  }

  selectedPlan() {
    let defaultQuantity: any = 1;
    let unitPrice: any;
    let amount: any;
    this.showAddOn = true;
    this.planToggle = false;
    this.planId = (document.getElementById("selected-plan-field") as HTMLInputElement).value

    this.subscriptionService.selectedPlan(this.planId).subscribe((res: any) => {
      if (res.success) {
        this.plan = res.plan;
        if (this.addOnId !== undefined) {
          const addOnPrice = this.calculateAddOnPrice().toString()
          const unitPrice = Number(addOnPrice)
          this.subscriptionForm.controls.addOnUnitPrice.patchValue(unitPrice.toFixed(2))
          const amount = Number(addOnPrice) * Number(this.subscriptionForm.controls.addOnQty.value);
          this.subscriptionForm.controls.addOnAmount.patchValue(amount.toFixed(2))
        }
        this.planName = res?.plan?.name;
        this.planBillCycle = res?.plan?.billingCycle;
        this.planBillEvery = res?.plan?.billEvery;
        this.subscriptionForm.controls.planDescription.patchValue(res?.plan?.description)
        this.subscriptionForm.controls.planQty.patchValue(defaultQuantity)
        this.subscriptionForm.controls.planUnitPrice.patchValue((res?.plan?.price).toFixed(2))
        unitPrice = res?.plan?.price;
        amount = unitPrice * defaultQuantity;
        this.subscriptionForm.controls.planAmount.patchValue(amount.toFixed(2))
      }
    })
  }

  addOnAmount() {
    let unitPrice: any;
    let quantity: any;
    let amount: any;
    unitPrice = this.subscriptionForm.controls.addOnUnitPrice.value;
    quantity = this.subscriptionForm.controls.addOnQty.value;
    amount = unitPrice * quantity;
    this.subscriptionForm.controls.addOnAmount.patchValue(amount.toFixed(2))
  }

  selectedAddOn() {

    let defaultQuantity: any = 1;
    let unitPrice: any;
    let amount: any;

    this.addOnToggle = false;

    this.addOnId = (document.getElementById("selected-addOn-field") as HTMLInputElement).value

    this.subscriptionService.selectedAddOn(this.addOnId).subscribe((res: any) => {
      if (res.success) {
        this.addOn = res.addOn
        const addOnPrice = this.calculateAddOnPrice().toString()
        this.addOnName = res.addOn.name;
        this.addOnPriceEvery = res?.addOn?.pricingEvery;
        this.addOnPriceInterval = res?.addOn?.pricingInterval;
        this.unitAddON = res.addOn.unit;
        this.subscriptionForm.controls.addOnDescription.patchValue(res?.addOn?.description)
        this.subscriptionForm.controls.addOnQty.patchValue(defaultQuantity)
        unitPrice = Number(addOnPrice)
        this.subscriptionForm.controls.addOnUnitPrice.patchValue(unitPrice.toFixed(2))
        amount = Number(addOnPrice) * defaultQuantity;
        this.subscriptionForm.controls.addOnAmount.patchValue(amount.toFixed(2))
      }
    })
  }

  calculateAddOnPrice() {
    const planDays = (this.plan.billingCycle === "week") ? 7 : (this.plan.billingCycle === "month") ? 30 : 365
    const planTotalDays = planDays * this.plan.billEvery
    const addOnDays = (this.addOn.pricingInterval === "week") ? 7 : (this.addOn.pricingInterval === "month") ? 30 : 365
    const addOnTotalDays = addOnDays * this.addOn.pricingEvery
    const addOnOneDayPrice = this.addOn.price / addOnTotalDays
    const addOnPrice = addOnOneDayPrice * planTotalDays
    return addOnPrice
  }

  checkDataValidity() {
    if (this.subscriptionForm.controls.productName.value === '') {
      this.toastr.warning("Select a product first")
    }
    else if ((this.subscriptionForm.controls.planName.value === ''))
    // && (this.subscriptionForm.controls.addOnName.value === '')
    {
      this.toastr.warning('Select a plan first')
    }
    // if (this.subscriptionForm.controls.salesPersonName.value === '') {
    //   this.subscriptionForm.controls.salesPersonName.markAsTouched()
    // }   
    else {
      if (this.subscriptionForm.controls.planQty.valid && this.subscriptionForm.controls.planQty.value) {
        if (this.subscriptionForm.controls.addOnQty.valid && this.subscriptionForm.controls.addOnQty.value) {
          this.createSubscription();
        }
        else if (this.subscriptionForm.controls.addOnName.value === '') {
          this.createSubscription();
        }
      }
    }
  }

  resetPlanAndAddOn() {
    this.subscriptionForm.controls.addOnDescription.reset()
    this.subscriptionForm.controls.addOnQty.reset()
    this.subscriptionForm.controls.addOnUnitPrice.reset()
    this.subscriptionForm.controls.addOnAmount.reset()
    this.subscriptionForm.controls.planDescription.reset()
    this.subscriptionForm.controls.planQty.reset()
    this.subscriptionForm.controls.planUnitPrice.reset()
    this.subscriptionForm.controls.planAmount.reset()
  }

  createSubscription() {
    const payload = {
      productId: this.productId,
      customerId: this.cId,
      startDate: this.subscriptionForm.controls.startOn.value,
      refrenceId: this.subscriptionForm.controls.referenceId.value,
      plan: {
        name: this.planName,
        description: this.subscriptionForm.controls.planDescription.value,
        billingCycle: this.planBillCycle,
        price: this.subscriptionForm.controls.planAmount.value,
        unitPrice: this.subscriptionForm.controls.planUnitPrice.value,
        noOfUsers: this.subscriptionForm.controls.planQty.value,
        billEvery: this.planBillEvery,
        code: this.plan.code
      },
      addOn: {
        name: this.addOnName,
        description: this.subscriptionForm.controls.addOnDescription.value,
        quantity: this.subscriptionForm.controls.addOnQty.value,
        price: this.subscriptionForm.controls.addOnAmount.value,
        unitPrice: this.subscriptionForm.controls.addOnUnitPrice.value,
        unit: this.unitAddON,
        pricingEvery: this.addOnPriceEvery,
        pricingInterval: this.addOnPriceInterval
      }
    }

    this.subscriptionService.addSubscription(payload).subscribe((res: any) => {
      if (res.success) {
        this.walletService.showWalletAmount()
        this.router.navigate(['subscriptions-list'],
          { queryParams: { customerId: this.cId } }
        );
        this.toastr.success("Subscription assigned to customer")
      }
    },
      (error) => {
        this.toastr.error(error.error.message)
      }
    )
  }

  displayDetails() {
    this.user.userDetails(this.cId).subscribe((res: any) => {
      if (res.success) {
        this.subscriptionForm.controls.customerName.patchValue(res?.customer?.name)
      }
    })
  }

  openSubscriptionModal() {
    this.bsModalRef = this.modalService.show(SubscriptionPreviewComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
      }
      else {
      }
    }
    )
  }

  goBack() {
    this.router.navigate(['account/subscriptions'],
      { queryParams: { customerId: this.cId } }
    );
  }


}
