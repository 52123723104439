<!-- -----add---user--modal------ -->
<div>
    <div class="modal-header border-0 pt-0 pb-3">
        <h4 class="modal-title pull-left">Assign Subscription: </h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="ps-4 pe-4">
        <form [formGroup]="assignSubsToSalespersonForm">

            <div class="form-group">
                <label class="lbl">Sub ID:</label><br/>
                <input type="text" class="form-control" placeholder="" formControlName="subCode"  onkeypress="return event.charCode !== 47 && event.charCode !== 92 ">
               
                <div *ngIf="assignSubsToSalespersonForm.get('subCode')?.invalid" class="alert mb-0 p-0">
                    <div
                        *ngIf="assignSubsToSalespersonForm.get('subCode')?.errors?.['required'] &&  assignSubsToSalespersonForm.get('subCode')?.touched">
                        <p class="text-danger m-0 p-0"><small>it's mandatory field.</small>
                        </p>
                    </div>
                </div>
            </div>

            <div class="form-group">
                <label class="lbl">Subscription User Email:</label><br/>
                <input type="email" class="form-control" placeholder="" formControlName="customerEmail">
               
                <div *ngIf="assignSubsToSalespersonForm.get('customerEmail')?.invalid" class="alert mb-0 p-0">
                    <div
                        *ngIf="assignSubsToSalespersonForm.get('customerEmail')?.errors?.['required'] &&  assignSubsToSalespersonForm.get('customerEmail')?.touched">
                        <p class="text-danger m-0 p-0"><small>it's mandatory field.</small>
                        </p>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="ps-4 pe-4 text-end">
        <button type="button" class="comman-btn btn-primary me-2" (click)="submit()">Submit</button>
        <button type="button" class="comman-btn btn-secondary text-light" (click)="bsModalRef.hide()">Close</button>
    </div>
</div>
<!-- -----end---- -->
