import { Component, OnInit } from '@angular/core';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
@Component({
  selector: 'app-assign-subs-to-salesperson',
  templateUrl: './assign-subs-to-salesperson.component.html',
  styleUrls: ['./assign-subs-to-salesperson.component.scss']
})
export class AssignSubsToSalespersonComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private subscriptionsService: SubscriptionsService
  ) { }

  assignSubsToSalespersonForm = new FormGroup({
    customerEmail: new FormControl('', [Validators.required]),
    subCode : new FormControl('',[Validators.required]),
  })
  ngOnInit(): void {
  }
  submit() {
    if (!this.assignSubsToSalespersonForm.valid) {
      this.assignSubsToSalespersonForm.markAllAsTouched()
    }
    else {
      const payload = this.assignSubsToSalespersonForm.value;
      this.subscriptionsService.assignSubscription(payload).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success(res.message);
          this.closedModal();
        } else {
          this.toastr.warning(res.message);
        }
      },
        (err) => {
          this.toastr.error(err.error.message);
        }
      )
    }
  }
  closedModal() {
    this.bsModalRef.hide();
  }
}
