// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiURL: "https://dev.partner.cocloud.in/api",
  // apiURL : "http://192.168.0.125:2110/api",
  url: "http://dev.cocloud.in",
  brandingIP: "103.171.134.33",
  intercomAppId: 'f3o05enl',
  accessCode: "AVVE97KH23AQ61EVQA",
  redirectUrl: "https://dev.partner.cocloud.in",
  workingKey: "kTUlP9Z0cvNNY9bZ",
  paymentRedirectUrl: "https://test.ccavenue.com/transaction/transaction.do?command=initiateTransaction"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
