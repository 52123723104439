import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SalesmanService } from 'src/app/services/salesman.service';
import { ChangePlanComponent } from '../modals/change-plan/change-plan.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';

@Component({
  selector: 'app-sales-man',
  templateUrl: './sales-man.component.html',
  styleUrls: ['./sales-man.component.scss']
})
export class SalesManComponent implements OnInit {

  salesmanData: any = []
  relationshipManagerData: any = []
  bsModalRef!: BsModalRef;
  isActive: boolean = false

  constructor(
    private salesmanService: SalesmanService,
    private router: Router,
    private toasts: ToastrService,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.salesmanList()
  }

  salesmanList() {
    this.salesmanService.salesmanList().subscribe((res: any) => {
      if (res.success) {
        this.salesmanData = res.salesPerson;
        this.relationshipManagerData = res.relationShipManager;
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })
  }

  editSalesman(isActive: string, salesmanId: string) {
    if (isActive) {
      this.router.navigate(['add-salesman'], { queryParams: { salesmanId: salesmanId } })
    } else {
      this.toasts.warning('Salesperson is Inactive')
    }

  }

  deleteSalesman(salesmanId: string) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-sm',
      backdrop: 'static',
    });

    this.bsModalRef.content.salesmanId = salesmanId;
    this.bsModalRef.content.title = 'Delete'
    this.bsModalRef.content.message = 'Are you sure you want to delete this?'
    this.bsModalRef.content.confirmText = 'Yes'
    this.bsModalRef.content.cancelText = 'Cancel'
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.salesmanList();
      } else {
        this.salesmanList();
      }
    });
  }

  toggleStatus(value: boolean, salesmanId: string) {
    const salesmanPayload = {
      salesmanId: salesmanId,
      isActive: !value
    }
    this.salesmanService.updateStatus(salesmanPayload).subscribe((res: any) => {
      if (res.success) {
        this.toasts.success(res.message)
        this.salesmanList()
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })

  }

}
