<div class="container-fluid ps-0 pe-0">
    <div class="main-panel">
        <div class="row">
            <div class="col-md-12 ">
                <div class="card">
                    <div class="card-body class-padding">
                        <h6 class="heading text-center ">Comhard Technologies Private Limited</h6>
                        <h6 class=" text-center fs-6">Comprehensive Terms & Conditions for Partners/Resellers </h6>

                        <div class="row mt-4">

                            <div class="ps-5">
                                <ul>
                                    <li>
                                        <p class="sub-heading mb-3">
                                            1. Agreement Overview
                                        </p>
                                        <p class="text mb-3">TThis legally binding agreement ("Agreement") is
                                            established between Comhard Technologies Pvt Ltd ("Comhard") and
                                            [Partner/Reseller Name] ("Partner" or "Reseller"). The Agreement governs the
                                            subscription, use, and resale of Comhard's Cloud Services through the
                                            Cocloud Portal.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>2. Definitions </b> </p>
                                        <p class="text fs-6 mb-3"><b class="text-black">a. Authorized Representative:
                                            </b>
                                            A designated individual within the Partner's organization, such as the CEO
                                            or Finance Director.
                                        </p>
                                        <p class="text fs-6 mb-3"><b class="text-black">b. Cloud Service(s): </b>
                                            Online resources provided by Comhard through the Cocloud Portal.
                                        </p>
                                        <p class="text fs-6 mb-3"><b class="text-black">c. Cloud Services Provider
                                                (CSP): </b>
                                            Comhard Technologies Pvt Ltd, the provider of Cloud Services.
                                        </p>
                                        <p class="text fs-6 mb-3"><b class="text-black">d. Cloud Services Reseller
                                                (CSR)/Partner: </b>
                                            The entity authorized to resell Comhard's Cloud Services.
                                        </p>
                                        <p class="text fs-6 mb-3"><b class="text-black">e. End User: </b>
                                            An individual who uses a Cloud Service provided by the Partner.
                                        </p>
                                        <p class="text fs-6 mb-3"><b class="text-black">f.
                                                Confidential Information:</b>
                                            Any non-public information exchanged between the parties.
                                        </p>
                                        <p class="text fs-6 mb-3"><b class="text-black">g. Contract: </b>
                                            Agreements between the Partner and Comhard, including Purchase Orders.
                                        </p>
                                        <p class="text fs-6 mb-3"><b class="text-black">h. Cocloud Portal: </b>
                                            The online platform through which Cloud Services are accessed and managed.
                                        </p>
                                        <p class="text fs-6 mb-3"><b class="text-black">i. Support Services: </b>
                                            Additional services provided by Comhard to assist the Partner in using Cloud
                                            Services.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>3. Acceptance of the Agreement </b></p>
                                        <p class="text mb-3">By subscribing to or reselling Comhard's Cloud Services,
                                            the Partner agrees to the terms outlined in this Agreement. Acceptance can
                                            be indicated by selecting a checkbox, clicking a button, or actively using
                                            the Service.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>4. Description of Service </b></p>
                                        <p class="text mb-3">Comhard provides Cloud Services through the Cocloud Portal,
                                            which includes access to accounting software and other cloud-hosted
                                            applications. The Partner may offer these services to their customers, who
                                            are considered End Users.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>5. CSR Portal Features </b> </p>
                                        <p class="text mb-3"><b class="text-black">a. Create and Manage Customers: </b>Comhard provides
                                            Cloud Services through the Cocloud Portal, which includes access to
                                            accounting software and other cloud-hosted applications. The Partner may
                                            offer these services to their customers, who are considered End Users.
                                        </p>
                                        <p class="text mb-3"><b class="text-black">b. Create and Manage User Subscriptions: </b> CSRs can
                                            set up and manage user subscriptions, ensuring appropriate access and
                                            permissions.
                                        </p>
                                        <p class="text mb-3"><b class="text-black">c. Self-Branding: </b> CSRs may brand the platform with
                                            their logo and branding, providing a customized experience. (This is a
                                            chargeable service.)
                                        </p>
                                        <p class="text mb-3"><b class="text-black">d. Portal Logs: </b> CSRs have access to portal logs to
                                            monitor activities and troubleshoot issues.
                                        </p>
                                        <p class="text mb-3"><b class="text-black">e. Usage Report: </b> A wallet summary in the billing
                                            section allows CSRs to track historical wallet transactions.
                                        </p>
                                        <p class="text mb-3"><b class="text-black">f. Feature Updates: </b> CSRs will receive new features
                                            and updates to enhance their service capabilities.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b class="text-black">6. Beta Service </b> </p>
                                        <p class="text mb-3">Comhard may offer certain components of the Service as
                                            closed or open beta services, hereinafter referred to as "Beta Service" or
                                            "Beta Services," for testing and evaluation purposes. Comhard retains sole
                                            authority and discretion to determine the testing period and the decision to
                                            offer Beta Services as commercial services. Subscribing to any Beta Service
                                            does not obligate You to acquire a subscription for any paid Service.
                                            Comhard reserves the right to fully or partially discontinue any Beta
                                            Service, temporarily or permanently, with or without notice. Comhard shall
                                            not be liable for any harm resulting from the modification, suspension, or
                                            discontinuance of Beta Services.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b class="text-black">7. Limitations and Exclusions </b> </p>
                                        <p class="text mb-3">Comhard Technologies Pvt Ltd is responsible only for
                                            cloud-level support and is not liable for resolving end-user issues related
                                            to local systems or environments, including but not limited to internet
                                            connectivity, local printers, or local system resources. Any issue arising
                                            due to external factors, such as third-party software or hardware
                                            malfunctions, will not be the liability of Comhard. <br />
                                            End users can reach Comhard for cloud-related support via the CoCloud portal
                                            chat, while partners may submit service requests directly through the
                                            partner portal for cloud-level issues. End-user issues outside the cloud
                                            environment, such as those related to local systems or connectivity, remain
                                            the responsibility of the partner or the end-user's own support channels.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>Exclusions: </b> </p>
                                        <p class="text mb-3"><b>a. </b>Application issues (such as Tally, Busy, Excel)
                                        </p>
                                        <p class="text mb-3"><b>b. </b>Data mismatches
                                        </p>
                                        <p class="text mb-3"><b>c. </b>License issues
                                        </p>
                                        <p class="text">These exclusions are not directly related to the cloud services
                                            provided by Comhard and are therefore outside the scope of Comhard’s
                                            responsibility. </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>8. Partner/Reseller Responsibilities </b> </p>
                                        <p class="text mb-3"><b class="text-black">a. User Sign-In Obligations: </b>The
                                            Partner must ensure that all user information is accurate and up to date.
                                        </p>
                                        <p class="text mb-3"><b class="text-black">b. Internet Connectivity & Device
                                                Configuration: </b>The Partner and their customers are responsible for
                                            maintaining stable internet connectivity and necessary local resources to
                                            access the Cloud Services. </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>9. Data Backup and Protection </b> </p>
                                        <p class="text mb-3"><b class="text-black">a.Google Drive Backup: </b>The Google
                                            Drive backup feature is mandatory for all users of the Cocloud portal.
                                            Comhard is not responsible for any data loss if this backup feature is not
                                            properly implemented by the Partner or their customers, even in the event of
                                            cloud-level issues. </p>
                                        <p class="text mb-3"><b class="text-black">b. Data Retention: </b>Backup data is
                                            retained according to the retention policies set by the Partner or their
                                            customers. </p>
                                        <p class="text mb-3"><b class="text-black">c. Security </b>Industry-standard
                                            security measures are implemented to protect data during transmission and
                                            storage. Comhard uses NGFW for security. However, Comhard cannot guarantee
                                            absolute security. </p>
                                        <p class="text mb-3">Both parties must comply with applicable data protection
                                            laws, ensuring personal data is protected with appropriate security measures
                                            and necessary consents. </p>
                                        <p class="text mb-3">Comhard restricts CSRs from accessing customer data within
                                            any accounting software to protect privacy. </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>10. CoCloud Credits and Refunds </b> </p>
                                        <p class="text mb-3"><b class="text-black">a. User Inventory Credits: </b>CSRs
                                            earn credits added to the partner wallet upon purchasing user inventory.
                                            These credits can be used to create users in the CoCloud partner portal.
                                        </p>
                                        <p class="text mb-3"><b class="text-black">b. Refunds: </b>Once products or
                                            services have been ordered, they cannot be canceled. Products or services
                                            that have been sold will not be accepted back by Comhard Technologies Pvt
                                            Ltd. </p>
                                        <p class="text mb-3"><b class="text-black">c. Non-Monetizable Wallet Credits:
                                            </b>Wallet credits cannot be monetized back after purchase. Wallet credits
                                            cannot be adjusted with any other services provided by Comhard. </p>
                                        <p class="text mb-3"><b class="text-black">d. Trial Subscription Charges: </b>If
                                            a trial subscription is made live, and the service is canceled before
                                            renewal, the charges for the trial period will be deducted, as extra trial
                                            days are provided at the end of the subscription period.</p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>11. Restrictions on Use </b></p>
                                        <p class="text mb-3">The Partner shall not: </p>

                                        <p class="text mb-3"><b class="text-black">a. </b>Transfer the Service to any
                                            third party without prior written consent. </p>
                                        <p class="text mb-3"><b class="text-black">b. </b>Offer services based on the
                                            Service without prior written permission from Comhard. </p>
                                        <p class="text mb-3"><b class="text-black">c. </b>Attempt to disassemble,
                                            reverse engineer, or decompile the Service. </p>
                                        <p class="text mb-3"><b class="text-black">d. </b>Use the Service in any manner
                                            that disrupts Comhard’s systems or networks. </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>12. Spamming and Illegal Activities </b> </p>
                                        <p class="text mb-3">You agree to be solely responsible for the content
                                            transmitted through the Service and to refrain from using it for illegal,
                                            harmful, or malicious purposes. This includes not using the Service for the
                                            transmission of unsolicited email or spam. Comhard reserves the right to
                                            terminate access to the Service for any illegal or unauthorized activities.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>13. Fees, Payments & Renewals</b> </p>
                                        <p class="text mb-3"><b class="text-black">a. Payment Terms: </b>Payments for
                                            subscriptions must be made in advance through the Payment Gateway. Comhard
                                            reserves the right to change prices for both new sales and renewals, as well
                                            as charge for services or features that were previously provided for free.
                                            These price changes may apply to future subscriptions based on market
                                            conditions and incurred costs. Comhard also reserves the right to change the
                                            pricing of past subscriptions, including modifying charges for previous
                                            subscription periods, as deemed necessary. </p>
                                        <p class="text mb-3"><b class="text-black">b. Wallet Balance Refunds:
                                            </b>Refunds are not applicable.</p>
                                        <p class="text mb-3"><b class="text-black">c. Renewals</b>If the wallet balance
                                            is insufficient during the renewal of any end user’s subscription before the
                                            expiry date, services will be automatically paused. The Partner can make an
                                            online payment, add wallet balance, and renew their end user’s services
                                            within a specified period. After this period, the customer’s account and
                                            data will be automatically deleted, and data retrieval will require
                                            necessary payment. After expiry, data cannot be retrieved by Comhard, if
                                            partner has not renewed subscription from their partner portal. </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>14. Termination </b> </p>
                                        <p class="text mb-3"><b class="text-black">a. Breach of Agreement: </b>Either
                                            party can terminate the Agreement if the other violates its terms. </p>
                                        <p class="text mb-3"><b class="text-black">b. Failure to Meet Business Plan:
                                            </b>Comhard may terminate the Agreement if the CSR fails to meet the agreed
                                            business plan. </p>
                                        <p class="text mb-3"><b class="text-black">c. Non-Payment: </b>If CSR fails to
                                            make overdue payments, Comhard may terminate the Agreement and suspend
                                            services. This may impact partner and their customers usage of cloud
                                            platform. </p>
                                        <p class="text mb-3"><b class="text-black">d. Legal and Regulatory Compliance:
                                            </b> Comhard reserves the right to terminate the Agreement if the CSR
                                            engages in activities contrary to Indian laws or in response to customer
                                            complaints alleging fraud related to Cloud services. </p>
                                        <p class="text mb-3"><b class="text-black">e. Mutual Agreement: </b> Both
                                            parties can agree to terminate the Agreement at any time. </p>
                                        <p class="text mb-3"><b class="text-black">f. Change of Control: </b> Comhard
                                            can terminate the Agreement if there’s a change in control of the CSR. </p>
                                        <p class="text mb-3"><b class="text-black">g. Insolvency: </b> If the CSR
                                            becomes bankrupt, insolvent, or has a receiver appointed, Comhard can
                                            terminate the Agreement. In such cases, upon direct request from customers,
                                            Comhard can deal directly and provide their data by charging for services.
                                        </p>
                                        <p class="text mb-3"><b class="text-black">h. Violations of Data Protection:
                                            </b> If the CSR does not adhere to data protection rules, Comhard can
                                            suspend access. </p>
                                        <p class="text mb-3"><b class="text-black">i. Legal Requirements: </b> Comhard
                                            may suspend access to comply with legal requirements or court orders. </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>15. Appointment and non-solicitation </b> </p>
                                        <p class="text mb-3"><b class="text-black">a. Non-Exclusive Appointment:
                                            </b>Comhard Technologies appoints the CSR in a non-exclusive capacity,
                                            allowing them to sell Comhard Technologies' products or services within a
                                            specified territory or market. </p>
                                        <p class="text mb-3"><b class="text-black">b. Title to Trademarks: </b>The CSR
                                            is not granted ownership or rights to use Comhard Technologies' trademarks,
                                            logos, or branding. Use of these marks requires a separate agreement or
                                            adherence to Comhard Technologies' Trademark Guidelines. </p>
                                        <p class="text mb-3"><b class="text-black">c. Non-Solicitation of Customers:
                                            </b>Comhard Technologies agrees not to solicit CSR's customers to switch
                                            their business away from CSR, even after the Agreement ends. </p>
                                        <p class="text mb-3"><b class="text-black">d. Non-Solicitation of CSP Customers:
                                            </b> The CSR agrees not to solicit Comhard's existing Cloud customers or
                                            other CSR's customers to switch to their reseller portal, ensuring healthy
                                            market competition. </p>
                                        <p class="text mb-3"><b class="text-black">e. Non-Solicitation of Employees:</b>
                                            Both Comhard Technologies and the CSR agree not to hire or entice each
                                            other's employees involved in marketing, promotion, sale, or distribution to
                                            leave their roles during the Agreement and for a specified period after
                                            termination. General recruitment methods like advertising job openings are
                                            not considered a breach. </p>

                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>16. Customer Transfer and Data Usage After
                                                Contract Termination </b> </p>
                                        <p class="text mb-3">Customer Transfer: Customers introduced by CSR are
                                            considered CSR’s property. Upon contract termination, CSR can transfer these
                                            customers to another platform. Comhard will not use customer data for direct
                                            engagement post-termination.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>17. Data Ownership</b> </p>
                                        <p class="text mb-3"><b class="text-black">a. Confidential Information: </b>Both
                                            parties agree to protect confidential information received from each other,
                                            treating it with the same level of care as their own. </p>
                                        <p class="text mb-3"><b class="text-black">b. Data Processing Indemnification:
                                            </b>CSR will indemnify Comhard against any claims arising from CSR’s data
                                            processing activities, including breaches of data protection laws. </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>18. CSR Access and Applications Permissions </b>
                                        </p>
                                        <p class="text mb-3"><b class="text-black">a. CSR Restrictions: </b>CSRs are
                                            restricted from accessing the user’s application in cloud to ensure customer
                                            data privacy. CSRs may log in to the customer portal for administrative
                                            functions but cannot access the customer’s application in portal. </p>
                                        <p class="text mb-3"><b class="text-black">b. TDL File Management: </b>Partners
                                            managing specific TDL files on their customer portal may apply for
                                            additional Tally access rights through a separate application. Any liability
                                            arising from such access will be the CSR's responsibility. </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>19. Hosting Location </b> </p>
                                        <p class="text mb-3">The cloud facilities are located at Ctrl-s Data Centre in
                                            Noida, Uttar Pradesh, India, a Tier 4 location. Comhard may relocate the
                                            hosting location with prior notice, ensuring the same level of data centre
                                            quality.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>20. Personal Information and Privacy </b> </p>
                                        <p class="text mb-3">Personal information provided to Comhard is governed by the
                                            Comhard Privacy Policy. The Partner is responsible for safeguarding their
                                            account's confidentiality and reporting any unauthorized use.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>21. Communications from Comhard </b> </p>
                                        <p class="text mb-3">The Service may include communications from Comhard, such
                                            as service announcements and newsletters. You may opt-out of newsletters but
                                            not service announcements. Comhard also shares communication on Partner
                                            Cocloud portal, from time to time.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>22. Dispute Resolution and Feedback </b> </p>
                                        <p class="text mb-3">The Partner agrees to resolve any disputes or provide
                                            feedback by contacting Comhard at <a
                                                href="mailto:contact@comhard.co.in. ">contact&#64;comhard.co.in</a>.Disputes
                                            will be adjudicated exclusively by the courts of Noida, Uttar Pradesh,
                                            India, in accordance with Indian laws.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>23. Suspension and Termination </b> </p>
                                        <p class="text mb-3"><b class="text-black">a. Suspension: </b> Comhard may
                                            suspend the Partner's account for suspected illegal activity or extended
                                            periods of inactivity. The Partner may object to the suspension within
                                            thirty days of notification. </p>
                                        <p class="text mb-3"><b class="text-black">b. Termination: </b>Either party may
                                            terminate this Agreement for breach of terms or failure to meet the agreed
                                            business plan. </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>24. Customer Support & Service Level Agreement
                                                (SLA) </b> </p>

                                        <p class="text mb-3"><b class="text-black">a. Support Services: </b> Comhard
                                            will provide support services as outlined in the SLA, including response
                                            times, issue resolution processes, and available support channels. </p>
                                        <p class="text mb-3"><b class="text-black">b. Service Availability: :
                                            </b>Comhard aims to provide a high level of service availability, but does
                                            not guarantee uninterrupted service due to maintenance, upgrades, or
                                            unforeseen circumstances. </p>

                                    <li>
                                        <p class="sub-heading mb-3"><b>25. Modification of Terms of Service </b> </p>
                                        <p class="text mb-3">Comhard may modify this Agreement at any time by posting
                                            the updated Terms of Service online. It is your responsibility to review the
                                            Terms of Service periodically to stay informed of any changes. Although
                                            Comhard will notify you of significant changes either through a service
                                            announcement on the portal or by sending an email to your primary email
                                            address, it is still advised that you regularly check for updates. <br />
                                            If Comhard makes changes that significantly affect your rights, you will be
                                            provided with at least 3 days' advance notice via email to your primary
                                            email address. You may raise any concerns regarding these changes by
                                            providing Comhard notice through email within 3 days of being informed of
                                            the modified Agreement. In such a case, you may choose not to renew your
                                            subscription for the next billing cycle. Your continued use of the Cloud
                                            Subscription Service after the effective date of any changes will constitute
                                            your acceptance of the modified Agreement.
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>26. Governing Law and Jurisdiction  </b> </p>
                                        <p class="text mb-3">This Agreement shall be governed by and construed in accordance with the laws of India. Any controversy or claim shall be settled and adjudicated exclusively by the courts of Noida, Uttar Pradesh, India. 
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>27. Limitation of Liability </b> </p>
                                        <p class="text mb-3">You agree that Comhard Technologies Pvt. Ltd. ("Comhard") shall, in no event, be liable for any consequential, incidental, indirect, special, punitive, or other loss or damage whatsoever, including but not limited to loss of business profits, business interruption, computer failure, loss of business information, or any other loss arising out of or caused by your use of or inability to use the cloud subscription service, or that of your end users, even if Comhard has been advised of the possibility of such damage. <br/>
                                            In no event shall Comhard’s entire liability to you, whether direct or indirect, in respect of the cloud subscription service, exceed the value of one month’s subscription, which will be provided as an extension. 
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>28. Indemnification   </b> </p>
                                        <p class="text mb-3">You agree to indemnify and hold harmless Comhard, its officers, directors, employees, suppliers, and affiliates, from and against any losses, damages, fines, and expenses (including attorney's fees and costs) arising out of or relating to any claims that you have used the Cloud Subscription Service in violation of another party's rights, in violation of any law, in violation of any provisions of this Agreement, or any other claim related to your use of the Cloud Subscription Service, except where such use is authorized by Comhard. 
                                        </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>29. Links to Additional Policies:    </b> </p>
                                        <p class="fs-6 text-black">For detailed information about our service commitments and user rights, please refer to the following documents: </p>
                                        <p class="text mb-3"><b class="text-black">a. Service Level Agreement (SLA): </b> Our SLA outlines the level of service you can expect from us, including uptime guarantees, response times, and procedures for reporting and addressing downtime. <a href="https://partners.cocloud.in/sla">Read the SLA </a> for more details.  </p>
                                        <p class="text mb-3"><b class="text-black">b. Privacy Policy:
                                            </b>Our Privacy Policy describes how we collect, use, store, and protect your personal information. It also explains your rights regarding your data and how to exercise them. <a href="https://partners.cocloud.in/privacy-policy">Read the Privacy Policy</a> for more information. </p>
                                    </li>
                                    <li>
                                        <p class="sub-heading mb-3"><b>30. End of Terms of Service</b> </p>
                                        <p class="text mb-3">For any questions or concerns regarding this Agreement, please contact Comhard at
                                            <a href="mailto:contact@comhard.co.in">contact&#64;comhard.co.in</a>.

                                        </p>
                                        <p class="text"><b class="text-black">Last Updated:  </b>19<sup>th</sup> Sep 2024</p>
                                        <p class="text"><b class="text-black">Effective From:</b> 23<sup>rd</sup> Sep 2024 </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>