import { Component, EventEmitter, OnInit,HostListener  } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { UserService } from '../services/user.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WalletService } from '../services/wallet.service';
import { LogoutComponent } from '../shared/modals/logout/logout.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isShow: boolean = false
  isSideNavOpen: boolean = false;
  isExpanded: boolean = false;
  bsModalRef!: BsModalRef;
  screenWidth = window.innerWidth;
  screenHeight = window.innerHeight;
  mobileView: boolean = false;
  partnerId: string = ''
  userName = localStorage.getItem("userName")
  showNav: boolean = (this.screenWidth < 920)
  expandedMenu: string = '';

  hideBtn() {
    this.showNav = !this.showNav;
  }

  onToggle() {
    this.isShow = !this.isShow;
  }

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private walletService: WalletService,
    private modalService: BsModalService

  ) { 
    this.screenWidth = window.innerWidth;
    this.showNav = !(this.screenWidth < 920); 
    this.sideNavToggle();
  }

  balanceAmount!: any;
  logo: any = '';
  role: string = ''


  ngOnInit() {
    this.partnerId = localStorage.getItem("PartnerId") || ''
    this.role = localStorage.getItem("role") || ''
    this.profileData()
    this.walletService.walletAmountSubject.subscribe((res) => {
      this.balanceAmount = res;
    })
    this.walletService.logoSubject.subscribe((res: any) => {
      this.logo = res;
    })

  }



  toggleMenu(menu: string) {
    this.expandedMenu = this.expandedMenu === menu ? '' : menu;
  }


  profileData() {
    this.walletService.showWalletAmount()
  }

  @HostListener('window:resize', ['$event'])
  onResize(event:any) {
    this.screenWidth = window.innerWidth;
    this.sideNavToggle();
  }
  sideNavToggle() {
    if(this.screenWidth < 920){
      this.hideBtn()
    }else{
      this.showNav = false
    }
  }


  logout() {
    this.authService.logout()
  }

  openModal() {
    this.bsModalRef = this.modalService.show(LogoutComponent, {
      class: 'modal-sm ',
      backdrop: 'static'
    })

  }

  

  get isSubUser() {
    return this.authService.isSubUser()
  }

  get isPartnerSalesman() {
    const userType = this.authService.userType()
    const partnerType = localStorage.getItem('partnerType');
    if (userType && this.partnerId != '645a4eca8c6428e9ac655957' && partnerType == 'subUser') {
      return false
    } else {
      return true
    }
  }
  get userType() {
    return this.authService.userType()
  }

  get isSalesman() {
    return this.authService.isSalesman()
  }
}
