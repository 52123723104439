<div class="content-wrapper">
    <div class="row">
        <div class="sub-heading ">MRR Report</div>
        <div>
            <form [formGroup]="searchForm">
                <div class="row mt-3">
                    <div class="col-md-3" *ngIf="!userType ||(userType && isSalesman)">
                        <mat-form-field appearance="fill" class="example-form-field w-100">
                            <mat-label>Salesperson</mat-label>
                            <mat-select multiple #multiSelect1 formControlName="salesPersonId">
                                <div class="custom-panel">
                                    <div class="search ">
                                        <input type="text" class="custom-input-style  p-2" placeholder="Search here..."
                                            formControlName="searchText">
                                    </div>
                                    <div class="select-all">
                                        <mat-checkbox [(ngModel)]="allSalesPersonSelected"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection(multiSelect1,1)">Select All</mat-checkbox>
                                    </div>
                                    <mat-option *ngFor="let salesman of salesmans | subFilter: searchText"
                                        [value]="salesman?._id" (click)="optionClick(multiSelect1,1)">
                                        {{salesman?.name |
                                        titlecase}}</mat-option>
                                </div>

                                <footer>
                                    <button mat-raised-button class="me-2"
                                        (click)="submitAll();multiSelect1.close()">Ok</button>
                                    <button mat-button (click)="multiSelect1.close()">Cancel</button>
                                </footer>
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="col-md-3" *ngIf="!userType ||(userType && !isSalesman)">
                        <mat-form-field appearance="fill" class="example-form-field w-100">
                            <mat-label>Relationship Manager</mat-label>
                            <mat-select multiple #multiSelect2 formControlName="relationshipManagerId">
                                <div class="custom-panel">
                                    <div class="search ">
                                        <input type="text" class="custom-input-style  p-2" placeholder="Search here..."
                                            formControlName="searchText">
                                    </div>
                                    <div class="select-all">
                                        <mat-checkbox [(ngModel)]="allRelationshipSelected"
                                            [ngModelOptions]="{standalone: true}"
                                            (change)="toggleAllSelection(multiSelect2,2)">Select All</mat-checkbox>
                                    </div>
                                    <mat-option *ngFor="let relationship of relationshipManager | subFilter: searchText"
                                        [value]="relationship?._id" (click)="optionClick(multiSelect2,2)">
                                        {{relationship?.name |
                                        titlecase}}</mat-option>
                                </div>

                                <footer>
                                    <button mat-raised-button class="me-2"
                                        (click)="submitAll();multiSelect2.close()">Ok</button>
                                    <button mat-button (click)="multiSelect2.close()">Cancel</button>
                                </footer>
                            </mat-select>
                        </mat-form-field>
                    </div>

                </div>
            </form>
            <div class="col-lg-12 stretch-card mt-1">
                <div class="card">
                    <div class="card-body">

                        <div class="mt-2">
                            <!-- <div class="wrapper"> -->
                            <button class="graph me-2" [ngClass]="chartType=='area'?'active':''"
                                (click)="toggleChartType('area')">Line</button>
                            <button class="graph" [ngClass]="chartType=='column'?'active':''"
                                (click)="toggleChartType('column')">Bar</button>
                            <!-- </div> -->
                        </div>
                        <div id="mrrReportChart"
                            class="chart-container d-flex justify-content-center align-items-center position-relative ">
                            <highcharts-chart [Highcharts]="Highcharts" [options]="mrrReportOption"
                                style="width: 100%; height: 400px; display: block;">
                            </highcharts-chart>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 mt-3">
                <div class="card ">
                    <div class="card-body ">
                        <div class="row ">
                            <div class="col-3 mrr-table  pe-0">
                                <table class="table table-bordered  ">
                                    <thead class="roundedTable ">
                                        <tr>
                                            <th scope="col "><span>&nbsp;</span></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>New Business MRR <i class="bi bi-question-circle text-muted "
                                                    ngbTooltip="Revenue increases when customers convert to paid subscriptions."></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Expansion MRR <i class="bi bi-question-circle text-muted"
                                                    ngbTooltip="Revenue increases upon upgrades from subscribers."></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Contraction MRR <i class="bi bi-question-circle text-muted"
                                                    ngbTooltip="Revenue decreases upon downgrade from subscribers."></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Churn MRR <i class="bi bi-question-circle text-muted"
                                                    ngbTooltip="Revenue lost from subscribers cancelling all their subscriptions."></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Reactivation MRR <i class="bi bi-question-circle text-muted"
                                                    ngbTooltip="Revenue added when customers renew their subscriptions after expired"></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Net Churn MRR <i class="bi bi-question-circle text-muted"
                                                    ngbTooltip="Revenue lost from subscribers cancelling all their subscriptions."></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Net MRR Movement <i class="bi bi-question-circle text-muted"
                                                    ngbTooltip="Net change in recurring revenue from the previous period."></i>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>MRR </td>
                                        </tr>
                                        <tr>
                                            <td>Change <i class="bi bi-question-circle text-muted"
                                                    ngbTooltip="Compared to the previous interval."></i></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="col-9 table-responsive custom-table  ps-0">
                                <table class="table table-bordered ">
                                    <thead class="text-center thead-header ">
                                        <tr>
                                            <th scope="col " *ngFor="let mrr of mrrReports">{{mrr?.month}}</th>
                                        </tr>
                                    </thead>
                                    <tbody class="custom-table1">

                                        <tr>
                                            <td *ngFor="let mrr of mrrReports"
                                                (click)="mrr?.newBizCount>0?mrrDetails(mrr?.month,'new subscription'):''"
                                                [ngClass]="mrr?.newBizCount>0?'pointer':''">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div
                                                        [ngClass]="mrr?.newBizCount>0?'col-7 text-end':'col-12 text-center'">
                                                        {{mrr?.newBizCount>0 ? (mrr?.newBizMrr| currency:"INR"):'-'}}
                                                    </div>
                                                    <div *ngIf="mrr?.newBizCount>0" class="col-5"><span
                                                            class="text-success ms-2">{{mrr?.newBizCount}}</span>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td *ngFor="let mrr of mrrReports"
                                                (click)="mrr?.upgradeCount>0?mrrDetails(mrr?.month,'upgrade'):''"
                                                [ngClass]="mrr?.upgradeCount>0?'pointer':''">
                                                <div class=" row d-flex justify-content-center align-items-center">
                                                    <div
                                                        [ngClass]="mrr?.upgradeCount>0?'col-7 text-end':'col-12 text-center'">
                                                        {{mrr?.upgradeCount>0?(mrr?.upgradeMrr | currency:"INR"):'-'}}
                                                    </div>
                                                    <div *ngIf="mrr?.upgradeCount>0" class="col-5"><span
                                                            class="text-success ms-2">{{mrr?.upgradeCount}}</span>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td *ngFor="let mrr of mrrReports"
                                                (click)="mrr?.downgradeCount>0?mrrDetails(mrr?.month,'downgrade'):''"
                                                [ngClass]="mrr?.downgradeCount>0?'pointer':''">
                                                <div class="row d-flex justify-content-center align-items-center">
                                                    <div
                                                        [ngClass]="mrr?.downgradeCount>0?'col-7 text-end':'col-12 text-center'">
                                                        {{mrr?.downgradeCount>0?(mrr?.downgradeMrr |
                                                        currency:"INR"):'-'}}</div>
                                                    <div *ngIf="mrr?.downgradeCount>0" class="col-5"><span
                                                            class="text-danger ms-2">{{mrr?.downgradeCount}}</span>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>

                                            <td *ngFor="let mrr of mrrReports"
                                                (click)="mrr?.churnCount>0?mrrDetails(mrr?.month,'churn'):''"
                                                [ngClass]="mrr?.churnCount>0?'pointer':''">
                                                <div class="row d-flex justify-content-center align-items-center">
                                                    <div
                                                        [ngClass]="mrr?.churnCount>0?'col-7 text-end':'col-12 text-center'">
                                                        {{mrr?.churnCount?(mrr?.churnMrr | currency:"INR"):'-'}}</div>
                                                    <div *ngIf="mrr?.churnCount>0" class="col-5"><span
                                                            class="text-danger ms-2">{{mrr?.churnCount}}</span>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>

                                            <td *ngFor="let mrr of mrrReports"
                                                (click)="mrr?.reactivationCount>0?mrrDetails(mrr?.month,'reactivation'):''"
                                                [ngClass]="mrr?.reactivationCount>0?'pointer':''">
                                                <div class="row d-flex justify-content-center align-items-center">
                                                    <div
                                                        [ngClass]="mrr?.reactivationCount>0?'col-7 text-end':'col-12 text-center'">
                                                        {{mrr?.reactivationCount>0?(mrr?.reactivationMrr |
                                                        currency:"INR"):"-"}}</div>
                                                    <div *ngIf="mrr?.reactivationCount>0" class="col-5"><span
                                                            class="text-success ms-2">{{mrr?.reactivationCount}}</span>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr>

                                            <td *ngFor="let mrr of mrrReports"
                                                (click)="mrr?.netChurnCount>0?mrrDetails(mrr?.month,'netchurn'):''"
                                                [ngClass]="mrr?.netChurnCount>0?'pointer':''">
                                                <div class="row d-flex justify-content-center align-items-center">
                                                    <div
                                                        [ngClass]="mrr?.netChurnCount>0?'col-7 text-end':'col-12 text-center'">
                                                        {{mrr?.netChurnCount?(mrr?.netChurnMrr | currency:"INR"):'-'}}
                                                    </div>
                                                    <div *ngIf="mrr?.netChurnCount>0" class="col-5"><span
                                                            class="text-danger ms-2">{{mrr?.netChurnCount}}</span>
                                                    </div>

                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="custom-row">
                                            <td *ngFor="let mrr of mrrReports">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <div>{{mrr?.netMrr | currency:"INR"}}</div>
                                                    <div><span class="ms-2">&nbsp;</span></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td *ngFor="let mrrGrowth of mrrGrowths">

                                                <div class="d-flex justify-content-center align-items-center">
                                                    <div>{{mrrGrowth?.value |currency:"INR"}}</div>
                                                    <div><span class="ms-2">&nbsp;</span></div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td *ngFor="let mrrGrowth of mrrGrowths">
                                                <div class="d-flex justify-content-center align-items-center">
                                                    <div> <span
                                                            [ngClass]="mrrGrowth?.changePercentage>0?'text-success':'text-danger'">
                                                            {{mrrGrowth?.changePercentage}}%</span></div>
                                                    <div><span class="ms-2">&nbsp;</span></div>
                                                </div>

                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class=" col-lg-12  mt-3" *ngIf="isShowDetails" #scrollContainer>
                <div class="sub-heading ">Activity <span class="float-end excel" *ngIf="!userType" (click)="exportexcel()">EXPORT (EXCEL)</span></div>
                <div class="card  mt-3">
                    <div class="card-body">
                        <div class="table-responsive table-responsive1">
                            <table class="table table-bordered" id="mrr-detail-table">
                                <thead class="roundedTable thead-header">
                                    <tr>
                                        <th scope="col">Description</th>
                                        <th scope="col">Sub Id</th>
                                        <th scope="col">Partner Email</th>
                                        <th scope="col">Relationship Manager</th>
                                        <th scope="col">Salesperson</th>
                                        <th scope="col">Customer Email</th>
                                        <th scope="col">MRR Movement</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Date</th>

                                    </tr>
                                </thead>
                                <tbody class="custom-table1">

                                    <tr *ngFor="let details of mrrDetail">

                                        <td>
                                            <div class="d-inline-block text-truncate" style="max-width: 200px;"
                                                ngbTooltip="{{details?.description}}">{{details?.description}}</div>
                                        </td>
                                        <td>
                                            <a class="sub-link" [routerLink]="['/subscriptions-details']"
                                            [queryParams]="{ subId: details?.subId, domainName: this.domainName  }">
                                                {{details?.subId}}</a>

                                        </td>
                                        <td>{{details?.partnerEmail?details?.partnerEmail:'NA'}}</td>
                                        <td>{{details?.subscription?.relationshipManager?.name ? details?.subscription?.relationshipManager?.name : 'NA'}}</td>
                                        <td>{{details?.subscription?.salesPerson?.name ? details?.subscription?.salesPerson?.name: 'NA'}}</td>
                                        <td>{{details?.customerEmail?details?.customerEmail:'NA'}}</td>
                                        <td>{{details?.mrrMovement ? (details?.mrrMovement| currency:"INR"):'NA'}}</td>
                                        <td>{{details?.billingType? (details?.billingType | titlecase):'NA'}}</td>
                                        <td>{{details?.createdAt ? (details?.createdAt| date):''}}</td>
                                    </tr>

                                </tbody>

                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</div>