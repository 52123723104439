<!-- -----------product--page-------- -->
<div class="main-panel">
 <div class="content-wrapper">
  <div class="row">
   <div class="col-md-12">
    <div class="row mb-4">
     <div class="col-md-6">
      <h3 class="font-weight-bold mb-3">All Products</h3>
     </div>
    </div>
   </div>
  </div>
  <!-- ------table-section------------ -->
  <div class="row table-section pe-0">
   <div class="col-md-12 grid-margin stretch-card pe-0">
    <div class="card detailed-section">
     <div class="card-body">
      <div class="card-title">
       Products List
      </div>
      <div class="table-responsive">
       <table class="table table-striped table-borderless" *ngIf="productFound">
        <thead>
         <tr>
          <th scope="col">Product Id</th>
          <th scope="col">Product Name</th>
          <th scope="col">Product Description</th>
          <th scope="col">Status</th>
          <!-- <th scope="col">Credit<i class="bi bi-arrow-down-up"></i></th>
               <th scope="col">Balance<i class="bi bi-arrow-down-up"></i></th> -->
         </tr>
        </thead>
        <tbody>
         <tr *ngFor="let list of productList">
          <td>{{list.id}}</td>
          <td (click)="showPlanAndAddOn(list?._id)" class="show-detail">{{list?.name}}
          </td>
          <td>{{list.description}}</td>
          <td [ngClass]="list.isActive ? 'text-success' : 'text-danger'">{{list?.isActive
           ? 'Active' : 'Inactive'}}</td>
         </tr>
        </tbody>
       </table>
       <div class="text-center" *ngIf="!productFound">
        <p class="fw-bold">No Products assigned yet</p>
       </div>
      </div>
     </div>
    </div>
   </div>
  </div>
  <!-- -----end---------- -->
 </div>
</div>