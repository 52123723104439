import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { InstanceUserService } from 'src/app/services/instance-user.service';

@Component({
  selector: 'app-instance-user-details',
  templateUrl: './instance-user-details.component.html',
  styleUrls: ['./instance-user-details.component.scss']
})
export class InstanceUserDetailsComponent implements OnInit {

  searchForm = new FormGroup({
    cloudUserEmail: new FormControl(''),
    customerEmail: new FormControl(''),
    subCode: new FormControl('')
  })
  instanceUsers: any = []
  showData: boolean = false
  constructor(
    private authService: AuthService,
    readonly instanceUserService: InstanceUserService,
    readonly toasts: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['search']) {
        const searchQuery = params['search'];
        const searchData = JSON.parse(searchQuery)
        this.searchForm.controls.cloudUserEmail.setValue(searchData.cloudUserEmail)
        this.searchForm.controls.customerEmail.setValue(searchData.customerEmail)
        this.searchForm.controls.subCode.setValue(searchData.subCode)
        this.instanceUserList()
      }
    });
  }

  resetForm() {
    this.searchForm.reset()
    this.instanceUsers = []
    this.showData = false
  }
  submit() {
    const searchFormField = this.searchForm.value
    if (searchFormField.cloudUserEmail === "" && searchFormField.customerEmail === "" && searchFormField.subCode === "") {
      this.toasts.warning('Please fill in data in at least one of the search fields.')
    }
    else {
      this.instanceUserList()
    }
  }
  instanceUserList() {
    const searchField = JSON.stringify(this.searchForm.value)
    this.instanceUserService.instanceUserList(searchField).subscribe((res: any) => {
      if (res.success) {
        this.showData = true
        this.instanceUsers = res.instanceUsers
      }
    }, (error: any) => {
      this.instanceUsers = []
      this.toasts.error(error.error.message);
    })
  }

  instanceUserDetails(subCode: string) {
    const searchField = JSON.stringify(this.searchForm.value)
    this.router.navigate(['cloud-user-details'], { queryParams: { subCode: subCode, search: JSON.stringify(this.searchForm.value) } })
  }

  get userType() {
    return this.authService.userType()
  }
}
