import { Component, OnInit } from '@angular/core';

// import {Chart} from 'chart.js/auto';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
  template: '<canvas #myChart></canvas>',
})
export class ReportsComponent implements OnInit{
 
  private barChart: any;
  private doughnutChart: any;
  private lineChart: any;
  
  ngOnInit(): void {
    // this.createBarChart();
    // this.createDoughnutChart();
    // this.createLineChart();
  }

  ngOnDestroy() {
    // Ensure that each chart is destroyed when the component is destroyed
    if (this.barChart) {
      this.barChart.destroy();
    }
    if (this.doughnutChart) {
      this.doughnutChart.destroy();
    }
    if (this.lineChart) {
      this.lineChart.destroy();
    }
  }

  // createBarChart() {
  //   this.barChart = new Chart("barChart", {
  //     type: 'bar',
  //     data: {
  //       labels: ['2022-05-10', '2022-05-11', '2022-05-12', '2022-05-13', '2022-05-14'],
  //       datasets: [
  //         {
  //           label: "Subscriptions",
  //           data: ['467', '576', '572', '79', '92', '574'],
  //           backgroundColor: '#8784FE',
  //           borderRadius: 5,
  //         },
  //         {
  //           label: "Users",
  //           data: ['542', '542', '536', '327', '17', '0.00'],
  //           backgroundColor: '#4B49AC',
  //           borderRadius: 5,
  //         }
  //       ]
  //     },
  //     options: {
  //       aspectRatio: 1.5,
  //     }
  //   });
  // }

  // createDoughnutChart() {
  //   this.doughnutChart = new Chart("doughnutChart", {
  //     type: 'pie',
  //     data: {
  //       labels: ['Paid', 'Trial', 'Renewal'],
  //       datasets: [{
  //         label: 'My First Dataset',
  //         data: [300, 50, 100],
  //         backgroundColor: ['#F3797E', '#4B49AC', '#4389F9'],
  //         hoverOffset: 4
  //       }]
  //     },
  //     options: {
  //       aspectRatio: 1.5,
  //     }
  //   });
  // }

  // createLineChart() {
  //   this.lineChart = new Chart("lineCharts", {
  //     type: 'line',
  //     data: {
  //       labels: ['User 1', 'User 2', 'User 3', 'User 4', 'User 5', 'User 6', 'User 7'],
  //       datasets: [{
  //         label: 'Subscription',
  //         data: [35, 29, 30, 21, 46, 15, 40,10],
  //         borderColor: '#4B49AC',
  //         tension: 0.2
  //       },
  //       {
  //         label: 'User',
  //         data: [25, 35, 23, 21, 32, 35, 30],
  //         borderColor: '#008000',
  //         tension: 0.2
  //       }
  //     ]
  //     },
  //     options: {
  //       responsive: true,
  //       aspectRatio: 2,
  //     }
  //   });
  // }
}
