<!-- -----branding ----page ---start------>
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12 mb-2">
          <form [formGroup]="brandingForm">
            <div class="row">
              <div class="col-12 ">
                <h3 class="heading">Branding</h3>

              </div>
              <!-- ------------------- -->
              <div class="col-md-12 grid-margin stretch-card">
                <div class="card brand-card">
                  <span class="text-primary mb-2">Set Branding - Step 1 of 2</span>
                  <div class="card-body py-0 px-0">
                    <h4>1.Domain:</h4>
                    <div class="card mt-4 select-domain">
                      <p class="choose-domain"><i class="bi bi-tools"></i> Choose Domain:</p>
                      <div class="container">
                        <div class="text-primary">Setup the main web domain from your system
                          <div class="float-end">
                            <input type="checkbox" class="me-2"  (click)="preventCheckboxChange($event)" [checked]="brandDatas?.isSSL" >
                            <label class="ssl">SSL </label>
                          </div>
                        </div>

                        <div class="form-check ms-4 mb-0">
                          <input class="form-check-input" type="radio" name="domainCheckbox" id="domain-checkbox"
                            checked>
                          <label for="domain-checkbox">
                            Use your own web domain
                          </label>
                        </div>
                        <p class="ms-4 bg-secondary text-light mb-4"><small>Please follow the below step to connect your
                            domain to Cocloud system</small></p>
                        <!-- -------list-section--------- -->
                        <div class="row">
                          <div class="col-md-6">
                            <ol>
                              <li>Domain Address</li>
                              <li class="mt-3">Use this IP address ({{brandIp}}) and add it as an 'A' record
                                from your DNS configuration. When your finish,please confirm
                                your DNS configuration by clicking on the "check and confirm DNS
                                configuration" button</li>
                              <li class="mt-3">
                                Free SSL will be applied to your Sub-domain
                              </li>
                            </ol>
                          </div>
                          <div class="col-md-6">
                            <div class="mb-4 domain-input">
                              <input type="text" class="form-control" placeholder="company@4356" aria-label="Username"
                                aria-describedby="basic-addon1" formControlName="domainName" required>

                              <div *ngIf="brandingForm.controls.domainName.invalid && (brandingForm.controls.domainName.dirty
                 || brandingForm.controls.domainName.touched)" class="error-message">
                                <div *ngIf="brandingForm.controls.domainName.errors?.['required']">
                                  <p class="text-danger m-0 p-0"><small>Domain name cannot be empty</small></p>
                                </div>
                              </div>

                            </div>
                            <div>
                              <button type="button" class="comman-btn1 btn-primary" (click)="checkDNS()">Check And
                                Confirm DNS
                                Configuration</button>
                            </div>
                          </div>
                          <div class="col-md-12 mb-4 text-end">
                            <button type="button" class="comman-btn1 btn-primary text-light"
                              (click)="checkDomainFields()">Save</button>
                          </div>
                        </div>
                        <!-- ---end------ -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- --------------------- -->
              <div class="col-md-12 grid-margin stretch-card">
                <div class="card brand-card">
                  <span class="text-primary mb-2">Set Branding - Step 2 of 2</span>
                  <div class="card-body py-0 px-0">
                    <h4>2.Website:</h4>
                    <!-- --------------brand-section-------------- -->
                    <div class="brand-section">
                      <h4><i class="bi bi-building font-weight-light"></i> Brand Name</h4>
                      <div class="brand-label">
                        <p>This information will be displayed on the customer’s web console and
                          software</p>
                        <div class="row mb-3">
                          <label for="text" class="col-sm-3 col-form-label">Brand Name</label>
                          <div class="col-sm-6 mt-2">
                            <input type="text" class="form-control" id="mobile" formControlName="brandName"
                              [readOnly]="!brandName" required>

                            <div *ngIf="brandingForm.controls.brandName.invalid && (brandingForm.controls.brandName.dirty
               || brandingForm.controls.brandName.touched)" class="error-message">
                              <div *ngIf="brandingForm.controls.brandName.errors?.['required']">
                                <p class="text-danger m-0 p-0"><small>Brand name cannot be empty</small></p>
                              </div>
                            </div>


                          </div>
                          <div class="col-sm-3 mt-2">
                            <button type="button" class="comman-btn1 btn-primary"
                              (click)="enableBrandField()">Change</button>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mb-4 text-end">
                        <button type="button" class="comman-btn1 btn-primary text-light me-4"
                          (click)="brandData()">Save</button>
                      </div>
                    </div>
                    <!--   logo   -->

                    <div class="brand-section">
                      <h4><i class="bi bi-envelope-at font-weight-light"></i> Logo</h4>
                      <div class="brand-label">

                        <div class="row mb-3">


                          <div class="card-body py-0 px-0">
                            <p class="text-secondary sub-text">This email will be used in the reseller system. Your logo
                              should be PNG Transparent Pereferred and Dimensions of: 170px*50px.</p>
                            <div class="contact-email">

                              <div class="mb-4 mt-4">
                                <h5>To upload/update your Company logo , follow the mentioned instructions :</h5>
                                <p>1. Click on the given link and upload your logo <a href="https://imgbb.com/"
                                    target="_blank">
                                    <span class="fw-bold text-end">Upload here</span></a></p>
                                <p>2. Select html full linked and copy the link from src in img</p>
                                <p>3. Paste the copied link in given input box</p>
                              </div>
                              <div>
                                <div class="row mb-3">
                                  <label for="text" class="col-sm-3 col-form-label">Paste your link here</label>
                                  <div class="col-sm-9 mt-2">
                                    <input type="text" class="form-control" formControlName="logo" required>
                                    <div *ngIf="brandingForm.controls.logo.invalid && (brandingForm.controls.logo.dirty
                         || brandingForm.controls.logo.touched)" class="error-message">
                                      <div *ngIf="brandingForm.controls.logo.errors?.['required']">
                                        <p class="text-danger m-0 p-0"><small>It is mandatory field</small></p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                            </div>
                            <!-- --- -->
                          </div>

                        </div>

                      </div>
                      <div class="col-md-12 mb-4 text-end">
                        <button type="button" class="comman-btn1 btn-primary me-4 text-light"
                          (click)="submitLogo()">Save</button>
                      </div>
                    </div>
                    <!-- -------end--------- -->
                    <!-- --------------brand-section-------------- -->
                    <div class="brand-section">
                      <h4><i class="bi bi-envelope-at font-weight-light"></i> Email</h4>
                      <div class="brand-label">
                        <p>Specify the sender’s email you would like the system emails to
                          be sent in</p>
                        <div class="row mb-3">
                          <label for="text" class="col-sm-3 col-form-label">Sender's Email</label>
                          <div class="col-sm-6 mt-2">
                            <input type="text" class="form-control" id="Name" formControlName="senderEmail" required
                              pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$">

                            <div *ngIf="brandingForm.controls.senderEmail.invalid && (brandingForm.controls.senderEmail.dirty
               || brandingForm.controls.senderEmail.touched)" class="error-message">
                              <div *ngIf="brandingForm.controls.senderEmail.errors?.['required']">
                                <p class="text-danger m-0 p-0"><small>Email address is required</small></p>
                              </div>
                              <div *ngIf="brandingForm.controls.senderEmail.errors?.['pattern']">
                                <p class="text-danger m-0 p-0"><small>Enter valid email address</small></p>
                              </div>
                            </div>

                          </div>
                          <div class="col-sm-3 mt-2">
                            <button type="button" class="comman-btn1 btn-primary" (click)="validateEmail()">Validate
                              Email</button>
                          </div>

                        </div>

                      </div>
                      <div class="col-md-12 mb-4 text-end">
                        <button type="button" class="comman-btn1 btn-primary me-4 text-light"
                          (click)="checkMailFields()">Save</button>
                      </div>
                    </div>

                    <div class="brand-section">
                      
                      <h4><i class="mdi mdi-google-drive me-1"></i>Show Google Drive</h4>
                      <div class="brand-label">
                        <p>The Google authentication page is not white-labeled, the CoCloud logo and name are displayed on the Google authentication page. This is due to the fact, that google authenticates main domain and brand. If you want to show the Google Drive of your users, check this. This will enable GoogleDrive backup feature on all your customer’s portal. Your customers or you can set this up.</p>
                        <p>Partner can also use common gmail ID to get all customer’s backups.</p>
                        <div class="row mb-3">
                          <div>
                            <span class="me-2">See Google Pages : </span>
                          
                              <a href="../../../assets/images/google-auth.png"  target="_blank" class="me-3 " ><img src="../../../assets/images/google-auth.png" width="50px" height="50px" alt="google-page" class="box" ngbTooltip="Click here!"/></a>
                           
                            <a href="../../../assets/images/google-auth(1).png" alt="google-page"width="50px" height="50px" target="_blank"><img src="../../../assets/images/google-auth(1).png" width="50px" height="50px" alt="google-page"  class="box" ngbTooltip="Click here!"/></a>
                          </div>
                          <div class="mt-2">
                            <input type="checkbox" class="me-3" formControlName="isGoogleDriveShowToUser"/> <label for="text" class="col-sm-3 col-form-label">Show google drive <i class="bi bi-info-circle" ngbTooltip="If you want to show Google Drive to your users, please check this."></i></label>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-12 mb-4 text-end">
                        <button type="button" class="comman-btn1 btn-primary me-4 text-light"
                          (click)="IsGoogleDriveShowToUser()">Save</button>
                      </div>
                    </div>
                    <div class="brand-section">
                      <h4><i class="bi bi-envelope-at font-weight-light"></i> Contact Support</h4>
                      <div class="brand-label">
                        <p>Specify the emails for contact support and contact sales</p>

                        <div class="row mb-3">
                          <label for="text" class="col-sm-3 col-form-label">Contact Sales</label>
                          <div class="col-sm-6 mt-2">
                            <input type="text" class="form-control" formControlName="sales" required
                              pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$">

                            <div
                              *ngIf="brandingForm.controls.sales.invalid && (brandingForm.controls.sales.dirty|| brandingForm.controls.sales.touched)"
                              class="error-message">
                              <div *ngIf="brandingForm.controls.sales.errors?.['required']">
                                <p class="text-danger m-0 p-0"><small>Email address is required</small></p>
                              </div>
                              <div *ngIf="brandingForm.controls.sales.errors?.['pattern']">
                                <p class="text-danger m-0 p-0"><small>Enter valid email address</small></p>
                              </div>
                            </div>


                          </div>
                        </div>

                        <div class="row mb-3">
                          <label for="text" class="col-sm-3 col-form-label">Contact Support</label>
                          <div class="col-sm-6 mt-2">
                            <input type="text" class="form-control" formControlName="support"
                              pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$" required>
                            <div
                              *ngIf="brandingForm.controls.support.invalid && (brandingForm.controls.support.dirty || brandingForm.controls.support.touched)"
                              class="error-message">
                              <div *ngIf="brandingForm.controls.support.errors?.['required']">
                                <p class="text-danger m-0 p-0"><small>Email address is required</small></p>
                              </div>
                              <div *ngIf="brandingForm.controls.support.errors?.['pattern']">
                                <p class="text-danger m-0 p-0"><small>Enter valid email address</small></p>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="col-md-12 mb-4 text-end">
                        <button type="button" class="comman-btn1 btn-primary me-4 text-light"
                          (click)="submitContactForm()">Save</button>
                      </div>
                    </div>

                  </div>
                  <!-- -------end--------- -->
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>