<!-- ----------partner---details----page ---- -->
<div class="main-panel">
 <div class="content-wrapper">
  <div class="row">
   <div class="col-md-12">
    <div class="row mb-4">
     <div class="col-12 col-xl-8 mb-xl-0 heading-section">
      <h3 class="font-weight-bold">
       <i class="bi bi-arrow-left" [routerLink]="'/account'"></i> Customer:
      </h3>
     </div>

     <div class="col-md-12 mt-3">
      <h4 class="font-weight-bold mb-3">Customer-Details</h4>
     </div>
     <div class="col-md-12">
      <ul class="list-group">
       <li class="list-group-item">Account Id<span class="float-end">{{details?.id}}</span></li>
       <li class="list-group-item">Name<span class="float-end">{{details?.name | titlecase}}</span></li>
       <li class="list-group-item">Mobile No.<span class="float-end">{{details?.mobile}}</span></li>
       <li class="list-group-item">Company Name<span class="float-end">{{details?.companyName | titlecase}}</span></li>
       <li class="list-group-item">Email<span class="float-end">{{details?.email}}</span></li>
       <li class="list-group-item">Created by<span class="float-end">{{details?.createdBy | titlecase}}</span></li>
       <li class="list-group-item">Label <span class="float-end label-class" [ngStyle]="{'background-color':details?.label?.color}"
        >{{details?.label?.name}}</span></li>
      </ul>
     </div>
     <!-- -----Plan-Details----- -->
     <div class="col-md-12 mt-3">
      <h4 class="font-weight-bold mb-3">Plan-Details</h4>
     </div>
     <div class="col-md-12">
      <ul class="list-group">
       <li class="list-group-item">Account <span class="float-end">0</span></li>
       <li class="list-group-item">In Confirmed <span class="float-end">Yes</span></li>
       <li class="list-group-item">Create Date <span class="float-end">17/11/2022 5:45:38 pm</span></li>
       <li class="list-group-item">Expire Date <span class="float-end">{{details?.plan?.endDate | date}}</span></li>
       <li class="list-group-item">Windows Build No. <span class="float-end">**********</span></li>
      </ul>
     </div>
     <!-- ----Service-Details------ -->
     <div class="col-md-12 mt-3">
      <h4 class="font-weight-bold mb-3">Service-Details</h4>
     </div>
     <div class="col-md-12">
      <ul class="list-group">
       <li class="list-group-item">Account <span class="float-end">0</span></li>
       <li class="list-group-item">In Confirmed <span class="float-end">Yes</span></li>
      </ul>
     </div>
     <div class="col-md-12 mt-3 text-end">
      <button type="button" class="comman-btn1 btn-primary me-2" (click)="customerEdit(details._id)">Edit details</button>
      <button type="button" class="comman-btn1 btn-secondary text-light" routerLink="/account">Cancel</button>
     </div>
     <!-- ---------- -->
    </div>
   </div>
  </div>
 </div>
</div>