import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Intercom } from 'ng-intercom';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  constructor(
    private intercom: Intercom,
    private http: HttpClient
  ) { }

  updateIntercomData(email: any) {
    this.intercom.update({
      email: this.maskEmail(email),
    })
  }


  updateIntercomDataWhenLogout(): void {
    this.intercom.shutdown();
    this.intercom.boot({
      app_id: environment.intercomAppId,
      // Supports all optional configuration.
      widget: {
        "activator": "#intercom"
      }
    });
  }

  maskEmail(email: string) {
    // Split the email into local part and domain
    var parts = email.split('@');
    var username = parts[0];
    var domain = parts[1];

    // Find the index of the first dot in the domain
    var dotIndex = domain.indexOf('.');

    // Mask characters in the local part
    const maskedUsername = username.substring(0, 2) + "***" + username.slice(-1);

    // Mask characters in the domain
    var maskedDomain = "";
    if (dotIndex !== -1) {
      var domainPrefix = domain.substring(0, dotIndex); // Get the part before the dot
      var domainSuffix = domain.substring(dotIndex); // Get the part after the dot
      var maskedPrefix = domainPrefix.substring(0, 3) + "***" + domainPrefix.slice(-1); // Mask the first three characters
      maskedDomain = maskedPrefix + domainSuffix; // Combine the masked prefix with the original suffix
    } else {
      maskedDomain = domain; // No dot found, keep the original domain
    }

    return maskedUsername + "@" + maskedDomain;
  }

}
