import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { CustomerAddUpdateRoot } from 'src/app/interfaces/customer/add-and-update';
import { SubscriptionListRoot } from 'src/app/interfaces/subscription/list';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { UserService } from 'src/app/services/user.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { ChangePlanComponent } from '../modals/change-plan/change-plan.component';
import { ChangeCustomerComponent } from '../modals/change-customer/change-customer.component';

@Component({
  selector: 'app-subscriptions',
  templateUrl: './subscriptions.component.html',
  styleUrls: ['./subscriptions.component.scss'],
})
export class SubscriptionsComponent implements OnInit {
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private subscriptionService: SubscriptionsService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private userService: UserService
  ) { }

  bsModalRef!: BsModalRef;

  ngOnInit(): void {
    this.getCustomerID();
    this.customerDetails();
    this.subscriptionList();
  }

  customerId: any;
  subsList: any;

  customerDetail: any = [];

  subscriptionFound: boolean = false;

  createNew(customerID: string) {
    this.router.navigate(['account/new-subscription'], {
      queryParams: { customerId: customerID },
    });
  }

  customerForm = new FormGroup({
    name: new FormControl(''),
    companyName: new FormControl(''),
  });

  getCustomerID() {
    this.customerId = this.activeRoute.snapshot.queryParamMap.get('customerId');
  }

  customerDetails() {
    this.userService
      .userDetails(this.customerId)
      .subscribe((res: CustomerAddUpdateRoot) => {
        if (res.success) {
          // this.customerForm.patchValue(res.customer)
          this.customerDetail = res?.customer;
        }
      });
  }

  planEndDate(startDate: Date, billCycle: string, billEvery: number) {
    if (billCycle !== undefined) {
      const planDays =
        billCycle === 'week' ? 7 : billCycle === 'month' ? 30 : 365;
      let date: Date = new Date(startDate);
      date.setDate(date.getDate() + planDays * billEvery);
      let datePipe: DatePipe = new DatePipe('en-IN');
      return datePipe.transform(date, 'mediumDate');
    } else {
      return 'No Plan Assigned';
    }
  }

  addOnEndDate(startDate: Date, pricingInterval: string, priceEvery: number) {
    if (priceEvery !== undefined) {
      const addOnDays =
        pricingInterval === 'week' ? 7 : pricingInterval === 'month' ? 30 : 365;
      let date: Date = new Date(startDate);
      date.setDate(date.getDate() + addOnDays * priceEvery);
      let datePipe: DatePipe = new DatePipe('en-IN');
      return datePipe.transform(date, 'mediumDate');
    } else {
      return 'No AddOn Assigned';
    }
  }

  deleteSubscription(subscriptionId: string) {
    // this.calculateRefund()

    this.bsModalRef = this.modalService.show(ChangePlanComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });

    this.bsModalRef.content.subscriptionId = subscriptionId;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionList();
      } else {
        this.subscriptionList();
      }
    });
  }

  subscriptionList() {
    this.subscriptionService.subscriptionList(this.customerId, {}, 1, 10,'',[]).subscribe(
      (res: SubscriptionListRoot) => {
        if (res.success) {
          if (res?.subscriptions.length === 0) {
            this.subscriptionFound = false;
          } else {
            this.subsList = res?.subscriptions;
            this.subscriptionFound = true;
          }
        }
      },
      (error) => {
        this.toastr.warning(error.error.message);
      }
    );
  }

  statusChange(id: string, status: string) {
    const payload = {
      subscriptionId: id,
      status: !status
    }
    this.subscriptionService.toggleStatus(payload).subscribe((res: any) => {
      if (res.success) {
        this.subscriptionList();
        this.toastr.success(res.message)
      }
    },
      (error) => {
        this.toastr.error(error.error.message)
      })
  }

  changeCustomerIdModal(subscriptionCode: string) {
    this.bsModalRef = this.modalService.show(ChangeCustomerComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });

    this.bsModalRef.content.subscriptionCode = subscriptionCode;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionList();
      } else {
        this.subscriptionList();
      }
    });
  }

}
