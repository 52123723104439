import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InstanceUserService {

  constructor(
    private http: HttpClient
  ) { }

  instanceUserList(search: any) {
    return this.http.get(`${environment.apiURL}/partner/instanceuser?search=${search}`);
  }
}
