
<!-- ----------Delete--Modal------- -->
<div class="modal-xl">
  <div class="modal-header py-0 border-0">
    <h4 class="modal-title pull-left border-0"><b>Select Subscription: </b></h4>
    <span class="close-btn" (click)="bsModalRef.hide()"><i class="bi bi-x-circle"></i></span>
  </div>
  <!-- <div>
    <div class="modal-body">
      <h6>Subscriptions assigned to this customer:</h6>
    </div>
    <div class="form-check " *ngFor="let subscription of subscriptionList; let indexOfElement = index">
     <div class="row">
      <div class="col-3">
        <input class="form-check-input ms-4 me-2" type="radio" name="flexRadioDefault" 
            [value]="subscription?._id" [id]="'listItem'+indexOfElement" (click)="customerPortal(subscription?._id)">
        <label class="form-check-label" [for]="'listItem'+indexOfElement">
          {{subscription?.code}}
        </label>
      </div>
      <div class="col-3">
        <label class="form-check-label">
          {{subscription?.plan?.name | titlecase}}
        </label>
      </div>
      <div class="col-3">
        <label class="form-check-label">
          {{subscription?.plan?.name | titlecase}}
        </label>
      </div>
      <div class="col-3">
        <label class="form-check-label">
          {{subscription?.plan?.name | titlecase}}
        </label>
      </div>
     </div>
          
       
    </div>   
  </div> -->

  <div>
    <div class="modal-body">
      <h6>Subscriptions assigned to this customer:</h6>
    </div>
    <div class="form-check " *ngFor="let subscription of subscriptionList; let indexOfElement = index">
   
     
        <input class="form-check-input ms-4 me-2" type="radio" name="flexRadioDefault" 
            [value]="subscription?._id" [id]="'listItem'+indexOfElement" (click)="customerPortal(subscription?._id)">
        <label class="form-check-label" [for]="'listItem'+indexOfElement">
          {{subscription?.code}}&nbsp;&nbsp; {{subscription?.plan?.name}}&nbsp;&nbsp; {{subscription?.instance?.title}} &nbsp;&nbsp;{{subscription?.instance?.companyName}}
        </label>
      
          
       
    </div>   
  </div>
</div>

  <!-- ---------------End-------------- -->
  
  




