import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'src/app/services/payment.service';
import { ProfileService } from 'src/app/services/profile.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RenewConfirmationComponent } from '../modals/renew-confirmation/renew-confirmation.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  paymentRedirectUrl: string = environment.paymentRedirectUrl
  totalOrderValue: number = 0
  minOrderValue: number = 0
  encRequest: string = ""
  accessCode: string = ""
  profileDetail: any
  walletBalance: number = 0
  selectedQuantity: number = 1
  currentAmount: number = 0
  gstPrice: number = 0
  increaseAmount: number = 0
  isAccountDetails: boolean = false
  isDeduct: boolean = false
  bsModalRef!: BsModalRef;
  @ViewChild('form') form: ElementRef | undefined;


  constructor(
    private profileService: ProfileService,
    private paymentService: PaymentService,
    private toastr: ToastrService,
    private modalService: BsModalService,

  ) { }

  tdsForm = new FormGroup({
    tanNumber: new FormControl('', [Validators.pattern("[A-Za-z]{4}[0-9]{5}[A-Za-z]{1}"), Validators.required]),
    creditWallet: new FormControl<number>(0),
    paymentMode: new FormControl('online', Validators.required)
  })
  ngOnInit(): void {
    this.profileDetails();
    this.tdsForm.controls.creditWallet.valueChanges.subscribe((value: any) => {
      this.updateGstAndCurentPrice()
    })
  }

  updateGstAndCurentPrice() {
    this.currentAmount = Number(this.tdsForm.get('creditWallet')?.value || 0)
    this.gstPrice = this.currentAmount * 0.18
  }

  increasePayAmount() {
    this.increaseAmount++
    this.currentAmount += this.minOrderValue
    this.gstPrice = this.currentAmount * 0.18
    this.tdsForm.controls.creditWallet.setValue(this.currentAmount);
  }
  decreasePayAmount() {
    this.increaseAmount--
    this.currentAmount -= this.minOrderValue
    this.gstPrice = this.currentAmount * 0.18
    this.tdsForm.controls.creditWallet.setValue(this.currentAmount);
  }


  profileDetails() {
    this.profileService.profileDetails().subscribe((res: any) => {
      if (res.success) {
        this.totalOrderValue = res.partner?.minOrderValue * (1.18)
        this.currentAmount = this.minOrderValue = res.partner?.minOrderValue
        this.tdsForm.controls.creditWallet.setValue(res.partner?.minOrderValue)
        this.gstPrice = this.currentAmount * 0.18
        this.profileDetail = res.partner?.billing
        this.walletBalance = res.partner.wallet
      }
    })
  }

  submit() {
    if (!this.tdsForm.controls.paymentMode.valid) {
      this.tdsForm.controls.paymentMode.markAsTouched()
    } else {
      this.proceedToPayment(0)
    }
  }

  proceedToPayment(deductedAmount: number) {
    if (!this.profileDetail) {
      this.toastr.warning("Please fill the billing details!")
      return;
    }
    if (this.currentAmount >= this.minOrderValue) {
      let paymentPayload = Object.assign(
        this.profileDetail,
        { tanNumber: this.tdsForm.controls.tanNumber.value },
        { deductedAmount: deductedAmount },
        { amount: ((deductedAmount > 0 ? this.amountAfterDeduction : this.currentAmount) + this.gstPrice) });
      const paymentMode = this.tdsForm.controls.paymentMode.value
      if (paymentMode == 'manual') {
        this.bsModalRef = this.modalService.show(RenewConfirmationComponent, {
          class: 'modal-md',
          backdrop: 'static'
        }),
          this.bsModalRef.content.paymentPayload = paymentPayload;
        this.bsModalRef.content.currentAmount = this.currentAmount + this.gstPrice;

      } else {
        let _key = CryptoJS.enc.Utf8.parse(environment.workingKey);
        let _iv = CryptoJS.enc.Utf8.parse(environment.workingKey);
        let encrypted = CryptoJS.AES.encrypt(
          JSON.stringify(paymentPayload), _key, {
          keySize: 16,
          iv: _iv,
          mode: CryptoJS.mode.ECB,
          padding: CryptoJS.pad.Pkcs7
        });

        this.paymentService.requestForPayment({ encrypted: encrypted.toString() }).subscribe((res: any) => {
          if (res.success) {
            this.encRequest = res.encRequest;
            this.accessCode = environment.accessCode;
            setTimeout(() => {
              this.form?.nativeElement.submit();
            });
          }
        }, (error) => {
          this.toastr.error(error.error.message);
        });
      }

    }
    else {
      this.toastr.warning(`Your min order Value is ${this.minOrderValue}`);
    }
  }

  get deductPrice() {
    const price = this.currentAmount;
    return price * 0.02
  }

  get amountAfterDeduction() {
    return this.currentAmount - this.deductPrice
  }

  deductTds() {
    if (!this.tdsForm.controls.paymentMode.valid) {
      this.tdsForm.controls.paymentMode.markAsTouched()
    } else {
      this.isDeduct = !this.isDeduct
    }

  }

  deductPricePayment() {
    if (!this.tdsForm.controls.tanNumber.valid) {
      this.tdsForm.controls.tanNumber.markAsTouched()
    }
    else {
      if (this.currentAmount >= this.minOrderValue) {
        this.proceedToPayment(this.deductPrice)
      } else {
        this.toastr.warning(`Your min order Value is ${this.minOrderValue}`)
      }

    }
  }

}
