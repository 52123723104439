import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CustomerAddUpdateRoot } from '../interfaces/customer/add-and-update';
import { CustomerListRoot } from '../interfaces/customer/list';
import { LabelAddUpdateRoot } from '../interfaces/label/label-add-and-update';
import { LabelListRoot } from '../interfaces/label/list';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private isShow = new BehaviorSubject<boolean | null>(null);
  isShowValue$ = this.isShow.asObservable();

  constructor(private http: HttpClient) { }

  // for sticking the filters on dashboard
  // isShowToggleValue(val: boolean) {
  //   this.isShow.next(val)
  // }


  userAdd(userPayload: any) {
    return this.http.post<CustomerAddUpdateRoot>(`${environment.apiURL}/partner/customer`, userPayload)
  }

  userList(search: any) {
    return this.http.get<CustomerListRoot>(`${environment.apiURL}/partner/customer?search=${search}`)
  }

  customerList(search: any, limit: any, page: any) {
    return this.http.get<CustomerListRoot>(`${environment.apiURL}/partner/customer?search=${search}&limit=${limit}&page=${page}`)
  }

  userUpdate(userPayload: any) {
    return this.http.put<CustomerAddUpdateRoot>(`${environment.apiURL}/partner/customer`, userPayload)
  }

  userDetails(id: any) {
    return this.http.get<CustomerAddUpdateRoot>(`${environment.apiURL}/partner/customer/details?customerId=${id}`)
  }

  userStatus(body: any) {
    return this.http.put(`${environment.apiURL}/partner/customer`, body)
  }

  userDelete(id: any) {
    return this.http.delete(`${environment.apiURL}/partner/customer?customerId=${id}`)
  }

  loginWithPartner(payload: any) {
    return this.http.post(`${environment.apiURL}/partner/customer/login`, payload)
  }

  showAmount() {
    return this.http.get(`${environment.apiURL}/partner/profile`)
  }

  changeExpiryDate(payload: any) {
    return this.http.put(`${environment.apiURL}/partner/plan/data`, payload)
  }

  // changeNoOfUsers(payload:any){
  //   return this.http.put(`${environment.apiURL}/partner/plan/data`,payload)
  // }

  addLabel(payload: any) {
    return this.http.post<LabelAddUpdateRoot>(`${environment.apiURL}/partner/label`, payload)
  }

  showLabel() {
    return this.http.get<LabelListRoot>(`${environment.apiURL}/partner/label`)
  }

  labelUpdate(payload: any) {
    return this.http.put<LabelAddUpdateRoot>(`${environment.apiURL}/partner/label`, payload)
  }

  labelDetails(labelId: any) {
    return this.http.get<LabelAddUpdateRoot>(`${environment.apiURL}/partner/label/details?labelId=${labelId}`)
  }

  setLabel(payload: any) {
    return this.http.post(`${environment.apiURL}/partner/label/assign`, payload)
  }

  labelSearch(name: string) {
    return this.http.get<LabelListRoot>(`${environment.apiURL}/partner/label?search=${name}`)
  }

  deleteLabel(id: any) {
    return this.http.delete(`${environment.apiURL}/partner/label?labelId=${id}`)
  }

  removeSelectedLabel(id: any) {
    return this.http.get(`${environment.apiURL}/partner/label/remove?customerId=${id}`)
  }
}
