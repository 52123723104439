import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {

  constructor(private authService: AuthService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();

    if (token) {
      const headers = {
        'x-access-token': token
      };
      request = request.clone({
        setHeaders: headers
      });
    }
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401 || error.error.tokenVerification === false) {
          // this.toastService.show("Session expired. Please Login");
          this.authService.logout();
        }
        else if (error.status === 403) {
          // this.toastService.show("Please Login");
          this.authService.logout();
        }
        return throwError(error);
      })
    );
  }

}
