<div class="container-fluid ps-0 pe-0">
    <div class="main-panel">
        <div class="row">
            <div class="col-md-12 ">
                <div class="card">
                    <div class="card-body class-padding">
                        <p class="heading text-center ">PRIVACY POLICY</p>
                        <div class="row mt-4">

                            <p class="text mb-3">
                                At Comhard Technologies Private Limited, safeguarding the privacy of our website
                                visitors, as well as those who respond to our advertisements or communicate via email,
                                is a top priority. This Privacy Policy elucidates the collection and utilization of
                                information to provide clarity on when and how personal data is acquired, used,
                                disclosed, and protected.<br /> <br />

                                For services powered by Comhard Technologies Private Limited, such as CoCloud, offering
                                Tally on Cloud Services, users are encouraged to review and adhere to this Privacy
                                Policy.
                            </p>

                            <p class="sub-heading mb-3">
                                Network Security
                            </p>
                            <p class="text mb-3">
                                Comhard Technologies employs a comprehensive set of physical, electronic, and procedural
                                safeguards, coupled with robust encryption technologies, to fortify the protection of
                                your personal information. This security framework extends across various interactions,
                                such as viewing bills or requesting information about our services. <br />

                                It is essential to note that while we ensure the security of your information within our
                                systems, we do not have control over the security measures implemented by other websites
                                on the internet. To prevent unauthorized access to your account information, maintaining
                                the confidentiality of your username, password, and other access credentials is
                                imperative.
                            </p>
                            <p class="sub-heading mb-3">
                                Personal Information
                            </p>
                            <p class="text mb-3">
                                Comhard Technologies refrains from collecting personal information (such as name,
                                address, telephone number, email address, etc.) unless voluntarily provided by the user.
                                We do not share, disclose, or sell any personal information collected through
                                comhard.co.in with unaffiliated companies. Additionally, the disclosure of Customer
                                Proprietary Network Information (CPNI) is restricted for purposes related to providing
                                or marketing our products and services. In the context of a merger, sale, or corporate
                                reorganization, personal information would be treated as a valuable asset.
                            </p>
                            <p class="sub-heading mb-3">
                                How We Use Collected Information
                            </p>
                            <p class="text mb-3">
                                The information collected by Comhard Technologies is utilized to fulfill the services
                                you've ordered and to inform you about other offerings that may align with your
                                interests. This includes communication regarding updates, seamless provisioning and
                                billing of services, and addressing customer inquiries. <br />

                                While Comhard Technologies prioritizes the protection of user information, it may
                                disclose personally-identifiable information in response to legal demands or when
                                necessary to identify, contact, or take legal action against individuals posing threats
                                to public safety or interfering with our property or services.
                            </p>
                            <p class="sub-heading mb-3">
                                Internal Business Use 
                            </p>
                            <p class="text mb-3">
                                We leverage personal information for various aspects of our business operations, including service delivery, identifying customers, billing, creditworthiness assessments, tax compliance, protecting our rights, and communicating about accounts and services. This ensures a smooth and secure interaction with our customers. 
                            </p>
                            <p class="sub-heading mb-3">
                                Marketing Communications  
                            </p>
                            <p class="text mb-3">
                                Comhard Technologies may use personal information to inform users about new products and services via various communication channels. Users have the option to opt out of receiving marketing information while still receiving essential notifications related to invoices, customer service, technical or network updates, and similar information.
                            </p>
                            <p class="sub-heading mb-3">
                                Content   
                            </p>
                            <p class="text mb-3">
                                All hosting services provided by Comhard Technologies are to be used for lawful purposes. Transmission, storage, or presentation of any information in violation of Indian law is strictly prohibited. This includes copyrighted materials, trade secrets, obscene content, and materials contrary to public interest or policy.<br/> 
                                Prohibited content includes pornographic materials and sex-related merchandising. Comhard Technologies reserves the right to determine violations of this provision and take appropriate action. 
                            </p>
                            <p class="sub-heading mb-3">
                                Materials and Products 
                            </p>
                            <p class="text mb-3">
                                Comhard Technologies exercises no control over the content of customer networks or websites. We do not provide warranties or guarantees, expressed or implied, for the services offered. The responsibility for the accuracy and quality of information obtained through Comhard Technologies lies with the customer. <br/>
                                Any violation of this policy should be reported to Comhard Technologies. Connection speed represents the connection to Comhard Technologies and does not guarantee end-to-end bandwidth. 
                            </p>
                            <p class="sub-heading mb-3">
                                Changes to this Privacy Policy 
                            </p>
                            <p class="text mb-3">
                                Comhard Technologies retains the right to modify this Privacy Policy at its discretion without notice. Amendments are effective upon posting, and continued use of our services constitutes acceptance of the changes.  
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>