<!-- -----------setting--page--- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12 grid-margin">
          <div class="row">
            <div class="col-12">
              <p class="font-weight-bold setting-heading mb-0">Setting</p>
            </div>
          </div>
        </div>

        <form [formGroup]="logoForm">
          <div class="col-md-12 stretch-card">
            <div class="card">
              <div class="card-body ">
                <p class="setting-logo p-1 text-light mb-0"><i class="bi bi-ticket-perforated-fill me-3"></i> Ticket
                  support </p>
                <div class=" border-line">
                  <div clas="mt-4">
                    <div class="row">
                      <div class="col-md-9">
                        <p class="fs-6 me-5 mt-3"><i class="bi bi-stars fs-4 me-3"></i>
                          Do you want to enable ticketing support feature on your customer portal?
                        </p>
                      </div>
                      <div class="col-md-3 d-flex justify-content-between align-items-center ">
                        <div class="fs-6  mt-3">
                          <input type="radio" name="ticket" class="me-2" (click)="ticketFeature('yes')"
                            [checked]="isShow" />
                          <label>Yes</label>
                        </div>
                        <div class="fs-6  mt-3">
                          <input type="radio" name="ticket" class="me-2" (click)="ticketFeature('no')"
                            [checked]="!isShow" />
                          <label class="me-3">No</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-4" *ngIf="isShow">
                    <div class="mt-3">
                      <input type="radio" name="ticket1" class="me-4" (click)="openTicketModal('Self  Support')" />
                      <label>Partner self support : When customer will submit ticket on portal, partner will get email
                        and partner will support their customer.</label>
                    </div>
                    <div class="mt-3 position-relative">
                      <input type="radio" name="ticket1" class="me-4 " (click)="openTicketModal('Comhard Support')" />
                      <label>Comhard support : When customer will submit ticket on portal, comhard’s support team will
                        call customer and resolve. This call will <br />represent call only from partner’s side, and no
                        information will shared from “comhard’s” end. <b>[white-labelled support]</b></label>
                    </div>
                  </div>

                </div>
                <!-- --- -->
              </div>
            </div>
          </div>
        </form>
        <!-- -------form---1------- -->
        <!-- <form [formGroup]="logoForm">
     <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
       <div class="card-body py-0 px-0">
        <p class="fs-5 setting-logo p-1 text-light"><i class="bi bi-card-image"></i> Logo</p>
        <p class="text-secondary sub-text">This email will be used in the reseller system. Your logo
         should be PNG Transparent Pereferred and Dimensions of: 170px*50px.</p>
        <div class="contact-email">
         <div class="choose-file">
                  <img src="../../../assets/images/comhard-logo.svg" alt="" class="mt-2">
                  <input id="inptLogo2" accept=".ico" type="file" name="file" class="btn btn-info inputFile">
                </div>
         <div class="mb-4 mt-4">
          <h5>To upload/update your Company logo , follow the mentioned instructions :</h5>
          <p>1. Click on the given link and upload your logo <a href="https://imgbb.com/" target="_blank">
            <span class="fw-bold text-end">Upload here</span></a></p>
          <p>2. Select html full linked and copy the link from src in img</p>
          <p>3. Paste the copied link in given input box</p>
         </div>
         <div>
          <div class="row mb-3">
           <label for="text" class="col-sm-3 col-form-label">Paste your link here</label>
           <div class="col-sm-9 mt-2">
            <input type="text" class="form-control" formControlName="logo" required>
            <div *ngIf="logoForm.controls.logo.invalid && (logoForm.controls.logo.dirty
                       || logoForm.controls.logo.touched)" class="error-message">
             <div *ngIf="logoForm.controls.logo.errors?.['required']">
              <p class="text-danger m-0 p-0"><small>It is mandatory field</small></p>
             </div>
            </div>
           </div>
          </div>
         </div>
         <div class="text-end mb-4">
          <button type="button" class="comman-btn1 btn-primary text-light" (click)="submitLogo()"
           [disabled]="!logoForm.valid">
           Submit</button>
         </div>
        </div>
      
       </div>
      </div>
     </div>
    </form> -->
        <!-- -----end------ -->
        <!-- -----form--2 -->
        <!-- <form [formGroup]="contactForm">
     <div class="col-md-12 grid-margin stretch-card">
      <div class="card">
       <div class="card-body py-0 px-0">
        <p class="fs-5 setting-logo p-1 text-light"><i class="bi bi-person-badge"></i>
         Contact/Email:</p>
        <p class="text-secondary sub-text">This email will be used by your users to contact you.</p>
        <div class="contact-email">
         <div class="row mb-3">
          <label for="text" class="col-sm-3 col-form-label">Contact Sales</label>
          <div class="col-sm-9 mt-2">
           <input type="text" class="form-control" formControlName="sales" pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$"
            required>

           <div
            *ngIf="contactForm.controls.sales.invalid && (contactForm.controls.sales.dirty || contactForm.controls.sales.touched)"
            class="error-message">
            <div *ngIf="contactForm.controls.sales.errors?.['required']">
             <p class="text-danger m-0 p-0"><small>Email address is required</small></p>
            </div>
            <div *ngIf="contactForm.controls.sales.errors?.['pattern']">
             <p class="text-danger m-0 p-0"><small>Enter valid email address</small></p>
            </div>
           </div>

          </div>
         </div>
      
         <div class="row mb-3">
          <label for="text" class="col-sm-3 col-form-label">Contact Support</label>
          <div class="col-sm-9 mt-2">
           <input type="text" class="form-control" formControlName="support" required
            pattern="^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$">

           <div
            *ngIf="contactForm.controls.support.invalid && (contactForm.controls.support.dirty || contactForm.controls.support.touched)"
            class="error-message">
            <div *ngIf="contactForm.controls.support.errors?.['required']">
             <p class="text-danger m-0 p-0"><small>Email address is required</small></p>
            </div>
            <div *ngIf="contactForm.controls.support.errors?.['pattern']">
             <p class="text-danger m-0 p-0"><small>Enter valid email address</small></p>
            </div>
           </div>
          </div>
         </div>
      
         <div class="text-end mb-4">
          <button type="button" class="comman-btn1 btn-primary" (click)="submitContactForm()"
           [disabled]="!contactForm.valid">Submit</button>
         </div>
        </div>
       </div>
      </div>
     </div>
    </form> -->
        <!-- -----end------------- -->
        <!-- <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body py-0 px-0">
              <p class="fs-5 setting-logo p-1 text-light"><i class="bi bi-envelope-fill"></i>
                Send Block Status Email</p>
              <p class="text-secondary sub-text">This email will be used by your users to contact you.</p>
              <div class="contact-email">
                <div class="row">
                  <div class="col-12">
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                      <label for="flexCheckDefault">
                        Send expiry email notification
                      </label>
                    </div>
                  </div>
            
                  <div class="col-12">
                    <p>Send accounts expiry email notifications to:</p>
                  </div>
                  <div class="col-12">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                      <label for="flexRadioDefault1">
                        All admins
                      </label>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1">
                      <label for="flexRadioDefault1">
                        Specific admins
                      </label>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="col-md-6">
                     
                      <select class="form-select" id="validationServer04" aria-describedby="validationServer04Feedback">
                        <option selected disabled value="">All</option>
                        <option>User 1</option>
                        <option>User 2</option>
                        <option>User 3</option>
                      </select>

                    </div>
                  </div>
                  <div class="col-12">                   
                    <div class="form-check">
                      <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                      <label for="flexCheckDefault">
                        Send Users Expiry Email Notification
                      </label>
                    </div>
                  </div>
                  
                  <div class="text-end mb-4">
                    <button type="button" class="comman-btn1 btn-primary">Save</button>
                  </div>
                </div>

              </div>
            </div>
          </div>

          <!- ------------------ -->
      </div>
    </div>
  </div>
</div>