import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { WalletService } from 'src/app/services/wallet.service';

@Component({
  selector: 'app-change-plan',
  templateUrl: './change-plan.component.html',
  styleUrls: ['./change-plan.component.scss']
})
export class ChangePlanComponent implements OnInit {

  plan: any = [];
  customerDetail: any;
  subscription: any

  @Input() customerId!: string;
  @Input() subscriptionId!: string;

  event: EventEmitter<any> = new EventEmitter();

  constructor(
    public bsModalRef: BsModalRef,
    private subscriptionService: SubscriptionsService,
    private toastr: ToastrService,
    private walletService: WalletService
  ) { }

  deleteSubForm = new FormGroup({
    remark: new FormControl('', Validators.required)
  })

  ngOnInit(): void {
    setTimeout(() => {
      this.subscriptionDetail()
    });
  }

  creditPlanPrice: any = 0;
  creditAddOnPrice: any = 0;

  subscriptionDetail() {
    this.subscriptionService.subscriptionDetails(this.subscriptionId).subscribe((res: any) => {
      if (res.success) {
        const subscription = this.subscription = res.subscription
        const isPaidSub = subscription.plan.unitPrice > 100
        const planDays = (subscription.plan.billingCycle === "week") ? 7 :
          (subscription.plan.billingCycle === "month") ? 30 : 365;
        const currentDate = moment().format('YYYY-MM-DD');
        const assignDate = subscription.activate ? moment((subscription.lastRenewDate === null) ? subscription.startDate : subscription.lastRenewDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
        const paidPlanDays = isPaidSub ? moment(currentDate).diff(subscription.paidSubDate, 'days') : 0
        let totalDays = moment(currentDate).diff(moment(assignDate), 'days');

        if (paidPlanDays <= planDays) {
          totalDays = subscription.isTrial ? (totalDays + 7) : totalDays
        }

        if (subscription.plan.price != null) {
          const planOneDayPrice = (subscription.plan.unitPrice / planDays) / subscription.plan.billEvery;
          this.creditPlanPrice = (subscription.plan.noOfUsers * subscription.plan.unitPrice) - (planOneDayPrice * totalDays * subscription.plan.noOfUsers);
        }

        if (subscription.addOn && subscription.addOn.price != null) {
          const addOnOneDayPrice = (subscription.addOn.price / planDays) / subscription.plan.billEvery;
          this.creditAddOnPrice = (subscription.addOn.price * subscription.addOn.quantity) - (addOnOneDayPrice * totalDays * subscription.addOn.quantity);
        }

      }
    })
  }

  removeSubscription() {
    if (this.deleteSubForm.valid) {
      const remarkByUser = this.deleteSubForm.value.remark || ''
      const remark = JSON.stringify(remarkByUser.replace(/[&?]/g, ''));
      this.subscriptionService.deleteSubscription(this.subscription._id, remark).subscribe((res: any) => {
        if (res.success) {
          this.event.emit('ok');
          this.walletService.showWalletAmount()
          this.closedModal();
          this.toastr.info('Subscription removed')
        } else {
          this.toastr.info(res.message)
          this.closedModal();
        }
      },
        (error) => {
          this.toastr.error(error.error.message)
        }
      )
    } else {
      this.deleteSubForm.markAllAsTouched()
    }

  }

  closedModal() {
    this.bsModalRef.hide();
  }

}
