import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  private subId: any;

  constructor(
    private http: HttpClient
  ) { }


  getSubscriptionReport(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/partner/dashboard/sub`, payload);
  }

  getSubscriptionTrialReport(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/partner/dashboard/trial`, payload);
  }

  getSubscriptionRenewalSummary(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/partner/dashboard/renewal`, payload);
  }

  getRiskySubscriptionReport(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/partner/dashboard/risky`, payload);
  }

  getPaidSubscriptionReport(payload: any): Observable<any> {
    return this.http.post<any>(`${environment.apiURL}/partner/dashboard/paid`, payload);
  }


  setData(subId: string) {
    this.subId = subId;
  }

  getSubId(): string {
    return this.subId;
  }

  releaseData(): any {
    this.subId = []
  }
}
