import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionListRoot } from 'src/app/interfaces/subscription/list';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-subscription-select',
  templateUrl: './subscription-select.component.html',
  styleUrls: ['./subscription-select.component.scss'],
})
export class SubscriptionSelectComponent implements OnInit {
  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private subscriptionService: SubscriptionsService,
    private user: UserService
  ) { }

  @Input() customerId!: string;
  @Input() mobile!: string;
  @Input() email!: string;
  @Input() domainName!: string;
  subscriptionList: any;

  ngOnInit() {
    setTimeout(() => {
      this.allSubscriptions();
    });
  }

  allSubscriptions() {
    this.subscriptionService.subscriptionList(this.customerId, {}, 1, 10, '').subscribe((res: SubscriptionListRoot) => {
      if (res.success) {
        this.subscriptionList = res.subscriptions.filter(subscription => {
          if (!subscription.isDeleted) {
            return subscription;
          }
        });
      }
    });
  }

  customerPortal(subscriptionId: string) {
    console.log('Clicked');
    const payload = { email: this.email, subscriptionId: subscriptionId };
    this.user.loginWithPartner(payload).subscribe((res: any) => {
      if (res.success) {
        const url = this.domainName
          ? `http://${this.domainName}/loginWithPartner?token=${res.user.token} `
          : `http://staging.cocloud.in/loginWithPartner?token=${res.user.token}`;
        window.open(url, '_blank');
        this.bsModalRef.hide();
      }
    });
  }
}
