import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-subscription-preview',
  templateUrl: './subscription-preview.component.html',
  styleUrls: ['./subscription-preview.component.scss']
})
export class SubscriptionPreviewComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef,
    private toastr : ToastrService
    ) { }

  ngOnInit(): void {
  }

 

}
