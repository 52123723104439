<!-- ---------change--plan--price--modal------------------ -->
<div class="px-4">
    <h4 class="modal-title pull-left mb-2"><b>Upgrade/Downgrade plan({{code |uppercase}})</b></h4>
    <form action="" [formGroup]="planUpgradeDowngradeForm">
        <div class="row mb-2">
            <label for="fullName" class="col-sm-4 col-form-label">Previous Plan</label>
            <div class="col-sm-8">
                <input type="text" placeholder="Plan Name" class="form-control" formControlName="previousPlan" readonly>
            </div>
        </div>
        <div class="row mb-2">
            <label for="fullName" class="col-sm-4 col-form-label">Select New Plan</label>
            <div class="col-sm-8">
                <select class="form-select form-control mt-2" aria-label="Default select example" id="planId"
                    formControlName="planId">
                    <option value="" selected>Open this select menu</option>
                    <option *ngFor="let plan of plans" [value]="plan._id">{{plan?.name}}</option>
                </select>
            </div>
        </div>
        <div class="row mb-3">
            <label for="fullName" class="col-sm-4 col-form-label">Unit Price</label>
            <div class="col-sm-8 mt-2">
                <input type="number" placeholder="Enter Unit Price" class="form-control" formControlName="unitPrice"
                    min="0" readonly
                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
            </div>
        </div>

        <div class="row mb-3">
            <label for="fullName" class="col-sm-4 col-form-label">No. Of Users</label>
            <div class="col-sm-8 mt-2">
                <input type="text" placeholder="Enter No. Of Users" class="form-control" formControlName="noOfUsers"
                    readonly>
                <div *ngIf="planUpgradeDowngradeForm.get('noOfUsers')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="planUpgradeDowngradeForm.get('noOfUsers')?.errors?.['required'] && (planUpgradeDowngradeForm.get('noOfUsers')?.dirty || planUpgradeDowngradeForm.get('noOfUsers')?.touched)">
                        <p class="text-danger m-0 p-0"><small>it's a mandatory field</small></p>
                    </div>
                    <div class="error-message"
                        *ngIf="planUpgradeDowngradeForm.get('noOfUsers')?.errors?.['min'] && (planUpgradeDowngradeForm.get('noOfUsers')?.dirty || planUpgradeDowngradeForm.get('noOfUsers')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Value must be greater than 0</small></p>
                    </div>
                </div>
            </div>
        </div>


        <div class="row mb-3">
            <label for="fullName" class="col-sm-4 col-form-label">Price</label>
            <div class="col-sm-8 mt-2">
                <input type="number" placeholder="Enter Price" class="form-control" formControlName="price" readonly
                    min="0"
                    onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
            </div>
        </div>
        <div class="row mb-2">
            <label for="fullName" class="col-sm-4 col-form-label">Select User</label>
            <div class="col-sm-8">
                <select class="form-select form-control mt-2" id="salesPersonName" formControlName="salesPersonName">
                    <option value="direct" selected (click)="salesPersons('direct')">Direct</option>
                    <option value="support" (click)="salesPersons('support')">Support</option>
                    <option *ngFor="let salesPerson of salesPersonList" (click)="salesPersons(salesPerson.name)" [value]="salesPerson.name">
                        {{salesPerson?.name}}
                    </option>
                </select>
            </div>
        </div>
    </form>
    <p>Return Amount: <span class="text-success">{{previousPlanCost | number :"1.0-2"}}</span></p>
    <p>Current plan charges: <span class="text-danger">{{currentPlanCost | number :"1.0-2"}}</span></p>
    <p class="plan-font">Plan charges On Renewal: <span class="">{{currentTotalPrice | number :"1.0-2"}}</span></p>

    <div class="text-end">
        <button type="submit" class="comman-btn1 btn-primary me-2" (click)="submit()">Submit</button>
        <button type="button" class="comman-btn1 btn-secondary text-light" (click)="closeModal()">Cancel</button>
    </div>
</div>