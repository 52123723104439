import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/services/auth.service';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss']
})
export class LogoutComponent implements OnInit {

  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }
  closedModal() {
    this.bsModalRef.hide();
  }

  logout() {
    this.authService.logout();
    this.closedModal();
  }
}
