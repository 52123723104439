<!-- ---------------Audit Logs---------------- -->
<div class="container-scroller">
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            <h3 class="heading">Audit Logs:</h3>
                        </div>
                        <div class="row ">
                            <div class="col-md-3 col-sm-12 mb-3">
                                <input type="text" class="form-control" id="searchField" placeholder="search here"
                                    [(ngModel)]="searchText" [ngModelOptions]="{standalone: true}"
                                    (keyup)="searchVMLogs($event)">
                            </div>
                        </div>

                        <!-- -----end----- -->
                    </div>
                </div>
                <!-- ------------------ -->
                <div class="row table-section pe-0">
                    <div class="col-md-12 grid-margin stretch-card pe-0">
                        <div class="card">
                            <div class="card-body">
                               
                                <div class=" mb-4 records">{{updatePaginationText}}</div>
                                <div *ngIf="!isAuditLog" class="text-center">
                                    <h4>There is no audit log created yet</h4>
                                </div>
                                <div class="table-responsive" *ngIf="isAuditLog">
                                    
                                    <table class="table table-striped table-borderless">
                                        <thead class=" table-header">
                                            <tr>
                                                <th scope="col">#Id</th>
                                                <th scope="col">Date & Time</th>
                                                <th scope="col">Name</th>
                                                <th scope="col">Email</th>
                                                <th scope="col">Task Type</th>
                                                <th scope="col">URL Endpoint</th>
                                                <th scope="col">API Response</th>
                                            </tr>
                                        </thead>
                                        <tbody >
                                            <tr *ngFor="let auditLog of auditLogs| paginate: config">
                                                <td>{{auditLog.id}}</td>
                                                <td>{{auditLog?.createdAt| date : "dd/MM/yyyy, hh:mm a":'UTC +05:30'}}
                                                </td>
                                                <td class="textWrap">{{auditLog?.userInfo?.name | titlecase}}</td>
                                                <td class="textWrap">{{auditLog?.userInfo?.email |titlecase}}</td>
                                                <td class="textWrap"
                                                    [ngClass]="(auditLog?.method==='create' )? 'text-warning': (auditLog?.method==='update' )?'text-primary':(auditLog?.method==='find' )?'text-success':'text-danger'">
                                                    {{auditLog?.method |titlecase}}</td>
                                                <td class="textWrap">{{ auditLog?.url?.length > 50 ? (auditLog?.url |
                                                    slice:0:50) + '...' : auditLog?.url }}</td>
                                                <td class="textWrap">{{auditLog?.response |titlecase}}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class=" mt-4 records">{{updatePaginationText}}</div>
                                <div class="float-end me-4" *ngIf="isAuditLog">
                                    <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- ----------- -->
            </div>
        </div>
    </div>