import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SalesmanService } from 'src/app/services/salesman.service';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { RaiseRequestService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent implements OnInit {
  typeOfOrder!: any
  addOnsList!: any
  subCodes!: any
  subCodeSelected: boolean = false
  selectedSub: String = ""
  customerList!: any[];
  productList!: any[];
  planList!: any[];
  addOnList!: any[];
  salesmanData: any = []
  planName!: string;
  addOnName!: string;
  planBillCycle!: string;
  planBillEvery!: number;
  planCode!: string;
  addOnPriceEvery!: any
  unitAddOn!: any
  getDetails!: any
  update !: any
  vmCodes!: any
  isUpdate: boolean = false
  getVmDetails!: any
  addOnForm = new FormGroup({
    subId: new FormControl('', [Validators.required]),
    name: new FormControl('', Validators.required),
    price: new FormControl(0, Validators.required),
    quantity: new FormControl(1, Validators.required),
    description: new FormControl('', Validators.required),
    remark: new FormControl('', Validators.required),
    searchText: new FormControl(''),
  })

  subscriptionForm = new FormGroup({
    customerId: new FormControl('', Validators.required),
    customerEmail: new FormControl('', Validators.required),
    productName: new FormControl(''),
    planName: new FormControl(''),
    planDescription: new FormControl(''),
    planQty: new FormControl(''),
    planUnitPrice: new FormControl(''),
    planAmount: new FormControl(''),
    salesmanId: new FormControl(''),
    ram: new FormControl(''),
    cpu: new FormControl(''),
    storage: new FormControl(''),
    subId: new FormControl('', [Validators.required]),
    searchText: new FormControl(''),
    subSearch: new FormControl('')
  })

  constructor(
    private activatedRoute: ActivatedRoute,
    private raiseRequestService: RaiseRequestService,
    private salesmanService: SalesmanService,
    private subscriptionService: SubscriptionsService,
    private toastr: ToastrService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.typeOfOrder = params['type']
      this.update = params['update']
      if (this.typeOfOrder == 'addOn') {
        this.subList()
        this.showAddonList()
      }
      else {
        if (this.update) {
          this.isUpdate = true
          this.getAllVmCodes()
          this.subscriptionForm.get('productName')?.valueChanges.subscribe((value: any) => {
            this.selectedProduct()
          })
        }
        this.subscriptionForm.get('planUnitPrice')?.valueChanges.subscribe((value) => {
          this.subscriptionForm.get('planAmount')?.setValue(value)
        })
        this.getAccountList()
        this.salesmanList()
        this.showProductList()
      }
    })
  }

  subscriptionDetails(value: any) {
    this.subscriptionService.subscriptionDetails(value).subscribe((res: any) => {
      if (res.success) {
        this.getDetails = res?.subscription?.addOn
        const { description, name, price, quantity } = this.getDetails;
        this.addOnForm.patchValue({ description, name, price, quantity });
      }
    },
      (error) => {
        this.toastr.warning(error.error.message);
      }
    );
  }


  showAddonList() {
    this.subscriptionService.getAddOnList().subscribe((res: any) => {
      if (res.success) {
        this.addOnList = res?.addOns
      }
    })
  }


  subList() {
    this.raiseRequestService.ticketSubList(true).subscribe((res: any) => {
      if (res.success) {
        this.subCodes = res.subCodes
      }
    })
  }

  showProductList() {
    this.subscriptionService.getProductList().subscribe((res: any) => {
      if (res.success) {
        this.productList = res.virtualProductList;
      }
    })
  }


  getAccountList() {
    this.subscriptionService.customerEmailList().subscribe((res: any) => {
      this.customerList = res.customers
    })
  }

  selectedSubId(subId: string, code: string) {
    this.subCodeSelected = true
    this.selectedSub = code
    this.subscriptionDetails(this.selectedSub)
    this.addOnForm.controls.subId.setValue(subId)
  }

  salesmanList() {
    this.salesmanService.salesmanList().subscribe((res: any) => {
      if (res.success) {
        this.salesmanData = res.salesPerson
      }
    })
  }

  selectedCustomer(email: string, customerId: string) {
    this.subscriptionForm.controls.customerEmail.setValue(email)
    this.subscriptionForm.controls.customerId.setValue(customerId)
  }

  selectedProduct(): void {
    const productname = this.subscriptionForm.get('productName')?.value;
    this.planList = this.productList.find((product: any) => product.name === productname).plans;
  }

  selectedPlan() {
    let defaultQuantity: any = 1;
    let unitPrice: any;
    let amount: any;
    const planName = this.subscriptionForm.get('planName')?.value
    const data = this.planList.find((item: any) => item.name == planName)
    unitPrice = data?.price;
    amount = unitPrice * defaultQuantity;
    this.subscriptionForm.controls.planDescription.setValue(data?.name)
    this.subscriptionForm.controls.planDescription.setValue(data?.description)
    this.subscriptionForm.controls.planQty.setValue(defaultQuantity)
    this.subscriptionForm.controls.planUnitPrice.setValue((data?.price).toFixed(2))
    this.subscriptionForm.controls.planAmount.setValue(amount.toFixed(2))
  }

  planAmount() {
    let unitPrice: any;
    let quantity: any;
    let amount: any;
    unitPrice = this.subscriptionForm.controls.planUnitPrice.value;
    quantity = this.subscriptionForm.controls.planQty.value;
    amount = unitPrice * quantity;
    this.subscriptionForm.controls.planAmount.setValue(amount.toFixed(2));
  }

  addonAmount() {
    let unitPrice: any;
    let quantity: any;
    let amount: any;
    unitPrice = this.addOnPriceEvery;
    quantity = this.addOnForm.controls.quantity.value;
    amount = unitPrice * quantity;
    this.addOnForm.controls.price.patchValue(amount.toFixed(2));
  }
  submit() {
    if (this.typeOfOrder == 'addOn') {
      this.createAddon()
    }
    else {
      this.createSubscription()
    }
  }

  createSubscription() {
    const subscriptionFormValues = this.subscriptionForm.value
    if (
      !subscriptionFormValues.planName ||
      !subscriptionFormValues.planUnitPrice ||
      !(subscriptionFormValues.customerEmail && subscriptionFormValues.customerId) ||
      !subscriptionFormValues.planDescription || !subscriptionFormValues.salesmanId || !subscriptionFormValues.cpu || !subscriptionFormValues.storage || !subscriptionFormValues.ram
    ) {
      this.subscriptionForm.markAllAsTouched();
      this.toastr.error("Please fill all the required fields")
      return
    }
    const planName = this.subscriptionForm.get('planName')?.value
    const planDetails = this.planList.find((item: any) => planName == item.name)
    let payload = {
      customerId: subscriptionFormValues.customerId,
      salesmanId: subscriptionFormValues.salesmanId,
      customerEmail: subscriptionFormValues.customerEmail,
      productName: subscriptionFormValues.productName,
      reqType: this.typeOfOrder,
      subId: subscriptionFormValues.subId,
      plan: {
        name:subscriptionFormValues.planName,
        description: subscriptionFormValues.planDescription,
        billingCycle: planDetails.billingCycle,
        price: subscriptionFormValues.planAmount,
        unitPrice: subscriptionFormValues.planUnitPrice,
        noOfUsers: subscriptionFormValues.planQty,
        billEvery: planDetails.billEvery,
        code:planDetails.code,
        cpu: subscriptionFormValues.cpu,
        storage: subscriptionFormValues.storage,
        ram: subscriptionFormValues.ram
      },

    }
    this.subscriptionService.createSubManagement(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message)
        this.router.navigate(['/order-management'])
      }
    },(err)=>{
      this.toastr.error(err.error.message)
    })
  }


  selectedAddOn() {
    const addOnName = this.addOnForm.get('name')?.value
    const addOnData = this.addOnList.find((addon: any) => addon.name == addOnName)
    this.addOnPriceEvery = addOnData.price
    this.unitAddOn = addOnData.unit
    this.addOnForm.controls.description.setValue(addOnData.description)
    this.addOnForm.controls.price.patchValue(addOnData.price)
  }


  createAddon() {
    const formValues = this.addOnForm.value
    if (
      !formValues.name ||
      !formValues.price ||
      !formValues.description || !formValues.remark || !formValues.quantity || !formValues.subId
    ) {
      this.addOnForm.markAllAsTouched();
      this.toastr.error("Please fill all the required fields")
      return
    }
    const payload = {
      addOn: {
        name: formValues.name,
        price: formValues.price,
        description: formValues.description,
        remarks: formValues.remark,
        quantity: formValues.quantity,
        unit: this.unitAddOn
      },
      reqType: this.typeOfOrder,
      subId: formValues.subId,
    }
    this.subscriptionService.createSubManagement(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message)
        this.router.navigate(['/order-management'])
      }
    },(err)=>{
      this.toastr.error(err.error.message)
    })
  }


  getAllVmCodes() {
    this.subscriptionService.getVmCodes().subscribe((res: any) => {
      if (res.success) {
        this.vmCodes = res.subCodes
      }
    })
  }


  selectedVmSubId(subId: string, code: string) {
    this.subCodeSelected = true
    this.selectedSub = code
    this.subscriptionVMDetails(this.selectedSub)
    this.subscriptionForm.controls.subId.setValue(subId)
  }


  subscriptionVMDetails(value: any) {
    this.subscriptionService.subscriptionDetails(value).subscribe((res: any) => {
      if (res.success) {
        this.getVmDetails = res?.subscription
        this.subscriptionForm.patchValue({
          customerId: this.getVmDetails?.customerId,
          customerEmail: this.getVmDetails?.customerEmail,
          productName: this.getVmDetails?.productName,
          planName: this.getVmDetails?.plan?.name,
          planDescription: this.getVmDetails?.plan?.description,
          planUnitPrice: this.getVmDetails?.plan?.unitPrice,
          planQty: this.getVmDetails?.plan?.noOfUsers,
          salesmanId: this.getVmDetails?.salesPerson?.id,
          planAmount: this.getVmDetails?.plan?.price,
          cpu: this.getVmDetails?.plan?.cpu,
          storage: this.getVmDetails?.plan?.storage,
          ram: this.getVmDetails?.plan?.ram
        });
      }
    },
      (error) => {
        this.toastr.warning(error.error.message);
      }
    );
  }


  get subscriptionSearchText() {
    return this.subscriptionForm.controls.searchText.value || '';
  }

  get selectedEmail() {
    return this.subscriptionForm.controls.customerEmail.value || '';
  }

  get searchText() {
    return this.addOnForm.controls.searchText.value || '';
  }
  get searchSub() {
    return this.subscriptionForm.controls.subSearch.value || ''
  }
  cancel() {
    this.router.navigate(['/order-management'])
  }
}
