<!-- -----branding ----page ---start------>
<div class="container-scroller">
 <div class="main-panel">
  <div class="content-wrapper">
   <div class="row">
    <div class="col-md-12 grid-margin">
     <div class="row">
      <div class="col-12 mb-4 heading-section">
       <h3 class="font-weight-bold"><i class="bi bi-arrow-left" [routerLink]="'/plan'"></i> {{isEdit? 'Update Plan': 'Add Plan'}}</h3>
       <!-- <h5 class="font-weight-bold mb-4"></h5> -->
       <!-- ----------section----------------->
       <form action="" [formGroup]="planForm">
        <div class="card main-card rounded-0 p-4">
         <h6 class="text-danger">CREATE NEW PLAN - STEP 1 OF 4</h6>
         <h3 class="mb-4 mt-4">Select Plan Type</h3>
         <div class="row">
          <div class="col-md-4">
           <div class="card plan-section pt-4 plan" (click)="togglePlan('homePlan')"
            [ngClass]="selectedPlanType==='homePlan'? 'plan': 'noPlanSelect' ">
            <i class="bi bi-house-door-fill"></i>
            <input class="form-check-input planChoose" type="radio" name="planType" id="flexRadioDefault1"
             formControlName="planType">
             <div>
            <p class="form-check-label plan-font fs-4 mt-2" for="flexRadioDefault1">
             Home Plan
            </p>
             </div>
            <!-- <h3 formControlName="planType">Home Plan</h3> -->
           </div>
          </div>
          <div class="col-md-4">
           <div class="card plan-section pt-4" (click)="togglePlan('businessPlan')"
            [ngClass]="selectedPlanType==='businessPlan'? 'plan': 'noPlanSelect' ">
            <i class="bi bi-building"></i>
            <!-- <input type="radio" formControlName="planType" value="businessPlan"> -->
            <input class="form-check-input planChoose" type="radio" name="planType" id="flexRadioDefault1"
             formControlName="planType">
             <div>
            <p class="form-check-label plan-font fs-4 mt-2" for="flexRadioDefault1">
             Business Plan
            </p>
             </div>
           </div>
          </div>
          <div class="col-md-4">
           <div class="card plan-section pt-4" (click)="togglePlan('deepFreezePlan')"
            [ngClass]="selectedPlanType==='deepFreezePlan'? 'plan': 'noPlanSelect' ">
            <i class="bi bi-asterisk"></i>
            <!-- <input type="radio" formControlName="planType" value="deepFreezePlan"> -->
            <input class="form-check-input planChoose" type="radio" name="planType" id="flexRadioDefault1"
             formControlName="planType">
             <div>
            <p class="form-check-label plan-font fs-4 mt-2" for="flexRadioDefault1">
             Deep Freeze Plan
            </p>
             </div>
            <!-- <p>Optimal solution for business archival purposes.</p> -->
           </div>
          </div>
         </div>
         <!-- -----second--section----------- -->
         <div class="row mt-4">
          <div class="col-md-12">
           <h3 class="mb-4">Create Plan</h3>
          </div>
          <div class="col-md-8">
           <!-- ---------------- -->
           <div class="row mb-3">
            <label for="fullName" class="col-sm-6 col-form-label">Plan Name</label>
            <div class="col-sm-6 mt-2">
             <input type="text" class="form-control" id="fullName" formControlName="name" required>
             <div *ngIf="planForm.controls.name.invalid && 
             (planForm.controls.name.dirty || planForm.controls.name.touched) "
             class="error-message">
             <div *ngIf="(planForm.controls.name.errors?.['required']) ">
              <p class="text-danger m-0 p-0"><small>It is mandatory field</small></p>
             </div>
             <div *ngIf="planForm.controls.name.errors?.['minlength'] ">
              <p class="text-danger m-0 p-0"><small>Plan name cannot be empty</small>
              </p>
             </div>
            </div>
            </div>
           </div>
           <div class="row mb-3">
            <label for="fullName" class="col-sm-6 col-form-label">Price</label>
            <div class="col-sm-6 mt-2">
             <input type="number" class="form-control" id="fullName" formControlName="price" required>
             <div *ngIf="planForm.controls.name.invalid && 
             (planForm.controls.price.dirty || planForm.controls.price.touched) "
             class="error-message">
             <div *ngIf="(planForm.controls.price.errors?.['required']) ">
              <p class="text-danger m-0 p-0"><small>It is mandatory field</small></p>
             </div>
            </div>
            </div>           
           </div>
           <!-- <div class="row mb-3">
                                                <label for="fullName" class="col-sm-6 col-form-label">Expiry
                                                    Date</label>
                                                <div class="col-sm-6 mt-2">
                                                    <input type="date" class="form-control" id="fullName"
                                                        formControlName="expiryDate" required>
                                                </div>
                                            </div> -->
           <div class="row mb-2">
            <div class="col-sm-6 col-form-label">
             <label class="form-check-label mt-1 " for="flexCheckDefault">
              Users
             </label>
            </div>
            <div class="col-sm-6 mt-2">
             <div class="d-flex"><button type="button" class="minus-value">-</button>
              <input type="number" class="form-control" id="fullName" formControlName="noOfUsers" required>
              <button type="button" class="add-value">+</button>
              
             </div>
             <div
              *ngIf="planForm.controls.noOfUsers.invalid && 
               (planForm.controls.noOfUsers.dirty || planForm.controls.noOfUsers.touched) "
              class="error-message">
              <div *ngIf="(planForm.controls.noOfUsers.errors?.['required']) ">
               <p class="text-danger m-0 p-0"><small>It is mandatory field</small></p>
              </div>
             </div>

            </div>
           </div>
           <!-- --------------- -->
           <!-- --------------- -->
          </div>
          <!-- -----side--section -->
          <!-- <div class="col-md-4 mt-2">
                                            <div class="summary-section">
                                                <h4 class="text-center">Summary</h4>
                                                <ul>
                                                    <li>Hot Storage :<span class="float-end">$0.180</span></li>
                                                    <li>Cold Storage :<span class="float-end"> $0.000</span></li>
                                                    <li>Video Streaming :<span class="float-end">$0.034</span></li>
                                                    <li>Mobile Backup :<span class="float-end">$0.000</span></li>
                                                    <li>AI Actions :<span class="float-end">$0.016</span></li>
                                                    <li>SaaS Backup :<span class="float-end"> Free</span></li>
                                                    <li>Users :<span class="float-end">$0.338</span></li>
                                                    <li>Workstation Disaster</li>
                                                    <li>Recovery :<span class="float-end">$0.000</span></li>
                                                    <li>Unlimited external</li>
                                                    <li>storage support :<span class="float-end">Free</span></li>
                                                </ul>
                                                <div class="plan-cost">
                                                    <h3>Plan Cost</h3>
                                                    <h3><b>$0.568</b></h3>
                                                    <p>Please note that the system will only deduct this amount when you
                                                        assign this plan to an account</p>
                                                </div>
                                            </div>
                                        </div> -->
          <!-- -------end--------- -->
         </div>
         <!-- -----------end----------------- -->
         <!-- ---------third--section-------- -->
         <div class="row">
          <div class="col-md-12 mt-4">
           <h3 class="mb-1">Select The Frequency of The User Plan's Subsciption</h3>
          </div>
          
          <div class="col-md-12 d-flex col-form-label">
           <div class="form-check ms-4">

            <input *ngIf="isEdit" class="form-check-input" type="radio" 
                formControlName="planDuration" name="planDuration"
                 id="flexRadioDefault1" value="month" [checked]="isEdit && isMonthly">
             <input *ngIf="!isEdit" class="form-check-input" type="radio" formControlName="planDuration" name="planDuration"
             id="flexRadioDefault1" value="month" [checked]="isEdit && isMonthly" required>

            <label class="form-check-label m-0" for="flexRadioDefault1">
             Monthly
            </label>
           </div>
           <div class="form-check ms-5">

            <input *ngIf="isEdit" class="form-check-input" type="radio" formControlName="planDuration" name="planDuration"
             id="flexRadioDefault2" value="year" [checked]="isEdit && isYearly">
             <input *ngIf="!isEdit" class="form-check-input" type="radio" formControlName="planDuration" name="planDuration"
             id="flexRadioDefault2" value="year" [checked]="isEdit && isYearly" required>
            <label class="form-check-label m-0" for="flexRadioDefault2">
             Yearly
            </label>
           </div>

          </div>
          <div class="col-md-12 text-end">
            <button type="button" class="comman-btn1 btn-primary me-2" [disabled]="!planForm.valid"
            (click)="submit()">{{isEdit? 'Update': 'Submit'}} </button>
           <button type="button" class="comman-btn1 btn-secondary text-light "
            routerLink="/plan">Cancel</button>           
          </div>
         </div>
         <!-- --------------end-------------- -->
        </div>
       </form>
       <!-- -------------end-------------- -->
      </div>
     </div>
    </div>
   </div>
  </div>

  <!-- -------------end-------------- -->
 </div>
</div>