import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { PaymentService } from 'src/app/services/payment.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-verify-payment',
  templateUrl: './verify-payment.component.html',
  styleUrls: ['./verify-payment.component.scss']
})
export class VerifyPaymentComponent implements OnInit {

  message = "Direct access not allowed for this url."
  waitMessage: boolean = true
  success: boolean = false;
  failed: boolean = false;
  pending: boolean = false;
  redirectUrl: string = environment.redirectUrl
  paymentDetail: any
  notAuthrised: boolean = true
  constructor(
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private router: Router
  ) { }

  ngOnInit(): void {
    const orderId = this.route.snapshot.queryParamMap.get('orderId');
    if (orderId) {
      this.notAuthrised = false
      this.paymentStatusCheck(orderId)
    } else {
      setTimeout(() => {
        this.router.navigate(['/payment'])
      });
    }
  }

  paymentStatusCheck(orderId: string) {
    this.paymentService.paymentStatusCheck(orderId).subscribe((res: any) => {
      if (res.success) {
        this.paymentDetail = res.paymentData
        this.waitMessage = false;
        const status = res.paymentData.order_status
        this.failed = (status === "Unsuccessful" || status === "Invalid" ||
          status === "Cancelled" || status === "Timeout" || status === "Aborted")
        this.success = (status === "Successful" || status === "Shipped")
        this.pending = (status === "Initiated" || status === "Awaited")
      } else {
        this.notAuthrised = true
        this.message = res.message
        setTimeout(() => {
          this.router.navigate(['/payment'])
        });
      }
    })
  }
}
