import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BrandingRoot, SettingRoot } from 'src/app/interfaces/branding';
import { BrandingService } from 'src/app/services/branding.service';
import { WalletService } from 'src/app/services/wallet.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styleUrls: ['./branding.component.scss']
})
export class BrandingComponent implements OnInit {

  partnerType: any = '';

  ngOnInit() {
    this.walletService.partnerTypeSubject.subscribe((res: any) => {
      this.partnerType = res;
    })
    this.showBrandDetails()
    this.contactDetails()

  }

  brandIp = environment.brandingIP;

  constructor(private router: Router,
    private brand: BrandingService,
    private toastr: ToastrService,
    private walletService: WalletService,
    private brandingService: BrandingService,
  ) { }

  brandingForm = new FormGroup({
    domainName: new FormControl('', Validators.required),
    brandName: new FormControl('', Validators.required),
    senderEmail: new FormControl('', Validators.required),
    senderName: new FormControl('', Validators.required),
    logo: new FormControl(''),
    // favicon : new FormControl(''),
    contactSupport: new FormControl('', Validators.required),
    onlineHelp: new FormControl('', Validators.required),
    aboutProduct: new FormControl('', Validators.required),
    termsOfService: new FormControl('', Validators.required),
    productAgreement: new FormControl('', Validators.required),
    privacyPolicy: new FormControl('', Validators.required),
    thirdPartyLicense: new FormControl('', Validators.required),
    dataTerms: new FormControl(''),
    dataProcessingAgreement: new FormControl(''),
    sales: new FormControl(''),
    support: new FormControl(''),
    isGoogleDriveShowToUser: new FormControl<boolean>(false)
  })


  dName!: string;
  brandName!: boolean;
  emailVerify: string = 'default';
  dnsVerify: boolean = false;
  brandDatas: any
  textBoxDisabled = false;
  textBoxDisabled2 = false;
  countCheck: number = 1;
  countCheckToc: number = 1;

  contactDetails() {
    this.brandingService.getSettingData().subscribe((res: any) => {
      if (res.success) {
        this.brandingForm.controls.sales.patchValue(res?.partner?.contact?.sales)
        this.brandingForm.controls.support.patchValue(res?.partner?.contact?.support)
        this.brandingForm.controls.logo.patchValue(res?.partner?.logo)
      }
    })
  }

  submitContactForm() {
    if (this.partnerType == 'white label') {
      const payload = {
        sales: this.brandingForm.controls.sales.value,
        support: this.brandingForm.controls.support.value
      }

      if (this.brandingForm.controls.sales.value === '') {
        this.brandingForm.controls.sales.markAsTouched()
      }

      if (this.brandingForm.controls.support.value === '') {
        this.brandingForm.controls.support.markAsTouched()
      }

      if (this.brandingForm.controls.sales.valid && this.brandingForm.controls.support.valid) {
        this.brandingService.submitSettingData(payload).subscribe((res: SettingRoot) => {
          if (res.success) {
            this.toastr.info("Contact info saved successfully")
          }
        })
      }
    } else {
      this.toastr.warning("Please contact your account manager to enable white label.")
    }

  }

  submitLogo() {
    if (this.partnerType == 'white label') {
      const payload = {
        logo: this.brandingForm.controls.logo.value
      }

      if (this.brandingForm.controls.logo.value === '') {
        this.brandingForm.controls.logo.markAsTouched()
      }
      else {
        this.brandingService.submitSettingData(payload).subscribe((res: any) => {
          if (res.success) {
            this.walletService.showWalletAmount()
            this.toastr.info("Link saved successfully")
          }
        })
      }

    } else {
      this.toastr.warning("Please contact your account manager to enable white label.")
    }

  }


  toggle() {
    this.textBoxDisabled = !this.textBoxDisabled;
  }

  toggle2() {
    this.textBoxDisabled2 = !this.textBoxDisabled2;
  }

  enableBrandField() {
    if (this.partnerType == 'white label') {
      if (this.countCheck === 1) {
        this.brandName = !this.brandName
      }
      this.countCheck++;
    } else {
      this.toastr.warning("Please contact your account manager to enable white label.")
    }

  }

  logoUrl!: string;

  showBrandDetails() {
    this.brand.showDetails().subscribe((res: BrandingRoot) => {
      if (res.success) {
        this.brandDatas = res?.partner
        this.brandingForm.patchValue(res?.partner?.branding)
        this.brandName = false;

      }
    })
  }



  preventCheckboxChange(event: Event): void {
    event.preventDefault();
  }


  checkDomainFields() {
    if (this.partnerType == 'white label') {
      if (this.brandingForm.controls.domainName.value === '') {
        this.brandingForm.controls.domainName.markAsTouched()
      }
      else if (this.dnsVerify === false) {
        this.toastr.warning("Please verify DNS first")
      }
      else if (this.dnsVerify === true) {
        this.domainData()
      }
    } else {
      this.toastr.warning("Please contact your account manager to enable white label.")
    }


  }

  checkBrandFields() {

    if (this.brandingForm.controls.brandName.value === '') {
      this.brandingForm.controls.brandName.markAsTouched()
    }
    else {
      this.brandData()
    }

  }

  checkMailFields() {
    if (this.partnerType == 'white label') {
      if (this.brandingForm.controls.senderEmail.value === '') {
        this.brandingForm.controls.senderEmail.markAsTouched()
      }
      else {
        this.emailVerified()
      }
    } else {
      this.toastr.warning("Please contact your account manager to enable white label.")
    }

  }

  checkLinksFields(type: string) {
    if (this.brandingForm.controls.contactSupport.value === '' ||
      this.brandingForm.controls.onlineHelp.value === '' ||
      this.brandingForm.controls.aboutProduct.value === ''
    ) {
      this.toastr.warning("Mandatory fields are empty in Links section")
    }
    else {
      this.linkData()
    }
  }

  checkTermsFields(type: string) {
    if (this.textBoxDisabled2) {
      if (this.brandingForm.controls.termsOfService.value === '' ||
        this.brandingForm.controls.productAgreement.value === '' ||
        this.brandingForm.controls.privacyPolicy.value === '' ||
        this.brandingForm.controls.thirdPartyLicense.value === ''
      ) {
        this.toastr.warning("Mandatory fields are empty in TOC section")
      }
    }
    else {
      this.termsOfUseData()
    }
  }

  domainData() {
    const payload = {
      domainName: this.brandingForm.value.domainName,
    }
    this.brand.createBrand(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message)
      }
    },
      (error) => {
        this.toastr.error(error.error.message)
      }
    )
  }

  brandData() {
    if (this.partnerType == 'white label') {
      const payload = {
        brandName: this.brandingForm.value.brandName,
      }
      this.brand.createBrand(payload).subscribe((res: any) => {
        if (res.success) {
          this.toastr.info("brand data saved")
        }
      })
    }
    else {
      this.toastr.warning("Please contact your account manager to enable white label.")
    }

  }

  emailData() {
    const payload = {
      senderEmail: this.brandingForm.value.senderEmail,
    }
    this.brand.createBrand(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.info("email data saved")
      }
    })
  }

  artWorkData() {
    // const payload = {
    //   domainName : this.brandingForm.value.domainName,
    // }
    // this.brand.createBrand(payload).subscribe((res:any)=>{
    //   if(res.success){
    //     this.toastr.info("Successfully uploaded")
    //   }
    // })
  }

  linkData() {
    const payload = {
      contactSupport: this.brandingForm.value.contactSupport,
      onlineHelp: this.brandingForm.value.onlineHelp,
      aboutProduct: this.brandingForm.value.aboutProduct,
    }
    this.brand.createBrand(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.info("Data saved")
      }
    })
  }

  toggleDefault() {
    this.textBoxDisabled2 = false;
    this.brandingForm.controls.termsOfService.reset();
    this.brandingForm.controls.productAgreement.reset();
    this.brandingForm.controls.privacyPolicy.reset();
    this.brandingForm.controls.thirdPartyLicense.reset();
  }

  toggleCustom() {
    this.textBoxDisabled2 = true;
  }

  // signUpData(){
  //   const payload = {
  //     domainName : this.brandingForm.value.domainName,
  //   }
  //   this.brand.createBrand(payload).subscribe((res:any)=>{
  //     if(res.success){
  //       this.toastr.info("Sign up data saved")
  //     }
  //   })
  // }

  termsOfUseData() {
    const payload = {
      termsOfService: this.brandingForm.value.termsOfService,
      productAgreement: this.brandingForm.value.productAgreement,
      privacyPolicy: this.brandingForm.value.privacyPolicy,
      thirdPartyLicense: this.brandingForm.value.thirdPartyLicense,
    }

    const defaultPayload = {
      termsOfService: 'https://company6938.backupinsta.com/terms#terms',
      productAgreement: 'https://company6938.backupinsta.com/terms#privacy',
      privacyPolicy: 'https://company6938.backupinsta.com/terms#privacy',
      thirdPartyLicense: 'https://company6938.backupinsta.com/terms#thirdPartyLicenses',
    }

    if (this.textBoxDisabled2) {
      this.brand.createBrand(payload).subscribe((res: any) => {
        if (res.success) {
          this.toastr.info("Terms of use saved")
        }
      })
    }
    else {
      this.brand.createBrand(defaultPayload).subscribe((res: any) => {
        if (res.success) {
          this.toastr.info("Terms of use saved")
        }
      })
    }

  }

  validateEmail() {
    if (this.partnerType == 'white label') {
      if (this.brandingForm.controls.senderEmail.value === '') {
        this.brandingForm.controls.senderEmail.markAsTouched()
      }
      else {
        const payload = {
          email: this.brandingForm.value.senderEmail
        }
        this.brandingService.emailValidation(payload).subscribe((res: any) => {
          if (res.success) {
            this.toastr.info("Validation email sent to your email address")
          }
        },
          (error) => {
            this.toastr.error(error.error.message)
          })
      }
    } else {
      this.toastr.warning("Please contact your account manager to enable white label.")
    }




  }

  emailVerified() {
    if (this.brandingForm.value.senderEmail === '') {
      this.brandingForm.controls.senderEmail.markAsTouched();
    }
    else {
      this.checkMailAndDNS('mail');
    }


  }

  checkMailAndDNS(section: string) {
    if (section === 'mail') {
      if (this.brandingForm.value.senderEmail === '') {
        //do nothing
      }
      else {
        const payload = this.brandingForm.value.senderEmail;
        this.brandingService.emailVerify(payload).subscribe((res: any) => {
          if (res.success) {
            if (res?.IsEmailVerified === 'Success') {
              this.emailVerify = 'checked';
              this.emailData()
            }
            if (res?.IsEmailVerified === 'Pending') {
              this.emailVerify = 'unchecked';
              this.toastr.warning("Please validate email first")
            }
          }
        },
          (error) => {
            this.toastr.error(error.error.message)
          })
      }
    }
    if (section === 'DNS') {
      const payload = {
        domain: this.brandingForm.value.domainName
      }
      this.brandingService.DNSVerify(payload).subscribe((res: any) => {
        if (res.success) {
          this.dnsVerify = true;
        }
      }
      )
    }

  }

  checkDNS() {
    if (this.partnerType == 'white label') {
      const payload = {
        domain: this.brandingForm.value.domainName
      }
      this.brandingService.DNSVerify(payload).subscribe((res: any) => {
        if (res.success) {
          this.dnsVerify = true;
          this.toastr.info("DNS verified. Press save to confirm")
        }
        else {
          this.toastr.warning(res.message)
        }
      },
        (error) => {
          this.toastr.warning(error.error.message)
        }
      )
    }
    else {
      this.toastr.warning("Please contact your account manager to enable white label.")
    }
  }

  IsGoogleDriveShowToUser() {
    const payload = {
      isGoogleDriveShowToUser: this.brandingForm.value.isGoogleDriveShowToUser
    }
    this.brand.createBrand(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message)
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

}


