<!-- ------user---management-------- -->
<div class="container-scroller">
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="row ">
                <div class="col-md-12 ">
                    <h3 class="font-weight-bold heading"><b>License Details</b></h3>
                </div>

                <div class="col-md-12 mb-2 mt-2 ">
                    <div class="search p-3 bg-white">
                        <p class="light mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
                            aria-expanded="false" aria-controls="collapseExample">
                            <i class="bi bi-search"></i>
                            <span class="ms-5">Search here</span>
                        </p>
                        <div class="collapse" id="collapseExample">
                            <form class="row g-3 mt-2" [formGroup]="searchForm">
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon1">TSS S.no</span>
                                        <input type="text" class="form-control" placeholder="Enter TSS S.no"
                                            onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                            formControlName="serialNumber" aria-label="Username"
                                            aria-describedby="basic-addon1">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon2">subId</span>
                                        <input type="text" class="form-control" placeholder="Enter Sub Id"
                                            formControlName="subId" aria-label="Username" onkeypress="return event.charCode !== 47 && event.charCode !== 92 "
                                            aria-describedby="basic-addon2">
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="input-group">
                                        <span class="input-group-text" id="basic-addon2">Expiry</span>
                                        <input type="month" class="form-control" placeholder="Tss Expiry"
                                            formControlName="expiryDate" aria-label="Username"
                                            aria-describedby="basic-addon2">
                                    </div>
                                </div>

                                <div class="col-md-12 text-end mt-4">
                                    <button type="submit" class="comman-btn1 btn-primary me-2"
                                        (click)="searchList()">Search</button>
                                    <button type="search" class="comman-btn1 btn-secondary text-light"
                                        (click)="resetSearchForm()">Reset</button>
                                </div>
                            </form>
                            <!-- ------ -->
                        </div>
                    </div>
                </div>
                <!----- ONLINE PAYMENT ----->


                <div class="col-md-12  mb-2 mt-2  stretch-card">
                    <div class="card">
                        <div class="card-body">

                            <div class=" mb-4 records">{{updatePaginationText}}</div>

                            <div *ngIf="!isTallyDataAvailable" class="text-center">
                                <h4>There is no Tally Data created yet</h4>
                            </div>
                            <div class="table-responsive">
                                <table class="table table-borderless table-hover" *ngIf="isTallyDataAvailable">
                                    <thead class="roundedTable thead-header">
                                        <tr>
                                            <th scope="col">Sub Id</th>
                                            <th scope="col">Product</th>
                                            <th scope="col">Tally S.no</th>
                                            <th scope="col">Release</th>
                                            <th scope="col">TSS Expiry </th>
                                            <th scope="col">License Email </th>
                                            <th scope="col">Last Updated</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let tallyData of tallyDatas | paginate : config">
                                            <td scope="col"><a [routerLink]="['/subscriptions-details']" [queryParams]="{subId : tallyData?.subscription?._id, domainName : domainName}">{{tallyData?.subId}}</a></td>
                                            <td scope="col">
                                                <div class="d-inline-block text-truncate" style="max-width: 100px;"
                                                ngbTooltip="  {{tallyData?.product}}" placement="top">
                                                {{tallyData?.product}}</div>
                                              
                                            </td>
                                            <td scope="col">{{tallyData?.serialNumber}}</td>
                                            <td scope="col">
                                                <div class="d-inline-block text-truncate" style="max-width: 100px;"
                                                ngbTooltip="  {{tallyData?.tallyVersion }} " placement="top">
                                                {{tallyData?.tallyVersion }}
                                                </div>
                                            </td>

                                            <td scope="col">{{tallyData?.tallySoftwareServices}} </td>
                                            <td scope="col"><div class="d-inline-block text-truncate" style="max-width: 150px;"
                                                ngbTooltip="  {{tallyData?.licenseAdministratorEmail}} " placement="top">
                                                {{tallyData?.licenseAdministratorEmail}} </div>
                                           </td>
                                            <td scope="col">{{tallyData?.lastUpdate}}</td>

                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="mt-4 float-end me-4" *ngIf="isTallyDataAvailable">
                                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>