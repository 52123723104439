<div class="main-panel ">
    <div class="content-wrapper ">
        <div class="row">
            <div class="col-md-12 d-flex justify-content-between align-items-center">
                <div>
                    <p class="heading">Request for {{typeOfOrder=='addOn'? ' Addon' : 'Subscription'}}</p>
                </div>
            </div>

            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                        <!-- addon -->
                        <div class="col-sm-12" *ngIf="typeOfOrder=='addOn'">
                            <form [formGroup]="addOnForm">
                                <div class="row mb-3">
                                    <div class="col-sm-6">
                                        <label class="mb-2 " for="name"><b>Select SubId</b></label>
                                        <div class="col-sm-12">
                                            <div class="dropdown-field custom-select">
                                                <div class="dropdown ">
                                                    <p class="down-btn mt-0 mb-0 form-select form-group form-control text-muted"
                                                        type="button" id="dropdownMenuButton" data-mdb-toggle="dropdown"
                                                        aria-expanded="false">
                                                        {{(selectedSub!="")?selectedSub:'Select SubId'}}

                                                    </p>
                                                    <ul class="dropdown-menu menu-item-1 col-12 pt-0"
                                                        aria-labelledby="dropdownMenuButton">
                                                        <div class=" search p-2">
                                                            <input type="text" class="form-control  p-2"
                                                                formControlName="searchText" placeholder="Search here">
                                                        </div>
                                                        <div class="customerList">
                                                            <li *ngFor="let sub of subCodes | subFilter: searchText">
                                                                <a class="dropdown-item"
                                                                    (click)="selectedSubId(sub._id,sub.code)">
                                                                    {{ sub.code }}
                                                                </a>
                                                            </li>
                                                        </div>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label for="name" class="mb-2"><b>Name</b></label>
                                        <div>
                                            <div class="select-section">
                                                <select class="form-select form-control" id="selected-addOn-field"
                                                    formControlName="name" (change)="selectedAddOn()">
                                                    <option selected disabled value="">Select Name</option>
                                                    <option value="{{addon?.name}}" *ngFor="let addon of addOnList">
                                                        {{addon?.name}}</option>
                                                </select>
                                                <div *ngIf="addOnForm.get('name')?.invalid" class="alert mb-0 p-0">
                                                    <div class="error-message"
                                                        *ngIf="addOnForm.get('name')?.errors?.['required'] && (addOnForm.get('name')?.dirty || addOnForm.get('name')?.touched)">
                                                        <p class="text-danger m-0 p-0"><small>name is mandatory.</small>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>

                                <div class="row mb-3">

                                    <div class="col-sm-6">
                                        <label for="quantity" class="mb-2"><b>Quantity</b></label>
                                        <div>
                                            <input type="number" placeholder="Enter Quantity" class="form-control"
                                                formControlName="quantity" min="0" (input)="addonAmount()"
                                                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                                            <div *ngIf="addOnForm.get('quantity')?.invalid" class="alert mb-0 p-0">
                                                <div class="error-message"
                                                    *ngIf="addOnForm.get('quantity')?.errors?.['required'] && (addOnForm.get('quantity')?.dirty || addOnForm.get('quantity')?.touched)">
                                                    <p class="text-danger m-0 p-0"><small>Quantity is mandatory.</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label for="price" class="mb-2"><b>Price/Unit </b></label>
                                        <div>
                                            <input type="number" placeholder="Enter Price" class="form-control"
                                                formControlName="price" min="0"
                                                onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                                            <div *ngIf="addOnForm.get('price')?.invalid" class="alert mb-0 p-0">
                                                <div class="error-message"
                                                    *ngIf="addOnForm.get('price')?.errors?.['required'] && (addOnForm.get('price')?.dirty || addOnForm.get('price')?.touched)">
                                                    <p class="text-danger m-0 p-0"><small>Price is mandatory.</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row mb-3">
                                    <div class="col-sm-6">
                                        <label for="description" class="mb-2"><b>Description</b></label>
                                        <div>
                                            <textarea type="text" class="form-control description"
                                                aria-describedby="emailHelp" placeholder="Description..."
                                                formControlName="description"></textarea>
                                            <div *ngIf="addOnForm.get('description')?.invalid" class="alert mb-0 p-0">
                                                <div class="error-message"
                                                    *ngIf="addOnForm.get('description')?.errors?.['required'] && (addOnForm.get('description')?.dirty || addOnForm.get('description')?.touched)">
                                                    <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <label for="fullName" class="mb-2"><b>Remarks</b></label>
                                        <div>
                                            <textarea rows="1" class="form-control " id="fullName" placeholder="Remarks"
                                                style="overflow:hidden" formControlName="remark"></textarea>
                                            <div *ngIf="addOnForm.get('remark')?.invalid" class="alert mb-0 p-0">
                                                <div class="error-message"
                                                    *ngIf="addOnForm.get('remark')?.errors?.['required'] && (addOnForm.get('remark')?.dirty || addOnForm.get('remark')?.touched)">
                                                    <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>

                        <!-- subscription -->
                        <div class="col-sm-12" *ngIf="typeOfOrder=='subscription'">
                            <form [formGroup]="subscriptionForm">
                                <div class="row">
                                    <div class="col-md-12 grid-margin stretch-card">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="row mb-3">
                                                            <div class="col-sm-4">
                                                                <label for="addLabel" class="col-form-label">Select
                                                                    Customer<span class="text-danger">*</span></label>
                                                                <div>
                                                                    <div class="dropdown-field ">
                                                                        <div class="dropdown">
                                                                            <p class="down-btn down-btn mt-0 mb-0 form-select text-muted psd form- w-100 d-flex align-items-center"
                                                                                id="dropdownMenuButton"
                                                                                data-mdb-toggle="dropdown"
                                                                                aria-expanded="false"> {{ selectedEmail
                                                                                ?
                                                                                selectedEmail : 'Select Customer' }}
                                                                            </p>
                                                                            <ul class="dropdown-menu menu-item-1 pt-0 ps-2 pe-2"
                                                                                aria-labelledby="dropdownMenuButton">
                                                                                <div class="search p-2">
                                                                                    <input type="text"
                                                                                        class="form-control  p-3"
                                                                                        formControlName="searchText"
                                                                                        placeholder="Search here">
                                                                                </div>
                                                                                <div class="customerList">
                                                                                    <li
                                                                                        *ngFor="let list of customerList | filter: subscriptionSearchText">
                                                                                        <a class="dropdown-item"
                                                                                            (click)="selectedCustomer(list.email,list._id)">
                                                                                            {{ list.email }}
                                                                                        </a>
                                                                                    </li>
                                                                                </div>
                                                                            </ul>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                            <div class="col-sm-4">
                                                                <label for="addLabel" class="col-form-label">Sales
                                                                    Person<span class="text-danger">*</span></label>
                                                                <div>

                                                                    <select class="form-select form-control"
                                                                        formControlName="salesmanId" required>
                                                                        <option selected disabled value="">Select a
                                                                            salesperson</option>
                                                                        <option value="self">Self</option>
                                                                        <option *ngFor="let salesman of salesmanData"
                                                                            value="{{salesman._id}}">{{salesman.name}}
                                                                        </option>
                                                                    </select>
                                                                    <div *ngIf="subscriptionForm.controls.salesmanId.invalid && (subscriptionForm.controls.salesmanId.dirty|| subscriptionForm.controls.salesmanId.touched)"
                                                                        class="error-message">
                                                                        <div
                                                                            *ngIf="subscriptionForm.controls.salesmanId.errors?.['required']">
                                                                            <p class="text-danger m-0 p-0"><small>It is
                                                                                    mandatory field</small></p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <label for="addLabel" class="col-form-label">Select
                                                                    Product<span class="text-danger">*</span></label>
                                                                <div>
                                                                    <select
                                                                        class="form-select form-control custom-select"
                                                                        formControlName="productName" id="selected-field"
                                                                        (change)="selectedProduct()">
                                                                        <option selected disabled value="">Select
                                                                            Product
                                                                        </option>
                                                                        <option [value]="list?.name"
                                                                            *ngFor="let list of productList">
                                                                            {{list?.name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div>
                                                        <h4>Plan</h4>
                                                        <hr>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="row mb-3">
                                                                    <label for="addLabel"
                                                                        class="col-sm-5 col-form-label">Plan
                                                                        Name<span class="text-danger">*</span></label>
                                                                    <div class="col-sm-7 mt-2">
                                                                        <select class="form-select form-control"
                                                                            formControlName="planName"
                                                                            id="selected-plan-field"
                                                                            (change)="selectedPlan()">
                                                                            <option selected disabled value="">Select
                                                                                Plan</option>
                                                                            <option [value]="list?.name"
                                                                                *ngFor="let list of planList">
                                                                                {{list?.name}}</option>
                                                                        </select>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <label for="addLabel"
                                                                        class="col-sm-5 col-form-label">Quantity<span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-sm-7 mt-2">
                                                                        <input type="number" class="form-control"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="planQty"
                                                                            (input)="planAmount()" readonly
                                                                            pattern="[1-9][0-9]*" required>

                                                                        <div *ngIf="subscriptionForm.controls.planQty.invalid && (subscriptionForm.controls.planQty.dirty || subscriptionForm.controls.planQty.touched)"
                                                                            class="error-message">
                                                                            <div
                                                                                *ngIf="subscriptionForm.controls.planQty.errors?.['required']">
                                                                                <p class="text-danger m-0 p-0"><small>It
                                                                                        is mandatory field</small></p>
                                                                            </div>
                                                                            <div
                                                                                *ngIf="subscriptionForm.controls.planQty.errors?.['pattern']">
                                                                                <p class="text-danger m-0 p-0">
                                                                                    <small>Not a valid quantity</small>
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="row mb-3">
                                                                    <label for="addLabel"
                                                                        class="col-sm-5 col-form-label">Ram(GB)<span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-sm-7 mt-2">
                                                                        <input type="number" class="form-control"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="ram"
                                                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <label for="addLabel"
                                                                        class="col-sm-5 col-form-label">Storage(GB)<span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-sm-7 mt-2">
                                                                        <input type="number" class="form-control"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="storage"
                                                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3" *ngIf="isUpdate">
                                                                    <label class="col-form-label col-sm-5"
                                                                        for="name">Select SubId</label>
                                                                    <div class="col-sm-7">
                                                                        <div class="dropdown-field custom-select">
                                                                            <div class="dropdown ">
                                                                                <p class="down-btn mt-0 mb-0 form-select form-group form-control text-muted"
                                                                                    type="button"
                                                                                    id="dropdownMenuButton"
                                                                                    data-mdb-toggle="dropdown"
                                                                                    aria-expanded="false">
                                                                                    {{(selectedSub!="")?selectedSub:'Select SubId'}}

                                                                                </p>
                                                                                <ul class="dropdown-menu menu-item-1 col-12 pt-0"
                                                                                    aria-labelledby="dropdownMenuButton">
                                                                                    <div class=" search p-2">
                                                                                        <input type="text"
                                                                                            class="form-control  p-2"
                                                                                            formControlName="subSearch"
                                                                                            placeholder="Search here">
                                                                                    </div>
                                                                                    <div class="customerList">
                                                                                        <li
                                                                                            *ngFor="let sub of vmCodes | subFilter: searchSub">
                                                                                            <a class="dropdown-item"
                                                                                                (click)="selectedVmSubId(sub._id,sub.code)">
                                                                                                {{ sub.code }}
                                                                                            </a>
                                                                                        </li>
                                                                                    </div>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="row mb-3">
                                                                    <label for="addLabel"
                                                                        class="col-sm-5 col-form-label">Unit
                                                                        Price<span class="text-danger">*</span></label>
                                                                    <div class="col-sm-7 mt-2">
                                                                        <input type="number" class="form-control"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="planUnitPrice">
                                                                    </div>
                                                                </div>

                                                                <div class="row mb-3">
                                                                    <label for="addLabel"
                                                                        class="col-sm-5 col-form-label">Amount<span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-sm-7 mt-2">
                                                                        <input type="number" class="form-control"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="planAmount" readonly>
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <label for="addLabel"
                                                                        class="col-sm-5 col-form-label">CPU(Core)<span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-sm-7 mt-2">
                                                                        <input type="number" class="form-control"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="cpu"
                                                                            onkeypress="return event.charCode >= 48 && event.charCode <= 57">
                                                                    </div>
                                                                </div>
                                                                <div class="row mb-3">
                                                                    <label for="addLabel"
                                                                        class="col-sm-5 col-form-label">Description<span
                                                                            class="text-danger">*</span></label>
                                                                    <div class="col-sm-7 mt-2">
                                                                        <textarea type="text"
                                                                            class="form-control description"
                                                                            aria-describedby="emailHelp"
                                                                            formControlName="planDescription"></textarea>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                        <div class="col-sm-12 ">
                            <div class="text-end border-0 mt-0 pt-0">
                                <button type="submit" class="comman-btn1 btn-primary me-2"
                                    (click)="submit()">Submit</button>
                                <button type="button" class="comman-btn1 btn-secondary text-light me-4"
                                    (click)="cancel()">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>