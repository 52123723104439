import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { WalletService } from 'src/app/services/wallet.service';
import { AuthService } from 'src/app/services/auth.service';
import { ProductListRoot } from 'src/app/interfaces/subscription/product';
import { SalesmanService } from 'src/app/services/salesman.service';
import { SubFilterPipe } from '../../customPipe/sub-filter.pipe';
import * as XLSX from 'xlsx';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { AssignSubsToSalespersonComponent } from '../../modals/assign-subs-to-salesperson/assign-subs-to-salesperson.component';
import { DashboardService } from 'src/app/services/dashboard.service';
import { AssginUserComponent } from '../../modals/assgin-user/assgin-user.component';
@Component({
  selector: 'app-subscription-list',
  templateUrl: './subscription-list.component.html',
  styleUrls: ['./subscription-list.component.scss'],
  providers: [SubFilterPipe],
})

export class SubscriptionListComponent implements OnInit {
  @ViewChild("selectedReportTime") selectedReportTime!: ElementRef
  @ViewChild("selectedRenewalTime") selectedRenewalTime!: ElementRef
  @ViewChild('multiSelect') multiSelect!: MatSelect;
  @ViewChild('multiSelect1') multiSelect1!: MatSelect;
  @ViewChild('multiSelect2') multiSelect2!: MatSelect;
  @ViewChild('multiSelect3') multiSelect3!: MatSelect;

  filteredIds: any
  excelSubIds: any

  salesmans: any
  relationshipManager: any
  searchForm = new FormGroup({
    startDateFrom: new FormControl(''),
    startDateTo: new FormControl(''),
    expiryDateFrom: new FormControl(''),
    expiryDateTo: new FormControl(''),
    userDetail: new FormControl(''),
    subCode: new FormControl(''),
    status: new FormControl(''),
    planName: new FormControl([]),
    salesPersonId: new FormControl([]),
    relationshipManagerId: new FormControl([]),
    stage: new FormControl(['Live', 'Trial', 'Expired', 'Trial Expired', 'Deleted']),
    lastDateFrom: new FormControl(''),
    lastDateTo: new FormControl(''),
    searchText: new FormControl(''),
    autoRenewExpire: new FormControl(''),
    tallySerialNo : new FormControl(''),
    isSchedular : new FormControl('')
  });

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private subscriptionService: SubscriptionsService,
    private modalService: BsModalService,
    private toastr: ToastrService,
    private walletService: WalletService,
    private salesmanService: SalesmanService,
    private dashboardService: DashboardService

  ) { }

  ngOnInit(): void {
    this.filteredIds = this.dashboardService.getSubId();
    this.partnerId = localStorage.getItem('PartnerId')
    this.customerId = this.activeRoute.snapshot.queryParamMap.get('customerId');
    this.activeRoute.queryParams.subscribe(params => {
      this.subType = params['subType']
      if (this.subType) {
        if (this.subType == 'paid' || this.subType == 'renewal') {
          this.searchForm.controls.stage.setValue(['Live'])
          this.allSelected = false;
        }
        else if (this.subType == 'trial') {
          this.searchForm.controls.stage.setValue(['Trial'])
          this.allSelected = false;
        }
        else if (this.subType == 'expired') {
          this.searchForm.controls.stage.setValue(['Expired'])
          this.allSelected = false;
        }
      } else {
        const stageFormControl = this.searchForm.controls.stage as FormControl;
        stageFormControl.patchValue(['Live', 'Trial', 'Expired', 'Trial Expired', 'Deleted'])
        this.allSelected = true;
      }
      this.timeInterval = params['timeInterval']
      this.subscriptionList();
    });

    this.getDomain();
    this.salesmanList();
    this.showProductList()
  }

  ngOnDestroy() {
    const url = this.router.url;
    if (!url.includes('subscriptions-details')) {
      this.dashboardService.releaseData();
    }
  }

  stageOptions = [
    { value: 'Live', label: 'Live' },
    { value: 'Expired', label: 'Expired' },
    { value: 'Trial', label: 'Trial' },
    { value: 'Trial Expired', label: 'Trial Expired' },
    { value: 'Deleted', label: 'Deleted' }
  ];

  allPlanSelected = false
  allSalesPersonSelected = false
  allSelected = true;
  allRelationshipSelected = false
  planName: any = []
  salesPersonId: any = []
  stage: any = []
  showDropDown: boolean = false
  timeInterval!: string
  subscriptionData: any
  subType: string = ''
  subReportData: any
  bsModalRef!: BsModalRef;
  domainName: string = ''
  reqSubId: string = ''
  productList!: any[];
  totalProductList!: any[];
  customerDetail: any
  customerId: any;
  subsList: any;
  subscriptionFound: boolean = false;
  page: number = 1;
  totalCount: number = 0;
  isTrialPlan: boolean = false;
  isSorting: boolean = false;
  sortValue: any = { id: -1 };
  selectAllStage: boolean = true
  isAllSelected = false;
  subTypeTitle: string = 'All Subscriptions'
  selectedSubs: { [key: string]: boolean } = {};
  selectAll = false;
  isPartnerSalesperson = true;
  fileName = 'SubscriptionReport.xlsx';
  config = {
    itemsPerPage: 20,
    currentPage: this.page,
    totalItems: this.totalCount,
  };
  partnerId: any
  subtypeTitles: { [key: string]: string } = {
    'paid': 'Paid Subscriptions',
    'trial': 'Trial Subscriptions',
    'renewal': 'Upcoming Renewal Subscriptions',
    'expired': 'Expired Subscriptions'
  };

  Subtype() {
    this.subTypeTitle = this.subtypeTitles[this.subType] || 'All Subscriptions';
  }

  clickableData(subType: string) {
    const selectedValue = this.timeInterval || 'week';
    if (subType != 'renewal') {
      this.router.navigate(['subscriptions-list'], { queryParams: { subType: subType } })
    }
    else {
      this.timeInterval = selectedValue
      this.router.navigate(['subscriptions-list'], { queryParams: { subType: subType, timeInterval: selectedValue } })
    }
    if (subType == '') {
      this.router.navigate(['subscriptions-list'])
      const stageFormControl = this.searchForm.controls.stage as FormControl;
      stageFormControl.patchValue(['Live', 'Trial', 'Expired', 'Trial Expired', 'Deleted'])
      this.allSelected = true;
    }
    this.subType = subType
  }

  createNew() {
    this.router.navigate(['subscriptions-list/add-subscription'], {
      queryParams: { customerId: this.customerId },
    });
  }



  getDomain() {
    this.walletService.getDomainName().subscribe((res: any) => {
      if (res.success) {
        this.domainName = res?.domain;
      }
    });
  }

  subscriptionList() {
    
    const selectedValue = this.selectedReportTime?.nativeElement?.value;
    let searchPayload = this.searchForm.value;
    
    searchPayload = Object.assign(searchPayload, { sortValue: this.sortValue }, { timeInterval: this.timeInterval });
    const subIds = this.filteredIds;

    this.subscriptionService.subscriptionList(this.customerId, JSON.stringify(searchPayload), this.config.currentPage, this.config.itemsPerPage, selectedValue, subIds).subscribe(
      (res: any) => {
        if (res.success) {
          if (res.totalSubscription.total === 0) {
            this.toastr.error('No data found');
            this.subscriptionFound = false;
            this.config.currentPage = 1
            this.config.totalItems = 0
            this.subsList = []
          } else {
            this.subsList = res.subscriptions
            this.subReportData = res.subReport
            this.subscriptionData = res.totalSubscription
            this.config.currentPage = res.totalSubscription.pages;
            this.config.totalItems = res.totalSubscription.total;
            this.excelSubIds = res.subIds
            if (this.customerId != undefined) {
              this.customerDetail = res.subscriptions[0]?.customer;
            }
            this.subscriptionFound = true;
          }
        }
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
    this.Subtype()
  }

  salesmanList() {
    this.salesmanService.salesmanList().subscribe((res: any) => {
      if (res.success) {
        this.salesmans = res?.salesPerson;
        this.relationshipManager = res?.relationShipManager
      }
    });
  }

  showProductList() {
    this.subscriptionService.showProductList().subscribe((res: ProductListRoot) => {
      if (res.success) {
        this.productList = res.products;
        this.totalProductList = [].concat(...this.productList.map(product => product?.plans));
      }
    })
  }

  sortingPartner(sortData: string) {
    if (!this.isSorting) {
      this.sortValue = { [sortData]: 1 };
    } else {
      this.sortValue = { [sortData]: -1 };
    }
    this.isSorting = !this.isSorting;
    this.subscriptionList()
  }



  get searchText() {
    return this.searchForm.controls.searchText.value || '';
  }



  excelSubsData() {
    let searchPayload = this.searchForm.value;
    searchPayload = Object.assign(searchPayload, { sortValue: this.sortValue }, { timeInterval: this.timeInterval });
    const subIds = this.filteredIds;
    this.subscriptionService.excelSubscriptionData(JSON.stringify(searchPayload), subIds).subscribe((res: any) => {
      if (res.success) {
        this.exportexcel(res.subscriptions)
      }
    })

  }


  exportexcel(subData: any): void {
    /* pass here the table id */
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(subData);
    /* generate workbook and add the worksheet */
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    /* save to file */
    XLSX.writeFile(wb, this.fileName);
  }

  resetSearchForm() {
    this.planName = []
    this.salesPersonId = []
    this.stage = []
    this.searchForm.reset();
    this.config.currentPage = 1;
    this.subscriptionList();
    this.allSelected = false;
    this.allPlanSelected = false
    this.allSalesPersonSelected = false
    this.allRelationshipSelected = false
  }


  pageChanged(event: any) {
    this.config.currentPage = event;
    this.subscriptionList();
  }


  userNotFound() {
    this.bsModalRef = this.modalService.show(AssignSubsToSalespersonComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });
  }


  toggleAllSelection(multiSelect: MatSelect, id: number) {

    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 0 && this.allSelected) {
          item.select();
        } else if (id === 1 && this.allPlanSelected) {
          item.select();
        } else if (id === 2 && this.allSalesPersonSelected) {
          item.select();
        } else if (id === 3 && this.allRelationshipSelected) {
          item.select();
        }
        else {
          item.deselect();
        }
      });
    }
  }

  optionClick(multiSelect: MatSelect, id: number) {
    let newStatus = true;

    multiSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });

    if (id === 0) {
      this.allSelected = newStatus;
    } else if (id === 1) {
      this.allPlanSelected = newStatus;
    } else if (id === 2) {
      this.allSalesPersonSelected = newStatus;
    }
    else if (id === 3) {
      this.allRelationshipSelected = newStatus
    }
  }

  get isPartnerSalesman() {
    const userType = this.authService.userType()
    const partnerType = localStorage.getItem('partnerType');
    if (userType && this.partnerId != '645a4eca8c6428e9ac655957' && partnerType == 'subUser') {
      return false
    } else {
      return true
    }
  }

  countSelected(): number {
    return Object.values(this.selectedSubs).filter(value => value).length;
  }
  selectAlls(event: any): void {
    const isChecked = event.target.checked;
    this.subsList.forEach((subdata: any) => {
      this.selectedSubs[subdata._id] = isChecked;
    });
  }

  toggleSelection(subId: string): void {
    this.selectedSubs[subId] = !this.selectedSubs[subId];
  }
  isAllSelect(): boolean {
    return this.subsList.every((subdata: any) => this.selectedSubs[subdata._id]);
  }

  performBulkAction(): void {
    const selectedIds = Object.keys(this.selectedSubs).filter(key => this.selectedSubs[key]);
    this.assignUserModal(selectedIds)
  }
  assignUserModal(selectedIds: any) {
    this.bsModalRef = this.modalService.show(AssginUserComponent, {
      class: 'modal-md',
      backdrop: 'static',
    })
    this.bsModalRef.content.selectedIds = selectedIds;
    this.bsModalRef.content.salesmans = this.salesmans;
    this.bsModalRef.content.relationshipManager = this.relationshipManager;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.selectedSubs = {}
      }
    })
    this.bsModalRef.onHidden?.subscribe(() => {
      this.selectedSubs = {}
    })
  }

  get userType() {
    return this.authService.userType()
  }

  get isSalesman() {
    return this.authService.isSalesman()
  }
  get updatePaginationText(){
    return this.authService.getPaginationText(this.config.currentPage,this.config.itemsPerPage,this.config.totalItems);
  }
}
