<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
    <div class="main-panel">
     <div class="content-wrapper">
      <div class="row">
       <div class="col-md-12 grid-margin">
        <div class="row">
         <div class="col-7">
          <h3 class="font-weight-bold">Roles & Permissions</h3>
         </div>
         <div class="col-5 text-end">
          <button type="button" class="comman-btn1 btn-primary me-2" [routerLink]="['/add-roles']">+ Roles</button>
         </div>
        </div>
       </div>
       <!-- ------------------ -->
       <div class="row table-section pe-0">
        <div class="col-md-12 grid-margin stretch-card pe-0">
         <div class="card">
          <div class="card-body">
           <div class="table-responsive">
            <table class="table table-striped table-borderless">
             <thead>
              <tr>
               <th scope="col">Administration Roles</th>
               <th scope="col" class="text-center">Action</th>
              </tr>
             </thead>
             <tbody>
              <tr>
               <td>Demo1</td>
               <td class="text-center">
                <i class="bi bi-pencil-fill text-primary productNameCursor me-3"></i>
                <i class="bi bi-trash3-fill text-danger mouseCursor"></i>
               </td>
              </tr>
             </tbody>
            </table>
           </div>
           <!-- <div class="mt-4 float-end me-4">
                                       <pagination-controls></pagination-controls>
                                   </div> -->
          </div>
         </div>
        </div>
       </div>
       <!-- ----------- -->
      </div>
     </div>
    </div>