<!-- -------logout----modal--- -->
<div class="modal-header border-0 py-0">
 <h4 class="modal-title pull-left border-0" *ngIf="!isEdit"><b>Add Label</b></h4>
 <h4 class="modal-title pull-left border-0" *ngIf="isEdit"><b>Edit Label</b></h4>
</div>
<form [formGroup]="colorForm">
<div class="modal-body">
 <!-- <h4>welcome</h4> -->
 <div class="row mb-3">
  <label for="inputLabel" class="col-sm-3 col-form-label">Label Name</label>
  <div class="col-sm-9">
   <input type="text" class="form-control mt-1" id="inputLabel" formControlName="colorName">
  </div>
 </div>
 <div class="row mb-3">
  <label for="inputLabel" class="col-sm-3 col-form-label">Color</label>
  <div class="col-sm-9">
   <div class="row">
    <div class="col-1 mb-2 mt-1" *ngFor="let selectedColor of colors;let  i = index">
     <!-- <div class="label-color " [ngStyle]="{ 'background-color' : selectedColor }" 
     (click)="getColor(selectedColor)">
    </div> -->
    <div class="label-color" [ngStyle]="{ 'background-color' : selectedColor }"        
     (click)="getColor(selectedColor)">
    </div>
    </div>
    <div class="col-4 mt-2">     
     <div class="color-pick"  [ngStyle]="{ 'background-color' : colorCode }" ></div>
     <!-- <div class="color-pick" 
       [ngStyle]="{ 'background-color' : colorForm.controls.customColor.value}" ></div> -->
    </div>
    <div class="col-8 mt-2">
     <div class="custome-color d-flex">
      <label for="exampleColorInput" class="form-label mb-0 me-2">Pick Custom Color</label>
      <input type="color" class="form-control border-0 p-0 mt-1 form-control-color" id="customInputColor"
       title="Choose your color" formControlName="customColor" (change)="changeCustomColor()">
     </div>
    </div>
   </div>
  </div>
 </div>
</div>
</form>
<div class="text-end border-0">
 <button type="button" class="comman-btn1 btn-primary me-2" *ngIf="!isEdit"
 (click)="addColorLabel()">OK</button>
 <button type="button" class="comman-btn1 btn-primary me-4" *ngIf="isEdit"
 (click)="updateColorLabel()">Update</button>
 <button type="button" class="comman-btn1 btn-secondary text-light me-4"
  (click)="bsModalRef.hide()">Cancel</button>
</div>
<!-- --------------end--------- -->