<div class="row">
    <p class="coming-soon text-center mt-5">Coming Soon</p>
    <!-- <div class="col-md-4 pe-0 stretch-card">
        <div class="card-body tabs">
            <p class="text-center tabs">Tabs</p>
            <p class="text-center tabs coming-soon">Coming Soon</p>
        </div>
    </div> -->
    <!-- <div class=" ps-0 pe-0 open-div-class" [ngClass]="toggle ? 'col-md-5': 'col-md-8'">
        <ul class="notes ps-3">
            <li (click)="toggleClick('notes')" [ngClass]="activeSection === 'notes' ? 'active' : ''">
                Notes
            </li>
            <li (click)="toggleClick('activity')" [ngClass]="activeSection === 'activity' ? 'active' : ''">
                Activity
            </li>
        </ul>
        <div class="row ps-3 mb-4">
            <div class="col-sm-12">
                <p class="txt">
                    Customer Relationship Management:
                    <span class="float-end icon" (click)="onToggle()">
                        <i [ngClass]="{
                    'bi-chevron-left': toggle,
                    'bi-chevron-right': !toggle
                  }"></i>
                    </span>
                </p>
                <p class="sub-txt">Schedule your activity</p>
                <form class="">
                    <div class=" mt-4">
                        <div class="row" *ngIf="activeSection === 'activity'">
                            <p class="title ms-5">Date & Time:</p>
                            <div class="mt-2 mb-4 date-time">
                                <i class="bi bi-stopwatch me-4 fs-5"></i>
                                <input type="date" class="form-control ps-1 pe-1" />
                                <input type="time" class="form-control ps-1 pe-1" />
                                <span class="dash">-</span>
                                <input type="date" class="form-control ps-1 pe-1" />
                                <input type="time" class="form-control ps-1 pe-1" />
                            </div>
                        </div>
                        <div class="ms-5">
                            <p class="title">Notes:</p>
                        </div>
                        <div class="">
                            <div class="d-flex area">
                                <i class="bi bi-pencil-square me-4 fs-5"></i>
                                <textarea id="exampleFormControlTextarea1" rows="3" placeholder="Add notes here..."
                                    class="txt-area col-sm-11"></textarea>
                            </div>
                            <p class="danger ms-5 mt-1">
                                Notes are visible within Cloud drive, but not to event guests.
                            </p>
                        </div>
                        <div class="mt-4 float-end">
                            <input type="checkbox" class="checkbox me-2" />
                            <label class="lbled me-2"> Mark as done </label>
                            <button class="me-2 btn btn-cancel">cancel</button>
                            <button class="btn btn-cancel btn-submit">Submit</button>
                        </div>
                    </div>
                </form>
                <div class="col-sm-12">
                    <div class="mt-5 ps-3">
                        <p>
                            <i class="bi bi-clock-history me-3 fs-5"></i><span class="history me-2">History</span><i
                                class="bi bi-chevron-down"></i>
                        </p>
                        <div class="history-div ms-4">
                            <p class="p-2 mb-0"><span class="all">All</span></p>
                        </div>
                        <div class="row mt-4">
                            <div class="col-sm-12">
                                <ul class="events">
                                    <li>
                                        <p class="activities">
                                            <strong>Stage: Qualified -> Contact made</strong><br /><span
                                                class="ms-2">Yesterday at
                                                05:12 Pm: Amisha Negi (Web App)</span>
                                        </p>
                                    </li>

                                    <li>
                                        <p class="activities">
                                            <strong>Stage: Qualified -> Contact made</strong><br /><span
                                                class="ms-2">Yesterday at
                                                05:12 Pm: Amisha Negi (Web App)</span>
                                        </p>
                                    </li>

                                    <li>
                                        <p class="activities">
                                            <strong>Stage: Qualified -> Contact made</strong><br /><span
                                                class="ms-2">Yesterday at
                                                05:12 Pm: Amisha Negi (Web App)</span>
                                        </p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->

    <!-- <div class="col-md-3 ps-0 pe-0 border-class" *ngIf="toggle">
        <div class="schedule-activity  p-3">
            Schedule Activity:
        </div>
        <div class=" border-class p-2">
            <p class="date text-center mb-0">{{formattedDate}}</p>
        </div>
        <div class="row ps-4 pe-4 mt-3">
           
            <table class=" table table-borderless roundedTable">
                <thead>
                    <tr>
                        <th scope="col-4">
                            Time
                        </th>
                        <th scope="col-8" colspan="2">
                            Type
                        </th>
                    </tr>
                </thead>
                <tr>
                    <td>3 AM </td>
                    <td>Call</td>
                </tr>
                <tr>
                    <td>4 AM </td>
                    <td></td>
                </tr>
                <tr>
                    <td>5 AM </td>
                    <td>Meeting</td>
                </tr>
            </table>
        </div>

    </div> -->
</div>