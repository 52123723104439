
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import { BillingService } from 'src/app/services/billing.service';
import { ProfileService } from 'src/app/services/profile.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  isBillDetailField: boolean = false
  isBillingAvailable: boolean = false
  profileData: any
  summarisedList: any = [];
  detailedList: any = [];
  billings!: any[];
  balance!: number;
  page: number = 1;
  totalCount: number = 0;
  month: number = 0
  year: number = 0
  currentView: 'main' | 'details' = 'main';
  currentExpandedBill: any = null;
  config = {
    itemsPerPage: 25,
    currentPage: this.page,
    totalItems: this.totalCount,
  };


  searchForm = new FormGroup({
    startDate : new FormControl(''),
    endDate : new FormControl(''),
    action: new FormControl(''),
    email : new FormControl('')
  })


  ngOnInit() {
    this.billList();
    this.profileDetails()
  }

  constructor(
    private bill: BillingService,
    private toastr: ToastrService,
    private profileService: ProfileService,
    private authService: AuthService,
    private router: Router,
  ) {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1
    this.month = Number(currentMonth)
    this.year = currentDate.getFullYear()
  }

  profileDetails() {
    this.profileService.profileDetails().subscribe((res: any) => {
      if (res.success) {
        this.profileData = res?.partner
        this.isBillDetailField = (Object.keys(res?.partner?.billing).length > 0) ? true : false
      }
    })
  }

  billList() {
    const payload= this.searchForm.value
    this.bill.getList(JSON.stringify(payload),this.config.itemsPerPage, this.config.currentPage).subscribe(
      (res: any) => {
        if (res.success) {
          if(res.billings.length == 0){
            this.toastr.error('No data found');
            this.isBillingAvailable = false;
            this.config.currentPage = 1
            this.config.totalItems = 0
            this.summarisedList = []
          }
          else{
            this.summarisedList = res.billings;
            this.isBillingAvailable= true
            this.config.currentPage = res?.totalBillCount?.pages;
              this.config.totalItems = res?.totalBillCount?.total;
            this.summarisedList.map((e: any) => {
              e.collapse = true;
            });
          }
          
        } else {
          this.toastr.warning(res.message);
        }
      },
      (error) => {
        this.toastr.error(error.error.message);
      }
    );
  }

  billDetail() {
    const payload= this.searchForm.value
    this.bill.getMonthlyList(JSON.stringify(payload),this.config.itemsPerPage, this.config.currentPage, this.month, this.year).subscribe((res: any) => {
      if (res.success) {
        if(res.billings.length==0){
          this.toastr.error('No data found');
            this.isBillingAvailable = false;
            this.config.currentPage = 1
            this.config.totalItems = 0
            this.detailedList = []
        }else{
          this.detailedList = res.billings;
          this.isBillingAvailable= true
          this.config.currentPage = res?.totalBillCount?.pages;
          this.config.totalItems = res?.totalBillCount?.total;
        }
        
      }
    });
  }

  payment() {
    if (this.isBillDetailField) {
      this.router.navigate(['/payment'])
    } else {
      this.toastr.warning('Please fill billing details first.')
    }
  }
  toggleTableExpend(bill: any) {

    if (this.currentExpandedBill === bill) {
      this.currentExpandedBill.collapse = true;
      this.currentExpandedBill = null;
      this.currentView = 'main';
      this.config.currentPage = 1
      this.config.itemsPerPage = 25;
      this.config.totalItems = this.summarisedList.length;
    } else {
      if (this.currentExpandedBill) {
        this.currentExpandedBill.collapse = true;
      }
      bill.collapse = false;
      this.currentExpandedBill = bill;
     
      this.currentView = 'details';
      this.loadMonthBilling(bill._id.month, bill._id.year);
    }
  }
  loadMonthBilling(month: number, year: number) {
    this.month = month;
    this.year = year;
    this.billDetail();
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    if (this.currentView === 'main') {
      this.billList();
    } else {
      this.billDetail();
    }
  }

  get userType() {
    return this.authService.userType()
  }
  get updatePaginationText(){
    return this.authService.getPaginationText(this.config.currentPage,this.config.itemsPerPage,this.config.totalItems);
  }
  searchList(){
    this.billList()
    this.billDetail()
  }
  resetForm(){
    this.searchForm.reset();
    this.config.currentPage=1
    this.billList()
    this.billDetail()
  }
}




