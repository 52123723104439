import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LabelListRoot } from 'src/app/interfaces/label/list';
import { UserService } from 'src/app/services/user.service';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import { AddLabelModalComponent } from '../../modals/add-label-modal/add-label-modal.component';

@Component({
  selector: 'app-manage-label',
  templateUrl: './manage-label.component.html',
  styleUrls: ['./manage-label.component.scss']
})
export class ManageLabelComponent implements OnInit {

  constructor(
    private router: Router,
    private user: UserService,
    private http: HttpClient,
    private modalService: BsModalService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.showLabelList()
  }
  bsModalRef!: BsModalRef;
  labelList!: any[];

  addLabelModal() {
    this.bsModalRef = this.modalService.show(AddLabelModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })

    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.showLabelList();
      }
      else {
        this.showLabelList();
      }
    }
    )

  }

  editLabelModal(id: any) {
    this.bsModalRef = this.modalService.show(AddLabelModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })

    this.bsModalRef.content.labelId = id;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.showLabelList();
      }
      else {
        this.showLabelList();
      }
    }
    )

  }

  showLabelList() {
    this.user.showLabel().subscribe((res: LabelListRoot) => {
      this.labelList = res.labels
    })
  }

  openModal(labelId: string) {

    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-sm',
      backdrop: 'static'
    })
    this.bsModalRef.content.labelId = labelId;
    this.bsModalRef.content.title = 'Delete'
    this.bsModalRef.content.message = 'Are you sure you want to delete this?'
    this.bsModalRef.content.confirmText = 'Yes'
    this.bsModalRef.content.cancelText = 'Cancel'
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.showLabelList();
      }
      else {
        this.showLabelList();
      }
    }
    )
  }

}
