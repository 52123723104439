<!-- -----add---user--modal------ -->
<div>
    <div class="modal-header border-0 pt-0 pb-3">
        <h4 class="modal-title pull-left">Change Unitprice:</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="ps-4 pe-4">
        <form [formGroup]="changePriceForm">

            <div class="form-group">
                <label for="fullName" class="pb-1">Current Price</label>
                <input type="email" class="form-control" [value]="CurrentUnitPrice" readonly>
            </div>
            <div class="form-group">
                <label for="fullName" class="pb-1">New Price</label>
                <input type="email" class="form-control" placeholder="" formControlName="newPrice">
            </div>
            <div class="row mb-3">
                <label for="fullName" class="pb-1">Remark</label>
                <div class="col-sm-12">
                    <textarea rows="1" class="form-control " id="fullName" placeholder="Remarks" style="overflow:hidden"
                        formControlName="remark"></textarea>
                    <div *ngIf="changePriceForm.get('remark')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="changePriceForm.get('remark')?.errors?.['required'] && (changePriceForm.get('remark')?.dirty || changePriceForm.get('remark')?.touched)">
                            <p class="text-danger m-0 p-0"><small>Remark is mandatory.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="ps-4 pe-4 text-end">
        <button type="button" class="comman-btn btn-primary me-2" (click)="submit()">Submit</button>
        <button type="button" class="comman-btn btn-secondary text-light" (click)="bsModalRef.hide()">Close</button>
    </div>
</div>
<!-- -----end---- -->