import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PartnerSalesmanGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const partnerId = localStorage.getItem('PartnerId');
    const isSalesman = localStorage.getItem('isSalesman');
    const partnerType = localStorage.getItem('partnerType');
    if (isSalesman == 'true' && partnerId != '645a4eca8c6428e9ac655957' && partnerType == 'subUser') {
      this.router.navigate(['subscriptions-list'])
      return false;
    }
    else {
      return true;
    }
  }

}
