<!-- <div class="modal-header py-0">
    <h4 class="modal-title pull-left"><b>Delete Subscription</b></h4>
    <span (click)="closedModal()"><i class="bi bi-x-lg"></i></span>
</div>
<div>
    <div class="modal-body">
        <div>
            <p>Account current plan is: <span class="text-success">{{customerDetail?.plan?.name | titlecase}}</span></p>
            <p> <a [routerLink]="['add-plan']" (click)="closedModal()">Add Plan</a> </p>
            <p>Change To:</p>
            <div class="col-sm-12 mt-2">
                <select class="form-select" aria-label="Default select example">
                    <option *ngFor="let plan of plan" [value]="">{{plan.name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="text-end border-0">
            <button type="button" class="comman-btn1 btn-primary me-2">Submit</button>
            <button type="button" class="comman-btn1 btn-warning text-light me-4" (click)="closedModal()">Close</button>
        </div>
    </div>
    <div class="modal-body">
        <div>
            <p>You do not have any plan that can be upgraded to.</p>
            <p>Please create a new plan that matches the current plan type, with more storage capacities or features
                than the one you are upgrading from.</p>
        </div>
    </div>
    <div class="text-end modal-footer">
        <button type="button" class="comman-btn1 btn-primary me-2">Add Plan</button>
        <button type="button" class="comman-btn1 btn-warning text-light me-4" (click)="closedModal()">Close</button>
    </div>
</div> -->
<div class="modal-header py-1 border-0">
  <h4 class="modal-title pull-left"><b>Delete Subscription</b></h4>
  <!-- <span (click)="closedModal()"><i class="bi bi-x-lg"></i></span> -->
</div>
<div>
  <div class="modal-body pt-0 mt-0">
    <div>
      <h4>Are you sure you want to delete this subscription?</h4>
      <div class="alert alert-warning mt-4" role="alert">
        <span class="fw-bold">Refundable Amount :
          <i class="bi bi-currency-rupee text-primary fs-6 m-0"></i>
          <span class="text-primary">0
          </span></span>
      </div>
      <div class="row mb-3">
        <label for="fullName" class="form-label">Remark</label>
        <div class="mt-2" [formGroup]="deleteSubForm">
          <input type="text" placeholder="Enter Remark" class="form-control" formControlName="remark">
          <div *ngIf="deleteSubForm.get('remark')?.invalid" class="alert mb-0 p-0">
            <div class="error-message"
              *ngIf="deleteSubForm.get('remark')?.errors?.['required'] && (deleteSubForm.get('remark')?.dirty || deleteSubForm.get('remark')?.touched)">
              <p class="text-danger m-0 p-0"><small>Remark is mandatory.</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <p class="text-danger">
        <span class="fw-bold">*Note :</span> Deleting this subscription will also remove all related data permanently
        from cloud. Refunds are not applicable in this process
      </p>
    </div>
    <div>
      <h5 class="text-danger">
        <span class="fw-bold">*This step is irreversible</span>
      </h5>
    </div>
  </div>


  <div class="modal-footer border-0 pe-4">
    <div class="text-end border-0">
      <button type="button" class="comman-btn1 btn-primary me-2" (click)="removeSubscription()">Yes</button>
      <button type="button" class="comman-btn1 btn-secondary text-light" (click)="bsModalRef.hide()">Back</button>
    </div>
  </div>
</div>