import { Component, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { interval } from 'rxjs';
import { LoginService } from '../services/login.service';
import { IntercomService } from '../services/intercom.service';
import { WalletService } from '../services/wallet.service';
import { BecomePartnerComponent } from '../shared/modals/become-partner/become-partner.component';
import { BsModalRef,BsModalService } from 'ngx-bootstrap/modal';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  loginData: any = [];
  getOTP: boolean = false;
  otp: any;
  token: any;
  otpValue: boolean = false;
  otpTimeout: boolean = false;
  timer1: any;
  display: any;
  changePassword: boolean = false;
  otpVerify: boolean = false;
  showPassword: boolean = false;
  showPassword2: boolean = false;
  showPassword3: boolean = false;
  resetHash: any;
  partnerDetails: any
  bsModalRef !: BsModalRef


  loginForm = new FormGroup({
    mobile: new FormControl('', Validators.compose([
      Validators.minLength(10),
      Validators.maxLength(10),
      Validators.required,
      Validators.pattern('(^[6-9][0-9]{9}$)'),
    ])),

    otp: new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern('^[0-9]{6,6}$')
    ])),
  })

  loginPasswordForm = new FormGroup({
    email: new FormControl('', Validators.compose([
      Validators.required,
      Validators.email
    ])),

    password: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(6),
    ])),
  })

  resetPasswordForm = new FormGroup({
    email: new FormControl('', Validators.compose([
      Validators.required,
      Validators.email,
    ])),

    password: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(6),
    ])),

    confirmPassword: new FormControl('', Validators.compose([
      Validators.required,
      Validators.minLength(6),
    ])),

    otp: new FormControl('', Validators.compose([
      Validators.required,
      Validators.pattern('^[0-9]{6,6}$'),
      Validators.maxLength(6),
    ])),
  })


  constructor(private loginService: LoginService,
    private router: Router,
    private toastr: ToastrService,
    private intercomService: IntercomService,
    private walletService: WalletService,
    private modalService:BsModalService
  ) { }

  generateOtp() {
    const otpPayload = { mobile: this.loginForm.value.mobile }
    this.loginService.getOTP(otpPayload).subscribe((res: any) => {
      if (res.success) {
        this.loginData = res;
        this.getOTP = true;
        this.otpValue = true;
        this.otp = res.otp;
        this.startTimer(1)
      }
    },
      (error) => {
        this.toastr.error(error.error.message)
      }
    )
  }

  login() {
    const loginPayload = {
      mobile: this.loginForm.value.mobile,
      hash: this.loginData.hash,
      otp: this.loginForm.value.otp
    }
    this.loginService.verifyOTP(loginPayload).subscribe((res: any) => {
      if (res.success) {
        this.token = res.user.token;
        localStorage.setItem("token", this.token);
        localStorage.setItem("userName", res.user.name);
        localStorage.setItem("isSalesman", res.user.isSalesman);
        localStorage.setItem("partnerType", res.user.partnerType);
        localStorage.setItem("role", res.user.role);
        this.router.navigate(['/dashboard'])
      }
    },
      (error) => {
        this.toastr.error(error.error.message)
      }
    )
  }


  validations = {
    'mobile': [
      { type: 'required', message: 'Mobile no. is required.' },
      { type: 'pattern', message: 'Please enter valid 10 digit mobile number' },
    ],

    'otp': [
      { type: 'pattern', message: 'Please enter valid 6 digit OTP' },
    ],

    'email': [
      { type: 'required', message: 'Email id is required.' },
      { type: 'email', message: 'Please enter valid email id' },
    ],

    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password is min 6 digit' },
    ],


  };

  validationsReset = {
    'email': [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Please enter valid email' },
    ],

    'otp': [
      { type: 'required', message: 'Otp is required' },
      { type: 'pattern', message: 'Please enter valid 6 digit OTP' },
    ],

    'password': [
      { type: 'required', message: 'Password is required' },
      { type: 'minlength', message: 'Password is min 6 digit' },
    ],


  };

  startTimer(minute: number) {
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;

    const prefix = minute < 10 ? "0" : "";

    this.timer1 = setInterval(() => {
      seconds--;
      if (statSec != 0) statSec--;
      else statSec = 59;

      if (statSec < 10) {
        textSec = "0" + statSec;
      } else textSec = statSec;

      this.display = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

      if (seconds == 0) {
        this.otpTimeout = true
        clearInterval(this.timer1);
      }
    }, 1000);
  }

  resendOTP() {
    const otpPayload = { mobile: this.loginForm.value.mobile }
    this.otpTimeout = false;
    this.loginService.getOTP(otpPayload).subscribe((res: any) => {
      if (res.success) {
        this.loginData = res;
        clearInterval(this.timer1);
        this.startTimer(1)
      }
    })
  }

  editMobile() {
    this.getOTP = false;
    this.otpValue = false;
    this.loginForm.controls.otp.reset()
    clearInterval(this.timer1);
  }

  loginWithPassword() {
    if (this.loginPasswordForm.invalid) {
      this.loginPasswordForm.markAllAsTouched()
    }
    else {
      const { email, password } = this.loginPasswordForm.value;
      this.loginService.loginPassword({ email, password }).subscribe((res: any) => {
        if (res.success) {
          this.intercomService.updateIntercomData(email)
          this.token = res.user.token;
          localStorage.setItem("token", this.token);
          localStorage.setItem("userName", res.user.name);
          localStorage.setItem("isSalesman", res.user.isSalesman);
          localStorage.setItem("partnerType", res.user.partnerType);
          localStorage.setItem("role", res.user.role);
          localStorage.setItem('PartnerId', res.user.partnerId);
          this.getDetails()
          this.router.navigate(['/dashboard'])
        }
      },
        (error) => {
          this.toastr.error(error.error.message)
        }
      )

    }
  }

  getDetails() {
    this.walletService.getWalletDetails().subscribe((res: any) => {
      if (res.success) {
        this.partnerDetails = res?.partner
        localStorage.setItem("PartnerId", this.partnerDetails?.subUser ? this.partnerDetails?.adminUserId : this.partnerDetails?._id)
      }
    })
  }

  changePass() {
    this.changePassword = true;
  }

  getOtp() {

    if (this.resetPasswordForm.controls.email.invalid) {
      this.resetPasswordForm.controls.email.markAsTouched();
    }
    else {
      const { email } = this.resetPasswordForm.value;
      this.loginService.forgotPassword({ email }).subscribe((res: any) => {
        if (res.success) {
          this.otpVerify = true;
          this.resetHash = res.hash;
          this.toastr.info("Otp send to registered email")
        }
        if (!res.success) {
          this.toastr.warning(res.message)
        }
      },
        (error) => {
          this.toastr.error(error.error.message)
        }
      )
    }

  }

  confirmChange() {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
    }
    else if (this.resetPasswordForm.value.password != this.resetPasswordForm.value.confirmPassword) {
      this.toastr.warning("Password does not match with re-entered password")
    }
    else {
      const payload = {
        hash: this.resetHash
      }
      const { email, password, otp } = this.resetPasswordForm.value;
      Object.assign(payload, { email, password, otp })

      this.loginService.changePassword(payload).subscribe((res: any) => {
        if (res.success) {
          this.changePassword = false;
          this.resetPasswordForm.reset()
          this.toastr.info("Password updated")
        }
      },
        (error) => {
          this.toastr.error(error.error.message)
        }
      )
    }
  }

  hidePassword(section: string) {

    if (section === 'login') {
      this.showPassword = !this.showPassword;
      (document.getElementById("pass") as HTMLInputElement).type = this.showPassword ? 'text' : 'password';
    }

    if (section === 'reset') {
      this.showPassword2 = !this.showPassword2;
      (document.getElementById("reset1") as HTMLInputElement).type = this.showPassword2 ? 'text' : 'password';
    }

    if (section === 'confirm') {
      this.showPassword3 = !this.showPassword3;
      (document.getElementById("reset2") as HTMLInputElement).type = this.showPassword3 ? 'text' : 'password';
    }

  }
  partner(){
   this.bsModalRef=this.modalService.show(BecomePartnerComponent,{
    class: 'modal-lg mt-4',
    backdrop: 'static'
   })
  }
}
