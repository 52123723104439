import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BrandingService } from 'src/app/services/branding.service';
import { WalletService } from 'src/app/services/wallet.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.scss']
})
export class SettingComponent implements OnInit {

  isShow: boolean = false
  bsModalRef!: BsModalRef;
  ngOnInit(): void {
    this.getDetails()
  }

  constructor(
    private brandingService: BrandingService,
    private toastr: ToastrService,
    private walletService: WalletService,
    private modalService: BsModalService,
  ) { }

  logoForm = new FormGroup({
    logo: new FormControl(''),
  })

  contactForm = new FormGroup({
    sales: new FormControl(''),
    support: new FormControl('')
  })

  // logo = new FormControl('')

  getDetails() {
    this.brandingService.getSettingData().subscribe((res: any) => {
      if (res.success) {
        this.logoForm.patchValue(res.partner)
        this.contactForm.patchValue(res.partner.contact)
        this.toastr.info("Saved settings loaded")
      }
    })
  }

  submitContactForm() {
    const payload = {
      sales: this.contactForm.controls.sales.value,
      support: this.contactForm.controls.support.value
    }

    this.brandingService.submitSettingData(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.info("Contact info saved successfully")
      }
    })
  }

  ticketFeature(ticket: string) {
    this.isShow = ticket === 'yes';

  }
  submitLogo() {
    const payload = {
      logo: this.logoForm.controls.logo.value
    }


    this.brandingService.submitSettingData(payload).subscribe((res: any) => {
      if (res.success) {
        this.walletService.showWalletAmount()
        this.toastr.info("Link saved successfully")
      }
    })
  }
  openTicketModal(supportType: string) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-sm',
      backdrop: 'static',
    });
    (this.bsModalRef.content.title = 'Support Confirmation');
    this.bsModalRef.content.message = `Are you sure you want to enable ${supportType} to customer?`;
    this.bsModalRef.content.confirmText = 'Yes';
    this.bsModalRef.content.cancelText = 'Cancel';
  }
}
