import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { RaiseRequestService } from 'src/app/services/ticket.service';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit {

  ticketDetails: any

  messageForm = new FormGroup({
    message: new FormControl('', Validators.required),
    status: new FormControl('open'),
  })

  constructor(
    private raiseRequestService: RaiseRequestService,
    private activeRoute: ActivatedRoute,
    private toastr: ToastrService

  ) { }

  ngOnInit(): void {
    const ticketId = this.activeRoute.snapshot.queryParamMap.get('ticketId') || '';
    this.getTicketDetails(ticketId)
  }

  getTicketDetails(ticketId: any) {
    this.raiseRequestService.ticketDetail(ticketId).subscribe((res: any) => {
      if (res.success) {
        this.ticketDetails = res?.ticketDetail
        const statusId = this.ticketDetails.status.id
        const status = statusId == 1 ? 'open' : statusId == 2 ? 'inProgress' : statusId == 3 ? 'resolved' : 'closed'
        this.messageForm.controls.status.setValue(status)
      }
    })
  }

  reply() {
    if (!this.messageForm.valid) {
      this.toastr.warning('Please add messages')
      this.messageForm.markAllAsTouched()
    } else {
      const replyPayload = {
        message: this.messageForm.value.message,
        status: this.messageForm.value.status,
        ticketId: this.ticketDetails.id
      }
      this.raiseRequestService.replyToCustomer(replyPayload).subscribe((res: any) => {
        if (res.success) {
          this.messageForm.reset()
          this.getTicketDetails(this.ticketDetails.id)
        }
      })
    }
  }
}
