<div class="main-panel modal-body pb-2">
    <form [formGroup]="partnerForm">
        <div>
            <h3 class="font-weight-bold"><strong>Become a partner</strong></h3>
        </div>
        <div class="row ">
            <div class="col-sm-12 col-md-4 mb-2 ">
                <label for="fullName" class="pt-2 pb-2">Name<span class="text-danger">*</span></label>
                <div class="position-relative">
                    <input type="text" class="form-control" id="fullName" formControlName="name"
                        placeholder="Enter Name" />
                </div>

                <div *ngIf="partnerForm.get('name')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('name')?.errors?.['required'] && (partnerForm.get('name')?.dirty || partnerForm.get('name')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Name is mandatory.</small>
                        </p>
                    </div>
                    <div class="error-message" *ngIf="partnerForm.get('name')?.errors?.['pattern']">
                        <p class="text-danger m-0 p-0"><small>Please enter valid Name.</small></p>
                    </div>
                </div>
            </div>


            <div class="col-sm-12 col-md-4 mb-2 ">
                <label for="companyName" class="pt-2 pb-2">Company Name<span class="text-danger">*</span></label>
                <div class="position-relative">
                    <input type="text" class="form-control" id="companyName" formControlName="companyName"
                        placeholder="Enter Company name">

                </div>

                <div *ngIf="partnerForm.get('companyName')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('companyName')?.errors?.['required'] && (partnerForm.get('companyName')?.dirty || partnerForm.get('companyName')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Company Name is mandatory.</small>
                        </p>
                    </div>
                    <div class="error-message" *ngIf="partnerForm.get('companyName')?.errors?.['pattern']">
                        <p class="text-danger m-0 p-0"><small>Please enter valid Company Name.</small></p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 mb-2">
                <label for="mobile" class="pt-2 pb-2">Mobile<span class="text-danger">*</span></label>
                <div class="position-relative">
                    <input type="tel" class="form-control" id="mobile" formControlName="mobile" maxlength="20"
                        placeholder="Enter Mobile Number">
                </div>

                <div *ngIf="partnerForm.get('mobile')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('mobile')?.errors?.['required'] && (partnerForm.get('mobile')?.dirty || partnerForm.get('mobile')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Mobile number is mandatory.</small>
                        </p>
                    </div>
                    <div class="error-message" *ngIf="partnerForm.get('mobile')?.errors?.['maxlength']">
                        <p class="text-danger m-0 p-0"><small>Please enter valid mobile number.</small></p>
                    </div>
                </div>
            </div>

        </div>

        <div class="row  ">

            <div class="col-sm-12 col-md-4 mb-2">
                <label for="email" class="pt-2 pb-2">Email<span class="text-danger">*</span></label>
                <div class="position-relative">
                    <input type="email" class="form-control" id="email" formControlName="email"
                        placeholder="Enter Email Id">
                </div>

                <div *ngIf="partnerForm.get('email')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('email')?.errors?.['required'] && (partnerForm.get('email')?.dirty || partnerForm.get('email')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Email Id is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 password mb-2">
                <label for="password" class="pt-2 pb-2">Password<span class="text-danger">*</span></label>
                <div class="position-relative">
                    <input [type]="passType" class="form-control" id="password" formControlName="password"
                        placeholder="Enter password">
                    <div class="hideshow-password"><i [class]="show_eye ? 'bi bi-eye' : 'bi bi-eye-slash'"
                            (click)="changePasswordType('pass')"> </i></div>
                </div>

                <div *ngIf="partnerForm.get('password')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('password')?.errors?.['required'] && (partnerForm.get('password')?.dirty || partnerForm.get('password')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Password is mandatory.</small>
                        </p>
                    </div>
                    <div class="error-message"
                        *ngIf="partnerForm.get('password')?.errors?.['pattern'] || partnerForm.get('password')?.errors?.['minlength']">
                        <p class="text-danger m-0 p-0"><small>Please enter minimum 6 digit Password.</small></p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 password mb-2">
                <label for="password" class="pt-2 pb-2">Confirm Password<span class="text-danger">*</span></label>
                <div class="position-relative">
                    <input [type]="confirmPass" class="form-control password" id="confirmPassword"
                        formControlName="confirmPassword" placeholder="Enter confirm password">
                    <div class="hideshow-password"><i [class]="show_eye1 ? 'bi bi-eye' : 'bi bi-eye-slash'"
                            (click)="changePasswordType('confirmPass')"> </i></div>
                </div>

                <div *ngIf="partnerForm.get('confirmPassword')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('confirmPassword')?.errors?.['required'] && (partnerForm.get('confirmPassword')?.dirty || partnerForm.get('confirmPassword')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Password is mandatory.</small>
                        </p>
                    </div>
                    <div class="error-message"
                        *ngIf="partnerForm.get('confirmPassword')?.errors?.['pattern'] || partnerForm.get('confirmPassword')?.errors?.['minlength']">
                        <p class="text-danger m-0 p-0"><small>Please enter minimum 6 digit Password.</small></p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-sm-12 col-md-4 mb-2 ">
                <label for="email" class="pt-2 pb-2">Country<span class="text-danger">*</span></label>
                <select class="form-control form-select" aria-label="Default select example" formControlName="country">
                    <option value="">Please Select Country</option>
                    <option *ngFor="let list of countryList" value="{{list.name}}">
                        {{ list.name | titlecase}}
                    </option>
                </select>
                <div *ngIf="partnerForm.get('country')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('country')?.errors?.['required'] && (partnerForm.get('country')?.dirty || partnerForm.get('country')?.touched)">
                        <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 mb-2 ">
                <label for="email" class="pt-2 pb-2">Pincode<span class="text-danger">*</span></label>
                <input type="number" class="form-control" (input)="onPincodeInput($event)" name="pinCode"
                    formControlName="pincode" placeholder="Enter pincode" />
                <div *ngIf="partnerForm.get('pincode')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('pincode')?.errors?.['required'] && (partnerForm.get('pincode')?.dirty || partnerForm.get('pincode')?.touched)">
                        <p class="text-danger m-0 p-0"><small>It is mandatory.</small></p>
                    </div>
                    <div class="error-message"
                        *ngIf="partnerForm.get('pincode')?.errors?.['maxlength'] && (partnerForm.get('pincode')?.dirty || partnerForm.get('pincode')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Maximum length is 6 digits.</small></p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 mb-2 ">
                <label for="email" class="pt-2 pb-2">City<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter your city" formControlName="district"
                    required id="city" [readonly]="validPinCode" />
                <div *ngIf="partnerForm.get('district')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('district')?.errors?.['required'] && (partnerForm.get('district')?.dirty || partnerForm.get('district')?.touched)">
                        <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-sm-12 col-md-4 mb-2 ">
                <label for="email" class="pt-2 pb-2">State<span class="text-danger">*</span></label>
                <input type="text" class="form-control" placeholder="Enter your state" formControlName="state" required
                    *ngIf="isOverseas" />
                <select class="form-control form-select" aria-label="Default select example" formControlName="state"
                    *ngIf="!isOverseas">
                    <option value="">Please Select State</option>
                    <option *ngFor="let list of stateList" value="{{list.name}}" [disabled]="validPinCode">
                        {{ list.name | titlecase}}
                    </option>
                </select>
                <div *ngIf="partnerForm.get('state')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('state')?.errors?.['required'] && (partnerForm.get('state')?.dirty || partnerForm.get('state')?.touched)">
                        <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 mb-2 ">
                <label for="email" class="pt-2 pb-2">Zone<span class="text-danger">*</span></label>
                <select class="form-control form-select" aria-label="Default select example" formControlName="zone">
                    <option value="">Please Select Zone</option>
                    <option *ngFor="let zone of zones" [value]="zone.value" disabled>
                        {{ zone.name }}</option>
                </select>
                <div *ngIf="partnerForm.get('zone')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('zone')?.errors?.['required'] && (partnerForm.get('zone')?.dirty || partnerForm.get('zone')?.touched)">
                        <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-sm-12 col-md-4 mb-2 ">
                <label class="pt-2 pb-2">Team Size Sales</label>

                <input type="number" class="form-control" placeholder="Enter Sales Team Size "
                    formControlName="salesTeamSize" />
                <div *ngIf="partnerForm.get('salesTeamSize')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('salesTeamSize')?.errors?.['required'] && (partnerForm.get('salesTeamSize')?.dirty || partnerForm.get('salesTeamSize')?.touched)">
                        <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-12 col-md-4 mb-2 ">
                <label class="pt-2 pb-2">Team Size Support</label>

                <input type="number" class="form-control" placeholder="Enter Support Team Size"
                    formControlName="supportTeamSize" />
                <div *ngIf="partnerForm.get('supportTeamSize')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('supportTeamSize')?.errors?.['required'] && (partnerForm.get('supportTeamSize')?.dirty || partnerForm.get('supportTeamSize')?.touched)">
                        <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 mb-2 ">
                <label for="email" class="pt-2 pb-2">Category</label>
                <select class="form-control form-select" aria-label="Default select example" formControlName="category">
                    <option value="">Select Category</option>
                    <option *ngFor="let category of PartnerCategory" value="{{category.value}}">
                        {{ category.name | titlecase}}
                    </option>
                </select>
                <div *ngIf="partnerForm.get('category')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('category')?.errors?.['required'] && (partnerForm.get('category')?.dirty || partnerForm.get('category')?.touched)">
                        <p class="text-danger m-0 p-0"><small>It is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-4 mb-2 ">
                <label for="email" class="pt-2 pb-2">Account Manager<span class="text-danger">*</span></label>

                <select class="form-control form-select" aria-label="Default select example"
                    formControlName="accountManagerId">
                    <option value="">Please Select account Manager</option>
                    <option *ngFor="let list of accountManagerList" value="{{list._id}}">
                        {{ list.name }}
                    </option>
                </select>
                <div *ngIf="partnerForm.get('accountManagerId')?.invalid" class="alert mb-0 p-0">
                    <div class="error-message"
                        *ngIf="partnerForm.get('accountManagerId')?.errors?.['required'] && (partnerForm.get('accountManagerId')?.dirty || partnerForm.get('accountManagerId')?.touched)">
                        <p class="text-danger m-0 p-0"><small>Account Manager is mandatory.</small>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <div class="row">
        <div class="col-sm-12 mt-2 text-end">
            <button type="submit" class="comman-btn1 btn-primary" (click)="submit()">Submit</button>
            <button type="button" class="comman-btn1 btn-secondary text-light ms-2"
                (click)="bsModalRef.hide()">Cancel</button>
        </div>
    </div>
    <!-- --------end------------- -->

</div>