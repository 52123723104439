import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormControlName, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SalesmanService } from 'src/app/services/salesman.service';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';

@Component({
  selector: 'app-change-salesman',
  templateUrl: './change-salesman.component.html',
  styleUrls: ['./change-salesman.component.scss']
})
export class ChangeSalesmanComponent implements OnInit {

  @Input() subscriptionId!: string
  @Input() isSalesman!: boolean
  event: EventEmitter<any> = new EventEmitter();
  salesmanData: any
  subscriptionData: any
  rmData: any
  subData: any
  changeSalesmanForm = new FormGroup({
    subCode: new FormControl('',),
    salesmanId: new FormControl('', [Validators.required]),
    rmId: new FormControl('', [Validators.required])
  })

  constructor(
    public bsModalRef: BsModalRef,
    private subscriptionService: SubscriptionsService,
    private salesmanService: SalesmanService,
    private toasts: ToastrService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.subscriptionId) {
        this.subsscriptionDetails()
      }
      if (this.isSalesman) {
        this.changeSalesmanForm.controls.rmId.clearValidators()
        this.changeSalesmanForm.controls.rmId.updateValueAndValidity()
      } else {
        this.changeSalesmanForm.controls.salesmanId.clearValidators()
        this.changeSalesmanForm.controls.salesmanId.updateValueAndValidity()
      }
    });
    this.salesmanList()
  }

  salesmanList() {
    this.salesmanService.salesmanList().subscribe((res: any) => {
      if (res.success) {
        this.salesmanData = res.salesPerson
        this.rmData = res.relationShipManager
      }
    })
  }

  subsscriptionDetails() {
    this.subscriptionService.subscriptionDetails(this.subscriptionId).subscribe((res: any) => {
      if (res.success) {
        this.subData = res.subscription
        if (this.isSalesman) {
          this.subscriptionData = { subCode: res.subscription.code, salesmanId: res.subscription.salesPerson.id }
        } else {
          this.subscriptionData = { subCode: res.subscription.code, rmId: res.subscription.relationshipManager.id }
        }
        this.changeSalesmanForm.patchValue(this.subscriptionData)
      }
    })
  }

  submit() {
    if (!this.changeSalesmanForm.valid) {
      this.changeSalesmanForm.markAllAsTouched()
    }
    else {
      this.updateUserInSub()
    }
  }

  updateUserInSub() {
    const payload = Object.assign(this.changeSalesmanForm.value, { subscriptionId: this.subData._id })
    this.subscriptionService.changeSalesman(payload).subscribe((res: any) => {
      if (res.success) {
        this.event.emit('ok');
        this.toasts.success(res.message);
        this.bsModalRef.hide();
      } else {
        this.toasts.success(res.message);
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })
  }

}
