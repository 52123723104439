import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { countryList } from '../../../../../country-list';
import { stateList } from '../../../../../state';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { LoginService } from 'src/app/services/login.service';
@Component({
  selector: 'app-become-partner',
  templateUrl: './become-partner.component.html',
  styleUrls: ['./become-partner.component.scss']
})
export class BecomePartnerComponent implements OnInit {
  show_eye: Boolean = false;
  show_eye1: Boolean = false;
  passType: string = 'password';
  confirmPass: string = 'password';
  stateList: any = stateList
  countryList: any = countryList
  isOverseas: boolean = false
  accountManagerList: any

  zones: any = [
    { name: "East Zone", value: "eastZone" },
    { name: "West Zone", value: "westZone" },
    { name: "North Zone", value: "northZone" },
    { name: "South Zone", value: "southZone" },
    { name: "Overseas", value: "overseas" }
  ]
  validPinCode: boolean = false
  PartnerCategory: any = [
    { name: "5 Star", value: "5_star" },
    { name: "3 Star", value: "3_star" },
    { name: "Associate Partner", value: "associate_partner" },
    { name: "Marg ", value: "marg " },
    { name: 'Busy', value: 'busy' },
    { name: 'Others', value: 'others' },
  ]
  partnerForm = new FormGroup({
    name: new FormControl('', [Validators.required]),
    companyName: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    mobile: new FormControl('', [Validators.minLength(8), Validators.required,]),
    password: new FormControl('', [Validators.minLength(6), Validators.required]),
    confirmPassword: new FormControl('', [Validators.minLength(6), Validators.required]),
    country: new FormControl('', [Validators.required]),
    pincode: new FormControl('', [Validators.maxLength(6), Validators.required]),
    district: new FormControl('', [Validators.required]),
    state: new FormControl('', [Validators.required]),
    zone: new FormControl('', [Validators.required]),
    salesTeamSize: new FormControl('', [Validators.required]),
    supportTeamSize: new FormControl('', [Validators.required]),
    category: new FormControl('', [Validators.required]),
    accountManagerId: new FormControl('', [Validators.required]),
  })
  constructor(

    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private loginService: LoginService,

  ) { }

  ngOnInit(): void {
    this.accountManagerLists()
  }

  changePasswordType(type: string) {
    if (type === 'pass') {
      this.show_eye = !this.show_eye;
      this.passType = this.passType === 'password' ? 'text' : 'password';
    }
    if (type === 'confirmPass') {
      this.show_eye1 = !this.show_eye1;
      this.confirmPass = this.confirmPass === 'password' ? 'text' : 'password';
    }
  }

  onPincodeInput(event: any) {
    const inputValue = event.target.value;
    if (inputValue.length === 6 && !this.isOverseas) {
      event.target.value = inputValue.slice(0, 6);
      this.getPincode(event.target.value)
    }
  }

  getPincode(pincode: any) {
    this.loginService.pincode(pincode).subscribe((res: any) => {
      if (res.success) {
        this.validPinCode = true
        this.partnerForm.controls.district.setValue(res.pincodeDetail.District)
        this.partnerForm.controls.state.setValue(res.pincodeDetail.State)
        this.partnerForm.controls.zone.setValue(res.pincodeDetail.zone)
      }
    })
  }

  accountManagerLists() {
    this.loginService.accountManagerList().subscribe((res: any) => {
      if (res.success) {
        this.accountManagerList = res?.accountManagers
      }
    })
  }

  submit() {
    if (!this.partnerForm.valid) {
      this.partnerForm.markAllAsTouched()
      return
    }
    const accountManagerId = this.partnerForm.value.accountManagerId;
    const accountManager = this.accountManagerList.find((manager: any) => manager._id === accountManagerId)?.name;
    const payload = { ...this.partnerForm.value, accountManager };
    this.loginService.becomePartner(payload).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message);
        this.bsModalRef.hide()
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }

}
