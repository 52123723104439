import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SalesmanService } from 'src/app/services/salesman.service';

@Component({
  selector: 'app-add-salesman',
  templateUrl: './add-salesman.component.html',
  styleUrls: ['./add-salesman.component.scss']
})
export class AddSalesmanComponent implements OnInit {
  passwordMatched: boolean = true
  salesmanId: string = ''
  isEdit: boolean = false
  salesmanData: any
  salesmanDataList: any
  relationshipManager: any
  allSelected = false;
  salesmanDetail: any
  salesmanForm = this.fb.group({
    name: new FormControl('', [Validators.pattern('[a-zA-Z]+.*|.*[a-zA-Z]+|.*[a-zA-Z]+.*'), Validators.required]),
    mobile: new FormControl('', [Validators.pattern("^((\\+91-?)|0)?[1-9]{1}[0-9]{9,11}$"), Validators.required,]),
    email: new FormControl('', [Validators.required, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]),
    password: new FormControl('', [Validators.minLength(8), Validators.required]),
    confirmPassword: new FormControl('', [Validators.minLength(8), Validators.required]),
    companyName: new FormControl('', [Validators.required]),
    role: new FormControl('salesPerson', [Validators.required]),
    accessibleUserIds: new FormControl<Array<string>>([])
  })
  constructor(private fb: FormBuilder,
    private salesmanService: SalesmanService,
    private toasts: ToastrService,
    private router: Router,
    private activateRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.salesmanForm.get('confirmPassword')?.valueChanges.subscribe(value => {
      if (this.salesmanForm.get('password')?.value === value) {
        this.passwordMatched = true;
      } else {
        this.passwordMatched = false;
      }
    });
    this.activateRoute.queryParams.subscribe((data) => {
      this.salesmanId = data['salesmanId']
    })

    if (this.salesmanId) {
      this.salesmanForm.get('password')?.clearValidators();
      this.salesmanForm.get('confirmPassword')?.clearValidators();
      this.salesmanForm.controls.password?.updateValueAndValidity();
      this.salesmanForm.controls.confirmPassword?.updateValueAndValidity()
      this.isEdit = true
      this.salesmanDetails()
      this.salesmanForm.get('role')?.valueChanges.subscribe(value => {
        this.salesmanList(value)
        if (this.salesmanDetail.role != value && value == "relationshipManager") {
          this.salesmanForm.controls.accessibleUserIds.setValue([])
        }
      })
    }
  }

  salesmanList(role: any) {
    this.salesmanService.salesmanList().subscribe((res: any) => {
      if (res.success) {
        const salesPerson = res.salesPerson.filter((user: any) => user.id != 1)
        const realtionShipManager = res.relationShipManager.filter((user: any) => user.id != 1)
        this.salesmanData = (role == 'relationshipManager') ? realtionShipManager : salesPerson
        this.salesmanData = this.salesmanData.filter((item: any) => {
          const assignIds = this.salesmanDetail?.accessibleUserIds && this.salesmanDetail?.accessibleUserIds.some((id: any) => id == item._id)
          const adminUserId = item?.accessibleUserIds && item?.accessibleUserIds.some((id: any) => id == this.salesmanId)
          if (!adminUserId) {
            if (item._id == this.salesmanId || assignIds) {
              item['isSelected'] = true
            } else {
              item['isSelected'] = false
            }
            return item
          }
        });
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })
  }

  submit() {
    if (this.isEdit) {
      this.updateSalesman()
    }
    else {
      this.addSalesman()
    }
  }


  addSalesman() {
    const formData = this.salesmanForm.value
    const salesmanPayload = {
      name: formData.name,
      email: formData.email,
      password: formData.password,
      mobile: formData.mobile,
      companyName: formData.companyName,
      role: formData.role,
      accessibleUserIds: formData.accessibleUserIds
    }
    this.salesmanService.createSalesman(salesmanPayload).subscribe((res: any) => {
      if (res.success) {
        this.toasts.success(res.message)
        this.router.navigate(['sales-man'])
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })
  }

  salesmanDetails() {
    this.salesmanService.salesmanDetail(this.salesmanId).subscribe((res: any) => {
      if (res.success) {
        this.salesmanDetail = res?.salesman
        this.salesmanForm.patchValue(res.salesman)

      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })
  }

  updateSalesman() {
    const formData = this.salesmanForm.value
    const salesmanPayload = {
      salesmanId: this.salesmanId,
      name: formData.name,
      email: formData.email,
      mobile: formData.mobile,
      companyName: formData.companyName,
      role: formData.role,
      accessibleUserIds: formData.accessibleUserIds
    }
    this.salesmanService.updateSalesman(salesmanPayload).subscribe((res: any) => {
      if (res.success) {
        this.toasts.success(res.message)
        this.router.navigate(['sales-man'])
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })
  }

  allowAll(event: any) {
    this.allSelected = event.target.checked;
    const salesPersonIds: string[] = []
    this.salesmanData.forEach((user: any) => {
      if (user._id != this.salesmanId) {
        user.isSelected = event.target.checked
        salesPersonIds.push(user._id)
      }
    });
    this.salesmanForm.controls.accessibleUserIds.setValue(salesPersonIds)
  }

  toggleUserAccess(event: any, index: number) {
    let salesPersonIds: string[] = []
    this.salesmanData[index].isSelected = event.target.checked;
    const salesPerson = this.salesmanData[index]
    if (salesPerson?.accessibleUserIds) {
      salesPersonIds = salesPerson?.accessibleUserIds
    }
    this.allSelected = this.salesmanData.every((user: any) => user.isSelected);
    this.salesmanData.forEach((user: any) => {
      const assignIds = salesPerson?.accessibleUserIds && salesPerson?.accessibleUserIds.some((id: any) => id == user._id)
      if (assignIds && user._id != this.salesmanId) {
        user['isSelected'] = event.target.checked;
      }
      if (user._id != this.salesmanId && user.isSelected) {
        salesPersonIds.push(user._id)
      }
    });
    this.salesmanForm.controls.accessibleUserIds.setValue(salesPersonIds)
  }
}
