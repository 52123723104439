import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  constructor(
    private subscriptionService : SubscriptionsService,
    private router : Router
  ) { }

  ngOnInit(){
    this.showProductList()
  }

  productList: any = [];
  productFound : boolean = false;

  showPlanAndAddOn(productId : string){    
    this.router.navigate(['/plan-and-addOn'],
    { queryParams: { productId : productId } });
  }

  showProductList() {
    this.subscriptionService.showProductList().subscribe((res: any) => {
      if (res.success) {
        this.productList = res?.products;
        this.productFound = true;
      }
    })
  }
}
