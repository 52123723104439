import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-crm',
  templateUrl: './crm.component.html',
  styleUrls: ['./crm.component.scss']
})
export class CrmComponent implements OnInit {

  activeSection = 'notes'
  toggle :boolean =false;
  isShowdate :boolean =false;
  formattedDate: string='';
  constructor() { }

  ngOnInit() {
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      // year: 'numeric',
      month: 'long',
      day: 'numeric'
    };
    const d = new Date();
    this.formattedDate = d.toLocaleString('en-US', options);
  
  }
  
  onToggle(){
    this.toggle = ! this.toggle;


  }
  toggleClick(section:string){
    this.activeSection=section
  }
}
