import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuditLogService {

  constructor(private http: HttpClient) { }

  auditLogList(page: number, limit: number, searchField: string) {

    return this.http.get(`${environment.apiURL}/partner/log?page=${page}&limit=${limit}&search=${searchField}`);
  }
}
