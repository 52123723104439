import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesmanService {

  constructor(private http: HttpClient) { }

  createSalesman(salesmanPayload: any) {
    return this.http.post(`${environment.apiURL}/partner/salesman`, salesmanPayload)
  }

  salesmanList() {
    return this.http.get(`${environment.apiURL}/partner/salesman`)
  }

  salesmanDetail(salesmanId: string) {
    return this.http.get(`${environment.apiURL}/partner/salesman/details?salesmanId=${salesmanId}`)
  }

  updateSalesman(salesmanPayload: any) {
    return this.http.put(`${environment.apiURL}/partner/salesman`, salesmanPayload)
  }

  deleteSalesman(salesmanId: string) {
    return this.http.delete(`${environment.apiURL}/partner/salesman?salesmanId=${salesmanId}`)
  }

  updateStatus(salesmanPayload: any) {
    return this.http.put(`${environment.apiURL}/partner/salesman`, salesmanPayload)
  }
}
