import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ProfileService } from 'src/app/services/profile.service';
import { stateList } from '../../../../state';
import { countryList } from 'country-list';
import { Location } from '@angular/common';
@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.scss']
})
export class BillingDetailsComponent implements OnInit {

  countries: any
  isBillDetailField: boolean = false
  profileData: any
  stateList: any = stateList
  countryList: any = countryList
  gstRequired: boolean = false
  pinDetails: any;
  pinCode = '';
  validPinCode: boolean = false

  gstTreatment = [
    { name: "registered", value: "business_gst" },
    { name: "unregistered", value: "business_none" },
    { name: "consumer", value: "consumer" },
    { name: "overseas", value: "overseas" }
  ];
  companyType = ["Company", "Trust", "Partership Firm", "Society", "Sole Proprietorship", "One Person Company", "LLP", "Others"];
  constructor(
    private profileService: ProfileService,
    private toasts: ToastrService,
    private router: Router,
    private location: Location,
  ) { }

  ngOnInit(): void {
    this.billingForm.get('gstTreatment')?.valueChanges.subscribe((value) => {
      if (value != 'business_gst') {
        this.gstRequired = false
        this.billingForm.controls.gstInNo.clearValidators()
        this.billingForm.controls.organizationType.clearValidators()
        this.billingForm.controls.panCardNo.clearValidators()
        this.billingForm.controls.registeredCompanyName.clearValidators()
        this.billingForm.controls.gstInNo.updateValueAndValidity()
        this.billingForm.controls.organizationType.updateValueAndValidity()
        this.billingForm.controls.panCardNo.updateValueAndValidity()
        this.billingForm.controls.registeredCompanyName.updateValueAndValidity()
      } else {
        this.gstRequired = true
        this.billingForm.controls.gstInNo.setValidators([Validators.required, Validators.pattern("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")]);
        this.billingForm.controls.gstInNo.updateValueAndValidity();
        this.billingForm.controls.organizationType.setValidators(Validators.required);
        this.billingForm.controls.organizationType.updateValueAndValidity();
        this.billingForm.controls.panCardNo.setValidators([Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')]);
        this.billingForm.controls.panCardNo.updateValueAndValidity();
        this.billingForm.controls.registeredCompanyName.setValidators(Validators.required);
        this.billingForm.controls.registeredCompanyName.updateValueAndValidity();
      }
    })
    this.profileDetails()
  }

  billingForm = new FormGroup({
    name: new FormControl('', Validators.required),
    mobile: new FormControl('', [Validators.pattern('^[1-9]{1}[0-9]{9}$'), Validators.required]),
    email: new FormControl('', [Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'), Validators.required]),
    address: new FormControl('', Validators.required),
    pinCode: new FormControl('', Validators.required),
    gstInNo: new FormControl('', [Validators.required, Validators.pattern("^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$")]),
    organizationType: new FormControl('', Validators.required),
    panCardNo: new FormControl('', [Validators.required, Validators.pattern('[A-Z]{5}[0-9]{4}[A-Z]{1}')]),
    companyName: new FormControl('', Validators.required),
    city: new FormControl('', [Validators.required,Validators.pattern('[a-zA-Z\\s]*$')]),
    state: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    gstTreatment: new FormControl('', Validators.required),
    registeredCompanyName: new FormControl('', Validators.required)
  })

  submit() {
    if (!this.billingForm.valid) {
      this.billingForm.markAllAsTouched()
    } else {
      this.profileupdate()
    }
  }

  profileDetails() {
    this.profileService.profileDetails().subscribe((res: any) => {
      if (res && res.success && res.partner && res.partner.billing) {
        this.isBillDetailField = (Object.keys(res?.partner?.billing).length > 0) ? true : false
        if (this.isBillDetailField) {
          this.billingForm.patchValue(res?.partner?.billing)
        }
      }
    })
  }

  profileupdate() {
    let profilePayload = this.billingForm.value
    profilePayload.email = profilePayload.email?.trim()
    profilePayload.mobile = profilePayload.mobile?.replace(/\s+/g, '').trim()
    profilePayload.gstInNo = profilePayload.gstInNo?.trim()
    profilePayload.panCardNo = profilePayload.panCardNo?.trim()
    profilePayload.city=profilePayload.city?.trim()
    const selectedState = this.stateList.find((state: any) => state.key === profilePayload.state)
    profilePayload = Object.assign(profilePayload, { stateName: selectedState.name })
    this.profileService.profileupdate(profilePayload).subscribe((res: any) => {
      if (res.success) {
        this.toasts.success(res.message)
        this.router.navigate(['/billing'])
      }
    }, (error) => {
      this.toasts.error(error.error.message)
    })
  }

  cancel() {
    this.location.back();
  }

  onPincodeInput(event: any) {
    const inputValue = event.target.value;
    if (inputValue.length === 6) {
      event.target.value = inputValue.slice(0, 6);
      this.getPincode(event.target.value)
    }
  }

  getPincode(pincode: any) {
    this.profileService.pincode(pincode).subscribe((res: any) => {
      if (res.success) {
        this.validPinCode = true
        this.billingForm.controls.city.setValue(res.pincodeDetail.District)
        this.billingForm.controls.state.setValue(res.pincodeDetail.key)
        this.billingForm.controls.country.setValue(res.pincodeDetail.Country)
      }
      else {
        this.validPinCode = false
        this.billingForm.controls.city.setValue('')
        this.billingForm.controls.state.setValue('')
        this.billingForm.controls.country.setValue('')
      }
    })
  }
}
