<div class="main-panel">
    <div class="content-wrapper">
        <div class="row">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-12 col-xl-8 mb-2 mb-xl-0">
                        <p class="activity">Activity</p>
                       
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-body">
                        <p class="list">Activity Scheduled List:</p>
                        <!-- <div class="row grid-margin">
                            <div class="col-sm-6">
                                <ul class="d-flex service ps-0 mb-0 ">
                                    <li (click)="toggleSection('all')"
                                        [ngClass]="(activeSection==='all') ? 'activate': ''">
                                        <a>All</a>
                                    </li>
                                    <li (click)="toggleSection('call')"
                                        [ngClass]="(activeSection==='call') ? 'activate': ''">
                                        <a>Call</a>
                                    </li>
                                    <li (click)="toggleSection('meeting')"
                                        [ngClass]="(activeSection==='meeting') ? 'activate': ''">
                                        <a>Meeting</a>
                                    </li>


                                </ul>
                            </div>
                            <div class="col-sm-6">
                                <span class="float-end">
                                    <div class="selected">
                                        <select class="dropdown">
                                            <option value="weekly">Today</option>
                                            <option value="weekly">Weekly</option>
                                            <option value="monthly">Monthly</option>
                                        </select>
                                    </div>
                                </span>
                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-sm-12">
                                <!-- <div class="table-responsive">
                                    <table class="table table-sm table-borderless roundedTable">
                                        <thead>
                                            <tr>
                                                <th><input type="checkbox" /></th>
                                                <th scope="col">Activity Type</th>
                                                <th scope="col">SUB Id</th>
                                                <th scope="col">Contact Person</th>
                                                <th scope="col">Scheduled</th>
                                                <th scope="col">Notes/Description</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <tr>
                                                <td><input type="checkbox" /></td>
                                                <td>
                                                    <i class="bi bi-telephone-forward-fill text-success me-1"></i>Call
                                                    back
                                                </td>
                                                <td>#7678uhdvd</td>
                                                <td>
                                                    Amisha Negi
                                                </td>
                                                <td>
                                                    03/11/23 07:48 Pm
                                                </td>
                                                <td>
                                                    Call back reminder for Comhard
                                                </td>
                                                <td class="text-success">
                                                    Due today
                                                </td>
                                                <td>
                                                    <i class="bi bi-pencil-fill text-success me-1"></i>
                                                    <i class="bi bi-trash-fill text-danger"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><input type="checkbox" /></td>
                                                <td class="text-danger">
                                                    <i class="bi bi-people-fill me-1"></i>Meeting
                                                </td>
                                                <td>#7678uhdvd</td>
                                                <td>
                                                    Srishti Sain
                                                </td>
                                                <td>
                                                    03/11/23 07:48 Pm
                                                </td>
                                                <td>
                                                    Call back reminder for Comhard
                                                </td>
                                                <td class="text-warning">
                                                    To-do
                                                </td>
                                                <td>
                                                    <i class="bi bi-pencil-fill text-success me-1"></i>
                                                    <i class="bi bi-trash-fill text-danger"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><input type="checkbox" /></td>
                                                <td>
                                                    <i class="bi bi-telephone-forward-fill me-1"></i>Reminder
                                                </td>
                                                <td>#7678uhdvd</td>
                                                <td>
                                                    Amisha Negi
                                                </td>
                                                <td>
                                                    03/11/23 07:48 Pm
                                                </td>
                                                <td>
                                                    Call back reminder for Comhard
                                                </td>
                                                <td class="text-warning">
                                                    To-do
                                                </td>
                                                <td>
                                                    <i class="bi bi-pencil-fill text-success me-1"></i>
                                                    <i class="bi bi-trash-fill text-danger"></i>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><input type="checkbox" /></td>
                                                <td class="text-danger">
                                                    <i class="bi bi-people-fill me-1"></i>Meeting
                                                </td>
                                                <td>#7678uhdvd</td>
                                                <td>
                                                    Srishti Sain
                                                </td>
                                                <td>
                                                    03/11/23 07:48 Pm
                                                </td>
                                                <td>
                                                    Call back reminder for Comhard
                                                </td>
                                                <td class="text-danger">
                                                    Done
                                                </td>
                                                <td>
                                                    <i class="bi bi-pencil-fill text-success me-1"></i>
                                                    <i class="bi bi-trash-fill text-danger"></i>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div> -->
                                <p class="coming-soon text-center mt-5 mb-5">Coming Soon</p>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>