import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AddOnDetailRoot } from '../interfaces/subscription/addOn';
import { SubscriptionListRoot } from '../interfaces/subscription/list';
import { PlanDetailRoot } from '../interfaces/subscription/plan';
import { ProductListRoot } from '../interfaces/subscription/product';
import { timeout } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionsService {
  constructor(private http: HttpClient) { }

  showProductList() {
    return this.http.get<ProductListRoot>(
      `${environment.apiURL}/partner/product`
    );
  }

  addSubscription(body: any) {
    return this.http.post(`${environment.apiURL}/partner/subscription`, body);
  }

  productDetails(productId: string) {
    return this.http.get(
      `${environment.apiURL}/partner/product/details?productId=${productId}`
    );
  }

  excelSubscriptionData(search: any, subIds: any[] = []): Observable<SubscriptionListRoot> {
    return this.http.post<SubscriptionListRoot>(
      `${environment.apiURL}/partner/excel/sub`,
      { search, subIds }
    );
  }

  // updateSubscription(){
  //   return this.http.put(`${environment.apiURL}/partner/customer`, userPayload)
  // }

  subscriptionDetails(subscriptionId: string) {
    return this.http.get(
      `${environment.apiURL}/partner/subscription/details?subscriptionId=${subscriptionId}`
    );
  }

  deleteSubscription(id: string, remark: string) {
    return this.http.delete(
      `${environment.apiURL}/partner/subscription?subscriptionId=${id}&remark=${remark}`
    );
  }

  extendExpireSubscription(id: string) {
    const subPayload = {
      subscriptionId: id
    }
    return this.http.post(
      `${environment.apiURL}/partner/subscription/extend`, subPayload
    );
  }

  showPlanList(productId: string) {
    return this.http.get<PlanDetailRoot>(
      `${environment.apiURL}/partner/plan?productId=${productId}`
    );
  }

  showAddOnList(productId: string) {
    return this.http.get<AddOnDetailRoot>(
      `${environment.apiURL}/partner/addOn?productId=${productId}`
    );
  }

  selectedPlan(planId: string) {
    return this.http.get(
      `${environment.apiURL}/partner/plan/details?planId=${planId}`
    );
  }

  selectedAddOn(addOnId: string) {
    return this.http.get(
      `${environment.apiURL}/partner/addOn/details?addOnName=${addOnId}`
    );


  }



  subscriptionList(customerId: string, search: any, page: Number, limit: Number, selectedValue: string, subIds: any[] = []): Observable<SubscriptionListRoot> {
    if (!customerId || customerId === null) {
      return this.http.post<SubscriptionListRoot>(
        `${environment.apiURL}/partner/subscriptions`,
        { search, page, limit, timePeriod: selectedValue, subIds }
      );
    } else {
      return this.http.post<SubscriptionListRoot>(
        `${environment.apiURL}/partner/subscriptions`,
        { customerId }
      );
    }
  }



  customerEmailList() {
    return this.http.get<SubscriptionListRoot>(
      `${environment.apiURL}/partner/customer/email`
    );
  }

  newPrice(id: string, noOfUser: number) {
    return this.http.get(
      `${environment.apiURL}/partner/subscription/noOfUser?subscriptionId=${id}&noOfUser=${noOfUser}`
    );
  }

  changeSubscriptionUsers(payload: any) {
    return this.http.post(
      `${environment.apiURL}/partner/subscription/noOfUser`,
      payload
    );
  }

  toggleStatus(payload: any) {
    return this.http.put(
      `${environment.apiURL}/partner/subscription`, payload
    );
  }

  changeSalesman(salesmanPayload: any) {
    return this.http.put(
      `${environment.apiURL}/partner/subscription`, salesmanPayload
    );
  }

  changeCustomer(payload: any) {
    return this.http.post(
      `${environment.apiURL}/partner/subscription/customerId`,
      payload
    );
  }

  changeTrialPlan(payload: any): Observable<any> {
    return this.http.post(`${environment.apiURL}/partner/subscription/paid`, payload).pipe(timeout(180000));
  }

  updateSub(subId: string) {
    return this.http.get(`${environment.apiURL}/partner/subscription/paid?subscriptionId=${subId}`)
  }

  setForRenew(subId: string) {
    return this.http.get(`${environment.apiURL}/partner/subscription/renew?subscriptionId=${subId}`)
  }

  planUpgradeDowngrade(planPayload: any) {
    return this.http.put(`${environment.apiURL}/partner/subscription/plan`, planPayload)
  }

  renewSubscription(subId: string) {
    return this.http.post(`${environment.apiURL}/partner/subscription/renew`, { subscriptionId: subId })
  }

  subBillingInfo(subId: string, subCode: string) {
    return this.http.get(`${environment.apiURL}/partner/subscription/order?subscriptionId=${subId}&subCode=${subCode}`)
  }

  subRecentActivityLog(subId: string) {
    return this.http.get(`${environment.apiURL}/partner/subscription/activity?subscriptionId=${subId}`)
  }

  assignSubscription(assignSubscriptionPayload: any) {
    return this.http.put(`${environment.apiURL}/partner/subscription/salesperson`, assignSubscriptionPayload)
  }

  assignUser(assignUserPayload: any) {
    return this.http.put(
      `${environment.apiURL}/partner/subscription/user/assign`, assignUserPayload
    );
  }
  changeNewPrice(changePricePayload: any) {
    return this.http.put(
      `${environment.apiURL}/partner/subscription/price`, changePricePayload
    );
  }

  tallyData(searchPayload: any, page: Number, limit: Number) {
    return this.http.get(
      `${environment.apiURL}/partner/tally/data?search=${searchPayload}&page=${page}&limit=${limit}`
    );
  }

  reasonForChurn(remarkPayload: any) {
    return this.http.put(
      `${environment.apiURL}/partner/subscription/churnReason`, remarkPayload
    );
  }
  subscriptionSalesPersonList(subId: string) {
    return this.http.get(`${environment.apiURL}/partner/subscriptionSalesPersonList?subscriptionId=${subId}`);
  }
  getProductList() {
    return this.http.get(`${environment.apiURL}/partner/virtualProductList`)
  }
  getProductDetailsList(productId: any) {
    return this.http.get(`${environment.apiURL}/partner/virtualProductList?productId=${productId}`)
  }
  getAddOnList() {
    return this.http.get(`${environment.apiURL}/partner/addOn/list `)
  }

  createSubManagement(payload: any) {
    return this.http.post(`${environment.apiURL}/partner/subManagementRequest`, payload)
  }
  getdetailsSubManagement(type: string, page: number, limit: number) {
    return this.http.get(`${environment.apiURL}/partner/subManagementRequest?type=${type}&page=${page}&limit=${limit}`)
  }

  getVmCodes(){
    return this.http.get(`${environment.apiURL}/partner/vmSubscription`)
  }
}
