import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginWithAdminService } from '../services/login-with-admin.service';

@Component({
  selector: 'app-login-with-admin',
  templateUrl: './login-with-admin.component.html',
  styleUrls: ['./login-with-admin.component.scss']
})
export class LoginWithAdminComponent implements OnInit {

  constructor(private router: Router,
    private loginWithAdminService: LoginWithAdminService,
    private activateRoute: ActivatedRoute) { }
  currentUrl: any = [];
  token: string = '';

  ngOnInit(): void {
    this.activateRoute.queryParams.subscribe((data) => {
      this.currentUrl = data['token']
    })
    if (this.currentUrl) {
      const userPayload = {
        token: this.currentUrl,
        admin: true
      }
      this.loginWithAdminService.verifyToken(userPayload).subscribe((res: any) => {
        if (res.success) {
          localStorage.removeItem('token')
          localStorage.removeItem('userName');
          localStorage.removeItem('isSalesman');
          localStorage.removeItem('partnerType');
          localStorage.setItem('token', this.currentUrl)
          localStorage.setItem('userName', res.partner.name);
          localStorage.setItem('partnerType', res.partner.partnerType);
          localStorage.setItem('PartnerId', res.partner._id);
          localStorage.setItem("isSalesman", res.partner.isSuperAdmin ? 'false' : 'true');
          this.router.navigate(['/dashboard'])
        }
        else {
          this.router.navigate(['/login']);
        }
      })
    }
  }


}
