import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { BrandingRoot, SettingRoot } from '../interfaces/branding';

@Injectable({
  providedIn: 'root'
})
export class BrandingService {

  constructor(
    private http : HttpClient
  ) { }

  createBrand(payload:any){
    return this.http.post(`${environment.apiURL}/partner/brand`, payload)
  }  

  showDetails(){
    return this.http.get<BrandingRoot>(`${environment.apiURL}/partner/profile`)
  }

  submitSettingData(payload:any){
    return this.http.post<SettingRoot>(`${environment.apiURL}/partner/setting`,payload)
  }

  getSettingData(){
    return this.http.get(`${environment.apiURL}/partner/setting`)
  }

  emailValidation(body:any){
    return this.http.post(`${environment.apiURL}/partner/email`,body)
  }

  emailVerify(email:any){
    return this.http.get(`${environment.apiURL}/partner/email?email=${email}`)
  }

  DNSVerify(body:any){
    return this.http.post(`${environment.apiURL}/partner/domain`,body)
  }



}
