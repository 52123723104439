import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';

@Component({
  selector: 'app-plan-and-add-on',
  templateUrl: './plan-and-add-on.component.html',
  styleUrls: ['./plan-and-add-on.component.scss']
})
export class PlanAndAddOnComponent implements OnInit {

  constructor(
    private subscriptionService : SubscriptionsService,
    private activeRoute : ActivatedRoute,
  ) { }

  ngOnInit(){
    this.getUserId()
  }

  planList : any = [];
  addOnList : any = [];

  productDetail : any = [];

  pId : any;

  productForm = new FormGroup({
    name : new FormControl(''),
    description : new FormControl('')
  })

  // planFound : boolean = false;
  // addOnFound : boolean = false;

  getUserId(){
    this.pId = this.activeRoute.snapshot.queryParamMap.get('productId')

    this.showPlanList(this.pId) 
    this.showAddOnList(this.pId)
    this.showProductDetails(this.pId)      
  }



  showProductDetails(productId:string){
    this.subscriptionService.productDetails(productId).subscribe((res:any)=>{
      if(res.success){
        // this.productForm.patchValue(res?.product)
        this.productDetail = res?.product
      }
    })
  }

  showPlanList(productId:string){
    this.subscriptionService.showPlanList(productId).subscribe((res:any)=>{
      if(res.success){
        this.planList = res?.plans;     
     
      }
    })
  }

  showAddOnList(productId:string){
    this.subscriptionService.showAddOnList(productId).subscribe((res:any)=>{
      if(res.success){
        this.addOnList = res?.addOns;
 
      }
    })
  }


}
