import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { EventEmitter, Input } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FormGroup, FormControl } from '@angular/forms';
import { LabelAddUpdateRoot } from 'src/app/interfaces/label/label-add-and-update';


@Component({
  selector: 'app-add-label-modal',
  templateUrl: './add-label-modal.component.html',
  styleUrls: ['./add-label-modal.component.scss']
})
export class AddLabelModalComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
    private user : UserService,
    private toastr : ToastrService,
  ) { }  

  ngOnInit(){
    setTimeout(() => {
      this.getDetails()
      });
  }

  isEdit : boolean = false;
  colorCode! : any;
  pickColor! : boolean;
  changeColor  : boolean = false;
  @Input() labelId!: string;
  event: EventEmitter<any> = new EventEmitter();

  colors : any[] = [
    '#f0f8ff','#faebd7','#00ffff','#7fffd4','#f0ffff','#f5f5dc',
    '#ffe4c4','#000000','#ffebcd','#0000ff','#8a2be2','#a52a2a',
    '#FFFACD','#FF69B4','#B22222','#FFA07A' ,'#DFFF00','#CCCCFF',
    '#FFBF00','#FF7F50','#6495ED','#DE3163'
  ]

  colorForm = new FormGroup({
    colorName : new FormControl(''),
    colorCode : new FormControl(''),
    customColor : new FormControl('')
  })

  getColor(colorVal : any){
    this.pickColor = false
    this.colorCode = colorVal
  }

  changeCustomColor(){   
     this.colorCode = this.colorForm?.controls?.customColor?.value;
  }
  
  addColorLabel(){
    let payload = {
      name : this.colorForm.controls.colorName.value,
      color : this.colorCode,      
    }  

    if(payload.color === undefined || payload.name === ''){
      this.toastr.warning("Please enter Label name and select Label color")
    }

    else{
      this.user.addLabel(payload).subscribe((res:LabelAddUpdateRoot) => {
      if(res.success){
        this.event.emit('ok');
        this.closedModal();
        this.toastr.info("Label added")
      }
    },
    (error)=>{
      this.toastr.error(error.error.message)
    })
    }    
  }

  updateColorLabel(){
    let payload = {
      name : this.colorForm.controls.colorName.value,
      color : this.colorCode,
      labelId : this.labelId
    }

    this.user.labelUpdate(payload).subscribe((res:LabelAddUpdateRoot) => {
      if(res.success){
        this.event.emit('ok');
        this.closedModal();
        this.toastr.info("Label updated")
      }
    },
    (error)=>{
      this.toastr.error(error.error.message)
    }
    )
  }

  getDetails(){
    this.user.labelDetails(this.labelId).subscribe((res:LabelAddUpdateRoot)=>{
      if(res.success){  
       this.isEdit= true     
       this.colorForm.controls.colorName.patchValue(res.label.name)
       this.colorCode = res.label.color
      }
    })
  }
  
  closedModal() {
    this.bsModalRef.hide();
  }

}
