<!-- -------contactForm--page------- -->

<div class="content-wrapper">
  <div class="card ">
    <div class="card-body">

      <!-- ************************ Billing Details *************************-->

      <form [formGroup]="billingForm" class="m-3">

        <h3 class="mb-3 mt-2 topup-heading">Billing Details:</h3>

        <div class="row mb-2 ">
          <div class="col-sm-12 col-md-6">
            <label for="comapany" class="col-form-label"><b>Company/Individual<span
                  class="text-danger">*</span></b></label>

            <input type="text" class="form-control" placeholder="Enter Company Name" formControlName="companyName"
              required id="comapany" />
            <div *ngIf="
                    billingForm.controls.companyName.invalid &&
                    (billingForm.controls.companyName.dirty ||
                      billingForm.controls.companyName.touched)
                  " class="error-message">
              <div *ngIf="billingForm.controls.companyName.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <label for="name" class="col-form-label"><b>Name<span class="text-danger">*</span></b></label>
            <input type="text" class="form-control" placeholder="Enter your name" formControlName="name" required
              id="name" />
            <div *ngIf="
                  billingForm.controls.name.invalid &&
                  (billingForm.controls.name.dirty ||
                    billingForm.controls.name.touched)
                " class="error-message">
              <div *ngIf="billingForm.controls.name.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-2 ">
          <div class="col-sm-12 col-md-6">
            <label for="mobile" class="col-form-label"><b>Mobile<span class="text-danger">*</span></b></label>

            <input type="tel" class="form-control" placeholder="Enter your mobile number" formControlName="mobile"
              required id="mobile" />
            <div *ngIf="
                  billingForm.controls.mobile.invalid &&
                  (billingForm.controls.mobile.dirty ||
                    billingForm.controls.mobile.touched)
                " class="error-message">
              <div *ngIf="billingForm.controls.mobile.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
              <div *ngIf="billingForm.get('mobile')?.errors?.['pattern']">
                <p class="text-danger m-0 p-0"><small>Please enter valid mobile no.</small>
                </p>
              </div>
            </div>
           
          </div>

          <div class="col-sm-12 col-md-6">
            <label for="email" class="col-form-label"><b>Email<span class="text-danger">*</span></b></label>

            <input type="email" class="form-control" placeholder="Enter your email" formControlName="email" required
              id="email" />
            <div *ngIf="billingForm.get('email')?.invalid" class="alert mb-0 p-0">
              <div
                *ngIf="billingForm.get('email')?.errors?.['required'] && (billingForm.get('email')?.dirty || billingForm.get('email')?.touched)">
                <p class="text-danger m-0 p-0 "><small>it's required field.</small>
                </p>
              </div>
              <div *ngIf="billingForm.get('email')?.errors?.['pattern']">
                <p class="text-danger m-0 p-0"><small>Please enter valid email id.</small>
                </p>
              </div>
            </div>

          </div>
        </div>

        <div class="row mb-2 ">
          <div class="col-sm-12 col-md-6">
            <label for="gst_treatment" class="col-form-label"><b>GST Treatment<span
                  class="text-danger">*</span></b></label>
            <select name="gstTreatment" class="form-control form-select" formControlName="gstTreatment" required
              id="gst_treatment">
              <option value="" disabled selected>Select gst treatment</option>
              <option *ngFor="let gstTreatment of gstTreatment" [value]="gstTreatment.value">{{gstTreatment.name |
                titlecase}}
              </option>

            </select>
            <div *ngIf="
                  billingForm.controls.gstTreatment.invalid &&
                  (billingForm.controls.gstTreatment.dirty ||
                    billingForm.controls.gstTreatment.touched)
                " class="error-message">
              <div *ngIf="billingForm.controls.gstTreatment.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
            </div>

          </div>
          <div class="col-sm-12 col-md-6">
            <label for="gstin" class="col-sm-4 col-form-label"><b>GSTIN<span class="text-danger"
                  *ngIf="gstRequired">*</span></b></label>
            <input type="text" class="form-control" placeholder="Enter your GSTIN" formControlName="gstInNo"
              id="gstin" />
            <div *ngIf="
                  billingForm.controls.gstInNo.invalid &&
                  (billingForm.controls.gstInNo.dirty ||
                    billingForm.controls.gstInNo.touched)
                " class="error-message">
              <div *ngIf="billingForm.controls.gstInNo.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
              <div *ngIf="billingForm.controls.gstInNo.errors?.['pattern']">
                <p class="text-danger m-0 p-0">
                  <small>Enter valid GSTIN no.</small>
                </p>
              </div>
            </div>

          </div>


        </div>

        <div class="row mb-2 ">

          <div class="col-sm-12 col-md-6">
            <label for="RegisteredCompanyName" class="col-form-label"><b>Registered Company Name<span
                  class="text-danger" *ngIf="gstRequired">*</span></b></label>

            <input name="company_type" class="form-control " formControlName="registeredCompanyName" required
              id="registeredCompanyName" />
            <div *ngIf="
                billingForm.controls.registeredCompanyName.invalid &&
                (billingForm.controls.registeredCompanyName.dirty ||
                  billingForm.controls.registeredCompanyName.touched)
              " class="error-message">
              <div *ngIf="billingForm.controls.registeredCompanyName.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <label for="compant_type" class="col-form-label"><b>Company Type<span class="text-danger"
                  *ngIf="gstRequired">*</span></b></label>

            <select name="company_type" class="form-control form-select" formControlName="organizationType" required
              id="compant_type">
              <option value="" disabled selected>Select your Company Type</option>
              <option *ngFor="let type of companyType" value="{{type}}">{{type | titlecase}}</option>
            </select>
            <div *ngIf="
                billingForm.controls.organizationType.invalid &&
                (billingForm.controls.organizationType.dirty ||
                  billingForm.controls.organizationType.touched)
              " class="error-message">
              <div *ngIf="billingForm.controls.organizationType.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-2 ">
          <div class="col-sm-12 col-md-6">
            <label for="pan" class="col-form-label"><b>PAN<span class="text-danger"
                  *ngIf="gstRequired">*</span></b></label>

            <input type="text" class="form-control" placeholder="Enter your PAN" formControlName="panCardNo" id="pan" />
            <div *ngIf="
                  billingForm.controls.panCardNo.invalid &&
                  (billingForm.controls.panCardNo.dirty ||
                    billingForm.controls.panCardNo.touched)
                " class="error-message">
              <div *ngIf="billingForm.controls.panCardNo.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
              <div *ngIf="billingForm.controls.panCardNo.errors?.['pattern']">
                <p class="text-danger m-0 p-0">
                  <small>Enter valid PAN</small>
                </p>
              </div>
            </div>

          </div>
          <div class="col-sm-12 col-md-6">
            <label for="address" class=" col-form-label"><b>Address<span class="text-danger">*</span></b></label>
            <input type="text" class="form-control" placeholder="Enter your address" formControlName="address" required
              id="address" />
            <div *ngIf="
                billingForm.controls.address.invalid &&
                (billingForm.controls.address.dirty ||
                  billingForm.controls.address.touched)
              " class="error-message">
              <div *ngIf="billingForm.controls.address.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
            </div>
          </div>

        </div>

        <div class="row mb-2 ">
          <div class="col-sm-12 col-md-6">
            <label for="zip" class="col-form-label"><b>Zip/Pin code<span class="text-danger">*</span></b></label>
            <input type="tel" class="form-control" placeholder="Enter Zip code" formControlName="pinCode" maxlength="10"
              minlength="5" required id="zip" [maxlength]="6" (input)="onPincodeInput($event)" />
            <div *ngIf="
                  billingForm.controls.pinCode.invalid &&
                  (billingForm.controls.pinCode.dirty ||
                    billingForm.controls.pinCode.touched)
                " class="error-message">
              <div *ngIf="billingForm.controls.pinCode.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
              <div *ngIf="billingForm.controls.pinCode.errors?.['maxlength']">
                <p class="text-danger m-0 p-0">
                  <small>Enter maximum 10 digit.</small>
                </p>
              </div>
              <div *ngIf="billingForm.controls.pinCode.errors?.['minlength']">
                <p class="text-danger m-0 p-0">
                  <small>Enter minimum 5 digit.</small>
                </p>
              </div>
            </div>

          </div>
          <div class="col-sm-12 col-md-6">
            <label for="city" class="col-form-label"><b>City<span class="text-danger">*</span></b></label>
            <input type="text" class="form-control" placeholder="Enter your city" formControlName="city" required
              id="city" [readonly]="validPinCode" />
            <div *ngIf="
                  billingForm.controls.city.invalid &&
                  (billingForm.controls.city.dirty ||
                    billingForm.controls.city.touched)
                " class="error-message">
              <div *ngIf="billingForm.controls.city.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
              <div *ngIf="billingForm.get('city')?.errors?.['pattern']">
                <p class="text-danger m-0 p-0"><small>Please enter valid city </small>
                </p>
              </div>
            </div>
            
          </div>


        </div>

        <div class="row mb-2 ">
          <div class="col-sm-12 col-md-6">
            <label for="state" class="col-form-label"><b>State<span class="text-danger">*</span></b></label>

            <select class="form-control form-select" aria-label="Default select example" formControlName="state">
              <option value="">Please Select State</option>
              <option *ngFor="let list of stateList" value="{{list.key}}" [disabled]="validPinCode">
                {{ list.name | titlecase}}
              </option>
            </select>
            <div *ngIf="
                  billingForm.controls.state.invalid &&
                  (billingForm.controls.state.dirty ||
                    billingForm.controls.state.touched)
                " class="error-message">
              <div *ngIf="billingForm.controls.state.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
            </div>
          </div>

          <div class="col-sm-12 col-md-6">
            <label for="country" class="col-form-label"><b>Country<span class="text-danger">*</span></b></label>

            <select name="country" class="form-control form-select" formControlName="country" required id="country">
              <option value="" disabled selected>Select your Country</option>
              <option *ngFor="let country of countryList" value="{{country.name}}" [disabled]="validPinCode">
                {{country.name | titlecase}}</option>
            </select>
            <div *ngIf="
                  billingForm.controls.country.invalid &&
                  (billingForm.controls.country.dirty ||
                    billingForm.controls.country.touched)
                " class="error-message">
              <div *ngIf="billingForm.controls.country.errors?.['required'] ">
                <p class="text-danger m-0 p-0">
                  <small>it's required field.</small>
                </p>
              </div>
            </div>

          </div>


        </div>
      </form>

      <div class="text-end  m-3 submit-btn">
        <button type="button" class="comman-btn1 btn-primary text-light billing-btn" (click)="submit()">
          Submit
        </button>
        <button type="button" class="comman-btn1  ms-2 billing-btn" (click)=" cancel()">Cancel</button>
      </div>

    </div>
  </div>
</div>

<!-- ---------end-------- -->