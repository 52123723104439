import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private router: Router
  ) { }

  getToken() {
    return localStorage.getItem('token');
  }

  userType() {
    const value = localStorage.getItem('isSalesman');
    return value === 'true';
  }

  isSubUser() {
    const value = localStorage.getItem('partnerType');
    return value === 'subUser';
  }

  isSalesman() {
    const value = localStorage.getItem('role');
    return value !== 'relationshipManager';
  }

  logout() {
    // remove user from local storage and set current user to null
    localStorage.clear();
    this.router.navigate(['login'])
  }
  getPaginationText(currentPage: number, itemsPerPage: number, totalItems: number): string {
    const startItem = (currentPage - 1) * itemsPerPage + 1;
    const endItem = Math.min(currentPage * itemsPerPage, totalItems);
    return `Showing ${startItem} to ${endItem} of ${totalItems} records`;
  }
}
