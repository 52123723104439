<!-- ------------New Subscription--page------------ -->
<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-12 col-xl-8 d-flex mb-4 mb-xl-0 heading-section">
            <div><i class="bi bi-arrow-left-short fs-2" (click)="goBack()"></i></div>
            <div>
              <h3 class="font-weight-bold">New Subscription</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- --------------form---------- -->
    <form [formGroup]="subscriptionForm">
      <div class="row mt-4 px-2">
        <div class="col-md-12 grid-margin stretch-card">
          <div class="card">
            <div class="card-body">
              <div class="row" *ngIf="isCustomerSelected">
                <div class="col-md-12">
                  <div class="row mb-3">
                    <label for="addLabel" class="col-sm-2 col-form-label">Customer Name*</label>
                    <div class="col-sm-5 mt-2">
                      <input type="text" class="form-control custom-select" aria-describedby="emailHelp"
                        formControlName="customerName" readonly>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="row" *ngIf="!isCustomerSelected">
        <div class="col-md-12">
         <div class="row mb-3">
          <label for="addLabel" class="col-sm-2 col-form-label">Select Customer*</label>
          <div class="col-sm-5 mt-2">
           <select class="form-select form-control custom-select" formControlName="selectedName"
            id="selected-name-field">
            <option selected disabled value="">Select Customer</option>
            <option [value]="list?._id" *ngFor="let list of customerList">{{list?.email}}</option>
           </select>
          </div>
         </div>
        </div>
       </div> -->

              <div class="row" *ngIf="!isCustomerSelected">
                <div class="col-md-12">
                  <div class="row mb-3">
                    <label for="addLabel" class="col-sm-2 col-form-label">Select Customer*</label>
                    <div class="col-sm-5 mt-2">
                      <div class="dropdown-field custom-select">
                        <div class="dropdown">
                          <p class="down-btn" type="button" id="dropdownMenuButton" data-mdb-toggle="dropdown"
                            aria-expanded="false">
                            {{ selectedEmail ? selectedEmail : 'Select Customer' }}
                            <span class="float-end"><i class="bi bi-chevron-down"></i></span>
                          </p>
                          <ul class="dropdown-menu menu-item-1" aria-labelledby="dropdownMenuButton">
                            <li class="px-3">
                              <input type="text" class="form-control p-2" formControlName="searchText"
                                placeholder="Search here">
                            </li>
                            <div class="customerList">
                              <li *ngFor="let list of customerList | filter: searchText">
                                <a class="dropdown-item" (click)="selectedCustomer(list.email,list._id)">
                                  {{ list.email }}
                                </a>
                              </li>
                            </div>
                          </ul>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12">
                  <div class="row mb-3">
                    <label for="addLabel" class="col-sm-2 col-form-label">Select Product*</label>
                    <div class="col-sm-5 mt-2">
                      <select class="form-select form-control custom-select" formControlName="productName"
                        id="selected-field" (change)="selectedProduct()">
                        <option selected disabled value="">Select Product</option>
                        <option [value]="list?._id" *ngFor="let list of productList">{{list?.name}}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <!-- ------------------ -->
              <!-- <div *ngIf="!(subscriptionForm.controls.customerName.value === '')"> -->
              <!-- ------------plan---section------ -->
              <div>
                <div>
                  <h4>Plan</h4>
                  <hr>
                  <div class="row">
                    <div class="col-md-6">
                      <!-- <div class="row mb-3">
          <label for="addLabel" class="col-sm-5 col-form-label">Select Product*</label>
          <div class="col-sm-7 mt-2">
           <select class="form-select form-control" formControlName="productName">
            <option selected disabled value="">Select Product</option>
            <option value="">demo1</option>
           </select>
          </div>
         </div> -->
                      <div class="row mb-3">
                        <label for="addLabel" class="col-sm-5 col-form-label">Plan Name</label>
                        <div class="col-sm-7 mt-2">
                          <select class="form-select form-control" formControlName="planName" id="selected-plan-field"
                            (change)="selectedPlan()">
                            <option selected disabled value="">Select Plan</option>
                            <option [value]="list?._id" *ngFor="let list of planList">{{list?.name}}</option>
                          </select>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <label for="addLabel" class="col-sm-5 col-form-label">Quantity</label>
                        <div class="col-sm-7 mt-2">
                          <input type="number" class="form-control" aria-describedby="emailHelp"
                            formControlName="planQty" (input)="planAmount()" [readOnly]="planToggle"
                            pattern="[1-9][0-9]*" required>

                          <div *ngIf="subscriptionForm.controls.planQty.invalid && (subscriptionForm.controls.planQty.dirty
             || subscriptionForm.controls.planQty.touched)" class="error-message">
                            <div *ngIf="subscriptionForm.controls.planQty.errors?.['required']">
                              <p class="text-danger m-0 p-0"><small>It is mandatory field</small></p>
                            </div>
                            <div *ngIf="subscriptionForm.controls.planQty.errors?.['pattern']">
                              <p class="text-danger m-0 p-0"><small>Not a valid quantity</small></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <label for="addLabel" class="col-sm-5 col-form-label">Description</label>
                        <div class="col-sm-7 mt-2">
                          <textarea type="text" class="form-control description" aria-describedby="emailHelp"
                            formControlName="planDescription" readonly></textarea>
                        </div>
                      </div>

                    </div>
                    <div class="col-md-6">
                      <div class="row mb-3">
                        <label for="addLabel" class="col-sm-5 col-form-label">Unit Price</label>
                        <div class="col-sm-7 mt-2">
                          <input type="number" class="form-control" aria-describedby="emailHelp"
                            formControlName="planUnitPrice" readonly>
                        </div>
                      </div>

                      <div class="row mb-3">
                        <label for="addLabel" class="col-sm-5 col-form-label">Amount</label>
                        <div class="col-sm-7 mt-2">
                          <input type="number" class="form-control" aria-describedby="emailHelp"
                            formControlName="planAmount" readonly>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- --------------------- -->
                <div *ngIf="showAddOn">
                  <h4>Add-on</h4>
                  <hr>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="row mb-3">
                        <label for="addLabel" class="col-sm-5 col-form-label">Add-on Name</label>
                        <div class="col-sm-7 mt-2">
                          <div class="select-section">
                            <select class="form-select form-control" formControlName="addOnName"
                              id="selected-addOn-field" (change)="selectedAddOn()">
                              <option selected disabled value="">Select Add-on</option>
                              <option [value]="list?._id" *ngFor="let list of addOnList">{{list?.name}}</option>
                            </select>
                          </div>
                        </div>
                      </div>


                      <div class="row mb-3">
                        <label for="addLabel" class="col-sm-5 col-form-label">Quantity</label>
                        <div class="col-sm-7 mt-2">
                          <input type="number" class="form-control" aria-describedby="emailHelp"
                            formControlName="addOnQty" (input)="addOnAmount()" [readOnly]="addOnToggle"
                            pattern="[1-9][0-9]*" required>

                          <div *ngIf="subscriptionForm.controls.addOnQty.touched" class="error-message">
                            <div *ngIf="subscriptionForm.controls.addOnQty.errors?.['required']">
                              <p class="text-danger m-0 p-0"><small>It is mandatory field</small></p>
                            </div>
                            <div *ngIf="subscriptionForm.controls.addOnQty.errors?.['pattern']">
                              <p class="text-danger m-0 p-0"><small>Not a valid quantity</small></p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3">
                        <label for="addLabel" class="col-sm-5 col-form-label">Description</label>
                        <div class="col-sm-7 mt-2 d-flex">
                          <textarea type="text" class="form-control description" aria-describedby="emailHelp"
                            formControlName="addOnDescription" readonly></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row mb-3">
                        <label for="addLabel" class="col-sm-5 col-form-label">Unit Price</label>
                        <div class="col-sm-7 mt-2">
                          <input type="number" class="form-control" aria-describedby="emailHelp"
                            formControlName="addOnUnitPrice" readonly>
                        </div>
                      </div>

                      <div class="row mb-3">
                        <label for="addLabel" class="col-sm-5 col-form-label">Amount</label>
                        <div class="col-sm-7 mt-2">
                          <input type="number" class="form-control" aria-describedby="emailHelp"
                            formControlName="addOnAmount" readonly>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- ------section--------------- -->
                <div class="row">
                  <h4>Subscription Term</h4>
                  <hr>
                  <div class="col-md-6">
                    <!-- <div class="row mb-3">
                        <label for="addLabel" class="col-sm-5 col-form-label">Subscription Code</label>
                        <div class="col-sm-7 mt-2">
                          <input type="text" class="form-control" aria-describedby="emailHelp"
                            formControlName="subscriptionCode" required>
                          <div *ngIf="subscriptionForm.controls.subscriptionCode.invalid && 
            (subscriptionForm.controls.subscriptionCode.dirty
             || subscriptionForm.controls.subscriptionCode.touched)" class="error-message">
                            <div *ngIf="subscriptionForm.controls.subscriptionCode.errors?.['required']">
                              <p class="text-danger m-0 p-0"><small>It is mandatory field</small></p>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    <div class="row mb-3">
                      <label for="addLabel" class="col-sm-5 col-form-label">Reference Id</label>
                      <div class="col-sm-7 mt-2">
                        <input type="text" class="form-control" aria-describedby="emailHelp"
                          formControlName="referenceId">
                      </div>
                    </div>

                    <!-- <div class="row mb-3">
                        <label for="addLabel" class="col-sm-5 col-form-label">Starts On</label>
                        <div class="col-sm-7 mt-2">
                          <input type="date" class="form-control" aria-describedby="emailHelp" formControlName="startOn"
                            required>
  
                          <div *ngIf="subscriptionForm.controls.startOn.invalid && (subscriptionForm.controls.startOn.dirty
              || subscriptionForm.controls.startOn.touched)" class="error-message">
                            <div *ngIf="subscriptionForm.controls.startOn.errors?.['required']">
                              <p class="text-danger m-0 p-0"><small>It is mandatory field</small></p>
                            </div>
                          </div>
                        </div>
                      </div> -->

                    <!-- <div class="row mb-3 ">
                        <div class="col-sm-12 mt-2 ">
                          <input type="checkbox" class="check-field me-2" (click)="toggleAutoRenew()" id="renewCheckBox">
                          <label for="renewCheckBox">Auto Renew Subscription</label>
                        </div>
                      </div> -->
                  </div>
                  <div class="col-md-6">
                    <div class="row mb-3">
                      <label for="addLabel" class="col-sm-5 col-form-label">Sales Person</label>
                      <div class="col-sm-7 mt-2">

                        <select class="form-select form-control" formControlName="salesmanId" required>
                          <option selected disabled value="">Select a salesperson</option>
                          <option value="self">Self</option>
                          <option *ngFor="let salesman of salesmanData" value="{{salesman._id}}">{{salesman.name}}
                          </option>
                        </select>
                        <div *ngIf="subscriptionForm.controls.salesmanId.invalid && (subscriptionForm.controls.salesmanId.dirty
              || subscriptionForm.controls.salesmanId.touched)" class="error-message">
                          <div *ngIf="subscriptionForm.controls.salesmanId.errors?.['required']">
                            <p class="text-danger m-0 p-0"><small>It is mandatory field</small></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="row">
            <label for="addLabel" class="col-sm-5 col-form-label">Expires After</label>
            <div class="col-sm-7 mt-2">
             <p >
               <input type="date" class="form-control description"  
               aria-describedby="emailHelp"  [readOnly]="dateCheck"
               formControlName="expireAfter">
             </p>
             <p class="ms-2">
               <input type="checkbox" class="check-field" (click)="toggleDateInput()"> Never Expires</p>
            </div>
           </div> -->
                    <!-- <div class="row mb-3">
          <label for="addLabel" class="col-sm-5 col-form-label">User limit</label>
           <div class="col-sm-7 mt-2">
            <input type="number" class="form-control"  aria-describedby="emailHelp"
            formControlName="noOfUsers">
           </div>
           </div> -->
                  </div>
                </div>
              </div>
              <!-- ---------end------------ -->
              <div class="row mb-3">
                <!-- <label for="number" class="col-sm-3 col-form-label"><a >+Create New Plan</a></label> -->
                <div class="col-sm-12 mt-2 text-end">
                  <button type="button" class="comman-btn1 btn-primary me-2" (click)="checkDataValidity()">Create
                  </button>
                  <button type="button" class="comman-btn1 btn-secondary text-light" (click)="goBack()">Cancel</button>
                </div>
              </div>
              <!-- -->
            </div>
          </div>
        </div>
      </div>
    </form>
    <!-- ------end------- -->
  </div>
</div>