<!-- -------add---admin----page----- -->
<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-12 col-xl-8 mb-4 mb-xl-0 heading-section">
        <h3 class="font-weight-bold"><i class="bi bi-arrow-left-short fs-2" [routerLink]="['/sales-man']"></i>
          {{isEdit? 'Update Salesman':'Add Salesman'}}
        </h3>
      </div>
    </div>
    <div class="row mt-4 mx-1">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <!-- ----------------- -->
            <form [formGroup]="salesmanForm">
              <div class="row mb-3">

                <div class="col-md-6">
                  <p class="sub">User Details</p>
                  <hr />
                  <div class="row mb-3">
                    <label for="fullName" class="">Full Name</label>
                    <div class="mt-2">
                      <input type="text" class="form-control" formControlName="name">
                      <div *ngIf="salesmanForm.get('name')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                          *ngIf="salesmanForm.get('name')?.errors?.['required'] && (salesmanForm.get('name')?.dirty || salesmanForm.get('name')?.touched)">
                          <p class="text-danger m-0 p-0"><small>Name is mandatory.</small>
                          </p>
                        </div>
                        <div class="error-message" *ngIf="salesmanForm.get('name')?.errors?.['pattern']">
                          <p class="text-danger m-0 p-0"><small>Please enter valid Name.</small></p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="mobile" class="">Mobile Number</label>
                    <div class="mt-2">
                      <input type="text" class="form-control" formControlName="mobile" maxlength="12"/>
                      <div *ngIf="salesmanForm.get('mobile')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                          *ngIf="salesmanForm.get('mobile')?.errors?.['required'] && (salesmanForm.get('mobile')?.dirty || salesmanForm.get('mobile')?.touched)">
                          <p class="text-danger m-0 p-0"><small>mobile is mandatory.</small>
                          </p>
                        </div>
                        <div class="error-message"
                          *ngIf="salesmanForm.get('mobile')?.errors?.['pattern'] ">
                          <p class="text-danger m-0 p-0"><small>Please enter valid Mobile Number.</small></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="email" class="">Email Id</label>
                    <div class="mt-2">
                      <input type="email" class="form-control" formControlName="email">
                      <div *ngIf="salesmanForm.get('email')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                          *ngIf="salesmanForm.get('email')?.errors?.['required'] && (salesmanForm.get('email')?.dirty || salesmanForm.get('email')?.touched)">
                          <p class="text-danger m-0 p-0"><small>Email is mandatory.</small>
                          </p>
                        </div>
                        <div class="error-message" *ngIf="salesmanForm.get('email')?.errors?.['pattern']">
                          <p class="text-danger m-0 p-0"><small>Please enter valid Email Id.</small></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3" [hidden]="isEdit">
                    <label for="password" class="">Password</label>
                    <div class="mt-2 hide-icon">
                      <input type="password" class="form-control" formControlName="password">
                      <div *ngIf="salesmanForm.get('password')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                          *ngIf="salesmanForm.get('password')?.errors?.['required'] && (salesmanForm.get('password')?.dirty || salesmanForm.get('password')?.touched)">
                          <p class="text-danger m-0 p-0"><small>Password is mandatory.</small>
                          </p>
                        </div>
                        <div class="error-message" *ngIf="salesmanForm.get('password')?.errors?.['minlength']">
                          <p class="text-danger m-0 p-0"><small>Password must contain 8 character.</small></p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3" [hidden]="isEdit">
                    <label for="mobile" class="">Confirm Password</label>
                    <div class=" mt-2">
                      <input type="password" class="form-control" formControlName="confirmPassword">
                      <div *ngIf="!passwordMatched || salesmanForm.get('confirmPassword')?.invalid"
                        class="alert mb-0 p-0">
                        <div class="error-message">
                          <span
                            *ngIf="salesmanForm.get('confirmPassword')?.errors?.['required'] && (salesmanForm.get('confirmPassword')?.dirty || salesmanForm.get('confirmPassword')?.touched)"
                            class="text-danger m-0 p-0"><small>Password is mandatory.</small>
                          </span>&nbsp;
                          <span *ngIf="!passwordMatched" class="text-danger m-0 p-0"><small>Password didn't
                              match.</small></span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="fullName" class="">Company Name</label>
                    <div class="mt-2">
                      <input type="text" class="form-control" formControlName="companyName">
                      <div *ngIf="salesmanForm.get('companyName')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                          *ngIf="salesmanForm.get('companyName')?.errors?.['required'] && (salesmanForm.get('companyName')?.dirty || salesmanForm.get('companyName')?.touched)">
                          <p class="text-danger m-0 p-0"><small>Company Name is mandatory.</small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="companyName" class="">Role</label>
                    <div class="mt-2">
                      <select class="form-control" formControlName="role">
                        <option value="salesPerson">Salesperson</option>
                        <option value="salesPersonManager">Salesperson Manager</option>
                        <option value="relationshipManager">Relationship Manager</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-md-6" *ngIf="isEdit">
                  <p class="sub">User Access</p>
                  <hr />
                  <input type="checkbox" class="me-3" (change)="allowAll($event)" [checked]="allSelected" />
                  <label class="lbl mb-0"><b>Allow All</b></label>
                  <p class="mb-0 mt-0 position-relative"> <small class="text-muted ms-4 access">Allow to access all
                      users</small></p>
                  <div class="dotted-line"></div>

                  <div class="position-relative user-class" *ngFor="let list of salesmanData; let i = index">
                    <input type="checkbox" class="me-3" (change)="toggleUserAccess($event, i)"
                      [checked]="list.isSelected" [disabled]="list._id==this.salesmanId" />
                    <label
                      [ngClass]="list._id==this.salesmanId?'text-muted lbl mb-0':'lbl mb-0'"><b>{{list.name}}</b></label>
                    <p class="mb-0 mt-0 position-relative"> <small class="text-muted ms-4 access">{{list.email}}</small>
                    </p>
                  </div>

                </div>
              </div>




              <div class="row mb-3">
                <div class="col-sm-12 mt-2 text-end">
                  <button type="submit" class="comman-btn1 btn-primary" [disabled]="!salesmanForm.valid"
                    (click)="submit()">{{isEdit? 'Update': 'Submit'}}</button>
                  <button type="button" class="comman-btn1 btn-secondary text-light ms-2"
                    [routerLink]="['/sales-man']">Cancel</button>
                </div>
              </div>
            </form>
            <!-- ------------------ -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>