<!-- --------Audit---Admin--page--start-------------------- -->
<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12">
        <div class="row mb-4">
          <div class="col-4">
            <h3 class="font-weight-bold">Audit</h3>
            <span class="text-primary">Admin</span>
          </div>
          <div class="col-8 text-end btn-section">
            <div>
              <button type="button" class="comman-btn1 btn-primary me-2"><i class="bi bi-filetype-csv"></i> Export
                to CSV</button>
            </div>
          </div>
        </div>
        <!-- --------------form----------- -->
        <div class="col-md-12">
          <div class="search-form">
            <p class="light mb-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseExample"
              aria-expanded="false" aria-controls="collapseExample">
              <i class="bi bi-search"></i> <span class="ms-5">Search here</span>
            </p>
            <div class="collapse" id="collapseExample">
              <form class="row g-3 pt-3">
                <div class="col-md-6">
                  <label for="validationServerDatefrom" class="form-label">From</label>
                  <div class="input-group has-validation">
                    <input type="date" class="form-control comman-field" id="validationServerDateTo"
                      aria-describedby="inputGroupPrepend3 validationServerDateToFeedback">
                  </div>
                </div>

                <div class="col-md-6">
                  <label for="validationServerDatefrom" class="form-label">To</label>
                  <div class="input-group has-validation">
                    <input type="date" class="form-control comman-field" id="validationServerDateTo"
                      aria-describedby="inputGroupPrepend3 validationServerDateToFeedback">
                  </div>
                </div>

                <div class="col-md-6">
                  <label for="validationServer04" class="form-label">Admin</label>
                  <select class="form-select" id="validationServer04" aria-describedby="validationServer04Feedback">
                    <option selected disabled value="">All</option>
                    <option>Home</option>
                    <option>Business</option>
                    <option>DeepFreez</option>
                  </select>

                  <div id="validationServer04Feedback" class="invalid-feedback">
                    Please select a valid field.
                  </div>
                </div>

                <div class="col-md-6">
                  <label for="validationServer04" class="form-label">Action</label>
                  <select class="form-select" id="validationServer04" aria-describedby="validationServer04Feedback">
                    <option selected disabled value="">All</option>
                    <option>Add Account</option>
                    <option>Activate Account</option>
                    <option>Delete Account</option>
                    <option>Change Company Name</option>
                    <option>Add Plan</option>
                    <option>Delete Plan</option>
                    <option>Rename Plan</option>
                    <option>Top Up Account</option>
                  </select>
                </div>
                <div class="col-12 text-end">
                  <button class="comman-btn1 btn-primary me-2" type="search">Search</button>
                  <button class="comman-btn1 btn-secondary text-light" type="reset">Reset</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!-- -----table------------- -->
      <div class="row table-section mt-3 pe-0">
        <div class="col-md-12 grid-margin stretch-card pe-0">
          <div class="card">
            <div class="card-body">
              <p class="card-title mb-0">Record</p>
              <div class="table-responsive">
                <table class="table table-striped table-borderless">
                  <thead>
                    <tr>
                      <th scope="col">Date & Time</th>
                      <th scope="col">Actor</th>
                      <th scope="col">Action</th>
                      <th scope="col">Target</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Tue, 07 Feb 2023 10:24:00 GMT</td>
                      <td>Piyush (piyush@algogenix.com)</td>
                      <td>Add Account</td>
                      <td>vikas2@algogenix.com</td>
                    </tr>
                    <tr>
                      <td>Tue, 07 Feb 2023 10:24:00 GMT</td>
                      <td>Piyush (piyush@algogenix.com)</td>
                      <td>Add Account</td>
                      <td>vikas2@algogenix.com</td>
                    </tr>
                    <tr>
                      <td>Tue, 07 Feb 2023 10:24:00 GMT</td>
                      <td>Piyush (piyush@algogenix.com)</td>
                      <td>Add Account</td>
                      <td>vikas2@algogenix.com</td>
                    </tr>
                    <tr>
                      <td>Tue, 07 Feb 2023 10:24:00 GMT</td>
                      <td>Piyush (piyush@algogenix.com)</td>
                      <td>Add Account</td>
                      <td>vikas2@algogenix.com</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- - -->