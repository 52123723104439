import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class MrrService {

  constructor(private http: HttpClient) { }

  mrrMovement(mrrReportPlayload: any) {
    return this.http.post(`${environment.apiURL}/partner/mrr/report `, mrrReportPlayload)
  }
  mrrGrowth(mrrGrowthPlayload: any) {
    return this.http.post(`${environment.apiURL}/partner/mrr/growth`, mrrGrowthPlayload)
  }
  mrrDetails(mrrDetailsPlayload: any) {
    return this.http.post(`${environment.apiURL}/partner/mrr/detail`, mrrDetailsPlayload)
  }

  salespersonMrrReport(salesPersonMrrPayload: any) {
    return this.http.get(`${environment.apiURL}/partner/mrr/salesPersonReport?search=${salesPersonMrrPayload}`)
  }

  salespersonSubscriptionReport(salesPersonSubPayload: any) {
    return this.http.post(`${environment.apiURL}/partner/subscription/salesPerson/report`, salesPersonSubPayload)
  }

  relationshipManagerMrrReport(relationshipManagerMrrPayload: any) {
    return this.http.get(`${environment.apiURL}/partner/mrr/relationshipManagerReport?search=${relationshipManagerMrrPayload}`)
  }

  relationshipManagerSubscriptionReport(relationshipManagerSubpayload: any) {
    return this.http.post(`${environment.apiURL}/partner/subscription/rm/report`, relationshipManagerSubpayload)
  }
}
