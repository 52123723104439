import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { UserService } from 'src/app/services/user.service';
@Component({
  selector: 'app-expire',
  templateUrl: './expire.component.html',
  styleUrls: ['./expire.component.scss']
})
export class ExpireComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
    private userService: UserService,
    private subscriptionService: SubscriptionsService,
    private toastr: ToastrService,
  ) { }

  @Input() subscriptionId!: string
  @Input() autoRenew !: boolean
  event: EventEmitter<any> = new EventEmitter();
  ngOnInit(): void {
  }

  closedModal() {
    this.bsModalRef.hide();
  }

  setForRenew() {
    this.subscriptionService.setForRenew(this.subscriptionId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message)
        this.event.emit('ok')
        this.closedModal()
        console.log(this.autoRenew)
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }
}
