<!-- -------add--roles--page-- -->
<div class="container-scroller">
    <div class="main-panel">
     <div class="content-wrapper">
      <div class="row">
       <div class="col-md-12 grid-margin">
        <!-- <div class="row">
         <div class="col-md-4">
          <h3 class="font-weight-bold">Add Roles & Permissions</h3>
         </div>
        </div> -->
        <div class="row">
          <div class="col-12 col-xl-8 mb-4 mb-xl-0 heading-section">
            <h3 class="font-weight-bold"><i class="bi bi-arrow-left-short fs-2" [routerLink]="['/roles-permissions']"></i>
              Add Roles & Permissions
          </h3>
          </div>
        </div>
       </div>
       <!-- ------------------ -->
       <div class="row mb-3 pe-0 mx-1">
        <label for="fullName" class="col-sm-2 col-form-label">Role Name</label>
        <div class="col-sm-10 mt-2">
         <input type="text" class="form-control" id="fullName" formControlName="name">
         <!-- <div *ngIf="productForm.get('name')?.invalid" class="alert mb-0 p-0">
                           <div class="error-message"
                             *ngIf="productForm.get('name')?.errors?.['required'] && (productForm.get('name')?.dirty || productForm.get('name')?.touched)">
                             <p class="text-danger m-0 p-0"><small>Name is mandatory.</small>
                             </p>
                           </div>
                           <div class="error-message" *ngIf="productForm.get('name')?.errors?.['pattern']">
                             <p class="text-danger m-0 p-0"><small>Please enter valid Name.</small></p>
                           </div>
                         </div> -->
        </div>
       </div>
       <!-- -----------table-section-------------- -->
       <div class="row table-section pe-0">
        <div class="col-md-12 grid-margin stretch-card pe-0">
         <div class="card">
          <div class="card-body">
           <div class="table-responsive">
            <table class="table table-striped table-borderless">
             <thead>
              <tr>
               <th>Name</th>
               <th class="text-center">Create</th>
               <th class="text-center">Update</th>
               <th class="text-center">Delete</th>
               <th class="text-center">View</th>
              </tr>
             </thead>
             <tbody>
              <tr>
               <td>Demo1</td>
               <td class="text-center"> <input type="checkbox"> </td>
               <td class="text-center"><input type="checkbox"> </td>
               <td class="text-center"><input type="checkbox"> </td>
               <td class="text-center"><input type="checkbox"></td>
              </tr>
              <tr>
               <td>Demo1</td>
               <td class="text-center"> <input type="checkbox"> </td>
               <td class="text-center"><input type="checkbox"> </td>
               <td class="text-center"><input type="checkbox"> </td>
               <td class="text-center"><input type="checkbox"></td>
              </tr>
              <tr>
               <td>Demo1</td>
               <td class="text-center"> <input type="checkbox"> </td>
               <td class="text-center"><input type="checkbox"> </td>
               <td class="text-center"><input type="checkbox"> </td>
               <td class="text-center"><input type="checkbox"></td>
              </tr>
              <tr>
               <td>Demo1</td>
               <td class="text-center"> <input type="checkbox"> </td>
               <td class="text-center"><input type="checkbox"> </td>
               <td class="text-center"><input type="checkbox"> </td>
               <td class="text-center"><input type="checkbox"></td>
              </tr>
             </tbody>
            </table>
           </div>
          </div>
         </div>
        </div>
       </div>
       <!-- ----end------- -->
      </div>
     </div>
    </div>