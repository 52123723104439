import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PlanService } from 'src/app/services/plan.service';
import { DeleteModalComponent } from '../delete-modal/delete-modal.component';

@Component({
  selector: 'app-plan',
  templateUrl: './plan.component.html',
  styleUrls: ['./plan.component.scss']
})
export class PlanComponent implements OnInit {

  plan: any = [];
  bsModalRef!: BsModalRef;
  constructor(
    private planService: PlanService,
    private activateRoute: ActivatedRoute,
    private router: Router,
    private modalService: BsModalService,
  ) { }

  ngOnInit(): void {
    this.planList();

  }

  planList() {
    this.planService.planList('').subscribe((res: any) => {
      if (res.success) {
        this.plan = res.plans;
      }
    })
  }

  editPlan(planId: string) {
    this.router.navigate(['add-plan'], { queryParams: { planId: planId } });
  }

  openModal(planId: string) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.planId = planId;
    this.bsModalRef.content.title = 'Delete'
    this.bsModalRef.content.message = 'Are you sure you want to delete this?'
    this.bsModalRef.content.confirmText = 'Yes'
    this.bsModalRef.content.cancelText = 'Cancel'
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.planList();
      }
      else {
        this.planList();
      }
    })
  }

}
