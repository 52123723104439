import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import {Inject,LOCALE_ID } from '@angular/core';
import { DATE_PIPE_DEFAULT_TIMEZONE } from '@angular/common';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-expiry-date',
  templateUrl: './edit-expiry-date.component.html',
  styleUrls: ['./edit-expiry-date.component.scss'],
  providers: [DatePipe]
})
export class EditExpiryDateComponent implements OnInit {

  constructor(
    public bsModalRef: BsModalRef,
    private user : UserService,
    private toastr : ToastrService,
    private datePipe: DatePipe,
    @Inject(LOCALE_ID) public locale: string,
  ) { 
    
  }

  planDate! : Date;
  currentDate! : string;
  // newDate! : Date ;
  // newSelectedDate = moment();

  selectedDate : any;
  newDate : any;


  ngOnInit() {
    setTimeout(() => {
      // console.log('userId is:',this.partnerId);
      this.getDetails()
        });
  }

  changeDateForm = new FormGroup({
    newDate : new FormControl('')
  })

  event: EventEmitter<any> = new EventEmitter();

  @Input() partnerId!: string;

  closedModal() {
    this.bsModalRef.hide();
  }

  getDetails(){
    
    this.user.userDetails(this.partnerId).subscribe((res:any)=>{
      if(res.success){ 
        this.planDate = res.customer.plan.endDate 
        const date = formatDate(this.planDate, 'yyyy-MM-dd', this.locale);          
        this.changeDateForm.controls.newDate.patchValue(date)
      }
    })
  }

  

  changePlanEndDate(){
    let payload = {
      customerId : this.partnerId,
      planEndDate : this.changeDateForm?.controls?.newDate?.value
    }      

    let todayDate = new Date()
    // this.currentDate = formatDate(todayDate, 'yyyy-MM-dd', this.locale);

    
    this.selectedDate = this.changeDateForm.get('newDate')?.value;
    this.newDate = new Date(this.selectedDate)  

    console.log(todayDate , "   ", this.newDate)


    if(todayDate.getDate() > this.newDate.getDate() ){
      this.toastr.warning("New date must be greater than current date")
    }
    else{
      this.user.changeExpiryDate(payload).subscribe((res:any) =>{
        if(res.success){
            
            this.event.emit('ok');
            this.closedModal();
            this.toastr.info("Plan expiry date updated")
        }
      })
    }

   
  }

}
