<div class="container-scroller">
    <div class="main-panel">
        <div class="content-wrapper">
            <div class="row">
                <div class="col-md-12">
                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <p class="heading-txt mb-4">Subject: {{ticketDetails?.subject}}</p>
                                <div class="position-relative" *ngFor="let msg of ticketDetails?.messages">
                                    <i class="bi bi-send fs-5 send"></i>
                                    <ul class="msg">
                                        <li><small>{{msg.createdAt | date:'dd MMM , y - h:mm a'}}</small></li>
                                        <li><small>{{msg.text}}</small></li>
                                        <li><b>{{msg.createdBy}}</b></li>
                                    </ul>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-12 mt-3 mb-4" *ngIf="ticketDetails.status.id==1 || ticketDetails.status.id==2">
                    <div class="row">
                        <div class="card">
                            <div class="card-body">
                                <form [formGroup]="messageForm">
                                    <div>
                                        <p class="mb-2 text-muted">
                                            Reply
                                        </p>
                                        <textarea class="w-100 reply" rows="5" title="Please fill in this field"
                                            formControlName="message"
                                            [class.is-invalid]="messageForm.get('message')?.invalid && messageForm.get('message')?.touched"></textarea>
                                            <p><span class="notes">Note: </span>Once you reply, customer will also receive email</p>
                                    </div>
                                    <div class="mt-3">
                                        <p class="mb-2 text-muted">Support Status</p>
                                        <select class="form-select " aria-label=".form-select-sm example"
                                            formControlName="status">
                                            <option value="open">Open</option>
                                            <option value="inProgress">In Progress</option>
                                            <option value="resolved">Resolved</option>
                                            <option value="closed">Closed</option>
                                        </select>
                                    </div>
                                    <button class="btns mt-3" (click)="reply()">Reply</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>