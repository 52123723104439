import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { PaymentService } from 'src/app/services/payment.service';
import { SubscriptionsService } from 'src/app/services/subscriptions.service';
import { environment } from 'src/environments/environment';
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-renew-confirmation',
  templateUrl: './renew-confirmation.component.html',
  styleUrls: ['./renew-confirmation.component.scss']
})
export class RenewConfirmationComponent implements OnInit {
  @Input() manual!: string
  @Input() subCode!: string;
  @Input() subscriptionId!: string;
  @Input() paymentPayload!: any
  @Input() currentAmount!: any
  paymentData: any
  dueAmount: any

  redirectUrl: string = environment.redirectUrl
  event: EventEmitter<any> = new EventEmitter();
  constructor(
    public bsModalRef: BsModalRef,
    private subscriptionService: SubscriptionsService,
    private toastr: ToastrService,
    private paymentService: PaymentService,
  ) { }

  transForm = new FormGroup({
    trackingId: new FormControl('', Validators.required),
    paidAmount: new FormControl('', [Validators.pattern('^[0-9]+[.]?[0-9]*$'), Validators.required]),
    dateTime: new FormControl(''),
    remark: new FormControl('')
  })

  ngOnInit(): void {
    this.transForm.get('paidAmount')?.valueChanges.subscribe((value: any) => {
      this.dueAmount = this.currentAmount - Number(value)
      if (this.dueAmount > 0) {
        this.transForm.controls.remark.setValidators([Validators.required])
        this.transForm.controls.remark.updateValueAndValidity()
      } else {
        this.transForm.controls.remark.clearValidators()
        this.transForm.controls.remark.updateValueAndValidity()
      }
    })

  }

  renewSubscription() {
    this.subscriptionService.renewSubscription(this.subscriptionId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.message)
        this.closedModal()
        this.event.emit('ok');
      }
    }, (err) => {
      this.toastr.error(err.error.message)
    })
  }
  payment() {
    let payload = { ...this.paymentPayload }
    payload['trackingId'] = this.transForm.controls.trackingId.value
    payload['paidAmount'] = this.transForm.controls.paidAmount.value
    payload['dateTime'] = this.transForm.controls.dateTime.value
    payload['remark'] = this.transForm.controls.remark.value
    let _key = CryptoJS.enc.Utf8.parse(environment.workingKey);
    let _iv = CryptoJS.enc.Utf8.parse(environment.workingKey);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(payload), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });

    this.paymentService.manualPayment({ encrypted: encrypted.toString() }).subscribe((res: any) => {
      if (res.success) {
        this.paymentData = res?.paymentData
      }
    }, (error) => {
      this.toastr.error(error.error.message)
    })
  }

  closedModal() {
    this.bsModalRef.hide();
  }

  submit() {
    if (!this.transForm.valid) {
      this.transForm.markAllAsTouched()
    } else {
      if (Number(this.transForm.get("paidAmount")?.value) > this.currentAmount) {
        this.toastr.error("Paid amount should be less than or equal to total amount")
      } else {
        this.payment();
      }
    }
  }

  get getPendingAmount() {
    const value = this.transForm.get('paidAmount')?.value
    return (this.currentAmount - Number(value))
  }

}
